import { Box, spacing, Table, TableProps } from '@alohi/flow-ui'
import { FaxPlusNumberCartItem } from 'api/gql/generated/graphql'
import { useActiveFaxSubscription } from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { AcquiredOn } from './components/AcquiredOn'
import { FaxNumber } from './components/FaxNumber'

export interface NumbersTableProps {
  className?: string
  items: FaxPlusNumberCartItem[]
}

export const NumbersTable: React.FC<NumbersTableProps> = ({ className, items }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { activeFaxSubscription } = useActiveFaxSubscription()
  const activeFaxSubscriptionNumbers = activeFaxSubscription.computed.numbers

  const headerItems: TableProps['headerItems'] = useMemo(
    () => [
      {
        label: t('faxNumber.faxNumber'),
        className: classes.faxNumberHeader,
      },
      {
        label: t('faxNumber.acquiredOn'),
      },
    ],
    [classes.faxNumberHeader, t]
  )

  const rowItems: TableProps['rowItems'] = useMemo(
    () =>
      items.map(
        (item) =>
          ({
            cells: [
              {
                label: item.number,
                item: <FaxNumber formattedNumber={item.number} />,
              },
              {
                label: 'Custom number',
                item: (
                  <AcquiredOn
                    acquiredOn={
                      activeFaxSubscriptionNumbers.find(
                        (activeFaxSubscriptionNumber) =>
                          activeFaxSubscriptionNumber.number === item.number
                      )?.acquiredOn
                    }
                  />
                ),
              },
            ],
          }) satisfies TableProps['rowItems'][number]
      ),
    [activeFaxSubscriptionNumbers, items]
  )

  return (
    <Box className={cx(classes.base, className)}>
      <Table headerItems={headerItems} rowItems={rowItems} rowsPerPage={5} />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  faxNumberHeader: {
    marginLeft: spacing['24'],
  },
}))
