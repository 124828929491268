import {
  Box,
  CountryCode,
  FormContextProvider,
  Helper,
  Link,
  LogoFrame,
  SectionDivider,
  spacing,
  StepDivider,
  Text,
  Tooltip,
  useDependencyChange,
  useOnMount,
} from '@alohi/flow-ui'
import {
  FaxNumberChannel,
  FaxPlusNumberSubscriptionItem,
  InitFlowFaxUpgradeDocument,
  Period,
  PlanType,
} from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { EffectiveDateSection } from 'components/common/EffectiveDateSection'
import { FlowLayout } from 'components/common/FlowLayout/FlowLayout/FlowLayout'
import { PaymentMethodSection } from 'components/common/PaymentMethodSection/PaymentMethodSection'
import { useCartApi } from 'contexts/cart/api'
import { PRODUCTS } from 'helpers/common'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useFaxNumberRequirementsCompliance } from 'hooks/useFaxNumberRequirementsCompliance/useFaxNumberRequirementsCompliance'
import { useInitFlow } from 'hooks/useInitFlow/useInitFlow'
import { FaxShopItemPlanType, useShopItems } from 'hooks/useShopItems/useShopItems'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { CurrentFaxNumberSection } from 'views/fax/FaxPlanUpgrade/components/CurrentFaxNumberSection/CurrentFaxNumberSection'

import { verifyQueryParam, websiteUrls } from 'helpers/urls'
import { FaxCartItemPlanType } from 'hooks/useCart/useCart'
import { FaxNumbersFailedModal } from 'modals/fax/FaxNumbersFailedModal'
import { FaxingDestinationRadioSection } from './components/FaxingDestinationRadioSection/FaxingDestinationRadioSection/FaxingDestinationRadioSection'
import { FaxPlanSelectSection } from './components/FaxPlanSelectSection/FaxPlanSelectSection'
import { PlanRequiredNumberSection } from './components/PlanRequiredNumberSection/PlanRequiredNumberSection'
import { CustomHighVolumePlanModal } from './components/RequiredPagesSection/modals/CustomHighVolumePlanModal/CustomHighVolumePlanModal'
import { RequiredPagesSection } from './components/RequiredPagesSection/RequiredPagesSection'
import { useViewApi } from './context/api'
import { ViewContextProvider } from './context/ViewContextProvider'
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics/useGoogleAnalytics'

export const FaxPlanUpgrade: React.FC = () => {
  const { isFlowReady } = useInitFlow(InitFlowFaxUpgradeDocument)
  if (!isFlowReady) return
  return (
    <FormContextProvider autoScroll>
      <ViewContextProvider>
        <FaxPlanUpgradeContent />
      </ViewContextProvider>
    </FormContextProvider>
  )
}

export const FaxPlanUpgradeContent: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { gtmCartInit } = useGoogleAnalytics()

  const { checkRequirementsCompliance } = useFaxNumberRequirementsCompliance()

  const {
    queryParams: { faxPlan },
  } = useRoutesApi()
  const queryParamPlanId = faxPlan.planId
  const queryParamIsHighVolume = faxPlan.isHighVolume
  const queryParamPlanType = faxPlan.planType
  const queryParamPeriod = faxPlan.period
  const queryParamShowAllPlans = faxPlan.showAllPlans
  const queryParamDiscountCode = faxPlan.discountCode

  const { activeFaxSubscription } = useActiveSubscriptions()
  const firstActiveSubscriptionNumber: FaxPlusNumberSubscriptionItem | undefined =
    activeFaxSubscription.computed.numbers[0]
  const secondActiveSubscriptionNumber: FaxPlusNumberSubscriptionItem | undefined =
    activeFaxSubscription.computed.numbers[1]

  const {
    cart,
    computedItems: { faxPlanCartItem, faxNumberCartItems },
  } = useCartApi()
  const hasCorporateSettings = faxPlanCartItem?.computed.type === FaxCartItemPlanType.CORPORATE

  const { faxPlans } = useShopItems()
  const defaultSignPlusPlan = faxPlans.find((faxPlan) => faxPlan.default)
  const queryParamsFaxPlanPerId = faxPlans.find((faxPlan) => faxPlan.id === queryParamPlanId)
  const queryParamsFaxPlanPerTypes = faxPlans.find(
    (faxPlan) =>
      verifyQueryParam(queryParamPlanType, faxPlan.planType) &&
      verifyQueryParam(
        queryParamPeriod ?? activeFaxSubscription.computed.planItem.period,
        faxPlan.period
      )
  )
  const queryParamsFaxPlanPerHighVolume = faxPlans.find(
    (faxPlan) => queryParamIsHighVolume && faxPlan.highVolume && faxPlan.default
  )
  const queryParamsFaxPlan =
    queryParamsFaxPlanPerId ?? queryParamsFaxPlanPerTypes ?? queryParamsFaxPlanPerHighVolume

  const requestedShopItem = useMemo(() => {
    if (queryParamsFaxPlan) {
      const isQueryParamValid = !queryParamsFaxPlan.notAvailable

      // If the query param plan is the same as the active subscription one,
      // we skip to the default plan sent by the BE (next suggested cart)
      if (isQueryParamValid) {
        return queryParamsFaxPlan
      }
    }

    return defaultSignPlusPlan
  }, [defaultSignPlusPlan, queryParamsFaxPlan])

  const {
    store: {
      selectedFaxPlanShopItemId,
      selectedSecondFaxNumberCartInput,
      purchaseNumbersErrorExtensions,
      selectedFirstFaxNumberCartInput,
    },
    selectedFaxPlanShopItem,
    submit,
    updateStore,
    setPlanCart,
    setCart,
  } = useViewApi()

  // Number of Fax Numbers needed for the selected plan
  const numbersRequiredForSelectedPlan = selectedFaxPlanShopItem
    ? selectedFaxPlanShopItem.numbersIncluded - activeFaxSubscription.computed.numbers.length
    : 0

  // First Required number is already in active subscription
  const hasFirstRequiredNumberActive = Boolean(firstActiveSubscriptionNumber?.id)
  // Second Required number is already in active subscription
  const hasSecondRequiredNumberActive = Boolean(secondActiveSubscriptionNumber?.id)
  // If a new First Required number needs to be added to the cart
  const requiresFirstNumber = activeFaxSubscription.computed.numbers.length === 0
  // If a new Second Required number needs to be added to the cart
  const requiresSecondNumber =
    selectedFaxPlanShopItem?.computed.type === FaxShopItemPlanType.CORPORATE &&
    numbersRequiredForSelectedPlan > 0 &&
    (Boolean(faxNumberCartItems?.length) || hasFirstRequiredNumberActive)

  // Display Plan Section
  const hasPlanSection = !(
    activeFaxSubscription.computed.planItem.highVolume &&
    activeFaxSubscription.period === Period.Annual
  )
  // Display Effective Date section
  const hasEffectiveDateSection =
    Boolean(activeFaxSubscription.expiresAt) &&
    Boolean(activeFaxSubscription.computed.planItem.highVolume)
  // Display First Number section (current number or new number)
  const hasFirstNumberSection = requiresFirstNumber || requiresSecondNumber
  // Display Second Number section (current number or new number)
  const hasSecondNumberSection = !hasSecondRequiredNumberActive && requiresSecondNumber
  // Display High Volume sections
  const hasFaxingDestinationRadioSection = Boolean(selectedFaxPlanShopItem?.highVolume)
  const hasRequiredPagesSection = hasFaxingDestinationRadioSection
  // Display Payment Method Section
  const hasPaymentMethodSection = !(cart.total === 0 && Boolean(cart.creditUsed))

  // Plan Selection section index
  const planSectionIndex = hasPlanSection ? 1 : 0
  // High Volume Faxes section index
  const faxingDestinationRadioIndex = hasFaxingDestinationRadioSection
    ? planSectionIndex + 1
    : planSectionIndex
  const requiredPagesSectionIndex = hasRequiredPagesSection
    ? faxingDestinationRadioIndex + 1
    : faxingDestinationRadioIndex
  // Effective Date section index
  const effectiveDateSectionIndex = hasEffectiveDateSection
    ? requiredPagesSectionIndex + 1
    : requiredPagesSectionIndex
  // First Required number section index (current number or new number)
  const firstNumberSectionIndex = hasFirstNumberSection
    ? effectiveDateSectionIndex + 1
    : effectiveDateSectionIndex
  // Second Required number section index (current number or new number)
  const secondNumberSectionIndex = hasSecondNumberSection
    ? firstNumberSectionIndex + 1
    : firstNumberSectionIndex
  // Payment Details section index
  const paymentDetailSectionIndex = secondNumberSectionIndex + 1

  const faxAvailableHighVolumeAnnualPlans = useMemo(
    () =>
      faxPlans.filter(
        (faxPlan) => faxPlan.highVolume && !faxPlan.notAvailable && faxPlan.period === Period.Annual
      ),
    [faxPlans]
  )

  const shouldDisplayCustomHighVolumePlanModal =
    activeFaxSubscription.computed.planItem.highVolume &&
    faxAvailableHighVolumeAnnualPlans.length === 0

  const Label = useMemo(
    () => (
      <Box className={classes.tooltipContent}>
        <Text variant="small" className={classes.tooltipDesc}>
          {t('faxNumber.helperDescription')}
        </Text>
        <Link
          variant="small"
          color="light"
          className={classes.link}
          withIcon
          hasBullet
          isExternal
          href={websiteUrls.fax.help.contactSales}
        >
          {t('faxNumber.helperLink')}
        </Link>
      </Box>
    ),
    [classes.link, classes.tooltipContent, classes.tooltipDesc, t]
  )

  const retry = async () => {
    updateStore({
      purchaseNumbersErrorExtensions: undefined,
    })
    try {
      if (selectedFaxPlanShopItemId && selectedFirstFaxNumberCartInput) {
        await setCart({
          selectedFaxPlanShopItemId: selectedFaxPlanShopItemId,
          firstFaxNumberCartInput: {
            ...selectedFirstFaxNumberCartInput,
            number: null,
          },
          secondFaxNumberCartInput: selectedSecondFaxNumberCartInput
            ? {
                ...selectedSecondFaxNumberCartInput,
                number: null,
              }
            : undefined,
        })
      }
    } catch {
      // Nothing to do
    }
  }

  // Create a cart with the appropriate initial plan
  useOnMount(() => {
    if (!requestedShopItem) return

    updateStore({
      selectedFaxPlanShopItemId: requestedShopItem.id,
    })
  })

  // Send event to GA
  useOnMount(() => {
    if (faxPlanCartItem) {
      gtmCartInit({
        faxPlan: {
          planType: faxPlanCartItem.planType,
          period: faxPlanCartItem.period,
          highVolumePages: faxPlanCartItem.pages,
        },
        faxNumbers: {
          totalCount: faxNumberCartItems?.length ?? 0,
          customCount:
            faxNumberCartItems?.filter(
              (faxNumberCartItem) => faxNumberCartItem.channel === FaxNumberChannel.Custom
            )?.length ?? 0,
        },
        totalPrice: cart.total,
      })
    }
  }, Boolean(faxPlanCartItem?.id))

  useDependencyChange(() => {
    if (!selectedFaxPlanShopItem) return
    if (selectedFaxPlanShopItem.id === faxPlanCartItem?.shopItemId) return

    const firstNumberType =
      firstActiveSubscriptionNumber?.type ?? selectedFirstFaxNumberCartInput?.numberType
    const firstNumberIso = (firstActiveSubscriptionNumber?.iso ??
      selectedFirstFaxNumberCartInput?.iso) as CountryCode

    // If no first number in the cart, we just check
    if (
      !faxPlanCartItem?.id ||
      (firstNumberIso &&
        firstNumberType &&
        checkRequirementsCompliance({
          country: firstNumberIso,
          numberType: firstNumberType,
          planType: selectedFaxPlanShopItem.planType,
        }))
    ) {
      setPlanCart(selectedFaxPlanShopItem, undefined, { discountCode: queryParamDiscountCode })
    } else {
      const compatiblePlan = faxPlans?.find(
        (faxPlan) =>
          faxPlan.planType !== PlanType.Basic &&
          faxPlan.period === selectedFaxPlanShopItem.period &&
          !faxPlan.notAvailable
      )
      if (!compatiblePlan) return

      updateStore({
        selectedFaxPlanShopItemId: compatiblePlan.id,
      })
    }
  }, [selectedFaxPlanShopItem?.id])

  return (
    <FlowLayout
      className={classes.base}
      header={
        <SectionDivider iconFrame={<LogoFrame variant="fax" />}>
          <Text variant="h3" className={classes.title}>
            {hasFaxingDestinationRadioSection
              ? t('purchase.highVolumeFaxing')
              : t('purchase.productPlan', {
                  value_1: PRODUCTS.FAX,
                })}
          </Text>
          {hasFaxingDestinationRadioSection ? (
            <Tooltip label={Label} className={classes.tooltip}>
              <Helper variant="large" />
            </Tooltip>
          ) : null}
        </SectionDivider>
      }
      onProcess={submit}
    >
      {hasPlanSection ? (
        <>
          <StepDivider className={classes.firstStepDivider} index={planSectionIndex}>
            <Text isBold>{t('purchase.yourPlan')}</Text>
          </StepDivider>
          <FaxPlanSelectSection autoOpenPlans={queryParamShowAllPlans} />
        </>
      ) : null}
      {hasFaxingDestinationRadioSection ? (
        <>
          <StepDivider
            className={hasPlanSection ? classes.stepDivider : classes.firstStepDivider}
            index={faxingDestinationRadioIndex}
          >
            <Text isBold>{t('purchase.faxingDestination')}</Text>
          </StepDivider>
          <FaxingDestinationRadioSection />
        </>
      ) : null}
      {hasRequiredPagesSection ? (
        <>
          <StepDivider className={classes.stepDivider} index={requiredPagesSectionIndex}>
            <Text isBold>{t('purchase.requiredPagesPerMonth')}</Text>
          </StepDivider>
          <RequiredPagesSection />
        </>
      ) : null}
      {hasEffectiveDateSection ? (
        <>
          <StepDivider className={classes.stepDivider} index={effectiveDateSectionIndex}>
            <Text isBold>{t('purchase.effectiveDate')}</Text>
          </StepDivider>
          <EffectiveDateSection expiresAt={activeFaxSubscription.expiresAt} />
        </>
      ) : null}
      <Box className={classes.container}>
        {hasFirstNumberSection ? (
          hasFirstRequiredNumberActive ? (
            <>
              <StepDivider className={classes.stepDivider} index={firstNumberSectionIndex}>
                <Text isBold>
                  {hasSecondNumberSection
                    ? t('faxNumber.yourFirstCurrentFaxNumber')
                    : t('faxNumber.yourCurrentFaxNumber')}
                </Text>
              </StepDivider>
              <CurrentFaxNumberSection id={firstActiveSubscriptionNumber.id} />
            </>
          ) : requiresFirstNumber ? (
            <>
              <StepDivider className={classes.stepDivider} index={firstNumberSectionIndex}>
                <Text isBold>
                  {hasSecondNumberSection
                    ? t('faxNumber.firstFaxNumber')
                    : t('faxNumber.yourFaxNumber')}
                </Text>
              </StepDivider>
              <PlanRequiredNumberSection idx={0} />
            </>
          ) : null
        ) : null}

        {hasSecondNumberSection ? (
          hasSecondRequiredNumberActive ? (
            <>
              <StepDivider className={classes.stepDivider} index={secondNumberSectionIndex}>
                <Text isBold>
                  {hasSecondNumberSection
                    ? t('faxNumber.yourSecondCurrentFaxNumber')
                    : t('faxNumber.yourCurrentFaxNumber')}
                </Text>
              </StepDivider>
              <CurrentFaxNumberSection id={secondActiveSubscriptionNumber.id} />
            </>
          ) : requiresSecondNumber ? (
            <>
              <StepDivider className={classes.stepDivider} index={secondNumberSectionIndex}>
                <Text isBold>{t('faxNumber.secondFaxNumber')}</Text>
              </StepDivider>
              <PlanRequiredNumberSection idx={1} />
            </>
          ) : null
        ) : null}

        {hasPaymentMethodSection ? (
          <>
            <StepDivider className={classes.stepDivider} index={paymentDetailSectionIndex}>
              <Text isBold>{t('payment.paymentDetails')}</Text>
            </StepDivider>
            <PaymentMethodSection hasCorporateSettings={hasCorporateSettings} />
          </>
        ) : null}
      </Box>
      {shouldDisplayCustomHighVolumePlanModal ? (
        <CustomHighVolumePlanModal
          isInternational={activeFaxSubscription?.computed.planItem.isInternational}
        />
      ) : null}
      {purchaseNumbersErrorExtensions ? (
        <FaxNumbersFailedModal
          purchaseNumbersErrorExtensions={purchaseNumbersErrorExtensions}
          onConfirm={retry}
        />
      ) : null}
    </FlowLayout>
  )
}
const useStyles = tss.create(() => ({
  base: {
    //
  },
  firstStepDivider: {
    marginBottom: spacing['24'],
  },
  stepDivider: {
    marginTop: spacing['48'],
    marginBottom: spacing['24'],
  },
  container: {
    position: 'relative',
    zIndex: 0,
  },
  title: {
    flexGrow: 1,
  },
  tooltip: {
    maxWidth: '300px',
  },
  tooltipContent: {
    padding: spacing['16'],
  },
  tooltipDesc: {
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '350px',
  },
  link: {
    marginTop: spacing['8'],
    textWrap: 'nowrap',
    marginLeft: spacing['4'],
  },
}))
