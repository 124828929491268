import {
  FormContextProvider,
  LogoFrame,
  SectionDivider,
  spacing,
  StepDivider,
  Text,
  useOnMount,
} from '@alohi/flow-ui'
import { InitFlowSignUpgradeDocument, SignPlanPurchaseFlowType } from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { FlowLayout } from 'components/common/FlowLayout/FlowLayout/FlowLayout'
import { PaymentMethodSection } from 'components/common/PaymentMethodSection/PaymentMethodSection'
import { SignSeatsSection } from 'components/sign/SignSeatsSection'
import { useCartApi } from 'contexts/cart/api'
import { urls } from 'helpers/urls'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useInitFlow } from 'hooks/useInitFlow/useInitFlow'
import { usePermissions } from 'hooks/usePermissions/usePermissions'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from './context/api'
import { ViewContextProvider } from './context/ViewContextProvider'

export const SignSeats: React.FC = () => {
  const { isFlowReady } = useInitFlow(InitFlowSignUpgradeDocument)
  if (!isFlowReady) return
  return (
    <FormContextProvider autoScroll>
      <ViewContextProvider>
        <SignSeatsContent />
      </ViewContextProvider>
    </FormContextProvider>
  )
}

export const SignSeatsContent: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { canAccessSignSeats } = usePermissions()

  const { navigate } = useRoutesApi()

  const { submit, setCart } = useViewApi()

  const { cart } = useCartApi()

  const {
    activeSignSubscription: {
      isFree,
      planItem: { totalSeats, shopItemId },
    },
  } = useActiveSubscriptions()

  const { signPlans } = useShopItems()
  const activeShopItem = signPlans.find((shopItem) => shopItem.id === shopItemId)
  const maxAmount = activeShopItem?.limits?.maxAmount
  const isRedirectedToPlan = isFree || (maxAmount && maxAmount <= 1)
  // Display Payment Method Section
  const hasPaymentMethodSection = !(cart.total === 0 && Boolean(cart.creditUsed))

  useOnMount(async () => {
    if (!canAccessSignSeats) {
      navigate(urls.sign.upgrade + window.location.search, {
        hardRedirection: true,
      })
      return
    }
    // Create a cart with first ShopItem available
    if (!activeShopItem) return

    let nextSeats = totalSeats + 1
    if (totalSeats === maxAmount) {
      nextSeats = totalSeats - 1
    }
    await setCart({
      id: activeShopItem.id,
      seats: nextSeats,
    })
  })

  if (!canAccessSignSeats || isRedirectedToPlan) return
  return (
    <FlowLayout
      className={classes.base}
      header={
        <SectionDivider iconFrame={<LogoFrame variant="sign" />}>
          <Text variant="h3">{t('seats.purchaseTitle')}</Text>
        </SectionDivider>
      }
      onProcess={submit}
    >
      <StepDivider className={classes.firstStepDivider} index={1}>
        <Text isBold>{t('seats.numberOfSeats')}</Text>
      </StepDivider>
      <SignSeatsSection
        flow={SignPlanPurchaseFlowType.ChangeSeats}
        minSeats={activeShopItem?.limits?.minAmount}
        maxSeats={activeShopItem?.limits?.maxAmount}
        onUpdate={setCart}
      />

      {hasPaymentMethodSection ? (
        <>
          <StepDivider className={classes.stepDivider} index={2}>
            <Text isBold>{t('payment.paymentDetails')}</Text>
          </StepDivider>
          <PaymentMethodSection />
        </>
      ) : null}
    </FlowLayout>
  )
}
const useStyles = tss.create(() => ({
  base: {
    //
  },
  firstStepDivider: {
    marginBottom: spacing['24'],
  },
  stepDivider: {
    marginTop: spacing['48'],
    marginBottom: spacing['24'],
  },
}))
