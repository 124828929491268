import {
  AnimatedBox,
  Box,
  breakpoints,
  Button,
  colors,
  RadioGroup,
  radius,
  spacing,
} from '@alohi/flow-ui'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { testIds } from 'helpers/tests'
import { PaymentMethodData } from 'hooks/usePaymentMethods/usePaymentMethods'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { PaymentMethod } from './components/PaymentMethod/PaymentMethod'

interface PaymentMethodListProps {
  className?: string
  paymentMethods: PaymentMethodData[] | undefined
  onAddNewPaymentMethod: () => void
}

export const PaymentMethodList: React.FC<PaymentMethodListProps> = ({
  className,
  paymentMethods,
  onAddNewPaymentMethod,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    store: { selectedPaymentMethodId },
  } = usePaymentMethodApi()

  return (
    <AnimatedBox className={cx(classes.base, className)}>
      <RadioGroup key={paymentMethods?.length} value={selectedPaymentMethodId}>
        {paymentMethods?.map((paymentMethod, index) => (
          <Box
            key={index.toString()}
            className={cx(classes.row, index === 0 ? classes.firstRow : undefined)}
          >
            <PaymentMethod paymentMethod={paymentMethod} />
          </Box>
        ))}
      </RadioGroup>
      <Box className={cx(classes.row, paymentMethods?.length ? classes.lastRow : classes.oneRow)}>
        <Button
          className={classes.addNewMethod}
          icon={faPlus}
          variant="text"
          onClick={onAddNewPaymentMethod}
          dataCy={testIds.paymentMethodsList.addNew}
        >
          {t('payment.addNewMethod')}
        </Button>
      </Box>
    </AnimatedBox>
  )
}

const useStyles = tss.create(() => ({
  base: {
    position: 'relative',
  },
  row: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    height: '60px',
    [breakpoints.down('sm')]: {
      height: '100px',
    },
    display: 'flex',
    alignItems: 'center',
    padding: spacing['16'],
    borderBottomWidth: 0,
  },
  firstRow: {
    borderTopRightRadius: radius['8'],
    borderTopLeftRadius: radius['8'],
  },
  lastRow: {
    borderBottomRightRadius: radius['8'],
    borderBottomLeftRadius: radius['8'],
    borderBottomWidth: 1,
    paddingLeft: spacing['8'],
  },
  oneRow: {
    borderRadius: radius['8'],
  },
  addNewMethod: {
    marginLeft: spacing['8'],
  },
}))
