import { ReactNode, Reducer, useReducer } from 'react'
import { ViewContext } from './store/context'
import { ViewAction, ViewReducer } from './store/reducer'
import {
  ViewStore,
  computeStore,
  initialViewComputedStore,
  initialViewStore,
} from './store/store'

interface ViewContextProviderProps {
  children: ReactNode
  initialUpdate?: Partial<ViewStore>
}

export function ViewContextProvider({
  children,
  initialUpdate = {},
}: ViewContextProviderProps) {
  const [store, dispatch] = useReducer<Reducer<ViewStore, ViewAction>>(
    ViewReducer,
    {
      ...initialViewStore,
      ...initialViewComputedStore,
      ...initialUpdate,
    }
  )

  const computedStore = computeStore(store)

  return (
    <ViewContext.Provider
      value={{
        store: {
          ...store,
          ...computedStore,
        },
        dispatch,
      }}
    >
      {children}
    </ViewContext.Provider>
  )
}
