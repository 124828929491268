export interface AppDataStore {
  isAppReady: boolean
  customerId: string
  currentUserId: string
  accessToken: string | undefined
  tokenType: string | undefined
}

export const initialAppStore: AppDataStore = {
  isAppReady: false,
  customerId: '',
  currentUserId: '',
  accessToken: undefined,
  tokenType: undefined,
}

export interface AppComputedStore {
  isUserLogged: boolean
}

export const initialAppComputedStore: AppComputedStore = {
  isUserLogged: false,
}

export type AppStore = AppDataStore & AppComputedStore

export const computeStore = (store: AppStore): AppComputedStore => ({
  isUserLogged: Boolean(store.accessToken),
})
