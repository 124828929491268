import { useCanAccessApp } from './hooks/useCanAccessApp'
import { useCanAccessFaxAddNumber } from './hooks/useCanAccessFaxAddNumber'
import { useCanAccessFaxChangeNumber } from './hooks/useCanAccessFaxChangeNumber'
import { useCanAccessSignSeats } from './hooks/useCanAccessSignSeats'
import { useCanAccessSignSms } from './hooks/useCanAccessSignSms'

export interface Permissions {
  canAccessApp: boolean
  canAccessSignPlan: boolean
  canAccessSignSeats: boolean
  canAccessSignSms: boolean
  canAccessSignQes: boolean
  canAccessAddFaxNumber: boolean
  canAccessChangeFaxNumber: boolean
}

export const usePermissions = (): Permissions => {
  const canAccessApp = useCanAccessApp()
  const canAccessSignPlan = true
  const canAccessSignSeats = useCanAccessSignSeats()
  const canAccessSignSms = useCanAccessSignSms()
  const canAccessSignQes = true
  const canAccessAddFaxNumber = useCanAccessFaxAddNumber()
  const canAccessChangeFaxNumber = useCanAccessFaxChangeNumber()

  return {
    canAccessApp,
    canAccessSignPlan,
    canAccessSignSeats,
    canAccessSignSms,
    canAccessSignQes,
    canAccessAddFaxNumber,
    canAccessChangeFaxNumber,
  }
}
