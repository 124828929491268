import { CountryCode } from '@alohi/flow-ui'
import { useFragment } from '@apollo/client'
import {
  FaxPlusNumberShopItemCountriesFragmentFragmentDoc,
  FaxPlusNumberShopItemCountry,
} from 'api/gql/generated/graphql'

export const useFaxSupportedCountries = () => {
  const { data } = useFragment({
    from: { __ref: `FaxPlusNumberShopItemCountries:FaxPlusNumberShopItemCountries` },
    fragment: FaxPlusNumberShopItemCountriesFragmentFragmentDoc,
    fragmentName: 'FaxPlusNumberShopItemCountriesFragment',
  })

  const getFaxSupportedIso = (iso: string) => {
    return (data.countries?.find((supportedCountry) => supportedCountry?.iso === iso)?.iso ??
      'US') as CountryCode
  }

  return {
    supportedCountries: (data.countries ?? []) as FaxPlusNumberShopItemCountry[],
    getFaxSupportedIso,
  }
}
