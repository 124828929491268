import { Box, Chip, colors, spacing, Text } from '@alohi/flow-ui'
import { TwilioEndUserType } from 'api/gql/generated/graphql'
import { formatDefaultDate } from 'helpers/date'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface LabelProps {
  className?: string
  city: string | undefined | null
  endUserType: TwilioEndUserType | undefined | null
  submittedOn: number | undefined | null
  isPending: boolean
}

export const Label: React.FC<LabelProps> = ({
  className,
  city,
  endUserType,
  submittedOn,
  isPending,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const title = `${city} (${endUserType === TwilioEndUserType.Business ? t('faxNumber.bundleBusiness') : t('faxNumber.bundleIndividual')})`
  const submittedText = submittedOn
    ? t('faxNumber.bundleSubmittedOn', {
        value_1: formatDefaultDate(submittedOn),
      })
    : undefined

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content}>
        <Text>{title}</Text>
        {submittedText ? <Text className={classes.description}>{submittedText}</Text> : null}
      </Box>
      {isPending ? <Chip color="orange" label={t('faxNumber.pendingApproval')} /> : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['8'],
    paddingBottom: spacing['8'],
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
  },
  description: {
    marginTop: spacing['4'],
    color: colors.neutral400,
  },
}))
