import {
  Grid,
  GridItem,
  GridProps,
  RadioCard,
  RadioGroup,
  spacing,
  SvgBox,
  Text,
} from '@alohi/flow-ui'
import { PaymentMethodType } from 'api/gql/generated/graphql'
import CardIcon from 'assets/svg/payment/credit-card-icon.svg'
import PaypalIcon from 'assets/svg/payment/paypal-icon.svg'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

const PAYPAL = 'PayPal'

interface MethodRadioGroupProps {
  className?: string
  type: PaymentMethodType
  onTypeSelect: (type: PaymentMethodType) => void
}

export const MethodRadioGroup: React.FC<MethodRadioGroupProps> = ({
  className,
  type,
  onTypeSelect,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const templateAreas: GridProps['templateAreas'] = {
    base: '"card" "paypal"', // One column
    xs: '"card paypal"', // One row
  }

  const gridTemplateColumns: GridProps['gridTemplateColumns'] = {
    base: '100%', // Single column layout for small screens
    xs: '1fr 1fr', // Two equal columns for large screens
  }

  return (
    <RadioGroup className={cx(classes.group, className)} value={type} onChange={onTypeSelect}>
      <Grid
        templateAreas={templateAreas}
        gridTemplateColumns={gridTemplateColumns}
        columnGap={spacing['16']}
        rowGap={spacing['16']}
      >
        <GridItem area={'card'}>
          <RadioCard
            className={classes.random}
            value={PaymentMethodType.Card}
            icon={<SvgBox src={CardIcon} />}
            iconLabel={<Text>{t('common.card')}</Text>}
            dataCy={testIds.newPaymentMethodForm.creditCard.radio}
            onClick={() => onTypeSelect(PaymentMethodType.Card)}
          />
        </GridItem>
        <GridItem area={'paypal'}>
          <RadioCard
            className={classes.custom}
            value={PaymentMethodType.Paypal}
            icon={<SvgBox src={PaypalIcon} />}
            iconLabel={<Text>{PAYPAL}</Text>}
            dataCy={testIds.newPaymentMethodForm.paypal.radio}
            onClick={() => onTypeSelect(PaymentMethodType.Paypal)}
          />
        </GridItem>
      </Grid>
    </RadioGroup>
  )
}

const useStyles = tss.create(() => ({
  group: {
    paddingTop: spacing[4],
    marginRight: '1px',
  },
  random: {
    //
  },
  custom: {
    //
  },
}))
