/// <reference types="vite/client" />

export const NODE_ENV = import.meta.env.PROD ? 'production' : 'development'
export const VERSION = import.meta.env.VITE_VERSION

export const API_PREFIX = import.meta.env.VITE_API_PREFIX
export const SUB_DOMAIN = import.meta.env.VITE_SUB_DOMAIN
export const DOMAIN = import.meta.env.VITE_DOMAIN

export const FAX_SUB_DOMAIN = import.meta.env.VITE_FAX_SUB_DOMAIN
export const FAX_APP = import.meta.env.VITE_FAX_APP
export const FAX_MARKETING_WEBSITE_URL = import.meta.env.VITE_FAX_MARKETING_WEBSITE_URL
export const FAX_X_CLIENT_ID = import.meta.env.VITE_FAX_X_CLIENT_ID
export const FAX_X_CLIENT_SECRET = import.meta.env.VITE_FAX_X_CLIENT_SECRET
export const FAX_BASIC_AUTH_TOKEN = import.meta.env.VITE_FAX_BASIC_AUTH_TOKEN

export const SIGN_SUB_DOMAIN = import.meta.env.VITE_SIGN_SUB_DOMAIN
export const SIGN_APP = import.meta.env.VITE_SIGN_APP
export const GRAPHQL_ENDPOINT = import.meta.env.VITE_GRAPHQL_ENDPOINT
export const SIGN_MARKETING_WEBSITE_URL = import.meta.env.VITE_SIGN_MARKETING_WEBSITE_URL
export const SIGN_X_CLIENT_ID = import.meta.env.VITE_SIGN_X_CLIENT_ID
export const SIGN_X_CLIENT_SECRET = import.meta.env.VITE_SIGN_X_CLIENT_SECRET

export const ENVIRONMENT_NAME = import.meta.env.VITE_ENVIRONMENT_NAME

export const SENTRY_URL = import.meta.env.VITE_SENTRY_URL
export const SENTRY_AUTH_TOKEN = import.meta.env.VITE_SENTRY_AUTH_TOKEN
export const KIBANA_RUM_NAME = import.meta.env.VITE_KIBANA_RUM_NAME
export const KIBANA_RUM_URL = import.meta.env.VITE_KIBANA_RUM_URL

export const STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID
export const BRAINTREE_CLIENT_ID = import.meta.env.VITE_BRAINTREE_CLIENT_ID

export const FAX_API_AFFOGATO = import.meta.env.VITE_FAX_API_AFFOGATO

export const GOOGLE_TAG_MANAGER_ID = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID
