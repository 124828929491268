import { Box, breakpoints, colors, spacing } from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { ReactNode } from 'react'
import { tss } from 'tss-react'

interface FlowContentProps {
  children: ReactNode
  className?: string
  header: ReactNode
}
export const FlowContent: React.FC<FlowContentProps> = ({
  children,
  className,
  header,
}) => {
  const { classes, cx } = useStyles()

  const {
    store: { isProcessing },
  } = useCartApi()
  const {
    store: { isPaymentDefaultLoading },
  } = usePaymentMethodApi()

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.header}>{header}</Box>
      {children}
      {isProcessing || isPaymentDefaultLoading ? (
        <Box className={classes.updateOverlay} />
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    width: '641px',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: spacing['16'],
  },
  updateOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: colors.neutral0,
    opacity: 0.7,
  },
}))
