import { getI18nCountryList, SelectCountry, SelectProps, useInput } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import { useGeolocation } from 'hooks/useGeolocation/useGeolocation'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const NEW_PAYMENT_COUNTRY_INPUT_ID = 'new-payment-country'

export interface CountrySelectProps {
  className?: string
}

export const CountrySelect: React.FC<CountrySelectProps> = ({ className }) => {
  const { cx, classes } = useStyles()
  const { t } = useTranslation()

  const { iso } = useGeolocation()

  const countryChecker = (country: string) => {
    if (!country) {
      return `${t('common.country')} ${t('common.required')}`
    }
  }

  const [country, countryInputProps] = useInput<string>({
    id: NEW_PAYMENT_COUNTRY_INPUT_ID,
    initialValue: iso,
    validator: countryChecker,
  })

  const items: SelectProps['items'] = useMemo(
    () =>
      getI18nCountryList().map((item) => ({
        id: item.value,
        label: item.label,
        item: <SelectCountry.Item code={item.value} label={item.label} />,
        onClick: () => undefined,
      })),
    []
  )

  return (
    <SelectCountry
      dataCys={testIds.newPaymentMethodForm.creditCard.country}
      className={cx(classes.base, className)}
      label={t('common.country')}
      placeholder={t('common.country')}
      value={country}
      items={items}
      {...countryInputProps}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
