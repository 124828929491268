import { FAX_BASIC_AUTH_TOKEN } from 'env'

export const request = <T>(url: string, options = {} as RequestInit) => {
  const plainRequest = async <T>(url: string, options = {} as RequestInit) => {
    const config: RequestInit = {
      method: 'GET',
      ...options,
    }

    const defaultHeaders: HeadersInit = {
      Accept: 'application/json',
    }

    config.headers = {
      ...defaultHeaders,
      ...config.headers,
    }

    if (!url) {
      throw new Error('Error! URL is mandatory.')
    }

    const response = await fetch(url, config)

    if (response.status === 500) {
      throw new Error(response.statusText)
    }

    if (response.status === 404) {
      throw new Error('404 - Not Found')
    }

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return (await response.json()) as T
  }

  const config: RequestInit = {
    ...options,
  }
  const requestHeaders: HeadersInit = {
    Authorization: `Basic ${FAX_BASIC_AUTH_TOKEN}`,
    ...options.headers,
  }

  config.headers = requestHeaders

  if (!url) {
    throw new Error('Error! URL is mandatory.')
  }

  return plainRequest<T>(url, config)
}
