import {
  Box,
  Button,
  Divider,
  Input,
  spacing,
  useBool,
  useInput,
  useOnMount,
  useOnPressKey,
} from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { testIds } from 'helpers/tests'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { ActivatedDiscountCode } from './components/ActivatedDiscountCode'
import { RightContainer } from './components/RightContainer'

interface AddDiscountCodeProps {
  className?: string
}
export const AddDiscountCode: React.FC<AddDiscountCodeProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { cart, setDiscountCode } = useCartApi()

  const isTrial = cart.isTrial
  const isScheduled = cart.isScheduled
  const cartDiscountCode = cart.discount?.code ?? ''
  const activatedDiscountPrice =
    cart.discount?.__typename === 'AppliedDiscount' ? cart.discount?.amount ?? 0 : 0
  const hasActivatedDiscountError =
    cart.discount?.__typename === 'InvalidDiscount' ? Boolean(cart.discount?.error) : false
  const [isInputDisplayed, isInputDisplayedBool] = useBool(false)

  const [discountCode, discountCodeInputProps] = useInput<string>({
    useForm: false,
    initialValue: cartDiscountCode,
    isLazyLoaded: true,
  })

  const handleClick = () => {
    isInputDisplayedBool.setTrue()
  }

  useOnPressKey('Enter', () => {
    if (discountCodeInputProps.ref.current) {
      if (discountCode && document.activeElement === discountCodeInputProps.ref.current) {
        handleApply()
      }
    }
  })

  const handleApply = async () => {
    try {
      await setDiscountCode(discountCode)
    } catch (error) {
      // Error caught in stores
    }
  }

  const handleResetDiscountCode = async () => {
    try {
      await setDiscountCode(null)
      handleClose()
    } catch (error) {
      // Error caught in stores
    }
  }

  const handleClose = () => {
    discountCodeInputProps.onChange('')
    isInputDisplayedBool.setFalse()
  }

  useEffect(() => {
    if (discountCode && (isScheduled || isTrial)) {
      discountCodeInputProps.onChange('')
      isInputDisplayedBool.setFalse()
    }
  }, [discountCode, discountCodeInputProps, isInputDisplayedBool, isScheduled, isTrial])

  useOnMount(() => {
    isInputDisplayedBool.setTrue()
  }, hasActivatedDiscountError)

  if (isScheduled || isTrial) {
    return null
  }

  if (cartDiscountCode && !hasActivatedDiscountError) {
    return (
      <>
        <ActivatedDiscountCode
          discountCode={cartDiscountCode}
          onClose={handleResetDiscountCode}
          discountPrice={activatedDiscountPrice === 0 ? 0 : -activatedDiscountPrice}
        />
        <Divider className={classes.divider} />
      </>
    )
  }

  if (isInputDisplayed) {
    return (
      <Box data-cy={testIds.orderSummary.discountCodeInput}>
        <Input
          placeholder={t('purchase.discountCode')}
          value={discountCode}
          autoFocus
          {...discountCodeInputProps}
          rightElement={
            <RightContainer
              onClose={handleClose}
              onApply={handleApply}
              isDisabled={!discountCode}
            />
          }
        />
        <Divider className={classes.divider} />
      </Box>
    )
  }

  return (
    <Box data-cy={testIds.orderSummary.discountCodeButton}>
      <Button variant="text" className={cx(classes.base, className)} onClick={handleClick}>
        {t('purchase.addDiscountCode')}
      </Button>
      <Divider className={classes.divider} />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    minHeight: 0,
    padding: 0,
    paddingTop: spacing['4'],
    paddingBottom: spacing['4'],
  },
  divider: {
    marginTop: spacing['16'],
    marginBottom: spacing['16'],
  },
}))
