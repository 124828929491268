import { ViewStore } from './store'

export interface ViewAction {
  type: 'UPDATE'
  payload: Partial<ViewStore>
}

export const ViewReducer = (
  state: ViewStore,
  action: ViewAction
): ViewStore => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
