import {
  ActiveFaxSubscriptionType,
  useActiveFaxSubscription,
} from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'

export const useCanAccessFaxAddNumber = (): boolean => {
  const {
    activeFaxSubscription: {
      computed: { type },
    },
  } = useActiveFaxSubscription()

  return type === ActiveFaxSubscriptionType.CORPORATE
}
