import * as Sentry from '@sentry/react'
import { useCallback, useMemo } from 'react'

import { useToast } from '@alohi/flow-ui'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentMethodType } from 'api/gql/generated/graphql'
import { GraphQLError } from 'graphql'
import { handleApiErrors } from 'helpers/graphql'
import { PaymentErrorExtensions } from 'helpers/payment'
import { usePaymentMethods } from 'hooks/usePaymentMethods/usePaymentMethods'
import { useTranslation } from 'react-i18next'
import { usePaymentMethodContext } from './store/context'
import { PaymentMethodDataStore } from './store/store'

const STRIPE_CREATE_PAYMENT_METHOD_ERROR = 'Stripe Payment Method Creation Error'

export function usePaymentMethodApi() {
  const { store, dispatch } = usePaymentMethodContext()
  const toast = useToast()
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()

  const {
    paymentMethods,
    defaultPaymentMethod,
    getPaymentMethodTypeById,
    mutatePaymentMethodAdd,
    mutatePaymentMethodDelete,
    mutatePaymentMethodSetDefault,
  } = usePaymentMethods()

  const updateStore = useCallback(
    (payload: Partial<PaymentMethodDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const selectNewPaymentMethodType = useCallback(
    (newPaymentMethodSelectedType: PaymentMethodDataStore['newPaymentMethodSelectedType']) => {
      updateStore({ newPaymentMethodSelectedType })
    },
    [updateStore]
  )

  const selectNewPaymentMethodId = useCallback(
    (id: string | undefined) => {
      updateStore({ selectedPaymentMethodId: id })
    },
    [updateStore]
  )

  const updateDefaultPaymentMethod = useCallback(
    async (id: string) => {
      try {
        updateStore({
          isPaymentDefaultLoading: true,
        })
        await mutatePaymentMethodSetDefault({
          variables: {
            id,
          },
        })
        updateStore({
          isPaymentDefaultLoading: false,
          selectedPaymentMethodId: id,
        })
      } catch (error) {
        handleApiErrors(error, {
          errors: {
            PAYMENT_METHOD_NOT_FOUND: () => {
              toast({
                description: t('error.paymentMethodNotFound'),
                variant: 'error',
              })
            },
          },
          default: () => {
            toast({
              description: t('common.serverError'),
              variant: 'error',
            })
          },
        })
        updateStore({
          isPaymentDefaultLoading: false,
        })
        throw error
      }
    },
    [mutatePaymentMethodSetDefault, t, toast, updateStore]
  )

  const deletePaymentMethod = useCallback(
    async (id: string) => {
      try {
        updateStore({
          isPaymentDefaultLoading: true,
        })
        await mutatePaymentMethodDelete({
          variables: {
            id,
          },
        })

        // Automatically select the default payment method, in case it's not already selected
        // Prevent cases where the user has 1 payment method, and it's not selected
        if (paymentMethods.length === 2 && defaultPaymentMethod?.id) {
          updateStore({
            selectedPaymentMethodId: defaultPaymentMethod.id,
          })
        }
      } catch (error) {
        handleApiErrors(error, {
          errors: {
            PAYMENT_ERROR: (extensions) => {
              updateStore({
                newPaymentMethodErrorExtensions: extensions as PaymentErrorExtensions,
              })
            },
            PAYMENT_METHOD_NOT_FOUND: () => {
              toast({
                description: t('error.paymentMethodNotFound'),
                variant: 'error',
              })
            },
          },
          default: () => {
            toast({
              description: t('common.serverError'),
              variant: 'error',
            })
          },
        })
        throw error
      } finally {
        updateStore({
          isPaymentDefaultLoading: false,
        })
      }
    },
    [
      defaultPaymentMethod?.id,
      mutatePaymentMethodDelete,
      paymentMethods.length,
      t,
      toast,
      updateStore,
    ]
  )

  const addStripePaymentMethod = useCallback(
    async (params: { name: string; country: string; street: string; postalCode: string }) => {
      const cardElement = elements?.getElement(CardElement)

      if (!stripe || !elements || !cardElement) {
        return
      }

      try {
        updateStore({
          isNewPaymentMethodLoading: true,
        })
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: params.name,
            address: {
              country: params.country,
              line1: params.street,
              postal_code: params.postalCode,
            },
          },
        })
        if (error) {
          updateStore({
            newPaymentMethodErrorExtensions: {
              STRIPE_ERROR_CODE: error.code,
              STRIPE_DECLINE_CODE: error.decline_code,
            } as PaymentErrorExtensions,
          })
          Sentry.withScope((scope) => {
            scope.setTag('stripeError', error.code)
            Sentry.captureException(new Error(STRIPE_CREATE_PAYMENT_METHOD_ERROR), {
              contexts: {
                stripe: {
                  code: error.code,
                  message: error.message,
                  card: params,
                },
              },
            })
          })
          throw new Error(STRIPE_CREATE_PAYMENT_METHOD_ERROR)
        }

        if (!paymentMethod) {
          throw new Error('No Payment Method created')
        }

        await mutatePaymentMethodAdd({
          variables: {
            input: {
              token: paymentMethod.id,
              type: PaymentMethodType.Card,
            },
          },
        })

        await mutatePaymentMethodSetDefault({
          variables: {
            id: paymentMethod.id,
          },
        })
        updateStore({
          selectedPaymentMethodId: paymentMethod.id,
          isNewPaymentMethodLoading: false,
        })
      } catch (error) {
        if ((error as GraphQLError)?.message === STRIPE_CREATE_PAYMENT_METHOD_ERROR) {
          // Nothing to do
        } else {
          handleApiErrors(error, {
            errors: {
              PAYMENT_ERROR: (extensions) => {
                updateStore({
                  newPaymentMethodErrorExtensions: extensions as PaymentErrorExtensions,
                })
              },
            },
            default: () => {
              toast({
                description: t('common.serverError'),
                variant: 'error',
              })
            },
          })
        }
        throw error
      } finally {
        updateStore({
          isNewPaymentMethodLoading: false,
        })
      }
    },
    [elements, mutatePaymentMethodAdd, mutatePaymentMethodSetDefault, stripe, t, toast, updateStore]
  )

  const addPaypalPaymentMethod = useCallback(
    async (params: { token: string }) => {
      try {
        updateStore({
          isNewPaymentMethodLoading: true,
        })

        const paymentResponse = await mutatePaymentMethodAdd({
          variables: {
            input: {
              token: params.token,
              type: PaymentMethodType.Paypal,
            },
          },
        })

        const newPaymentId =
          paymentResponse?.data?.paymentMethodAdd?.__typename === 'PaymentMethodPaypal'
            ? paymentResponse?.data?.paymentMethodAdd?.id
            : null

        if (!newPaymentId) {
          throw new Error('No Payment ID')
        }

        await mutatePaymentMethodSetDefault({
          variables: {
            id: newPaymentId,
          },
        })
        updateStore({
          selectedPaymentMethodId: newPaymentId,
          isNewPaymentMethodLoading: false,
        })
      } catch (error) {
        updateStore({
          isNewPaymentMethodLoading: false,
        })
        handleApiErrors(error, {
          errors: {
            PAYMENT_ERROR: (extensions) => {
              updateStore({
                newPaymentMethodErrorExtensions: extensions as PaymentErrorExtensions,
              })
            },
          },
          default: () => {
            toast({
              description: t('common.serverError'),
              variant: 'error',
            })
          },
        })
        throw error
      }
    },
    [mutatePaymentMethodAdd, mutatePaymentMethodSetDefault, t, toast, updateStore]
  )

  const selectedPaymentMethodType = useMemo(
    () => getPaymentMethodTypeById(store.selectedPaymentMethodId),
    [getPaymentMethodTypeById, store]
  )

  return {
    store,
    paymentMethods,
    defaultPaymentMethod,
    updateStore,
    selectNewPaymentMethodType,
    selectNewPaymentMethodId,
    updateDefaultPaymentMethod,
    addStripePaymentMethod,
    addPaypalPaymentMethod,
    deletePaymentMethod,
    selectedPaymentMethodType,
  }
}
