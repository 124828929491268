import { Dispatch, createContext, useContext } from 'react'
import { AppAction } from './reducer'
import { AppStore, initialAppComputedStore, initialAppStore } from './store'

export interface AppContextProps {
  store: AppStore
  dispatch: Dispatch<AppAction>
}

export const AppContext = createContext<AppContextProps>({
  store: {
    ...initialAppStore,
    ...initialAppComputedStore,
  },
  dispatch: () => undefined,
})

export function useAppContext() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useAppContext is ouside of the Provider')
  }

  return context
}
