import { RoutesStore } from './store'

export interface RoutesAction {
  type: 'UPDATE'
  payload: Partial<RoutesStore>
}

export const RoutesReducer = (
  state: RoutesStore,
  action: RoutesAction
): RoutesStore => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
