import { useToast } from '@alohi/flow-ui'
import { useFragment, useMutation, useQuery } from '@apollo/client'
import {
  CreditDocument,
  Currency,
  Customer,
  CustomerDocument,
  CustomerFragmentFragmentDoc,
  CustomerUpdateCompanyNameDocument,
} from 'api/gql/generated/graphql'
import { useAppApi } from 'contexts/app/api'
import { handleApiErrors } from 'helpers/graphql'
import { useTranslation } from 'react-i18next'

export const useCustomer = () => {
  const { t } = useTranslation()
  const toast = useToast()

  const {
    store: { customerId },
  } = useAppApi()

  const { refetch: queryCustomer } = useQuery(CustomerDocument, {
    skip: true,
  })
  const { refetch: queryCredit } = useQuery(CreditDocument, {
    skip: true,
  })
  const [mutateCustomerUpdateCompanyName] = useMutation(CustomerUpdateCompanyNameDocument)

  const handleQueryCredit = async () => {
    try {
      await queryCredit()
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
        },
      })
    }
  }

  const handleQueryCustomer = async () => {
    try {
      await queryCustomer()
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
        },
      })
    }
  }

  const { data } = useFragment({
    from: { __ref: `Customer:${customerId}` },
    fragment: CustomerFragmentFragmentDoc,
    fragmentName: 'CustomerFragment',
  })

  const customer = {
    ...({
      id: '',
      billingData: {
        id: '',
        billingAddresses: [],
        paymentMethods: {
          id: '',
          methods: [],
        },
      },
      credit: {
        id: '',
        amount: 0,
        currency: Currency.Usd,
        reservations: [],
      },
      orders: [],
      orderHistory: [],
      subscriptions: [],
      subscriptionsHistory: [],
      licenseUse: {
        fax: 0,
        sign: 0,
      },
      members: [],
      regulatoryData: { addresses: [], bundles: [] },
      marketingData: {
        faxSignCrossSellBanner: undefined,
      },
    } satisfies Customer),
    ...(data as Customer),
  }

  return {
    queryCredit: handleQueryCredit,
    queryCustomer: handleQueryCustomer,
    mutateCustomerUpdateCompanyName,
    customer,
  }
}
