import {
  Box,
  colors,
  CountryCode,
  Grid,
  GridItem,
  GridProps,
  RadioCard,
  RadioGroup,
  radius,
  spacing,
  SvgBox,
  Text,
  useBool,
} from '@alohi/flow-ui'
import { FaxNumberType } from 'api/gql/generated/graphql'
import CustomNumberIcon from 'assets/svg/numbers/custom.svg'
import RandomNumberIcon from 'assets/svg/numbers/random.svg'
import { formatCurrency } from 'helpers/payment'
import { testIds } from 'helpers/tests'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { CustomNumberModal } from '../../../../modals/CustomNumberModal/CustomNumberModal'

interface NumbersRadioGroupProps {
  className?: string
  country: CountryCode
  areaCode?: string | null
  randomPrice?: number
  customPrice?: number
  numberSelectionType: NumberType
  number?: string
  isLoading?: boolean
  numberType: FaxNumberType
  onCustomNumberSelect: (number: string | null) => void
}

export type NumberType = 'random' | 'custom'

export const NumbersRadioGroup: React.FC<NumbersRadioGroupProps> = ({
  className,
  country,
  areaCode,
  randomPrice,
  customPrice,
  numberSelectionType,
  number,
  isLoading,
  numberType,
  onCustomNumberSelect,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const [isCustomNumberModalOpen, isCustomNumberModalOpenBool] = useBool(false)

  const templateAreas: GridProps['templateAreas'] = {
    base: '"random" "custom"', // One area per row for small screens
    md: '"random custom"', // One row with two areas for large screens
  }

  const gridTemplateColumns: GridProps['gridTemplateColumns'] = {
    base: '100%', // Single column layout for small screens
    md: '1fr 1fr', // Two equal columns for large screens
  }

  const handleRandomClick = useCallback(async () => {
    if (numberSelectionType === 'random') return
    onCustomNumberSelect(null)
  }, [numberSelectionType, onCustomNumberSelect])

  const handleCustomClick = () => {
    isCustomNumberModalOpenBool.setTrue()
  }

  return (
    <>
      <RadioGroup className={cx(classes.group, className)} value={numberSelectionType}>
        <Grid
          templateAreas={templateAreas}
          gridTemplateColumns={gridTemplateColumns}
          columnGap={spacing['16']}
          rowGap={spacing['16']}
        >
          <GridItem area={'random'}>
            <RadioCard
              className={classes.random}
              dataCy={testIds.fax.numbers.radio.random}
              value="random"
              icon={<SvgBox src={RandomNumberIcon} />}
              label1={
                <Text className={classes.labelContainer} isBold>
                  {randomPrice
                    ? t('faxNumber.randomNumberX', {
                        value_1: formatCurrency({
                          amount: randomPrice,
                          currency: currency,
                        }),
                      })
                    : t('faxNumber.randomNumber')}
                </Text>
              }
              label2={
                <>
                  {numberSelectionType === 'random' && number && !isLoading ? (
                    <Box
                      dataCy={testIds.fax.numbers.number}
                      className={cx(classes.labelContainer, classes.randomNumberChip)}
                    >
                      <Text isBold>{number}</Text>
                    </Box>
                  ) : (
                    <Text>{t('faxNumber.yourRandomNumber')}</Text>
                  )}
                </>
              }
              isDisabled={isLoading}
              onClick={handleRandomClick}
            />
          </GridItem>
          <GridItem area={'custom'}>
            <RadioCard
              className={classes.custom}
              dataCy={testIds.fax.numbers.radio.custom}
              value="custom"
              icon={<SvgBox src={CustomNumberIcon} />}
              label1={
                <Text className={classes.labelContainer} isBold>
                  {customPrice
                    ? t('faxNumber.customNumberX', {
                        value_1: formatCurrency({
                          amount: customPrice,
                          currency: currency,
                        }),
                      })
                    : t('faxNumber.customNumber')}
                </Text>
              }
              label2={
                <>
                  {numberSelectionType === 'custom' && number && !isLoading ? (
                    <Box
                      dataCy={testIds.fax.numbers.number}
                      className={cx(classes.labelContainer, classes.customNumberChip)}
                    >
                      <Text isBold>{number}</Text>
                    </Box>
                  ) : (
                    <Text>{t('faxNumber.yourOwnCustomNumber')}</Text>
                  )}
                </>
              }
              onClick={handleCustomClick}
              withBorderAnimation
              isDisabled={isLoading}
            />
          </GridItem>
        </Grid>
        {isCustomNumberModalOpen ? (
          <CustomNumberModal
            country={country}
            areaCode={areaCode}
            onCancel={isCustomNumberModalOpenBool.setFalse}
            numberType={numberType}
            onCustomNumberSelect={onCustomNumberSelect}
          />
        ) : null}
      </RadioGroup>
    </>
  )
}

const useStyles = tss.create(() => ({
  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 0,
    marginRight: '1px',
  },
  random: {
    flex: 1,
    whiteSpace: 'nowrap',
    height: 72,
  },
  custom: {
    flex: 1,
    whiteSpace: 'nowrap',
    height: 72,
  },
  randomNumberChip: {
    display: 'flex',
    alignItems: 'center',
    background: colors.green900,
    color: colors.green200,
    height: '24px',
    borderRadius: radius['8'],
    paddingLeft: spacing['8'],
    paddingRight: spacing['8'],
  },
  customNumberChip: {
    display: 'flex',
    alignItems: 'center',
    background: '#3D1255',
    color: colors.purple100,
    height: '24px',
    borderRadius: radius['8'],
    paddingLeft: spacing['8'],
    paddingRight: spacing['8'],
  },
  labelContainer: {
    height: '20px',
  },
}))
