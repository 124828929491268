import { Box, Button, colors, radius, spacing, Text, useBool } from '@alohi/flow-ui'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { CompanyNameModal } from './modals/CompanyNameModal'

interface SettingsProps {
  className?: string
}

export const Settings: React.FC<SettingsProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: { companyName },
  } = useCustomer()

  const [isCompanyNameModalOpen, isCompanyNameModalOpenBool] = useBool(false)

  const organizationName = companyName ?? t('common.none')

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content}>
        <Box className={classes.leftContainer}>
          <Text>{t('organization.organizationName')}</Text>
        </Box>

        <Box className={classes.rightContainer}>
          <Text className={classes.organizationName}>{organizationName}</Text>
          <Button
            className={classes.button}
            icon={faChevronRight}
            iconPlacement="right"
            variant="text"
            onClick={isCompanyNameModalOpenBool.setTrue}
          >
            {t('common.edit')}
          </Button>
        </Box>
      </Box>
      {isCompanyNameModalOpen ? (
        <CompanyNameModal onClose={isCompanyNameModalOpenBool.setFalse} />
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    position: 'relative',
    marginTop: spacing['24'],
  },
  content: {
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    borderRadius: radius['8'],
    padding: spacing['16'],
  },
  leftContainer: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  button: {
    marginTop: spacing['8'],
  },
  organizationName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))
