import { FormContextProvider, Modal, NavigationModalSteps, useFormApi } from '@alohi/flow-ui'
import AddPaymentMethodContent from 'components/common/OrderSummarySection/components/TotalContainer/modals/PaymentErrorModal/components/AddPaymentMethodContent'
import ErrorContent from 'components/common/OrderSummarySection/components/TotalContainer/modals/PaymentErrorModal/components/ErrorContent'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { NEW_PAYMENT_COUNTRY_INPUT_ID } from '../components/NewPaymentMethodForm/components/CountrySelect'
import { NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID } from '../components/NewPaymentMethodForm/components/NameInput'
import { NEW_PAYMENT_POSTAL_INPUT_ID } from '../components/NewPaymentMethodForm/components/PostalInput'
import { NEW_PAYMENT_STREET_INPUT_ID } from '../components/NewPaymentMethodForm/components/StreetInput'

export const VIEWS = {
  NEW_PAYMENT: 'newPayment',
  ERROR: 'error',
}

interface NewPaymentMethodModalProps {
  className?: string
  onCancel: () => void
  onConfirm: () => void
}

export const NewPaymentMethodModal: React.FC<NewPaymentMethodModalProps> = (props) => {
  return (
    <FormContextProvider>
      <NewPaymentMethodModalContent {...props} />
    </FormContextProvider>
  )
}

const NewPaymentMethodModalContent: React.FC<NewPaymentMethodModalProps> = ({
  className,
  onCancel,
  onConfirm,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    store: {
      newPaymentMethodSelectedType,
      newPaymentMethodErrorExtensions,
      hasNewPaymentMethodFailed,
      isNewPaymentMethodLoading,
    },
    addStripePaymentMethod,
    addPaypalPaymentMethod,
    updateStore: updatePaymentMethodStore,
  } = usePaymentMethodApi()

  const {
    store: { canSubmit, tooltip },
    checkFormValidity,
    readInput,
  } = useFormApi()

  const [step, setStep] = useState(VIEWS.NEW_PAYMENT)

  const isLoading = isNewPaymentMethodLoading

  const handleAddStripe = useCallback(async () => {
    try {
      if (!(await checkFormValidity())) return

      const name = readInput<string>(NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID)
      const country = readInput<string>(NEW_PAYMENT_COUNTRY_INPUT_ID)
      const street = readInput<string>(NEW_PAYMENT_STREET_INPUT_ID)
      const postalCode = readInput<string>(NEW_PAYMENT_POSTAL_INPUT_ID)

      if (!name || !country || !street || !postalCode) {
        throw new Error('Missing parameters')
      }

      await addStripePaymentMethod({
        country,
        name,
        postalCode,
        street,
      })
      onConfirm()
    } catch (error) {
      // Error caught in stores
    }
  }, [addStripePaymentMethod, checkFormValidity, onConfirm, readInput])

  const handlePaypalApprove = useCallback(
    async (token: string) => {
      // If no Payment Method in Customer, we create one
      try {
        await addPaypalPaymentMethod({
          token,
        })
        onConfirm()
      } catch (error) {
        // Error caught in stores
      }
    },
    [addPaypalPaymentMethod, onConfirm]
  )

  const handleCancel = useCallback(() => {
    updatePaymentMethodStore({
      newPaymentMethodErrorExtensions: undefined,
    })
    if (step === VIEWS.ERROR) {
      setStep(VIEWS.NEW_PAYMENT)
    } else {
      onCancel()
    }
  }, [onCancel, step, updatePaymentMethodStore])

  const steps: NavigationModalSteps = useMemo(
    () => ({
      [VIEWS.NEW_PAYMENT]: {
        id: VIEWS.NEW_PAYMENT,
        children: (
          <AddPaymentMethodContent
            isLoading={isLoading}
            confirmTooltip={tooltip}
            isConfirmDisabled={!canSubmit}
            type={newPaymentMethodSelectedType}
            onCancel={handleCancel}
            onConfirm={handleAddStripe}
            onPaypalApprove={handlePaypalApprove}
          />
        ),
      },
      [VIEWS.ERROR]: {
        id: VIEWS.ERROR,
        children: (
          <ErrorContent
            title={t('payment.addMethodErrorTitle')}
            description={t('paymentError.addNewPaymentDescription')}
            confirmTitle={t('common.retry')}
            paymentErrorExtensions={newPaymentMethodErrorExtensions}
            onConfirm={handleCancel}
          />
        ),
      },
    }),
    [
      canSubmit,
      handleAddStripe,
      handleCancel,
      handlePaypalApprove,
      isLoading,
      newPaymentMethodErrorExtensions,
      newPaymentMethodSelectedType,
      t,
      tooltip,
    ]
  )

  useEffect(() => {
    if (hasNewPaymentMethodFailed) {
      setStep(VIEWS.ERROR)
    }
  }, [hasNewPaymentMethodFailed])

  return (
    <Modal
      className={cx(classes.base, className)}
      title={t('payment.addMethod')}
      onClose={handleCancel}
      steps={steps}
      stepId={step}
      isAnimated={step !== VIEWS.NEW_PAYMENT}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
