import { Box, Button, spacing, Text } from '@alohi/flow-ui'
import { tss } from 'tss-react'

interface SuccessModalFooterProps {
  className?: string
  primaryButtonTitle: string
  onPrimaryButtonClick: () => void
  secondaryButtonTitle: string
  onSecondaryButtonClick: () => void
}

export const SuccessModalFooter: React.FC<SuccessModalFooterProps> = ({
  className,
  primaryButtonTitle,
  onPrimaryButtonClick,
  secondaryButtonTitle,
  onSecondaryButtonClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.base, className)}>
      <Button size="full" onClick={onPrimaryButtonClick}>
        <Text>{primaryButtonTitle}</Text>
      </Button>
      <Button size="full" variant="tertiary" onClick={onSecondaryButtonClick}>
        <Text>{secondaryButtonTitle}</Text>
      </Button>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    width: '100%',
    paddingLeft: spacing['8'],
    paddingRight: spacing['8'],
    display: 'flex',
    flexDirection: 'column',
    gap: spacing['8'],
  },
}))
