import {
  Box,
  FormContextProvider,
  Helper,
  Link,
  LogoFrame,
  SectionDivider,
  spacing,
  StepDivider,
  Text,
  Tooltip,
  useOnMount,
} from '@alohi/flow-ui'
import { InitFlowSmsDocument } from 'api/gql/generated/graphql'
import { FlowLayout } from 'components/common/FlowLayout/FlowLayout/FlowLayout'
import { PaymentMethodSection } from 'components/common/PaymentMethodSection/PaymentMethodSection'
import { useAppApi } from 'contexts/app/api'
import { useCartApi } from 'contexts/cart/api'
import { websiteUrls } from 'helpers/urls'
import { useInitFlow } from 'hooks/useInitFlow/useInitFlow'
import { usePermissions } from 'hooks/usePermissions/usePermissions'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { SignSmsSection } from './components/SignSmsSection'
import { useViewApi } from './context/api'
import { ViewContextProvider } from './context/ViewContextProvider'

export const SignSms = () => {
  const { isFlowReady } = useInitFlow(InitFlowSmsDocument)
  if (!isFlowReady) return
  return (
    <FormContextProvider autoScroll>
      <ViewContextProvider>
        <SignSmsContent />
      </ViewContextProvider>
    </FormContextProvider>
  )
}

const SignSmsContent: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { canAccessSignSms } = usePermissions()

  const { submit } = useViewApi()

  const { redirectToUnauthorized } = useAppApi()

  const { cart } = useCartApi()

  // Display Payment Method Section
  const hasPaymentMethodSection = !(cart.total === 0 && Boolean(cart.creditUsed))

  const Label = useMemo(
    () => (
      <Box className={classes.tooltipContent}>
        <Text variant="body">{t('sms.sms')}</Text>
        <Text variant="small" className={classes.tooltipDesc}>
          {t('sms.tooltipDesc1')}
        </Text>
        <Text variant="small" className={classes.spacing}>
          {t('sms.tooltipVisitHelpCenter')}
        </Text>
        <Link
          variant="small"
          color="light"
          className={classes.link}
          withIcon
          hasBullet
          isExternal
          href={websiteUrls.sign.help.sms.addSmsAuth}
        >
          {t('sms.tooltipLink1')}
        </Link>
        <Link
          variant="small"
          color="light"
          className={classes.link}
          withIcon
          hasBullet
          isExternal
          href={websiteUrls.sign.help.sms.recipientVerification}
        >
          {t('sms.tooltipLink2')}
        </Link>
      </Box>
    ),
    [classes.link, classes.spacing, classes.tooltipContent, classes.tooltipDesc, t]
  )

  useOnMount(() => {
    if (!canAccessSignSms) {
      redirectToUnauthorized()
    }
  })

  if (!canAccessSignSms) return
  return (
    <FlowLayout
      onProcess={submit}
      className={classes.base}
      header={
        <SectionDivider iconFrame={<LogoFrame variant="sign" />}>
          <Text className={classes.title} variant="h3">
            {t('sms.purchaseTitle')}
          </Text>
          <Tooltip label={Label} className={classes.tooltip}>
            <Helper variant="large" />
          </Tooltip>
        </SectionDivider>
      }
    >
      <StepDivider className={classes.firstStepDivider} index={1}>
        <Text isBold>{t('sms.yourSmsCredits')}</Text>
      </StepDivider>
      <SignSmsSection />

      {hasPaymentMethodSection ? (
        <>
          <StepDivider className={classes.stepDivider} index={2}>
            <Text isBold>{t('payment.paymentDetails')}</Text>
          </StepDivider>
          <PaymentMethodSection />
        </>
      ) : null}
    </FlowLayout>
  )
}
const useStyles = tss.create(() => ({
  base: {
    //
  },
  firstStepDivider: {
    marginBottom: spacing['24'],
  },
  stepDivider: {
    marginTop: spacing['48'],
    marginBottom: spacing['24'],
  },
  title: {
    flexGrow: 1,
  },
  tooltip: {
    maxWidth: '300px',
  },
  tooltipContent: {
    padding: spacing['16'],
  },
  spacing: {
    marginTop: spacing['16'],
  },
  tooltipDesc: {
    marginTop: spacing['16'],
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '350px',
  },
  link: {
    marginTop: spacing['4'],
    textWrap: 'nowrap',
    marginLeft: spacing['4'],
  },
}))
