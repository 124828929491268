import { useActiveSignSubscription } from 'hooks/useActiveSubscription/hooks/useActiveSignSubscription'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useShopItems } from 'hooks/useShopItems/useShopItems'

export const useCanAccessSignSeats = (): boolean => {
  const { activeSignSubscription } = useActiveSignSubscription()

  const { signPlans } = useShopItems()
  const subscribedSignPlanShopItem = signPlans.find(
    (signPlan) => signPlan.id === activeSignSubscription.planItem.shopItemId
  )

  const {
    customer: {
      licenseUse: { sign: signUsedLicences },
    },
  } = useCustomer()

  const isFree = activeSignSubscription.isFree
  const hasApiPlan = activeSignSubscription.planItem.hasApi
  const cantChangeSeats =
    signUsedLicences === activeSignSubscription.planItem.totalSeats &&
    activeSignSubscription.planItem.totalSeats === subscribedSignPlanShopItem?.limits?.maxAmount

  return !isFree && !hasApiPlan && !cantChangeSeats
}
