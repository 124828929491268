import { Box, CountryCode, formatNumber } from '@alohi/flow-ui'
import {
  FaxNumberChannel,
  FaxPlusExtraNumberCartItem,
  FaxPlusNumberArea,
  FaxPlusNumberCartItem,
  FaxPlusNumberShopItemCountryInfo,
  SetFaxNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { useFaxNumberRequirementsCompliance } from 'hooks/useFaxNumberRequirementsCompliance/useFaxNumberRequirementsCompliance'
import { useMemo } from 'react'
import { tss } from 'tss-react'
import { NumbersRadioGroup } from './components/NumbersRadioGroup/NumbersRadioGroup'
import { InfosMessage } from './components/NumbersRadioGroup/components/InfosMessage'

export enum PriceType {
  setup = 'setup',
  replacement = 'replacement',
}

interface UniqueNumberProps {
  className?: string
  country: CountryCode
  numberCartInput: SetFaxNumberCartItemInput
  numberCartItem?: FaxPlusExtraNumberCartItem | FaxPlusNumberCartItem
  supportedAreas: FaxPlusNumberArea[]
  isLoading: boolean
  onCustomNumberSelect: (number: string | null) => void
  priceType: PriceType
}

export const UniqueNumber: React.FC<UniqueNumberProps> = ({
  className,
  country,
  numberCartInput,
  numberCartItem,
  supportedAreas,
  isLoading,
  onCustomNumberSelect,
  priceType,
}) => {
  const { classes, cx } = useStyles()

  const { getCountrySettings } = useFaxNumberRequirementsCompliance()

  const countrySettings: FaxPlusNumberShopItemCountryInfo | null | undefined = useMemo(
    () =>
      getCountrySettings({
        country,
        numberType: numberCartInput.numberType,
      }),
    [country, getCountrySettings, numberCartInput]
  )

  const areaCode = numberCartInput.areaCode
  const area = supportedAreas.find((supportedArea) => supportedArea.prefix === areaCode)?.name

  const formattedNumber = numberCartItem?.number
    ? formatNumber(numberCartItem.number, 'INTERNATIONAL')
    : undefined

  const inputCountry = (numberCartInput.iso ?? 'US') as CountryCode

  return (
    <Box className={cx(classes.base, className)}>
      <NumbersRadioGroup
        country={country}
        areaCode={areaCode}
        customPrice={
          priceType === PriceType.setup
            ? countrySettings?.setupPrice?.custom
            : countrySettings?.replacementPrice?.custom
        }
        randomPrice={
          priceType === PriceType.setup
            ? countrySettings?.setupPrice?.random
            : countrySettings?.replacementPrice?.random
        }
        number={formattedNumber}
        numberSelectionType={
          numberCartItem?.channel === FaxNumberChannel.Custom ? 'custom' : 'random'
        }
        numberType={numberCartInput.numberType}
        isLoading={isLoading}
        onCustomNumberSelect={onCustomNumberSelect}
      />
      {isLoading ? null : (
        <InfosMessage
          inputCountry={inputCountry}
          notAvailable={numberCartItem?.notAvailable}
          number={formattedNumber}
          numberType={numberCartInput.numberType}
          area={area}
        />
      )}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
