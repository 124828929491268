import { Dispatch, createContext, useContext } from 'react'
import { CartAction } from './reducer'
import { CartStore, initialCartComputedStore, initialCartStore } from './store'

export interface CartContextProps {
  store: CartStore
  dispatch: Dispatch<CartAction>
}

export const CartContext = createContext<CartContextProps>({
  store: {
    ...initialCartStore,
    ...initialCartComputedStore,
  },
  dispatch: () => undefined,
})

export function useCartContext() {
  const context = useContext(CartContext)

  if (!context) {
    throw new Error('useCartContext is ouside of the Provider')
  }

  return context
}
