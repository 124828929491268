import { PaymentMethodType } from 'api/gql/generated/graphql'
import { PaymentErrorExtensions } from 'helpers/payment'

export interface PaymentMethodDataStore {
  isNewPaymentMethodLoading: boolean
  selectedPaymentMethodId: string | undefined
  newPaymentMethodSelectedType: PaymentMethodType
  isPaymentDefaultLoading: boolean
  newPaymentMethodErrorExtensions?: PaymentErrorExtensions
}

export const initialPaymentMethodStore: PaymentMethodDataStore = {
  isNewPaymentMethodLoading: false,
  selectedPaymentMethodId: undefined,
  newPaymentMethodSelectedType: PaymentMethodType.Card,
  isPaymentDefaultLoading: false,
  newPaymentMethodErrorExtensions: undefined,
}

export interface PaymentMethodComputedStore {
  hasNewPaymentMethodFailed: boolean
}

export const initialPaymentMethodComputedStore: PaymentMethodComputedStore = {
  hasNewPaymentMethodFailed: false,
}

export type PaymentMethodStore = PaymentMethodDataStore & PaymentMethodComputedStore

export const computeStore = (store: PaymentMethodStore): PaymentMethodComputedStore => ({
  hasNewPaymentMethodFailed: Boolean(store.newPaymentMethodErrorExtensions),
})
