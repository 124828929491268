import { Box, Button, spacing, Text } from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { MobileFooter } from './MobileFooter'

interface MobileFooterPurchaseProps {
  className?: string
  onReview: () => void
}
export const MobileFooterPurchase: React.FC<MobileFooterPurchaseProps> = ({
  className,
  onReview,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const {
    store: { isUpdating, isProcessing },
    cart: { total },
  } = useCartApi()

  const {
    store: { isPaymentDefaultLoading, isNewPaymentMethodLoading },
  } = usePaymentMethodApi()
  const isLoading =
    isNewPaymentMethodLoading || isUpdating || isProcessing || isPaymentDefaultLoading

  return (
    <MobileFooter className={cx(classes.base, className)}>
      <Box className={classes.priceContainer}>
        <Text variant="h3" isBold>
          {t('common.total')}
        </Text>
        <Text variant="h2" className={classes.price}>
          {formatCurrency({ amount: total, currency })}
        </Text>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button className={classes.button} onClick={onReview} isLoading={isLoading}>
          {t('mobile.reviewOrder')}
        </Button>
      </Box>
    </MobileFooter>
  )
}

const useStyles = tss.create(() => ({
  base: {
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: spacing['24'],
    paddingBottom: spacing['24'],
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: spacing['8'],
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: spacing['8'],
  },
  button: {
    height: '48px',
  },
  price: {
    paddingTop: spacing['4'],
  },
}))
