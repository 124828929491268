import { Input, useInput } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const NEW_PAYMENT_POSTAL_INPUT_ID = 'new-payment-postal'

export interface PostalInputProps {
  className?: string
}

export const PostalInput: React.FC<PostalInputProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const postalChecker = (postal: string) => {
    if (!postal) {
      return `${t('payment.postalCode')} ${t('common.required')}`
    }
  }
  const [postal, postalInputProps] = useInput<string>({
    id: NEW_PAYMENT_POSTAL_INPUT_ID,
    initialValue: '',
    validator: postalChecker,
  })

  return (
    <Input
      dataCy={testIds.newPaymentMethodForm.creditCard.postal}
      className={cx(classes.base, className)}
      label={t('payment.postalCode')}
      placeholder={t('payment.postalCode')}
      value={postal}
      {...postalInputProps}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
