import { MainProvider } from '@alohi/flow-ui'
import '@alohi/flow-ui/dist/style.css'
import { ApolloProvider } from 'api/gql/provider/ApolloProvider.tsx'
import { GlobalContextsProvider } from 'contexts/GlobalContextsProvider.tsx'
import { STRIPE_CLIENT_ID } from 'env.ts'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import App from './app/App.tsx'
import i18n from './i18n'

const rootElement = document.getElementById('root')

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <I18nextProvider i18n={i18n}>
        <MainProvider stripeClientId={STRIPE_CLIENT_ID}>
          <BrowserRouter>
            <GlobalContextsProvider>
              <ApolloProvider>
                <App />
              </ApolloProvider>
            </GlobalContextsProvider>
          </BrowserRouter>
        </MainProvider>
      </I18nextProvider>
    </StrictMode>
  )
}
