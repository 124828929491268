import { Box, colors, formatNumber, spacing, Text } from '@alohi/flow-ui'
import { FaxPlusExtraNumberCartItem } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { ItemPrice } from './ItemPrice'

interface FaxPlusExtraNumberItemProps {
  className?: string
  item: FaxPlusExtraNumberCartItem
}
export const FaxPlusExtraNumberItem: React.FC<FaxPlusExtraNumberItemProps> = ({
  className,
  item,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const duration = t('faxNumber.months', {
    value_1: item.monthsToPay,
    count: item.monthsToPay,
  })

  if (!item.totalPrice) return
  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.container}>
        <Text isBold>
          {t('faxNumber.faxNumberX', {
            value_1: formatNumber(item.marketingData.name, 'INTERNATIONAL'),
          })}
        </Text>
        <ItemPrice totalPrice={item.totalPrice} />
      </Box>
      <Text className={classes.description}>{duration}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  description: {
    color: colors.neutral400,
  },
}))
