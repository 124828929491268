import { zendeskRequest } from '../zendesk'

interface Feedback {
  category: 'FEEDBACK'
  body: string
  subject: string
}

interface Baa_Request {
  category: 'BAA_REQUEST'
  signer_email: string
  signer_full_name: string
}

interface Soc2_Request {
  category: 'SOC2_REQUEST'
  signer_email: string
  signer_full_name: string
}

interface Deletion {
  category: 'DELETION_WEB' | 'DELETION_IOS'
}

interface HighValue {
  category: 'HIGH_VOLUME'
  destinations: string
  requested_pages: number
}

interface ContactSales {
  category: 'CONTACT_SALES'
  country: string
  message: string
  fax_numbers: string
  company_name: string
  company_size: string
  phone_number: string
  monthly_fax_transmission: string
}

type SupportRequest = Baa_Request | Soc2_Request | Feedback | Deletion | HighValue | ContactSales

export interface ContactUsParams {
  name: string
  email: string
  request: SupportRequest
}

export const contactSupport = async (params: { body: ContactUsParams }) => {
  return await zendeskRequest(`/support`, {
    method: 'POST',
    body: JSON.stringify({
      email: params.body.email,
      name: params.body.name,
      ...params.body.request,
    }),
  })
}
