import { useFragment } from '@apollo/client'
import {
  FaxPlusPlanShopItem,
  ShopItemsFragmentFragmentDoc,
  SignPlusPlanShopItem,
  SignPlusQesShopItem,
  SignPlusSmsShopItem,
} from 'api/gql/generated/graphql'
import { useMemo } from 'react'

export enum FaxShopItemPlanType {
  FREE = 'free',
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate',
}

export type FaxPlusPlanComputedShopItem = FaxPlusPlanShopItem & {
  computed: { type: FaxShopItemPlanType }
}

interface UseShopItems {
  signPlans: SignPlusPlanShopItem[]
  signQes: SignPlusQesShopItem[]
  signSms: SignPlusSmsShopItem[]
  faxPlans: FaxPlusPlanComputedShopItem[]
}

export const useShopItems = (): UseShopItems => {
  const { data } = useFragment({
    from: { __ref: `ShopItems:ShopItems` },
    fragment: ShopItemsFragmentFragmentDoc,
    fragmentName: 'ShopItemsFragment',
  })

  // Extract the shop items from the cache
  const faxPlans = useMemo(
    () => (data?.faxPlans?.items ?? []) as FaxPlusPlanShopItem[],
    [data?.faxPlans?.items]
  )
  const faxHighVolumePlans = useMemo(
    () => (data?.faxHighVolumePlans?.items ?? []) as FaxPlusPlanShopItem[],
    [data?.faxHighVolumePlans?.items]
  )

  const computedFaxPlans: UseShopItems['faxPlans'] = useMemo(
    () =>
      [...faxPlans, ...faxHighVolumePlans].map((item) => {
        let type: FaxShopItemPlanType = FaxShopItemPlanType.FREE
        if (item.numbersIncluded === 1) type = FaxShopItemPlanType.INDIVIDUAL
        if (item.numbersIncluded > 1) type = FaxShopItemPlanType.CORPORATE

        return {
          ...item,
          computed: {
            type,
          },
        }
      }) ?? [],
    [faxHighVolumePlans, faxPlans]
  )

  const signPlans = useMemo(
    () => (data?.signPlans?.items ?? []) as SignPlusPlanShopItem[],
    [data?.signPlans?.items]
  )
  const signApiPlans = useMemo(
    () => (data?.signApiPlans?.items ?? []) as SignPlusPlanShopItem[],
    [data?.signApiPlans?.items]
  )
  const computedSignPlans: UseShopItems['signPlans'] = useMemo(
    () =>
      [...signPlans, ...signApiPlans].map((item) => ({
        ...item,
      })) ?? [],
    [signApiPlans, signPlans]
  )

  const signQes = useMemo(
    () => (data?.signQes?.items ?? []) as SignPlusQesShopItem[],
    [data?.signQes?.items]
  )

  const signSms = useMemo(
    () => (data?.signSms?.items ?? []) as SignPlusSmsShopItem[],
    [data?.signSms?.items]
  )

  return {
    signPlans: computedSignPlans,
    signQes,
    signSms,
    faxPlans: computedFaxPlans,
  }
}
