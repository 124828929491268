import { Box, spacing, useBool, useOnMount } from '@alohi/flow-ui'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { tss } from 'tss-react'
import { NewPaymentMethodForm } from './components/NewPaymentMethodForm/NewPaymentMethodForm'
import { PaymentMethodList } from './components/PaymentMethodList/PaymentMethodList'
import { Settings } from './components/Settings/Settings'
import { NewPaymentMethodModal } from './modals/NewPaymentMethodModal'

interface PaymentMethodSectionProps {
  className?: string
  hasCorporateSettings?: boolean
}

export const PaymentMethodSection: React.FC<PaymentMethodSectionProps> = ({
  className,
  hasCorporateSettings,
}) => {
  const { classes, cx } = useStyles()

  const [isNewPaymentDisplayed, isNewPaymentDisplayedBool] = useBool(false)

  const {
    store: { newPaymentMethodSelectedType, newPaymentMethodErrorExtensions },
    defaultPaymentMethod,
    paymentMethods,
    updateStore,
    selectNewPaymentMethodType,
  } = usePaymentMethodApi()

  useOnMount(() => {
    if (defaultPaymentMethod?.id) {
      updateStore({
        selectedPaymentMethodId: defaultPaymentMethod?.id,
      })
    }
  })

  return (
    <>
      <Box className={cx(classes.base, className)}>
        {paymentMethods?.length ? (
          <PaymentMethodList
            paymentMethods={paymentMethods}
            onAddNewPaymentMethod={isNewPaymentDisplayedBool.setTrue}
          />
        ) : (
          <NewPaymentMethodForm
            className={classes.newPaymentForm}
            type={newPaymentMethodSelectedType}
            onTypeSelect={selectNewPaymentMethodType}
          />
        )}
        {hasCorporateSettings ? <Settings /> : null}

        {isNewPaymentDisplayed || newPaymentMethodErrorExtensions ? (
          <NewPaymentMethodModal
            onCancel={isNewPaymentDisplayedBool.setFalse}
            onConfirm={isNewPaymentDisplayedBool.setFalse}
          />
        ) : null}
      </Box>
    </>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginBottom: spacing['24'],
    position: 'relative',
    zIndex: 0,
  },
  newPaymentForm: {
    padding: 0,
  },
}))
