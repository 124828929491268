import {
  Box,
  breakpoints,
  Grid,
  GridItem,
  GridProps,
  Logo,
  spacing,
} from '@alohi/flow-ui'
import { tss } from 'tss-react'
import { ApiSwitch } from './components/ApiSwitch'
import { PeriodSwitch } from './components/PeriodSwitch'
import { AlohiProducts } from '../../PlanSelectionModal'

interface HeaderProps {
  className?: string
  isMonthlyPeriod: boolean
  onIsMonthlyPeriodChange: (isMonthlyPeriod: boolean) => void
  canToggleApi: boolean
  isApiSelected: boolean
  onApiToggle: () => void
  product: AlohiProducts
}

export const Header: React.FC<HeaderProps> = ({
  className,
  isMonthlyPeriod,
  onIsMonthlyPeriodChange,
  canToggleApi,
  isApiSelected,
  onApiToggle,
  product,
}) => {
  const { classes, cx } = useStyles()

  const templateAreas: GridProps['templateAreas'] = {
    base: '"filler" "period" "api"', // One column
    md: '"filler period api"', // One row
  }

  const gridTemplateRows: GridProps['gridTemplateRows'] = {
    base: `1fr 1fr 1fr`,
    md: '1fr',
  }

  const gridTemplateColumns: GridProps['gridTemplateColumns'] = {
    base: `100% 100% 100%`,
    md: '1fr 1fr 1fr',
  }

  return (
    <Box className={cx(classes.base, className)}>
      <Grid
        templateAreas={templateAreas}
        gridTemplateRows={gridTemplateRows}
        gridTemplateColumns={gridTemplateColumns}
        columnGap={spacing['16']}
      >
        <GridItem area={'filler'} className={classes.logoContainer}>
          <Logo
            className={classes.logo}
            variant={product === AlohiProducts.FAX ? 'fax-name' : 'sign-name'}
          />
        </GridItem>
        <GridItem area={'period'} className={classes.periodContainer}>
          <PeriodSwitch
            isMonthlyPeriod={isMonthlyPeriod}
            onIsMonthlyPeriodChange={onIsMonthlyPeriodChange}
          />
        </GridItem>
        <GridItem area={'api'} className={classes.apiToggleContainer}>
          {canToggleApi ? (
            <ApiSwitch
              isApiSelected={isApiSelected}
              onApiToggle={onApiToggle}
            />
          ) : null}
        </GridItem>
      </Grid>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: spacing['16'],
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    justifyContent: 'center',
  },
  periodContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      paddingBottom: spacing['16'],
    },
  },
  apiToggleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: spacing['16'],
    [breakpoints.down('md')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  logo: {
    height: 0,
    [breakpoints.down('md')]: {
      height: '25px',
    },
  },
}))
