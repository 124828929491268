import { Box, formatNumber, Message, Modal, spacing, Text } from '@alohi/flow-ui'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { Trans, useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { PurchaseNumbersErrorExtensions } from 'views/fax/ChangeNumber/context/store/store'

interface FaxNumbersFailedModalProps {
  className?: string
  purchaseNumbersErrorExtensions: PurchaseNumbersErrorExtensions
  onConfirm: () => void
}

export const FaxNumbersFailedModal: React.FC<FaxNumbersFailedModalProps> = ({
  className,
  purchaseNumbersErrorExtensions,
  onConfirm,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  return (
    <Modal
      variant="medium"
      className={cx(classes.base, className)}
      title={t('purchase.numberUnavailable')}
      onConfirm={onConfirm}
      confirmTitle={t('common.tryAgain')}
    >
      <Box className={classes.content}>
        <Message className={classes.messageContainer} variant="error">
          <Text variant="body" isBold>
            {t('purchase.paymentWasSuccessful')}
          </Text>
          <Text variant="body" isBold className={classes.line1}>
            {purchaseNumbersErrorExtensions.failedPurchaseNumbers?.length === 1
              ? t('purchase.numberPurchaseFailed', {
                  value_1: formatNumber(
                    purchaseNumbersErrorExtensions.failedPurchaseNumbers[0],
                    'INTERNATIONAL'
                  ),
                })
              : t('purchase.numbersPurchaseFailed')}
          </Text>
          <Text>
            <Trans
              i18nKey={t('purchase.chargeAmountHasBeenCreditedToYourAccount')}
              values={{
                value_1: formatCurrency({
                  amount: purchaseNumbersErrorExtensions?.reimbursedAmount ?? 0,
                  currency,
                }),
              }}
              components={{
                chargeAmount: (
                  <Text key={'chargeAmount'} className={classes.chargeAmount} isBold>
                    {''}
                  </Text>
                ),
              }}
            />
          </Text>
        </Message>
      </Box>
    </Modal>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  messageContainer: {
    //
  },
  line1: {
    marginBottom: spacing['16'],
  },
  chargeAmount: {
    display: 'inline-block',
  },
}))
