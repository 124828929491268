import { Period, PlanType } from 'api/gql/generated/graphql'
import { GOOGLE_TAG_MANAGER_ID } from 'env'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser'
import TagManager from 'react-gtm-module'

export enum GoogleAnalyticsCustomEvents {
  cartInitalization = 'cart_initialization',
  cartPurchaseSuccess = 'cart_purchase_success',
  cartPurchaseFailed = 'cart_purchase_failed',
}

type GtmDataLayerProduct = 'fax' | 'sign'
type GtmDataLayerOrigin = 'fax' | 'sign' | 'admin'

export interface GtmDataLayerSignSubscription {
  planType: PlanType | undefined
  period: Period | undefined
  api: boolean | undefined
}

export interface GtmDataLayerFaxSubscription {
  planType: PlanType | undefined
  period: Period | undefined
  highVolumePages: number | undefined
}

export interface GtmDataLayer {
  user: {
    id: string
    isInternalUser: boolean
    subscriptions: {
      sign: GtmDataLayerSignSubscription | undefined
      fax: GtmDataLayerFaxSubscription | undefined
    }
  }
  context: {
    origin: GtmDataLayerOrigin
    product: GtmDataLayerProduct
    page: string
  }
}

export interface GtmDataLayerCart {
  faxPlan?: Partial<GtmDataLayerFaxSubscription> & {
    customCount?: number
  }
  signPlan?: Partial<GtmDataLayerSignSubscription>
  faxNumbers?: {
    totalCount?: number
    customCount?: number
  }
  faxExtraNumbers?: {
    totalCount?: number
    customCount?: number
  }
  totalPrice: number | undefined
}

const checkIfUserIsInternal = (email: string): boolean => {
  return email.includes('@alohi.com')
}

export const useGoogleTagManager = () => {
  const { user } = useCurrentUser()
  const { activeFaxSubscription, activeSignSubscription } = useActiveSubscriptions()

  const initialize = (userId?: string) => {
    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER_ID,
      ...(userId
        ? {
            dataLayer: {
              user: {
                id: userId,
              },
            },
          }
        : undefined),
    })
  }

  const buildDataLayer = (params: { product: GtmDataLayerProduct; page: string }): GtmDataLayer => {
    return {
      user: {
        id: user.id,
        isInternalUser: checkIfUserIsInternal(user.email),
        subscriptions: {
          sign: activeSignSubscription.planItem.type
            ? {
                planType: activeSignSubscription.planItem.type,
                period: activeSignSubscription.planItem.period,
                api: activeSignSubscription.planItem.hasApi,
              }
            : undefined,
          fax: activeFaxSubscription.computed.planItem.type
            ? {
                planType: activeFaxSubscription.computed.planItem.type,
                period: activeFaxSubscription.computed.planItem.period,
                highVolumePages: activeFaxSubscription.computed.planItem.pages,
              }
            : undefined,
        },
      },
      context: {
        origin: 'admin',
        product: params.product,
        page: params.page,
      },
    }
  }

  const sendCustomEvent = (params: {
    event: string
    product: GtmDataLayerProduct
    page: string
    extraData?: {
      cart: GtmDataLayerCart
    }
  }) => {
    const baseDataLayer = buildDataLayer(params)
    TagManager.dataLayer({
      dataLayer: { ...params.extraData, ...baseDataLayer, event: params.event },
    })
  }

  return {
    initialize,
    buildDataLayer,
    sendCustomEvent,
  }
}
