import { useFragment, useMutation } from '@apollo/client'
import {
  AlohiCompensationCartItem,
  AlohiCreditCartItem,
  Cart,
  CartCancelPaymentDocument,
  CartConfirmPaymentDocument,
  CartDiscount,
  CartFragmentFragmentDoc,
  CartProcessDocument,
  CartProcessingInfo,
  CartSetDocument,
  FaxPlusExtraNumberCartItem,
  FaxPlusNumberCartItem,
  FaxPlusNumberReplacementCartItem,
  FaxPlusNumberSetupCartItem,
  FaxPlusPlanCartItem,
  PaymentMethod,
  SignPlusPlanCartItem,
  SignPlusQesCartItem,
  SignPlusSmsCartItem,
} from 'api/gql/generated/graphql'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'

export enum FaxCartItemPlanType {
  FREE = 'free',
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate',
}

export type CartItemsData =
  | (
      | AlohiCompensationCartItem
      | AlohiCreditCartItem
      | SignPlusPlanCartItem
      | SignPlusQesCartItem
      | SignPlusSmsCartItem
      | FaxPlusPlanCartItem
      | FaxPlusNumberCartItem
      | FaxPlusExtraNumberCartItem
      | FaxPlusNumberReplacementCartItem
      | FaxPlusNumberSetupCartItem
    )[]
  | undefined

export type CartItems = Omit<Cart, 'items'> & {
  items: CartItemsData
  isTrial: boolean
  isScheduled: boolean
  purchaseDate: Dayjs
}

export interface CartComputedItems {
  signPlanCartItem?: SignPlusPlanCartItem
  faxPlanCartItem?: FaxPlusPlanCartItem & {
    computed: { type: FaxCartItemPlanType }
  }
  faxNumberCartItems?: FaxPlusNumberCartItem[]
  faxExtraNumberCartItems?: FaxPlusExtraNumberCartItem[]
}

export const useCart = () => {
  const [mutateCartSet] = useMutation(CartSetDocument)
  const [mutateCartProcess] = useMutation(CartProcessDocument)
  const [mutateCartConfirmPayment] = useMutation(CartConfirmPaymentDocument)
  const [mutateCartCancelPayment] = useMutation(CartCancelPaymentDocument)

  const { data: cart } = useFragment({
    from: { __ref: 'Cart:{"__typename":"Cart"}' },
    fragment: CartFragmentFragmentDoc,
    fragmentName: 'CartFragment',
  })

  const items = cart?.items as CartItemsData

  const isTrial = useMemo(() => {
    const signPlanItem = items?.find((item) => item?.__typename === 'SignPlusPlanCartItem') as
      | SignPlusPlanCartItem
      | undefined
    return signPlanItem?.isTrial ?? false
  }, [items])

  const signPlanCartItem = useMemo(
    () =>
      items?.find((item) => item?.__typename === 'SignPlusPlanCartItem') as
        | SignPlusPlanCartItem
        | undefined,
    [items]
  )

  const faxPlanCartItem = useMemo(() => {
    const faxItem = items?.find((item) => item?.__typename === 'FaxPlusPlanCartItem') as
      | FaxPlusPlanCartItem
      | undefined

    let type: FaxCartItemPlanType = FaxCartItemPlanType.FREE
    if (faxItem && faxItem.numbersIncluded === 1) type = FaxCartItemPlanType.INDIVIDUAL
    if (faxItem && faxItem.numbersIncluded > 1) type = FaxCartItemPlanType.CORPORATE

    return faxItem ? { ...faxItem, computed: { type } } : undefined
  }, [items])

  const faxNumberCartItems = useMemo(
    () =>
      items?.filter(
        (item): item is FaxPlusNumberCartItem => item?.__typename === 'FaxPlusNumberCartItem'
      ),
    [items]
  )

  const faxExtraNumberCartItems = useMemo(
    () =>
      items?.filter(
        (item): item is FaxPlusExtraNumberCartItem =>
          item?.__typename === 'FaxPlusExtraNumberCartItem'
      ),
    [items]
  )

  const scheduledDate = useMemo(() => {
    if (cart.processingInfo?.__typename === 'CartScheduled') {
      return cart.processingInfo.processAt
    }
    if (cart.processingInfo?.__typename === 'CartTrial') {
      return cart.processingInfo.trialEndsAt
    }
    return undefined
  }, [cart.processingInfo])

  const purchaseDate = dayjs.unix(scheduledDate ?? dayjs().unix())

  const cartItems: CartItems = {
    id: cart.id ?? '',
    items,
    discount: cart.discount as CartDiscount | undefined,
    subtotal: cart.subtotal ?? 0,
    total: cart.total ?? 0,
    creditUsed: cart.creditUsed,
    paymentMethod: cart.paymentMethod as PaymentMethod,
    processingInfo: cart.processingInfo as CartProcessingInfo,
    isTrial,
    isScheduled: cart.processingInfo?.__typename === 'CartScheduled',
    purchaseDate,
  }

  const cartComputedItems: CartComputedItems = {
    signPlanCartItem,
    faxPlanCartItem,
    faxNumberCartItems,
    faxExtraNumberCartItems,
  }

  return {
    mutateCartSet,
    mutateCartProcess,
    mutateCartConfirmPayment,
    mutateCartCancelPayment,
    cart: cartItems,
    computedItems: cartComputedItems,
  }
}
