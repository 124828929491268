import { CountryCode, getI18nCountry, useToast } from '@alohi/flow-ui'
import {
  FaxNumberType,
  FaxPlusNumberShopItemCountryInfo,
  PlanType,
} from 'api/gql/generated/graphql'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useFaxNumberRequirementsCompliance = () => {
  const { t } = useTranslation()
  const toast = useToast()

  const { supportedCountries } = useFaxSupportedCountries()

  const getCountrySettings = useCallback(
    (params: {
      country: string
      numberType: FaxNumberType | undefined
    }): FaxPlusNumberShopItemCountryInfo | null | undefined => {
      if (!params.numberType) return undefined

      const supportedCountry = supportedCountries.find(
        (supportedCountry) => supportedCountry.iso === params.country
      )

      if (params.numberType === FaxNumberType.TollFree) {
        return supportedCountry?.tollFree
      } else {
        return supportedCountry?.local
      }
    },
    [supportedCountries]
  )

  const checkRequirementsCompliance = useCallback(
    (params: {
      country: CountryCode
      numberType: FaxNumberType | undefined
      planType: PlanType
    }) => {
      const requirements = getCountrySettings({
        country: params.country,
        numberType: params.numberType,
      })?.requirements?.minimumPlanTypeRequirement

      if (Boolean(requirements) && params.planType === PlanType.Basic) {
        const description =
          params.numberType === FaxNumberType.TollFree
            ? t('faxNumber.tollfreeRequiresPremium')
            : t('faxNumber.requiresPremium', {
                value_1: getI18nCountry(params.country)?.label,
              })
        toast({
          description,
          variant: 'info',
        })
        return false
      }

      return true
    },
    [getCountrySettings, t, toast]
  )

  return { getCountrySettings, checkRequirementsCompliance }
}
