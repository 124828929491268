import { useFragment } from '@apollo/client'
import { GeolocationFragmentFragmentDoc } from 'api/gql/generated/graphql'

export const useGeolocation = () => {
  const { data } = useFragment({
    from: { __ref: `Geolocation:Geolocation` },
    fragment: GeolocationFragmentFragmentDoc,
    fragmentName: 'GeolocationFragment',
  })

  return {
    iso: data.iso ?? 'US',
  }
}
