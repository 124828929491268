import { Box, CountryCode, Link, Modal, spacing, Text } from '@alohi/flow-ui'
import { FaxNumberType, FaxPlusNumberShopItems } from 'api/gql/generated/graphql'
import { websiteUrls } from 'helpers/urls'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { AvailableCustomNumbersList } from './components/AvailableCustomNumbersList/AvailableCustomNumbersList'
import { CustomNumberInput } from './components/CustomNumberInput'
import useSearchCustomNumber from './hooks/useSearchCustomNumber'

interface CustomNumberModalProps {
  className?: string
  country: CountryCode
  areaCode?: string | null
  numberType: FaxNumberType
  onCancel: () => void
  onCustomNumberSelect: (number: string) => void
}
export const CustomNumberModal: React.FC<CustomNumberModalProps> = ({
  className,
  country,
  areaCode,
  numberType,
  onCancel,
  onCustomNumberSelect,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const [customNumber, setCustomNumber] = useState('')
  const [highlightNumber, setHighlightNumber] = useState('')
  const [faxNumberShopItems, setFaxNumberShopItems] = useState<FaxPlusNumberShopItems | undefined>()

  const areaCodeNumber = areaCode ? parseInt(areaCode) : undefined

  const { isLoading, handleSearch } = useSearchCustomNumber({
    country,
    areaCode: areaCodeNumber,
    numberType,
    customNumber,
    setHighlightNumber,
    onShopItemChange: setFaxNumberShopItems,
  })

  const handleSelect = (number: string) => {
    onCancel()
    onCustomNumberSelect(number)
  }

  return (
    <Modal
      className={cx(classes.base, className)}
      title={t('faxPlans.customNumber')}
      onClose={onCancel}
      onCancel={onCancel}
      cancelTitle={t('common.back')}
      isAnimated
    >
      <Box className={classes.content}>
        <CustomNumberInput
          country={country}
          areaCode={areaCodeNumber}
          isLoading={isLoading}
          handleSearch={handleSearch}
          setCustomNumber={setCustomNumber}
        />
        {faxNumberShopItems?.id ? (
          <AvailableCustomNumbersList
            country={country}
            numberType={numberType}
            onSelect={handleSelect}
            highlight={highlightNumber}
            faxNumberShopItems={faxNumberShopItems}
          />
        ) : (
          <Box className={classes.portIn}>
            <Text>{t('faxPlans.portInYourNumber')}</Text>
            <Link href={websiteUrls.fax.help.numberPorting} withIcon isExternal>
              <Text>{t('common.learnMore')}</Text>
            </Link>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  content: {
    flexGrow: 1,
  },
  portIn: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing['4'],
  },
}))
