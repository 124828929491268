import {
  Box,
  Chip,
  IconButton,
  IconMenu,
  IconMenuProps,
  PaymentMethod as PaymentMethodSvg,
  PaymentMethodProps as PaymentMethodSvgProps,
  Radio,
  Text,
  capitalizeString,
  colors,
  radius,
  spacing,
} from '@alohi/flow-ui'
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons'
import { PaymentMethodStripeCardType } from 'api/gql/generated/graphql'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { testIds } from 'helpers/tests'
import { PaymentMethodData } from 'hooks/usePaymentMethods/usePaymentMethods'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { Infos } from './components/Infos'

interface PaymentMethodProps {
  className?: string
  paymentMethod: PaymentMethodData
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({ className, paymentMethod }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { selectNewPaymentMethodId, updateDefaultPaymentMethod, deletePaymentMethod } =
    usePaymentMethodApi()
  const isDefault = paymentMethod.default

  const radioRef = useRef<HTMLInputElement>(null)

  const paymentMethodSvgVariant = (() => {
    let variant: PaymentMethodSvgProps['variant'] = undefined
    if (paymentMethod.__typename === 'PaymentMethodPaypal') {
      variant = 'paypal'
    } else if (paymentMethod.__typename === 'PaymentMethodStripe') {
      switch (paymentMethod.cardType) {
        case PaymentMethodStripeCardType.Mastercard:
          variant = 'mastercard'
          break
        case PaymentMethodStripeCardType.Visa:
          variant = 'visa'
          break
        case PaymentMethodStripeCardType.Jcb:
          variant = 'jcb'
          break
        case PaymentMethodStripeCardType.Amex:
          variant = 'amex'
          break
        case PaymentMethodStripeCardType.Unionpay:
          variant = 'unionpay'
          break
      }
    }
    return variant
  })()

  const handleClick = () => {
    selectNewPaymentMethodId(paymentMethod.id)
  }

  const handleSetAsDefault = () => {
    updateDefaultPaymentMethod(paymentMethod.id)
  }

  const handleDelete = () => {
    try {
      deletePaymentMethod(paymentMethod.id)
    } catch {
      // Error caught in deletePaymentMethod
    }
  }

  const selectItems: IconMenuProps['items'] = [
    {
      id: 'default',
      label: t('payment.setAsDefault'),
      onClick: handleSetAsDefault,
    },
    {
      id: 'delete',
      label: 'Delete',
      item: <Text>{t('common.delete')}</Text>,
      onClick: handleDelete,
    },
  ]

  return (
    <Box className={cx(classes.base, className)} dataCy={testIds.paymentMethodsList.row}>
      <Box className={classes.leftContainer} onClick={handleClick}>
        <Radio value={paymentMethod.id} ref={radioRef} dataCy={testIds.paymentMethodsList.radio} />
        <Box className={classes.iconContainer}>
          <PaymentMethodSvg variant={paymentMethodSvgVariant} className={classes.icon} />
        </Box>
      </Box>

      <Infos className={classes.infos} paymentMethod={paymentMethod} />

      <Box className={classes.rightContainer}>
        {isDefault ? (
          <Chip className={classes.defaultChip} label={capitalizeString(t('common.default'))} />
        ) : (
          <IconMenu
            classNames={{ item: classes.item }}
            items={selectItems}
            placement="right-start"
            dataCys={testIds.paymentMethodsList.more}
          >
            <IconButton icon={faEllipsisVertical} />
          </IconMenu>
        )}
      </Box>
    </Box>
  )
}
const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 0,
    cursor: 'pointer',
  },
  iconContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    width: '44px',
    minWidth: '44px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: radius['4'],
    marginLeft: spacing['8'],
  },
  icon: {
    //
  },
  infos: {
    //
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: spacing['8'],
    width: '60px',
  },
  defaultChip: {
    //
  },
  item: {
    fontSize: '10px !important',
    whiteSpace: 'nowrap',
  },
}))
