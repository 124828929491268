import { useActiveFaxSubscription } from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'

export const useCanAccessFaxChangeNumber = (): boolean => {
  const {
    activeFaxSubscription: {
      computed: { numbers },
    },
  } = useActiveFaxSubscription()

  return Boolean(numbers.length)
}
