import { Box, colors, Helper, spacing, Text, Tooltip } from '@alohi/flow-ui'
import { FaxPlusExtraNumberCartItem } from 'api/gql/generated/graphql'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { FaxPlusExtraNumberItem } from '../FaxPlusExtraNumberItem'
import { ItemPrice } from '../ItemPrice'

interface FaxPlusExtraNumberItemGroupProps {
  className?: string
  items: FaxPlusExtraNumberCartItem[]
  isAnnual?: boolean
}
export const FaxPlusExtraNumberItemGroup: React.FC<FaxPlusExtraNumberItemGroupProps> = ({
  className,
  items,
  isAnnual,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const totalPrice = items.reduce((acc, current) => {
    return acc + current.totalPrice
  }, 0)

  const duration = t('faxNumber.months', {
    value_1: items[0]?.monthsToPay,
    count: items[0]?.monthsToPay,
  })

  const Label = useMemo(
    () => (
      <Box className={classes.tooltipContent}>
        <Text variant="small">
          {isAnnual
            ? t('faxNumber.eachAdditionalFaxLineCostsAnnual', {
                value_1: formatCurrency({ amount: items?.[0]?.totalPrice ?? 0, currency }),
              })
            : t('faxNumber.eachAdditionalFaxLineCosts', {
                value_1: formatCurrency({ amount: items?.[0]?.totalPrice ?? 0, currency }),
              })}
        </Text>
        {isAnnual ? (
          <Text className={classes.tooltipExtraInfo} variant="small">
            {t('faxNumber.youWillBeChargedForTheRemainingMonths')}
          </Text>
        ) : null}
      </Box>
    ),
    [classes.tooltipContent, classes.tooltipExtraInfo, currency, isAnnual, items, t]
  )

  if (items.length === 0) return
  if (items.length === 1) return <FaxPlusExtraNumberItem item={items[0]} />
  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.container}>
        <Text isBold>
          {t('faxNumber.faxNumbersX', {
            value_1: items.length,
          })}
        </Text>
        <Tooltip className={classes.tooltip} label={Label}>
          <Helper className={classes.helper} variant="small" />
        </Tooltip>
        <ItemPrice totalPrice={totalPrice} />
      </Box>
      <Text className={classes.description}>{duration}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipContent: {
    padding: spacing['8'],
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '290px',
  },
  helper: {
    marginLeft: spacing['8'],
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    color: colors.neutral400,
  },
  tooltipExtraInfo: {
    marginTop: spacing['8'],
  },
}))
