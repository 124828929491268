import { useCallback } from 'react'

import { useToast } from '@alohi/flow-ui'
import { useStripe } from '@stripe/react-stripe-js'
import { getFragmentData } from 'api/gql/generated'
import {
  CartDiscount,
  CartFragmentFragment,
  CartFragmentFragmentDoc,
  MutationCartSetArgs,
  OrderFragmentFragment,
} from 'api/gql/generated/graphql'
import { GraphQLError } from 'graphql'
import { getField, handleApiErrors } from 'helpers/graphql'
import { PaymentErrorExtensions } from 'helpers/payment'
import { useCart } from 'hooks/useCart/useCart'
import { useTranslation } from 'react-i18next'
import { useCartContext } from './store/context'
import { CartDataStore } from './store/store'

const SECURE_AUTH_PAYMENT_ERROR = '3DS canceled'

export function useCartApi() {
  const { store, dispatch } = useCartContext()
  const { t } = useTranslation()
  const toast = useToast()
  const stripe = useStripe()

  const {
    cart,
    computedItems,
    mutateCartSet,
    mutateCartProcess,
    mutateCartCancelPayment,
    mutateCartConfirmPayment,
  } = useCart()

  const updateStore = useCallback(
    (payload: Partial<CartDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const setCart = useCallback(
    async (params: MutationCartSetArgs) => {
      let discountCode = undefined
      if (params.discountCode === null) {
        discountCode = null
      } else if (params.discountCode) {
        discountCode = params.discountCode
      } else if (cart.discount?.__typename === 'AppliedDiscount') {
        discountCode = cart.discount.code
      }
      updateStore({
        isUpdating: true,
      })
      try {
        const response = await mutateCartSet({
          variables: {
            items: params.items,
            discountCode,
            useCredit: params.useCredit,
            schedule: params.schedule,
          },
        })
        const cartSet = getFragmentData(CartFragmentFragmentDoc, response.data?.cartSet)
        const discount = cartSet?.discount as CartDiscount | undefined
        updateStore({
          cartInput: params,
          discountCodeInvalid:
            discount?.__typename === 'InvalidDiscount' ? discount.error : undefined,
        })
        return cartSet
      } catch (error) {
        handleApiErrors(error, {
          errors: {
            LESS_THAN_MIN_AMOUNT_OF_ITEM_REQUESTED: () => {
              toast({
                description: t('common.serverError'),
                variant: 'error',
              })
            },
            MORE_THAN_MAX_AMOUNT_OF_ITEM_REQUESTED: () => {
              toast({
                description: t('common.serverError'),
                variant: 'error',
              })
            },
          },
          default: () => {
            toast({
              description: t('common.serverError'),
              variant: 'error',
            })
          },
        })
        throw error
      } finally {
        updateStore({
          isUpdating: false,
        })
      }
    },
    [cart.discount, mutateCartSet, t, toast, updateStore]
  )

  const setUseAvailableCredit = useCallback(
    async (useCredit: boolean) => {
      try {
        await setCart({ ...store.cartInput, useCredit })
      } catch (error) {
        // Error caught in setCart
      }
    },
    [setCart, store.cartInput]
  )

  const setDiscountCode = useCallback(
    async (code: string | null) => {
      try {
        await setCart({ ...store.cartInput, discountCode: code })
      } catch (error) {
        // Error caught in setCart
      }
    },
    [setCart, store.cartInput]
  )

  const scheduleCart = useCallback(
    async (schedule: boolean, params?: { useCredit?: boolean }) => {
      try {
        await setCart({
          ...store.cartInput,
          schedule,
          useCredit: params?.useCredit,
        })
      } catch (error) {
        // Error caught in setCart
      }
    },
    [setCart, store.cartInput]
  )

  const triggerNextStripeAction = useCallback(
    async (clientSecret: string) => {
      if (!stripe) {
        return
      }

      try {
        const response = await stripe.handleCardAction(clientSecret)
        const paymentIntent = response.paymentIntent
        const stripeError = response.error

        if (stripeError) {
          throw stripeError
        }

        if (paymentIntent) {
          const response = await mutateCartConfirmPayment()
          const cartConfirmPayment = getField(response.data?.cartConfirmPayment)

          if (cartConfirmPayment.__typename === 'ActiveOrder') {
            return cartConfirmPayment as OrderFragmentFragment
          }
        }
      } catch (error) {
        await mutateCartCancelPayment()
        throw new Error(SECURE_AUTH_PAYMENT_ERROR)
      }
    },
    [mutateCartCancelPayment, mutateCartConfirmPayment, stripe]
  )

  const processCart = useCallback(
    async (params?: { paymentMethodId?: string; waitForCompletion?: boolean }) => {
      updateStore({
        isProcessing: true,
      })
      try {
        const response = await mutateCartProcess({
          variables: {
            paymentMethod: params?.paymentMethodId,
            waitForCompletion: params?.waitForCompletion,
          },
        })
        const cartProcess = getField(response.data?.cartProcess)

        if (cartProcess.__typename === 'PaymentNextAction') {
          return await triggerNextStripeAction(cartProcess.clientSecret)
        } else if (cartProcess.__typename === 'ArchivedOrder') {
          return cartProcess as OrderFragmentFragment
        } else if (cartProcess.__typename === 'Cart') {
          return cartProcess as CartFragmentFragment
        }
      } catch (error) {
        handleApiErrors(error, {
          errors: {
            PAYMENT_ERROR: (extensions) => {
              updateStore({
                paymentErrorExtensions: extensions as PaymentErrorExtensions,
              })
            },
            PAYMENT_METHOD_NOT_FOUND: () => {
              toast({
                description: t('error.paymentMethodNotFound'),
                variant: 'error',
              })
              updateStore({
                paymentErrorExtensions: {
                  BRAINTREE_ERROR_CODE: undefined,
                  STRIPE_ERROR_CODE: 'payment_method_invalid_parameter',
                  STRIPE_DECLINE_CODE: undefined,
                },
              })
            },
            NUMBER_PURCHASE_FAILED: () => {
              // Handled in flows
            },
            ORDER_PROCESSING_FAILED: () => {
              toast({
                description: t('purchase.orderProcessingFailed'),
                variant: 'error',
              })
            },
          },
          default: () => {
            if ((error as GraphQLError)?.message === SECURE_AUTH_PAYMENT_ERROR) {
              updateStore({
                paymentErrorExtensions: {
                  BRAINTREE_ERROR_CODE: undefined,
                  STRIPE_ERROR_CODE: 'card_declined',
                  STRIPE_DECLINE_CODE: 'authentication_required',
                },
              })
            } else {
              toast({
                description: t('common.serverError'),
                variant: 'error',
              })
            }
          },
        })
        throw error
      } finally {
        updateStore({
          isProcessing: false,
        })
      }
    },
    [mutateCartProcess, t, toast, triggerNextStripeAction, updateStore]
  )

  return {
    store,
    cart,
    computedItems,
    updateStore,
    setCart,
    setDiscountCode,
    setUseAvailableCredit,
    processCart,
    scheduleCart,
  }
}
