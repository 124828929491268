import { Box } from '@alohi/flow-ui'
import { Period } from 'api/gql/generated/graphql'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useMemo } from 'react'
import { tss } from 'tss-react'
import { FaxHighVolumeFooter } from './components/FaxHighVolumeFooter'
import { Plan } from './components/Plan/Plan'

interface FaxPlansProps {
  className?: string
  isMonthlyPeriod: boolean
  onPlanSelected: () => void
}

export const FaxPlans: React.FC<FaxPlansProps> = ({
  className,
  isMonthlyPeriod,
  onPlanSelected,
}) => {
  const { classes, cx } = useStyles()

  const { faxPlans } = useShopItems()
  const planShopItems = useMemo(() => faxPlans.filter((faxPlan) => !faxPlan.highVolume), [faxPlans])

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content} key={isMonthlyPeriod.toString()}>
        {planShopItems
          ?.filter(
            (item) =>
              (item.period === Period.Monthly && isMonthlyPeriod) ||
              (item.period === Period.Annual && !isMonthlyPeriod)
          )
          .map((item) => <Plan key={item.id} item={item} onSelect={onPlanSelected} />)}
      </Box>
      <FaxHighVolumeFooter isMonthlyPeriod={isMonthlyPeriod} onPlanSelected={onPlanSelected} />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}))
