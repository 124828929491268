import { Box, breakpoints, Link, Logo, Modal, spacing, Text, useBool } from '@alohi/flow-ui'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { useCartApi } from 'contexts/cart/api'
import { websiteUrls } from 'helpers/urls'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { FaxPlans } from './components/FaxPlans/FaxPlans'
import { Header } from './components/Header/Header'
import { SignPlans } from './components/SignPlans/SignPlans'

export enum AlohiProducts {
  SIGN = 'sign',
  FAX = 'fax',
}

interface PlanSelectionModalProps {
  className?: string
  product: AlohiProducts
  onClose: () => void
}

export const PlanSelectionModal: React.FC<PlanSelectionModalProps> = ({
  className,
  product,
  onClose,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    activeSignSubscription: {
      planItem: { hasApi: signActiveSubHasApi },
    },
  } = useActiveSubscriptions()

  const {
    computedItems: { signPlanCartItem },
  } = useCartApi()

  const [isMonthlyPeriod, isMonthlyPeriodBool] = useBool(false)
  const [isApiSelected, isApiSelectedBool] = useBool(
    signActiveSubHasApi || Boolean(signPlanCartItem?.hasApi)
  )

  const canToggleApi = product === AlohiProducts.SIGN && !signActiveSubHasApi

  return (
    <Modal
      variant="full"
      onClose={onClose}
      className={cx(classes.base, className)}
      classNames={{
        closeIcon: classes.closeIcon,
      }}
    >
      <Box className={classes.wrapper}>
        <Logo
          className={classes.logo}
          variant={product === AlohiProducts.FAX ? 'fax-name' : 'sign-name'}
        />

        <Box className={classes.content}>
          <Header
            product={product}
            isMonthlyPeriod={isMonthlyPeriod}
            onIsMonthlyPeriodChange={isMonthlyPeriodBool.set}
            canToggleApi={canToggleApi}
            isApiSelected={isApiSelected}
            onApiToggle={isApiSelectedBool.toggle}
          />
          {
            {
              [AlohiProducts.FAX]: (
                <FaxPlans isMonthlyPeriod={isMonthlyPeriod} onPlanSelected={onClose} />
              ),
              [AlohiProducts.SIGN]: (
                <SignPlans
                  isMonthlyPeriod={isMonthlyPeriod}
                  onPlanSelected={onClose}
                  isApiSelected={isApiSelected}
                />
              ),
            }[product]
          }
        </Box>
        {product === AlohiProducts.SIGN ? (
          <Text className={classes.featureInDev} variant="small">
            {t('purchase.featuresCurrentlyInDevelopment')}
          </Text>
        ) : null}
        <Link
          className={classes.fullFeatureLink}
          classNames={{
            icon: classes.fullFeatureLinkIcon,
          }}
          variant="body"
          isBold
          withIcon
          icon={faChevronRight}
          href={product === AlohiProducts.FAX ? websiteUrls.fax.pricing : websiteUrls.sign.pricing}
          isExternal
        >
          {t('plans.checkFullFeatureSet')}
        </Link>
      </Box>
    </Modal>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  logo: {
    position: 'absolute',
    left: spacing['16'],
    top: 44,
    height: '25px',
    [breakpoints.down('md')]: {
      height: 0,
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing['32'],
    paddingLeft: spacing['48'],
    paddingRight: spacing['48'],
    width: '100%',
  },
  featureInDev: {
    marginTop: spacing['8'],
  },
  fullFeatureLink: {
    marginTop: spacing['8'],
  },
  fullFeatureLinkIcon: {
    marginLeft: 4,
  },
  closeIcon: {
    marginTop: spacing['16'],
    marginRight: spacing['16'],
    [breakpoints.down('md')]: {
      marginTop: 0,
      marginRight: 0,
    },
  },
}))
