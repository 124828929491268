import { PaymentMethodStore } from './store'

export interface PaymentMethodAction {
  type: 'UPDATE'
  payload: Partial<PaymentMethodStore>
}

export const PaymentMethodReducer = (
  state: PaymentMethodStore,
  action: PaymentMethodAction
): PaymentMethodStore => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
