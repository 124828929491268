import { Box, capitalizeString, colors, List, ListItem, spacing } from '@alohi/flow-ui'
import { IntOrInfinity } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface SignQuantitiesProps {
  className?: string
  hasApi: boolean
  maxAmountSeats: number
  templates: IntOrInfinity
  requestSignature: IntOrInfinity
}

export const SignQuantities: React.FC<SignQuantitiesProps> = ({
  className,
  hasApi,
  maxAmountSeats,
  templates,
  requestSignature,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const seatsDescription = (() => {
    if (maxAmountSeats === 1) {
      return capitalizeString(
        `${capitalizeString(`${t('perks.oneUser')}`.toLowerCase())}`.toLowerCase()
      )
    } else if (maxAmountSeats === 5) {
      return capitalizeString(`${t('perks.upToFiveUsers')}`.toLowerCase())
    } else {
      return capitalizeString(`${t('perks.multipleUsers')}`.toLowerCase())
    }
  })()

  const requestSignatureDescription = (() => {
    if (requestSignature.infinity) {
      if (hasApi) {
        return capitalizeString(`${t('perks.highVolumeRequests')}`.toLowerCase())
      } else {
        return capitalizeString(`${t('perks.unlimitedSignatures')}`.toLowerCase())
      }
    } else {
      return capitalizeString(
        `${t('perks.signaturesPerMonth', {
          value_1: requestSignature.value,
        })}`.toLowerCase()
      )
    }
  })()

  const templatesDescription = (() => {
    if (templates.infinity) {
      return capitalizeString(`${t('perks.unlimitedTemplates')}`.toLowerCase())
    } else {
      return capitalizeString(
        `${t('perks.templates', {
          count: templates.value,
          value_1: templates.value,
        })}`.toLowerCase()
      )
    }
  })()

  return (
    <Box className={cx(classes.base, className)}>
      <List listStyleType="disc">
        {hasApi ? null : (
          <ListItem className={classes.item} variant="small">
            {seatsDescription}
          </ListItem>
        )}
        <ListItem className={classes.item} variant="small">
          {requestSignatureDescription}
        </ListItem>
        <ListItem className={classes.item} variant="small">
          {templatesDescription}
        </ListItem>
      </List>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['16'],
    width: '100%',
    marginLeft: spacing['32'],
  },
  item: {
    marginTop: spacing['8'],
    color: colors.neutral700,
  },
}))
