import { Box, colors, Icon, IconButton, spacing, Text } from '@alohi/flow-ui'
import { faCircleXmark, faTag } from '@fortawesome/pro-solid-svg-icons'
import { formatCurrency } from 'helpers/payment'
import { testIds } from 'helpers/tests'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { tss } from 'tss-react'

interface ActivatedDiscountCodeProps {
  className?: string
  discountCode: string
  onClose: () => void
  discountPrice: number
}
export const ActivatedDiscountCode: React.FC<ActivatedDiscountCodeProps> = ({
  className,
  discountCode,
  onClose,
  discountPrice,
}) => {
  const { classes, cx } = useStyles()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  return (
    <Box className={cx(classes.base, className)}>
      <Icon className={classes.tagIcon} icon={faTag} />
      <Text className={classes.text} isBold>
        {discountCode}
      </Text>
      <Box data-cy={testIds.orderSummary.discountCodeClose}>
        <IconButton
          data-cy={'test'}
          onClick={onClose}
          variant="large"
          classNames={{ icon: classes.closeIcon }}
          icon={faCircleXmark}
        />
      </Box>
      <Box className={classes.filler} />
      <Text className={classes.priceText} isBold>
        {formatCurrency({
          amount: discountPrice,
          currency,
        })}
      </Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  tagIcon: {
    width: 16,
    height: 16,
    color: colors.green500,
    marginRight: spacing['4'],
  },
  text: {
    marginRight: spacing['4'],
    color: colors.green500,
  },
  closeIcon: {
    width: 15,
    height: 15,
  },
  filler: {
    flex: 1,
  },
  priceText: {
    color: colors.green500,
  },
}))
