import { Box, colors, spacing } from '@alohi/flow-ui'
import { ReactNode } from 'react'
import { tss } from 'tss-react'

interface MobileFooterProps {
  className?: string
  children: ReactNode
}
export const MobileFooter: React.FC<MobileFooterProps> = ({ className, children }) => {
  const { classes, cx } = useStyles()

  return <Box className={cx(classes.base, className)}>{children}</Box>
}

const useStyles = tss.create(() => ({
  base: {
    height: 'fit-content',
    width: '100%',
    borderTopWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    display: 'flex',
    alignItems: 'center',
    padding: spacing['16'],
  },
}))
