import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { SuccessModalContentProps } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { useViewContext } from './store/context'
import { ViewDataStore } from './store/store'

export function useViewApi() {
  const { store, dispatch } = useViewContext()
  const { t } = useTranslation()

  const {
    store: { selectedPaymentMethodId },
  } = usePaymentMethodApi()

  const { setCart: setCartApi, processCart: processCartApi } = useCartApi()

  const updateStore = useCallback(
    (payload: Partial<ViewDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const submit = useCallback(async (): Promise<SuccessModalContentProps | undefined> => {
    await processCartApi({
      paymentMethodId: selectedPaymentMethodId,
    })

    return {
      description: t('qes.successModalDescription'),
    }
  }, [processCartApi, selectedPaymentMethodId, t])

  const setCart = useCallback(
    async (params: { id: string }) => {
      try {
        await setCartApi({
          items: [
            {
              signQes: {
                id: params.id,
                itemAmount: 1,
              },
            },
          ],
        })
      } catch (error) {
        // Error caught in setCartApi
      }
    },
    [setCartApi]
  )

  return {
    store,
    updateStore,
    submit,
    setCart,
  }
}
