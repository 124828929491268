import { Box, spacing, SvgBox, Text } from '@alohi/flow-ui'
import TollFreeNumberIcon from 'assets/svg/numbers/area-toll-free.svg'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface TollFreeItemProps {
  className?: string
}

export const TollFreeItem: React.FC<TollFreeItemProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <SvgBox src={TollFreeNumberIcon} />
      <Text className={classes.mainItemText}>{t('faxNumber.tollFree')}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  mainItemText: {
    marginLeft: spacing['8'],
  },
}))
