import {
  Box,
  colors,
  CountryCode,
  getPrefixForCountry,
  Select,
  SelectProps,
  useDependencyChange,
  useInput,
} from '@alohi/flow-ui'
import { FaxNumberType, FaxPlusNumberArea, PlanType } from 'api/gql/generated/graphql'
import { testIds } from 'helpers/tests'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { MinimumPlanTypeRequirementChip } from '../CountrySelect/components/MinimumPlanTypeRequirementChip'
import { AreaCodeItem } from './components/AreaCodeItem'
import { AvailableAreaCodesHeader } from './components/AvailableAreaCodesHeader'
import { RandomItem } from './components/RandomItem'
import { TollFreeItem } from './components/TollFreeItem'

const DELIMITER = '\x1F'

export interface AreaCodeSelectProps {
  className?: string
  area: string | null | undefined
  numberType: FaxNumberType | null | undefined
  country: CountryCode
  onSelect: (params: {
    areaName: string | undefined
    areaPrefix: string | undefined
    numberType: FaxNumberType
  }) => void
  supportedAreas: FaxPlusNumberArea[]
  isDisabled?: boolean
  planType: PlanType
  disabledMinimumPlanTypeRequirement?: boolean
}

export const AreaCodeSelect: React.FC<AreaCodeSelectProps> = ({
  className,
  area,
  numberType = FaxNumberType.Local,
  country,
  onSelect,
  supportedAreas,
  isDisabled,
  planType,
  disabledMinimumPlanTypeRequirement,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const countryPrefix = getPrefixForCountry(country)

  const { supportedCountries } = useFaxSupportedCountries()
  const supportedCountry = supportedCountries.find(
    (supportedCountry) => supportedCountry.iso === country
  )

  const areaChecker = (type: string) => {
    if (!type) {
      return `${t('faxNumber.areaCode')} ${t('common.required')}`
    }
  }

  const [areaInputValue, areaInputProps] = useInput<string>({
    initialValue: 'random',
    validator: areaChecker,
    onChange: (value) => {
      if (value === 'random') {
        onSelect({ areaName: undefined, areaPrefix: undefined, numberType: FaxNumberType.Local })
      } else if (value === 'tollfree') {
        onSelect({ areaName: undefined, areaPrefix: undefined, numberType: FaxNumberType.TollFree })
      } else {
        const areaName = value.split(DELIMITER)[0]
        const areaPrefix = value.split(DELIMITER)[2]
        onSelect({ areaName, areaPrefix, numberType: FaxNumberType.Local })
      }
    },
  })

  useDependencyChange(() => {
    if (!numberType) {
      return
    } else if (numberType === FaxNumberType.TollFree) {
      if (areaInputValue !== 'tollfree') {
        areaInputProps.onChange('tollfree')
      }
    } else if (!area) {
      if (areaInputValue !== 'random') {
        areaInputProps.onChange('random')
      }
    }
  }, [area, numberType])

  const selectItems: SelectProps['items'] = useMemo(() => {
    const items: SelectProps['items'] = []
    items.push({
      id: 'random',
      label: t('faxNumber.randomAreaCode'),
      onClick: () => undefined,
      noCategory: true,
      position: 0,
      item: <RandomItem />,
    })

    if (supportedCountry?.tollFree) {
      const isItemDisabled =
        planType === PlanType.Basic &&
        disabledMinimumPlanTypeRequirement &&
        Boolean(supportedCountry?.tollFree?.requirements?.minimumPlanTypeRequirement)
      items.push({
        id: 'tollfree',
        label: t('faxNumber.tollFree'),
        onClick: () => undefined,
        noCategory: true,
        position: 1,
        item: <TollFreeItem />,
        isDisabled: isItemDisabled,
        rightComponent: (
          <Box className={classes.chipsContainer}>
            {supportedCountry?.tollFree?.requirements?.minimumPlanTypeRequirement ? (
              <MinimumPlanTypeRequirementChip
                planType={planType}
                minimumPlanTypeRequirement={
                  supportedCountry?.tollFree?.requirements?.minimumPlanTypeRequirement
                }
              />
            ) : null}
          </Box>
        ),
      })
    }
    if (supportedAreas.length) {
      items.push({
        id: 'header',
        label: '',
        position: 2,
        noCategory: true,
        renderRawItem: true,
        item: <AvailableAreaCodesHeader />,
      })
    }

    return items.concat(
      supportedAreas.map((area) => ({
        id: `${area.name}${DELIMITER}${countryPrefix}${DELIMITER}${area.prefix}`,
        label: `${area.name} ${countryPrefix} ${area.prefix}`,
        onClick: () => undefined,
        item: (
          <AreaCodeItem name={area.name} areaPrefix={area.prefix} countryPrefix={countryPrefix} />
        ),
      }))
    )
  }, [
    classes.chipsContainer,
    countryPrefix,
    disabledMinimumPlanTypeRequirement,
    planType,
    supportedAreas,
    supportedCountry?.tollFree,
    t,
  ])

  return (
    <Select
      className={cx(classes.base, className)}
      dataCys={testIds.fax.numbers.area}
      classNames={{
        list: classes.list,
        categoryHeader: classes.categoryHeader,
      }}
      value={areaInputValue}
      {...areaInputProps}
      label={t('faxNumber.areaCode')}
      placeholder={t('faxNumber.areaCode')}
      items={selectItems}
      withCategorizedLabels
      isSortingAlphabetically
      isDisabled={isDisabled}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  list: {
    maxHeight: '220px',
  },
  categoryHeader: {
    background: colors.neutral200,
  },
  chipsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))
