import { Box, spacing, Text } from '@alohi/flow-ui'
import { SignPlusSmsCartItem } from 'api/gql/generated/graphql'
import { tss } from 'tss-react'
import { ItemPrice } from './ItemPrice'

interface SignPlusSmsItemProps {
  className?: string
  item: SignPlusSmsCartItem
}
export const SignPlusSmsItem: React.FC<SignPlusSmsItemProps> = ({ className, item }) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.base, className)}>
      <Text isBold>{item.marketingData.name}</Text>
      <ItemPrice totalPrice={item.totalPrice} />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
}))
