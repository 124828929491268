import { Box, breakpoints, Button, colors, radius, spacing, Text } from '@alohi/flow-ui'
import { Period } from 'api/gql/generated/graphql'
import { FaxPlusPlanComputedShopItem, useShopItems } from 'hooks/useShopItems/useShopItems'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from 'views/fax/FaxPlanUpgrade/context/api'

interface FaxHighVolumeFooterProps {
  className?: string
  isMonthlyPeriod: boolean
  onPlanSelected: () => void
}

export const FaxHighVolumeFooter: React.FC<FaxHighVolumeFooterProps> = ({
  className,
  isMonthlyPeriod,
  onPlanSelected,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { faxPlans } = useShopItems()
  const faxHighVolumePlans = useMemo(
    () => faxPlans.filter((faxPlan) => faxPlan.highVolume),
    [faxPlans]
  )

  const monthlyHighVolumeNotAvailable = useMemo(
    () =>
      faxHighVolumePlans
        .filter((faxPlan) => faxPlan.period === Period.Monthly)
        .every((faxPlan) => faxPlan.notAvailable),
    [faxHighVolumePlans]
  )

  const annualHighVolumeNotAvailable = useMemo(
    () =>
      faxHighVolumePlans
        .filter((faxPlan) => faxPlan.period === Period.Annual)
        .every((faxPlan) => faxPlan.notAvailable),
    [faxHighVolumePlans]
  )

  const isHighVolumeDisabled =
    (monthlyHighVolumeNotAvailable && isMonthlyPeriod) ||
    (annualHighVolumeNotAvailable && !isMonthlyPeriod)

  const { updateStore } = useViewApi()

  const handleSelectPlan = () => {
    let newSelectedItem: FaxPlusPlanComputedShopItem | undefined = undefined
    if (isMonthlyPeriod) {
      newSelectedItem = faxHighVolumePlans.find(
        (item) => item.period === Period.Monthly && item.defaultForPeriod
      )
    } else {
      newSelectedItem = faxHighVolumePlans.find(
        (item) => item.period === Period.Annual && item.defaultForPeriod
      )
    }
    if (newSelectedItem) {
      updateStore({ selectedFaxPlanShopItemId: newSelectedItem.id })
    }
    onPlanSelected()
  }

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content}>
        <Box className={classes.titleContainer}>
          <Text className={classes.titleText} variant="h3">
            {t('highVolume.highVolume')}
          </Text>
        </Box>
        <Box className={classes.descriptionContainer}>
          <Text className={classes.titleText} isBold>
            {t('highVolume.everythingInEnterprise')}
          </Text>
          <Text className={classes.titleText}>{t('highVolume.discountedPrices')}</Text>
        </Box>
        <Box className={classes.buttonContainer}>
          <Button variant="dark" onClick={handleSelectPlan} isDisabled={isHighVolumeDisabled}>
            {t('plans.selectPlan')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: spacing['16'],
  },
  content: {
    background: colors.blue1000,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: spacing['16'],
    paddingLeft: spacing['32'],
    paddingRight: spacing['32'],
    borderRadius: radius['16'],
    minHeight: '96px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  titleContainer: {
    width: '100px',
  },
  titleText: {
    color: colors.neutral0,
  },
  descriptionContainer: {
    flex: 1,
    paddingLeft: spacing['40'],
    [breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: spacing['16'],
      paddingBottom: spacing['16'],
    },
  },
  buttonContainer: {
    flex: 0,
  },
}))
