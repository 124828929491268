import {
  ActiveSubscription,
  Period,
  PlanType,
  SignPlusPlanSubscriptionItem,
  SubscriptionItemFragmentFragment,
} from 'api/gql/generated/graphql'
import { useCustomer } from 'hooks/useCustomer/useCustomer'

export const useActiveSignSubscription = (): {
  activeSignSubscription: Omit<ActiveSubscription, 'items'> & {
    items: SubscriptionItemFragmentFragment[]
    isFree: boolean
    planItem: {
      name: string
      period: Period
      hasApi: boolean
      shopItemId: string
      totalSeats: number
      itemAmount: number
      type: undefined | PlanType
      isTrial: boolean
      marketingName: string
    }
  }
} => {
  const {
    customer: { subscriptions },
  } = useCustomer()
  const activeSubscriptions = subscriptions?.filter(
    (subscription) => subscription.__typename === 'ActiveSubscription'
  )
  const activeSignSubscription = activeSubscriptions?.find((subscription) =>
    (subscription.items as SubscriptionItemFragmentFragment[]).find(
      (item): item is SignPlusPlanSubscriptionItem =>
        item.__typename === 'SignPlusPlanSubscriptionItem'
    )
  )
  const activeSignSubscriptionItems = (activeSignSubscription?.items ??
    []) as unknown as SubscriptionItemFragmentFragment[]
  const activeSignSubscriptionSignItem = activeSignSubscriptionItems?.find(
    (item): item is SignPlusPlanSubscriptionItem =>
      item.__typename === 'SignPlusPlanSubscriptionItem'
  )

  return {
    activeSignSubscription: {
      id: activeSignSubscription?.id ?? '',
      items: activeSignSubscription?.items ?? [],
      isFree: !activeSignSubscription?.items?.length,
      expiresAt: activeSignSubscription?.expiresAt ?? 0,
      period: activeSignSubscription?.period ?? Period.Annual,
      nextCart: activeSignSubscription?.nextCart,
      autoRenew: activeSignSubscription?.autoRenew ?? false,
      lastRechargeDate: activeSignSubscription?.lastRechargeDate ?? 0,
      planItem: {
        name: activeSignSubscriptionSignItem?.name ?? '',
        type: activeSignSubscriptionSignItem?.planType,
        period: activeSignSubscriptionSignItem?.period ?? Period.Annual,
        hasApi: activeSignSubscriptionSignItem?.hasApi ?? false,
        shopItemId: activeSignSubscriptionSignItem?.shopItemId ?? '',
        totalSeats: activeSignSubscriptionSignItem?.totalSeats?.value ?? 1,
        isTrial: activeSignSubscriptionSignItem?.isTrial ?? false,
        itemAmount: activeSignSubscriptionSignItem?.itemAmount ?? 0,
        marketingName: activeSignSubscriptionSignItem?.marketingData.name ?? '',
      },
    },
  }
}
