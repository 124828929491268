import {
  Box,
  breakpoints,
  capitalizeString,
  spacing,
  Text,
} from '@alohi/flow-ui'
import { PaymentMethodData } from 'hooks/usePaymentMethods/usePaymentMethods'
import { useMemo } from 'react'
import { tss } from 'tss-react'

interface InfosProps {
  className?: string
  paymentMethod: PaymentMethodData
}

export const Infos: React.FC<InfosProps> = ({ className, paymentMethod }) => {
  const { classes, cx } = useStyles()

  const cardNumber = useMemo(() => {
    if (paymentMethod.__typename === 'PaymentMethodStripe') {
      return `${capitalizeString(paymentMethod.cardType.toLowerCase())} **** **** **** ${paymentMethod.last4}`
    }
    return ''
  }, [paymentMethod])

  const cardDate = useMemo(() => {
    if (paymentMethod.__typename === 'PaymentMethodStripe') {
      return `Exp. ${paymentMethod.expMonth}/${paymentMethod.expYear}`
    }
    return ''
  }, [paymentMethod])

  const cardAddress = useMemo(() => {
    if (paymentMethod.__typename === 'PaymentMethodStripe') {
      return `${capitalizeString(paymentMethod.billingAddress.address)}`
    }
    return ''
  }, [paymentMethod])

  const cardCity = useMemo(() => {
    if (paymentMethod.__typename === 'PaymentMethodStripe') {
      return `${capitalizeString(paymentMethod.billingAddress.zip)} ${capitalizeString(paymentMethod.billingAddress.city)}`
    }
    return ''
  }, [paymentMethod])

  return (
    <Box className={cx(classes.base, className)}>
      {paymentMethod.__typename === 'PaymentMethodPaypal' ? (
        <Text className={classes.paypalEmail}>{paymentMethod.email}</Text>
      ) : undefined}
      {paymentMethod.__typename === 'PaymentMethodStripe' ? (
        <Box className={classes.infoContainer}>
          <Box className={classes.numberContainer}>
            <Text>{cardNumber}</Text>
            <Text>{cardDate}</Text>
          </Box>

          <Box className={cx(classes.addressContainer)}>
            <Text>{cardAddress}</Text>
            <Text>{cardCity}</Text>
          </Box>
        </Box>
      ) : undefined}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing['8'],
    flexGrow: 1,
    overflow: 'hidden',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      maxWidth: '180px',
    },
  },
  numberContainer: {
    flex: 1,
  },
  addressContainer: {
    flex: 1,
    [breakpoints.down('sm')]: {
      marginTop: spacing['8'],
    },
  },
  paypalEmail: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [breakpoints.down('sm')]: {
      maxWidth: '180px',
      whiteSpace: 'normal',
    },
  },
}))
