import { Box, spacing } from '@alohi/flow-ui'
import { keyframes, tss } from 'tss-react'

interface DotAnimationProps {
  className?: string
  color?: string
}

export const DotAnimation: React.FC<DotAnimationProps> = ({ className, color = '#000' }) => {
  const { classes, cx } = useStyles({ color })

  return <Box className={cx(classes.loader, className)} />
}

const l7 = keyframes({
  '33%': {
    backgroundSize: 'calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%',
  },
  '50%': {
    backgroundSize: 'calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%',
  },
  '66%': {
    backgroundSize: 'calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%',
  },
})

const useStyles = tss.withParams<{ color: string }>().create(({ color }) => {
  return {
    loader: {
      marginLeft: spacing['8'],
      height: '5px',
      aspectRatio: '4',
      '--_g': `no-repeat radial-gradient(circle closest-side, ${color} 90%, #0000)`, // Use the color prop
      background: 'var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%',
      backgroundSize: 'calc(100%/3) 100%',
      animation: `${l7} 1s infinite linear`,
    },
  }
})
