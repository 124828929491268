import { useFragment, useQuery } from '@apollo/client'
import {
  CurrentUser,
  CurrentUserDocument,
  CurrentUserFragmentFragmentDoc,
  UserStatus,
  UserVerificationStatus,
} from 'api/gql/generated/graphql'
import { useAppApi } from 'contexts/app/api'

export const useCurrentUser = () => {
  const {
    store: { currentUserId },
  } = useAppApi()

  const { refetch: queryCurrentUser } = useQuery(CurrentUserDocument, {
    skip: true,
  })

  const { data } = useFragment({
    from: { __ref: `CurrentUser:${currentUserId}` },
    fragment: CurrentUserFragmentFragmentDoc,
    fragmentName: 'CurrentUserFragment',
  })

  const user: CurrentUser = {
    ...({
      id: '',
      email: '',
      name: '',
      lastName: '',
      status: UserStatus.Inactive,
      permissions: {
        assignAdmins: false,
        managePlan: false,
      },
      verification: {
        email: UserVerificationStatus.Unverified,
        phone: UserVerificationStatus.Unverified,
        skipEmailVerification: false,
      },
      licenses: [],
    } satisfies CurrentUser),
    ...(data as CurrentUser),
  }

  return {
    queryCurrentUser,
    isReady: Boolean(data.id),
    user,
  }
}
