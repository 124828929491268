import {
  Box,
  colors,
  Link,
  radius,
  spacing,
  Switch,
  Text,
} from '@alohi/flow-ui'
import { websiteUrls } from 'helpers/urls'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface ApiSwitchProps {
  className?: string
  isApiSelected: boolean
  onApiToggle: () => void
}

export const ApiSwitch: React.FC<ApiSwitchProps> = ({
  className,
  isApiSelected,
  onApiToggle,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onApiToggle()
  }

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content} onClick={handleClick}>
        <Switch
          className={classes.switch}
          isSwitched={isApiSelected}
          onSwitch={() => undefined}
        />
        <Text className={classes.text}>{t('common.with')}</Text>
        <Link
          onClick={(event) => event.stopPropagation()}
          className={classes.link}
          variant="body"
          withIcon
          href={websiteUrls.sign.api}
          isExternal
        >
          {t('plans.signPlusApi')}
        </Link>
      </Box>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    padding: spacing['16'],
    borderRadius: radius['8'],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    width: 'fit-content',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
  },
  text: {
    marginLeft: spacing['8'],
    marginRight: spacing['4'],
  },
  link: {
    textWrap: 'nowrap',
  },
  switch: {
    pointerEvents: 'none',
  },
}))
