import { Modal, useNavigationModalApi, useOnMount } from '@alohi/flow-ui'
import {
  SuccessModalContent,
  SuccessModalContentProps,
} from '../../SuccessModal/components/SuccessModalContent'

export const SuccessContent: React.FC<
  SuccessModalContentProps & {
    onConfirm?: () => void
  }
> = ({ className, description, onConfirm, customContainer }) => {
  const { updateStep } = useNavigationModalApi()

  useOnMount(() => {
    updateStep({
      title: '',
      onConfirm,
      onCancel: undefined,
      onClose: undefined,
    })
  })

  return (
    <Modal.Content>
      <SuccessModalContent
        className={className}
        description={description}
        customContainer={customContainer}
      />
    </Modal.Content>
  )
}

export default SuccessContent
