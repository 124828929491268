import { CountryCode } from '@alohi/flow-ui'
import { FaxNumberType, RegulatoryBundleStatus } from 'api/gql/generated/graphql'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useCallback } from 'react'

export const useBundles = () => {
  const {
    customer: {
      regulatoryData: { bundles },
    },
  } = useCustomer()

  const checkIfBundlesAvailable = useCallback(
    (params: { country?: CountryCode; numberType?: FaxNumberType }) => {
      if (params.country === 'GB' && params.numberType) {
        const approvedOrPendingBundles = bundles.filter(
          (bundle) =>
            bundle.iso === params.country &&
            bundle.numberType === params.numberType &&
            [RegulatoryBundleStatus.TwilioApproved, RegulatoryBundleStatus.PendingReview].includes(
              bundle.status
            )
        )

        return approvedOrPendingBundles.length > 0
      }

      const approvedOrPendingBundles = bundles.filter(
        (bundle) =>
          bundle.iso === params.country &&
          [RegulatoryBundleStatus.TwilioApproved, RegulatoryBundleStatus.PendingReview].includes(
            bundle.status
          )
      )
      return approvedOrPendingBundles.length > 0
    },
    [bundles]
  )

  const extractBundles = useCallback(
    (params: {
      country?: CountryCode
      numberType?: FaxNumberType
      statuses: RegulatoryBundleStatus[]
    }) => {
      // Extract the filtered bundles
      const filteredBundles = bundles.filter((bundle) => {
        if (params.country === 'GB' && params.numberType) {
          return (
            bundle.iso === params.country &&
            bundle.numberType === params.numberType &&
            params.statuses.includes(bundle.status)
          )
        }
        if (params.country) {
          return bundle.iso === params.country && params.statuses.includes(bundle.status)
        } else {
          return params.statuses.includes(bundle.status)
        }
      })

      // Create a map for status order
      const statusOrder = new Map(params.statuses.map((status, index) => [status, index]))

      // Sort bundles based on the order of statuses
      return filteredBundles.sort((a, b) => {
        const orderA = statusOrder.get(a.status) ?? Number.MAX_VALUE
        const orderB = statusOrder.get(b.status) ?? Number.MAX_VALUE
        return orderA - orderB
      })
    },
    [bundles]
  )

  return {
    bundles,
    extractBundles,
    checkIfBundlesAvailable,
  }
}
