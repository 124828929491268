import { Modal } from '@alohi/flow-ui'
import { FaxPlusNumberCartItem } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { NumbersTable } from './components/NumbersTable/NumbersTable'

interface NumbersDetailModalProps {
  className?: string
  onClose?: () => void
  items: FaxPlusNumberCartItem[]
}
export const NumbersDetailModal: React.FC<NumbersDetailModalProps> = ({
  className,
  onClose,
  items,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Modal
      className={cx(classes.base, className)}
      classNames={{
        body: classes.body,
      }}
      title={t('faxNumber.faxNumbers')}
      onClose={onClose}
      isAnimated
    >
      <NumbersTable className={classes.table} items={items} />
    </Modal>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  body: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  table: {
    flexGrow: 1,
  },
}))
