import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  ApolloProvider as GenericApolloProvider,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import {
  IS_MONITORING_ENABLED,
  useMonitoring,
} from 'api/gql/provider/hooks/useMonitoring/useMonitoring'
import { useAppApi } from 'contexts/app/api'
import {
  ENVIRONMENT_NAME,
  FAX_X_CLIENT_ID,
  FAX_X_CLIENT_SECRET,
  GRAPHQL_ENDPOINT,
  KIBANA_RUM_URL,
  NODE_ENV,
  SIGN_X_CLIENT_ID,
  SIGN_X_CLIENT_SECRET,
  VERSION,
} from 'env'
import { getAppType, getCustomBranchByClient } from 'helpers/common'
import * as React from 'react'
import { LoginRefreshTokenDocument } from '../generated/graphql'
import { cache } from './settings/cache'

export interface ApolloProviderProps {
  children: React.ReactNode
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ children }) => {
  const {
    store: { accessToken, tokenType },
    updateStore: updateAppStore,
  } = useAppApi()

  const { apm } = useMonitoring()
  const isKibanaEnabled = IS_MONITORING_ENABLED && Boolean(KIBANA_RUM_URL)
  const isFeatureBranch = ENVIRONMENT_NAME === 'local'

  function getAuthorizationHeader(params: {
    tokenType: string | undefined
    accessToken: string | undefined
  }) {
    if (!params.accessToken) {
      return null
    }

    switch (params.tokenType) {
      case 'Bearer':
        return `Bearer ${params.accessToken}`
      case 'ApiKey':
        return `ApiKey ${params.accessToken}`
      default:
        return null
    }
  }

  const httpLink = new HttpLink({
    uri: isFeatureBranch ? getCustomBranchByClient() || GRAPHQL_ENDPOINT : GRAPHQL_ENDPOINT,
    credentials: 'include',
  })

  const authLink = new ApolloLink((operation, forward) => {
    let clientId = ''
    let clientSecret = ''
    let clientVersion = ''

    if (getAppType() === 'sign') {
      clientId = SIGN_X_CLIENT_ID ?? ''
      clientSecret = SIGN_X_CLIENT_SECRET ?? ''
      clientVersion = VERSION ?? ''
    } else if (getAppType() === 'fax') {
      clientId = FAX_X_CLIENT_ID ?? ''
      clientSecret = FAX_X_CLIENT_SECRET ?? ''
      clientVersion = VERSION ?? ''
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'X-Client-ID': clientId,
        'X-Client-Secret': clientSecret,
        'X-Client-Version': clientVersion,
        Authorization: getAuthorizationHeader({
          tokenType,
          accessToken,
        }),
      },
    }))

    return forward(operation)
  })

  const responseLink = new ApolloLink((operation, forward) => {
    // Breadcrumb that request started
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `${operation.operationName}: Request`,
      level: 'info',
      data: { operationName: operation.operationName },
    })

    // Kibana APM: add a transaction span
    const transaction = isKibanaEnabled ? apm?.getCurrentTransaction() : undefined
    const span = transaction?.startSpan(`GraphQL: ${operation.operationName}`, 'graphql')

    return forward(operation).map((response) => {
      const context = operation.getContext()
      const responseHeaders = context.response?.headers

      if (responseHeaders) {
        const requestId = responseHeaders.get('X-Request-ID')
        const cfRayId = responseHeaders.get('Cf-Ray')

        Sentry.addBreadcrumb({
          category: 'graphql',
          message: `${operation.operationName}: Response Headers`,
          level: 'info',
          data: { requestId, cfRayId },
        })

        if (requestId && isKibanaEnabled) {
          span?.addLabels({
            x_request_id: requestId,
            cf_ray_id: cfRayId,
          })
        }
      }

      span?.end()
      return response
    })
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    const context = operation.getContext()
    const responseHeaders = context.response?.headers
    const requestId = responseHeaders?.get('X-Request-ID') ?? ''
    const cfRayId = responseHeaders?.get('Cf-Ray') ?? ''

    const retryWithNewAccessToken = () => {
      const genericClient = new ApolloClient<NormalizedCacheObject>({
        cache,
        link: ApolloLink.from([authLink, httpLink]),
      })

      return fromPromise(
        genericClient.mutate({ mutation: LoginRefreshTokenDocument }).then(({ data }) => {
          if (data?.loginRefreshToken?.__typename === 'LoginTokenResponse') {
            // Update store with new tokens
            updateAppStore({
              accessToken: data.loginRefreshToken.accessToken,
              tokenType: data.loginRefreshToken.tokenType,
            })

            const newAuthorizationHeader = getAuthorizationHeader({
              accessToken: data.loginRefreshToken.accessToken,
              tokenType: data.loginRefreshToken.tokenType,
            })
            if (newAuthorizationHeader) {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: newAuthorizationHeader,
                },
              }))
            }
          } else {
            throw new Error('Failed to refresh token.')
          }
        })
      ).flatMap(() => forward(operation))
    }

    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        if (
          // @FIXME: BE to send us a real error key here
          graphQLError.message.includes('Reason: not authenticated')
        ) {
          // Try to refresh token once
          return retryWithNewAccessToken()
        } else {
          Sentry.withScope((scope) => {
            const fieldNames = graphQLErrors
              .map((error) => error.path?.join(' -> ') || 'unknown')
              .join(', ')
            const errorCode =
              (graphQLError.extensions?.code as string | undefined) || 'UNKNOWN_ERROR_CODE'
            const transactionName = `GraphQL Error: ${operation.operationName} (${fieldNames})`

            scope.setTransactionName(transactionName)
            scope.setFingerprint([
              operation.operationName,
              fieldNames,
              errorCode,
              graphQLError.message,
            ])
            scope.setLevel('error')

            // Tags for filtering in Sentry
            scope.setTags({
              'graphql.name': operation.operationName,
              'graphql.info.field_names': fieldNames,
              'graphql.info.error_code': errorCode,
              'graphql.debug.x_request_id': requestId,
              'graphql.debug.cf_ray_id': cfRayId,
            })

            // Extra data for debugging
            scope.setExtras({
              variables: JSON.stringify(operation.variables, null, 2),
              response: JSON.stringify(graphQLError, null, 2),
              headers: JSON.stringify(operation.getContext().headers, null, 2),
            })

            Sentry.captureException(new Error(graphQLError.message))
          })
        }
      }
    }

    if (networkError) {
      Sentry.withScope((scope) => {
        const transactionName = `Network Error: ${operation.operationName} - ${networkError.message}`

        scope.setTransactionName(transactionName)
        scope.setFingerprint(['NETWORK_ERROR', operation.operationName])
        scope.setLevel('error')

        // Tags for filtering in Sentry
        scope.setTags({
          'request.name': operation.operationName,
          'request.error.name': networkError.name,
          'request.error.message': networkError.message,
        })

        // Extra data for debugging
        scope.setExtras({
          error: JSON.stringify(networkError, null, 2),
          variables: JSON.stringify(operation.variables, null, 2),
          headers: JSON.stringify(operation.getContext().headers, null, 2),
        })

        Sentry.captureException(new Error(networkError.message))
      })
    }
  })

  const link = ApolloLink.from([authLink, responseLink, errorLink, httpLink])

  const client = new ApolloClient({
    connectToDevTools: NODE_ENV !== 'production',
    link,
    cache,
  })

  return <GenericApolloProvider client={client}>{children}</GenericApolloProvider>
}
