import { InputPayment, useInput } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface PaymentInputProps {
  className?: string
}

export const PaymentInput: React.FC<PaymentInputProps> = ({ className }) => {
  const { cx, classes } = useStyles()
  const { t } = useTranslation()

  const [, paymentInputProps] = useInput<string>({
    initialValue: '',
  })

  return (
    <InputPayment
      dataCy={testIds.newPaymentMethodForm.creditCard.infos}
      className={cx(classes.base, className)}
      label={t('payment.cardInfos')}
      emptyErrorLabel={`${t('payment.cardInfos')} ${t('common.required')}`}
      {...paymentInputProps}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
