import { Box, CountryCode } from '@alohi/flow-ui'
import { PlanType, SetFaxNumberCartItemInput } from 'api/gql/generated/graphql'
import { PriceType } from 'components/fax/FaxNumberSection/components/NumbersList/components/UniqueNumber/UniqueNumber'
import { FaxNumberSection } from 'components/fax/FaxNumberSection/FaxNumberSection'
import { useCartApi } from 'contexts/cart/api'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useFaxNumberRequirementsCompliance } from 'hooks/useFaxNumberRequirementsCompliance/useFaxNumberRequirementsCompliance'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { tss } from 'tss-react'
import { useViewApi } from 'views/fax/FaxPlanUpgrade/context/api'

interface PlanRequiredNumberSectionProps {
  className?: string
  idx: number
}

export const PlanRequiredNumberSection: React.FC<PlanRequiredNumberSectionProps> = ({
  className,
  idx,
}) => {
  const { classes, cx } = useStyles()

  const { supportedCountries } = useFaxSupportedCountries()

  const { faxPlans } = useShopItems()

  const { checkRequirementsCompliance } = useFaxNumberRequirementsCompliance()

  const {
    computedItems: { faxPlanCartItem, faxNumberCartItems },
  } = useCartApi()
  const firstCartNumber = faxNumberCartItems?.find((item) => item.idx === 0)
  const secondCartNumber = faxNumberCartItems?.find((item) => item.idx === 1)
  const faxNumberCartItem = idx === 0 ? firstCartNumber : idx === 1 ? secondCartNumber : undefined
  const planTypeCart = faxPlanCartItem?.planType ?? PlanType.Basic

  const {
    store: { selectedFirstFaxNumberCartInput, selectedSecondFaxNumberCartInput },
    selectedFaxPlanShopItem,
    setPlanCart,
  } = useViewApi()

  const faxNumberCartInput =
    idx === 0
      ? selectedFirstFaxNumberCartInput
      : idx === 1
        ? selectedSecondFaxNumberCartInput
        : undefined

  const handleUpdateCart = async (params: {
    index?: number
    cartInput?: Maybe<Partial<SetFaxNumberCartItemInput>>
  }) => {
    try {
      const cartInput = params.cartInput
      if (!selectedFaxPlanShopItem) return

      if (cartInput?.iso || cartInput?.numberType) {
        if (
          !checkRequirementsCompliance({
            country: (cartInput.iso as CountryCode) ?? (faxNumberCartInput?.iso as CountryCode),
            numberType: cartInput.numberType ?? faxNumberCartInput?.numberType,
            planType: planTypeCart,
          })
        ) {
          const compatiblePlan = faxPlans?.find(
            (faxPlan) =>
              faxPlan.planType === PlanType.Premium &&
              faxPlan.period === selectedFaxPlanShopItem.period
          )
          if (!compatiblePlan) return
          await setPlanCart(
            compatiblePlan,
            {
              firstNumber: idx === 0 ? cartInput : undefined,
              secondNumber: idx === 1 ? cartInput : undefined,
            },
            {
              // Avoid the cart update here as plan change is handled by the FaxUpgradePlan
              skipCartUpdate: true,
            }
          )
          return
        }
      }

      await setPlanCart(selectedFaxPlanShopItem, {
        firstNumber: idx === 0 ? cartInput : undefined,
        secondNumber: idx === 1 ? cartInput : undefined,
      })
    } catch {
      // Error caught in setCart
    }
  }

  return (
    <Box className={cx(classes.base, className)}>
      <FaxNumberSection
        supportedCountries={supportedCountries}
        numberCartInputs={faxNumberCartInput ? [faxNumberCartInput] : []}
        numberCartItems={faxNumberCartItem ? [faxNumberCartItem] : []}
        onUpdateCart={handleUpdateCart}
        planType={planTypeCart}
        priceType={PriceType.setup}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
