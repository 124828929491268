import { Modal, NavigationModalSteps, useBool, useFormApi } from '@alohi/flow-ui'
import { PaymentMethodType } from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { NEW_PAYMENT_COUNTRY_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/CountrySelect'
import { NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/NameInput'
import { NEW_PAYMENT_POSTAL_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/PostalInput'
import { NEW_PAYMENT_STREET_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/StreetInput'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { PaymentErrorExtensions } from 'helpers/payment'
import lodash from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { SuccessModalContentProps } from '../SuccessModal/components/SuccessModalContent'
import AddPaymentMethodContent from './components/AddPaymentMethodContent'
import { ErrorContent } from './components/ErrorContent'
import { Footer } from './components/Footer'
import SuccessContent from './components/SuccessContent'

export const VIEWS = {
  ERROR: 'error',
  ADD_PAYMENT_METHOD: 'addPaymentMethod',
  ADD_PAYMENT_METHOD_ERROR: 'addPaymentMethodError',
  SUCCESS: 'success',
}

interface PaymentErrorModalProps {
  className?: string
  paymentErrorExtensions?: PaymentErrorExtensions
  onCancel: () => void
  onProcess: () => Promise<SuccessModalContentProps | undefined>
}
export const PaymentErrorModal: React.FC<PaymentErrorModalProps> = ({
  className,
  paymentErrorExtensions,
  onCancel,
  onProcess,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { navigateBack } = useRoutesApi()

  const {
    store: { canSubmit, tooltip },
    readInput,
  } = useFormApi()

  const {
    store: {
      hasNewPaymentMethodFailed,
      isNewPaymentMethodLoading,
      isPaymentDefaultLoading,
      newPaymentMethodErrorExtensions,
      selectedPaymentMethodId,
    },
    defaultPaymentMethod,
    paymentMethods,
    updateDefaultPaymentMethod,
    addPaypalPaymentMethod,
    addStripePaymentMethod,
    updateStore: updatePaymentMethodStore,
  } = usePaymentMethodApi()

  const {
    store: { isProcessing },
    updateStore,
  } = useCartApi()

  const [previousSelectedPaymentMethodId, setPreviousSelectedPaymentMethodId] = useState<
    string | undefined
  >(selectedPaymentMethodId)
  const [previousPaymentErrorExtensions, setPreviousPaymentErrorExtensions] =
    useState(paymentErrorExtensions)

  const selectedPaymentMethod = paymentMethods.find(
    (method) => method.id === selectedPaymentMethodId
  )
  const firstPaypalPaymentMethod = paymentMethods.find(
    (method) => method.__typename === 'PaymentMethodPaypal'
  )
  const firstStripePaymentMethod = paymentMethods.find(
    (method) => method.__typename === 'PaymentMethodStripe'
  )
  const switchToType =
    selectedPaymentMethod?.methodType === PaymentMethodType.Card
      ? PaymentMethodType.Paypal
      : PaymentMethodType.Card

  const [step, setStep] = useState(VIEWS.ERROR)
  const [successProps, setSuccessModalProps] = useState<SuccessModalContentProps | undefined>()
  const [isSwitchingPaymentMethod, isSwitchingPaymentMethodBool] = useBool(false)

  const isLoading =
    isNewPaymentMethodLoading || isPaymentDefaultLoading || isProcessing || isSwitchingPaymentMethod

  const handleCancel = useCallback(() => {
    if (step === VIEWS.ERROR || step === VIEWS.ADD_PAYMENT_METHOD) {
      onCancel()
    } else {
      setStep(VIEWS.ERROR)
    }
  }, [onCancel, step])

  const handleRetry = useCallback(async () => {
    try {
      setSuccessModalProps(await onProcess())
      setStep(VIEWS.SUCCESS)
    } catch (error) {
      // Error caught in stores
    }
  }, [onProcess])

  const handleSwitchMethod = useCallback(async () => {
    isSwitchingPaymentMethodBool.setTrue()

    try {
      if (switchToType === PaymentMethodType.Card && firstStripePaymentMethod) {
        await updateDefaultPaymentMethod(firstStripePaymentMethod.id)
      } else if (switchToType === PaymentMethodType.Paypal && firstPaypalPaymentMethod) {
        await updateDefaultPaymentMethod(firstPaypalPaymentMethod.id)
      } else {
        if (step === VIEWS.ERROR) {
          setStep(VIEWS.ADD_PAYMENT_METHOD)
          // Makes the hasPaymentFailed still raised but clear the data so that
          // a new error raising during the add payment method flow can be detected again
          updateStore({
            paymentErrorExtensions: {
              BRAINTREE_ERROR_CODE: undefined,
              STRIPE_DECLINE_CODE: undefined,
              STRIPE_ERROR_CODE: undefined,
            },
          })
        } else {
          setStep(VIEWS.ERROR)
        }
      }
    } catch (error) {
      // Error caught in stores
    } finally {
      isSwitchingPaymentMethodBool.setFalse()
    }
  }, [
    firstPaypalPaymentMethod,
    firstStripePaymentMethod,
    isSwitchingPaymentMethodBool,
    step,
    switchToType,
    updateDefaultPaymentMethod,
    updateStore,
  ])

  const handleAddStripe = useCallback(async () => {
    try {
      const name = readInput<string>(NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID)
      const country = readInput<string>(NEW_PAYMENT_COUNTRY_INPUT_ID)
      const street = readInput<string>(NEW_PAYMENT_STREET_INPUT_ID)
      const postalCode = readInput<string>(NEW_PAYMENT_POSTAL_INPUT_ID)

      if (!name || !country || !street || !postalCode) {
        throw new Error('Missing parameters')
      }

      await addStripePaymentMethod({
        country,
        name,
        postalCode,
        street,
      })
    } catch (error) {
      // Error caught in stores
    }
  }, [addStripePaymentMethod, readInput])

  const handleAddPaypal = useCallback(
    async (token: string) => {
      try {
        await addPaypalPaymentMethod({
          token,
        })
      } catch (error) {
        // Error caught in stores
      }
    },
    [addPaypalPaymentMethod]
  )

  const handleAddPaymentRetry = useCallback(() => {
    updatePaymentMethodStore({
      newPaymentMethodErrorExtensions: undefined,
    })
    setStep(VIEWS.ADD_PAYMENT_METHOD)
  }, [updatePaymentMethodStore])

  const steps: NavigationModalSteps = useMemo(
    () => ({
      [VIEWS.ERROR]: {
        id: VIEWS.ERROR,
        children: (
          <ErrorContent
            paymentErrorExtensions={paymentErrorExtensions}
            title={t('paymentError.title')}
            description={t('paymentError.description')}
            onCancel={handleCancel}
            onConfirm={handleRetry}
          />
        ),
      },
      [VIEWS.ADD_PAYMENT_METHOD]: {
        id: VIEWS.ADD_PAYMENT_METHOD,
        children: (
          <AddPaymentMethodContent
            isConfirmDisabled={!canSubmit}
            confirmTooltip={tooltip}
            isLoading={isLoading}
            type={switchToType}
            onCancel={handleCancel}
            onConfirm={handleAddStripe}
            onPaypalApprove={handleAddPaypal}
          />
        ),
      },
      [VIEWS.ADD_PAYMENT_METHOD_ERROR]: {
        id: VIEWS.ADD_PAYMENT_METHOD_ERROR,
        children: (
          <ErrorContent
            title={t('payment.addMethodErrorTitle')}
            description={t('paymentError.addNewPaymentDescription')}
            paymentErrorExtensions={newPaymentMethodErrorExtensions}
            onConfirm={handleAddPaymentRetry}
            confirmTitle={t('common.retry')}
          />
        ),
      },
      [VIEWS.SUCCESS]: {
        id: VIEWS.SUCCESS,
        children: <SuccessContent {...successProps} onConfirm={navigateBack} />,
      },
    }),
    [
      paymentErrorExtensions,
      t,
      handleCancel,
      handleRetry,
      canSubmit,
      tooltip,
      isLoading,
      switchToType,
      handleAddStripe,
      handleAddPaypal,
      newPaymentMethodErrorExtensions,
      handleAddPaymentRetry,
      successProps,
      navigateBack,
    ]
  )

  useEffect(() => {
    if (selectedPaymentMethodId !== previousSelectedPaymentMethodId) {
      handleRetry()
      setPreviousSelectedPaymentMethodId(selectedPaymentMethodId)
    }
  }, [defaultPaymentMethod, handleRetry, previousSelectedPaymentMethodId, selectedPaymentMethodId])

  useEffect(() => {
    if (hasNewPaymentMethodFailed) {
      updatePaymentMethodStore({
        newPaymentMethodErrorExtensions: undefined,
      })
      setStep(VIEWS.ADD_PAYMENT_METHOD_ERROR)
    }
  }, [hasNewPaymentMethodFailed, updatePaymentMethodStore])

  // If paymentErrorExtensions updates, then a new error was triggered during
  // the modal flow (purchase after adding new payment method for instance)
  useEffect(() => {
    if (
      paymentErrorExtensions &&
      !lodash.isEqual(paymentErrorExtensions, previousPaymentErrorExtensions)
    ) {
      if (
        paymentErrorExtensions.BRAINTREE_ERROR_CODE ||
        paymentErrorExtensions.STRIPE_DECLINE_CODE ||
        paymentErrorExtensions.STRIPE_ERROR_CODE
      ) {
        setStep(VIEWS.ERROR)
      }
      setPreviousPaymentErrorExtensions(paymentErrorExtensions)
    }
  }, [paymentErrorExtensions, previousPaymentErrorExtensions])

  return (
    <Modal
      variant="medium"
      className={cx(classes.base, className)}
      steps={steps}
      stepId={step}
      onClose={onCancel}
      isAnimated
      customFooter={
        step === VIEWS.ERROR ? (
          <Footer
            isSwitchingPaymentMethod={isSwitchingPaymentMethod}
            step={step}
            onCancel={handleCancel}
            onRetry={handleRetry}
            onSwitchMethod={handleSwitchMethod}
          />
        ) : undefined
      }
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    overflow: 'hidden',
  },
}))
