import { Box } from '@alohi/flow-ui'
import { Period } from 'api/gql/generated/graphql'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { tss } from 'tss-react'
import { Plan } from './Plan/Plan'

interface SignPlansProps {
  className?: string
  isMonthlyPeriod: boolean
  onPlanSelected: () => void
  isApiSelected: boolean
}

export const SignPlans: React.FC<SignPlansProps> = ({
  className,
  isMonthlyPeriod,
  onPlanSelected,
  isApiSelected,
}) => {
  const { classes, cx } = useStyles()

  const { signPlans } = useShopItems()

  const planShopItems = signPlans.filter((signPlan) => isApiSelected === signPlan.hasApi)

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content} key={isMonthlyPeriod.toString()}>
        {planShopItems
          ?.filter(
            (item) =>
              (item.period === Period.Monthly && isMonthlyPeriod) ||
              (item.period === Period.Annual && !isMonthlyPeriod)
          )
          .map((item) => (
            <Plan
              key={item.id}
              item={item}
              onSelect={onPlanSelected}
              isMonthlyPeriod={isMonthlyPeriod}
            />
          ))}
      </Box>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}))
