import { Box, colors } from '@alohi/flow-ui'
import { tss } from 'tss-react'
import './app.css'
import { InitOverlay } from './components/InitOverlay'
import { Routes } from './components/Routes/Routes'
import { useInitApp } from './hooks/useInitApp/useInitApp'

function App() {
  const { classes } = useStyles()

  useInitApp()

  return (
    <Box className={classes.base}>
      <Routes />
      <InitOverlay />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    width: '100%',
    minHeight: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: colors.neutral100,
  },
}))

export default App
