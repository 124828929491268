import { Box, colors, spacing, Text } from '@alohi/flow-ui'
import { AlohiCompensationCartItem } from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { formatDefaultDate } from 'helpers/date'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { ItemPrice } from './ItemPrice'

interface AlohiCompensationItemProps {
  className?: string
  item: AlohiCompensationCartItem
}
export const AlohiCompensationItem: React.FC<AlohiCompensationItemProps> = ({
  className,
  item,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    cart: { purchaseDate },
  } = useCartApi()

  const formattedStart = formatDefaultDate(purchaseDate.unix())
  const formattedEnd = formatDefaultDate(item.compensatesFor?.[0].expiresAt)

  const subscriptionPeriod = `${formattedStart} - ${formattedEnd}`

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.container}>
        <Text isBold>{t('purchase.proratedCompensation')}</Text>
        <Text className={classes.description}>{subscriptionPeriod}</Text>
      </Box>
      <ItemPrice totalPrice={item.totalPrice} />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  description: {
    color: colors.neutral400,
  },
}))
