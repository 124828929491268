import { Box, breakpoints, Card, colors, Divider, spacing } from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { AddDiscountCode } from './components/AddDiscountCode/AddDiscountCode'
import { AvailableCredit } from './components/AvailableCredit'
import { CartItems } from './components/CartItems/CartItems'
import { TotalContainer } from './components/TotalContainer/TotalContainer'
import { SuccessModalContentProps } from './components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'

interface OrderSummarySectionProps {
  className?: string
  onProcess: () => Promise<SuccessModalContentProps | undefined>
  successRedirectionUrl?: string
}
export const OrderSummarySection: React.FC<OrderSummarySectionProps> = ({
  className,
  onProcess,
  successRedirectionUrl,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    store: { isUpdating, isProcessing },
  } = useCartApi()

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content}>
        <Card className={classes.card} title={t('purchase.orderSummary')}>
          <CartItems />
          <Divider className={classes.divider} />
          <AddDiscountCode />
          <AvailableCredit />
          <TotalContainer onProcess={onProcess} successRedirectionUrl={successRedirectionUrl} />
        </Card>
        {isUpdating || isProcessing ? <Box className={classes.updateOverlay} /> : null}
      </Box>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    top: spacing['32'],
    width: '477px',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    maxWidth: '100%',
  },
  content: {
    [breakpoints.up('lg')]: {
      position: 'absolute',
    },
    width: '477px',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    maxWidth: '100%',
  },
  divider: {
    marginTop: spacing['16'],
    marginBottom: spacing['16'],
  },
  updateOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: colors.neutral0,
    opacity: 0.3,
  },
  card: {
    [breakpoints.down('sm')]: {
      border: 'none',
      padding: 0,
    },
  },
}))
