import { FormContextProvider, Modal, useBool, useFormApi, useToast } from '@alohi/flow-ui'
import { handleApiErrors } from 'helpers/graphql'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { COMPANY_NAME_INPUT_ID, Content } from './components/Content'

interface CompanyNameModalProps {
  className?: string
  onClose: () => void
}

export const CompanyNameModal: React.FC<CompanyNameModalProps> = (props) => {
  return (
    <FormContextProvider>
      <CompanyNameModalContent {...props} />
    </FormContextProvider>
  )
}

export const CompanyNameModalContent: React.FC<CompanyNameModalProps> = ({
  className,
  onClose,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const toast = useToast()

  const {
    store: { canSubmit, tooltip },
    readInput,
    checkFormValidity,
  } = useFormApi()

  const { mutateCustomerUpdateCompanyName } = useCustomer()

  const [isLoading, isLoadingBool] = useBool(false)

  const handleConfirm = async () => {
    try {
      if (!(await checkFormValidity())) return

      const companyName = readInput<string>(COMPANY_NAME_INPUT_ID)

      isLoadingBool.setTrue()
      mutateCustomerUpdateCompanyName({
        variables: {
          companyName,
        },
      })
      onClose()
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
        },
      })
    } finally {
      isLoadingBool.setFalse()
    }
  }

  return (
    <Modal
      className={cx(classes.base, className)}
      title={t('organization.addCompanyInfos')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      onPressEnter={handleConfirm}
      confirmTitle={t('common.save')}
      isConfirmLoading={isLoading}
      isConfirmDisabled={!canSubmit}
      confirmTooltip={tooltip}
    >
      <Content />
    </Modal>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
