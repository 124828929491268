import { FAX_API_AFFOGATO, VERSION } from 'env'
import { request } from '../request'

export const zendeskRequest = async <T>(
  endpoint: string,
  options: RequestInit = {}
): Promise<T> => {
  return await request<T>(`${FAX_API_AFFOGATO}${endpoint}`, {
    ...options,
    headers: {
      'X-Zang-App': `platform=web;appname=fax.plus;appver=${VERSION}`,
      'X-Zang-App-Version': 'web',
    },
  })
}
