import { Box, colors, List, ListItem, spacing } from '@alohi/flow-ui'
import { PlanType } from 'api/gql/generated/graphql'
import { formatLocalizedNumber } from 'helpers/common'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import {
  PAGES_PER_FAX_BASIC,
  PAGES_PER_FAX_BUSINESS,
  PAGES_PER_FAX_ENTERPRISE,
  PAGES_PER_FAX_PREMIUM,
} from 'views/fax/FaxPlanUpgrade/components/FaxPlanSelectSection/components/Perks'

interface FaxQuantitiesProps {
  className?: string
  planType: PlanType
  extraPageRate: number
  pages: number
}

export const FaxQuantities: React.FC<FaxQuantitiesProps> = ({
  planType,
  className,
  extraPageRate,
  pages,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const features = (() => {
    switch (planType) {
      case PlanType.Basic:
        return [
          t('faxFeatures.freePages', {
            value_1: formatLocalizedNumber({ num: pages }),
          }),
          t('faxFeatures.perPage', {
            value_1: formatCurrency({ amount: extraPageRate, currency }),
          }),
          t('faxFeatures.maxPagesPer', {
            value_1: formatLocalizedNumber({ num: PAGES_PER_FAX_BASIC }),
          }),
          t('faxFeatures.oneFaxNumber'),
          t('faxFeatures.oneMemberIncluded'),
        ]
      case PlanType.Premium:
        return [
          t('faxFeatures.freePages', {
            value_1: formatLocalizedNumber({ num: pages }),
          }),
          t('faxFeatures.perPage', {
            value_1: formatCurrency({ amount: extraPageRate, currency }),
          }),
          t('faxFeatures.maxPagesPer', {
            value_1: formatLocalizedNumber({ num: PAGES_PER_FAX_PREMIUM }),
          }),
          t('faxFeatures.oneFaxNumber'),
          t('faxFeatures.oneMemberIncluded'),
        ]
      case PlanType.Business:
        return [
          t('faxFeatures.freePages', {
            value_1: formatLocalizedNumber({ num: pages }),
          }),
          t('faxFeatures.perPage', {
            value_1: formatCurrency({ amount: extraPageRate, currency }),
          }),
          t('faxFeatures.maxPagesPer', {
            value_1: formatLocalizedNumber({ num: PAGES_PER_FAX_BUSINESS }),
          }),
          t('faxFeatures.multipleFaxNumbers'),
          t('faxFeatures.amountMembersIncluded', { value_1: t('common.five') }),
        ]
      case PlanType.Enterprise:
        return [
          t('faxFeatures.freePages', {
            value_1: formatLocalizedNumber({ num: pages }),
          }),
          t('faxFeatures.perPage', {
            value_1: formatCurrency({ amount: extraPageRate, currency }),
          }),
          t('faxFeatures.maxPagesPer', {
            value_1: formatLocalizedNumber({ num: PAGES_PER_FAX_ENTERPRISE }),
          }),
          t('faxFeatures.multipleFaxNumbers'),
          t('faxFeatures.amountMembersIncluded', {
            value_1: t('common.unlimited'),
          }),
        ]
    }
  })()

  return (
    <Box className={cx(classes.base, className)}>
      <List listStyleType="disc">
        {features.map((feature, index) => (
          <ListItem key={index} className={classes.item} variant="small">
            {feature}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['16'],
    width: '100%',
    marginLeft: spacing['32'],
  },
  item: {
    color: colors.neutral700,
    marginTop: spacing['8'],
  },
}))
