import {
  Box,
  FormContextProvider,
  LogoFrame,
  SectionDivider,
  spacing,
  StepDivider,
  Text,
  useOnMount,
} from '@alohi/flow-ui'
import {
  FaxNumberChannel,
  FaxNumberType,
  InitFlowFaxAddNumberDocument,
  RegulatoryBundleStatus,
} from 'api/gql/generated/graphql'
import { FlowLayout } from 'components/common/FlowLayout/FlowLayout/FlowLayout'
import { PaymentMethodSection } from 'components/common/PaymentMethodSection/PaymentMethodSection'
import { useAppApi } from 'contexts/app/api'
import { useCartApi } from 'contexts/cart/api'
import { websiteUrls } from 'helpers/urls'
import { useActiveFaxSubscription } from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'
import { useBundles } from 'hooks/useBundles/useBundles'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { useGeolocation } from 'hooks/useGeolocation/useGeolocation'
import { useInitFlow } from 'hooks/useInitFlow/useInitFlow'
import { usePermissions } from 'hooks/usePermissions/usePermissions'
import { FaxNumbersFailedModal } from 'modals/fax/FaxNumbersFailedModal'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { AddExtraNumberSection } from './components/AddExtraNumberSection/AddExtraNumberSection'
import { useViewApi } from './context/api'
import { ViewContextProvider } from './context/ViewContextProvider'
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics/useGoogleAnalytics'

export const AddNumber: React.FC = () => {
  const { isFlowReady } = useInitFlow(InitFlowFaxAddNumberDocument)
  if (!isFlowReady) return
  return (
    <FormContextProvider autoScroll>
      <ViewContextProvider>
        <AddNumberContent />
      </ViewContextProvider>
    </FormContextProvider>
  )
}

export const AddNumberContent: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { gtmCartInit } = useGoogleAnalytics()

  const { extractBundles } = useBundles()

  const { getFaxSupportedIso } = useFaxSupportedCountries()

  const { iso } = useGeolocation()
  const faxSupportedIso = getFaxSupportedIso(iso)

  const { activeFaxSubscription } = useActiveFaxSubscription()

  const {
    cart,
    computedItems: { faxExtraNumberCartItems },
  } = useCartApi()

  const { canAccessAddFaxNumber } = usePermissions()

  const { redirectToUnauthorized } = useAppApi()

  const {
    store: { selectedNumberCartInputs, purchaseNumbersErrorExtensions },
    updateStore,
    submit,
    setCart,
  } = useViewApi()

  // Display Payment Method Section
  const hasPaymentMethodSection = !(cart.total === 0 && Boolean(cart.creditUsed))

  // Plan Selection section index
  const addNumberSectionIndex = 1
  // Payment Details section index
  const paymentDetailSectionIndex = addNumberSectionIndex + 1

  const retry = async () => {
    updateStore({
      purchaseNumbersErrorExtensions: undefined,
    })
    try {
      if (selectedNumberCartInputs) {
        await setCart(
          [...selectedNumberCartInputs].map((selectedNumberCartInput) => ({
            ...selectedNumberCartInput,
            number: null,
          }))
        )
      }
    } catch {
      // Nothing to do
    }
  }

  useOnMount(() => {
    if (!canAccessAddFaxNumber) {
      redirectToUnauthorized()
    } else {
      setCart([
        {
          iso: faxSupportedIso,
          numberType: FaxNumberType.Local,
          period: activeFaxSubscription.period,
          bundleSid: extractBundles({
            country: faxSupportedIso,
            statuses: [RegulatoryBundleStatus.TwilioApproved, RegulatoryBundleStatus.PendingReview],
            numberType: FaxNumberType.Local,
          })?.[0]?.sid,
        },
      ])
    }
  })

  // Send event to GA
  useOnMount(() => {
    if (faxExtraNumberCartItems) {
      gtmCartInit({
        faxExtraNumbers: {
          totalCount: faxExtraNumberCartItems.length,
          customCount:
            faxExtraNumberCartItems.filter(
              (faxExtraNumberCartItem) => faxExtraNumberCartItem.channel === FaxNumberChannel.Custom
            )?.length ?? 0,
        },
        totalPrice: cart.total,
      })
    }
  }, Boolean(faxExtraNumberCartItems?.length))

  if (!canAccessAddFaxNumber) return
  return (
    <FlowLayout
      className={classes.base}
      header={
        <SectionDivider iconFrame={<LogoFrame variant="fax" />}>
          <Text className={classes.title} variant="h3">
            {t('addNumbers.purchaseFaxNumber')}
          </Text>
        </SectionDivider>
      }
      onProcess={submit}
      successRedirectionUrl={websiteUrls.fax.numbers}
    >
      <Box className={classes.container}>
        <StepDivider className={classes.firstStepDivider} index={addNumberSectionIndex}>
          <Text isBold>{t('addNumbers.yourNumbers')}</Text>
        </StepDivider>
        <AddExtraNumberSection />

        {hasPaymentMethodSection ? (
          <>
            <StepDivider className={classes.stepDivider} index={paymentDetailSectionIndex}>
              <Text isBold>{t('payment.paymentDetails')}</Text>
            </StepDivider>
            <PaymentMethodSection />
          </>
        ) : null}
      </Box>
      {purchaseNumbersErrorExtensions ? (
        <FaxNumbersFailedModal
          purchaseNumbersErrorExtensions={purchaseNumbersErrorExtensions}
          onConfirm={retry}
        />
      ) : null}
    </FlowLayout>
  )
}
const useStyles = tss.create(() => ({
  base: {
    //
  },
  firstStepDivider: {
    marginBottom: spacing['24'],
  },
  stepDivider: {
    marginTop: spacing['48'],
    marginBottom: spacing['24'],
  },
  container: {
    position: 'relative',
    zIndex: 0,
  },
  title: {
    flexGrow: 1,
  },
}))
