import { Box, colors, List, ListItem, spacing, Text } from '@alohi/flow-ui'
import { faClose } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { PlanType } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface FaxFeaturesProps {
  className?: string
  planType: PlanType
}

export const FaxFeatures: React.FC<FaxFeaturesProps> = ({ className, planType }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const title = (() => {
    switch (planType) {
      case PlanType.Basic:
        return `${t('features.keyFeatures')}`
      case PlanType.Premium:
        return t('features.everythingPlus', {
          value_1: 'Basic',
        })
      case PlanType.Business:
        return t('features.everythingPlus', {
          value_1: 'Premium',
        })
      case PlanType.Enterprise:
        return t('features.everythingPlus', {
          value_1: 'Business',
        })
    }
  })()

  const features = (() => {
    switch (planType) {
      case PlanType.Basic:
        return [
          t('faxFeatures.emailToFax'),
          t('faxFeatures.mobileApp'),
          t('faxFeatures.unlimitedFaxStorage'),
          t('faxFeatures.multipleFaxRecipients'),
        ]
      case PlanType.Premium:
        return [
          t('faxFeatures.tollFreeFaxNumber'),
          t('faxFeatures.faxNumberPorting'),
          t('faxFeatures.exportFaxLogs'),
          t('faxFeatures.switchOffFaxReception'),
        ]
      case PlanType.Business:
        return [
          t('faxFeatures.teamSetup'),
          t('faxFeatures.adminConsole'),
          t('faxFeatures.slackIntegration'),
          t('faxFeatures.faxNumbersManagement'),
        ]
      case PlanType.Enterprise:
        return [
          t('faxFeatures.zapierIntegration'),
          t('faxFeatures.faxPlusApi'),
          t('faxFeatures.dataResidency'),
          t('faxFeatures.sso'),
        ]
    }
  })()

  const unsupportedFeatures = (() => {
    switch (planType) {
      case PlanType.Basic:
        return []
      case PlanType.Premium:
        return []
      case PlanType.Business:
        return []
      case PlanType.Enterprise:
        return []
    }
  })()

  return (
    <Box className={cx(classes.base, className)}>
      <Text className={classes.title}>{title}</Text>

      <List listStyleType="none" className={classes.list}>
        {features.map((feature) => (
          <ListItem
            key={feature}
            icon={faCheck}
            className={classes.item}
            classNames={{
              icon: classes.icon,
            }}
            variant="medium"
          >
            {feature}
          </ListItem>
        ))}
        {unsupportedFeatures.map((feature) => (
          <ListItem
            key={feature}
            icon={faClose}
            className={classes.item}
            classNames={{
              icon: classes.closeIcon,
            }}
            variant="medium"
          >
            {feature}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['24'],
    width: '100%',
  },
  title: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: spacing['16'],
  },
  list: {
    //
  },
  item: {
    color: colors.neutral700,
    marginTop: spacing['8'],
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: colors.blue400,
    width: '15px',
    height: '15px',
  },
  closeIcon: {
    color: colors.red500,
    width: '15px',
    height: '15px',
  },
}))
