import { Box, Button, colors, spacing } from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { VIEWS } from '../PaymentErrorModal'

interface FooterProps {
  className?: string
  step: string
  isSwitchingPaymentMethod: boolean
  onCancel: () => void
  onRetry: () => void
  onSwitchMethod: () => void
}
export const Footer: React.FC<FooterProps> = ({
  className,
  step,
  isSwitchingPaymentMethod,
  onCancel,
  onRetry,
  onSwitchMethod,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    store: { isProcessing },
  } = useCartApi()
  const {
    store: { selectedPaymentMethodId, isPaymentDefaultLoading },
    paymentMethods,
  } = usePaymentMethodApi()
  const selectedPaymentMethod = paymentMethods.find(
    (method) => method.id === selectedPaymentMethodId
  )
  const isLoading =
    isPaymentDefaultLoading || isProcessing || isSwitchingPaymentMethod

  return (
    <Box className={cx(classes.base, className)}>
      {step === VIEWS.ERROR ? (
        <Button
          className={classes.switch}
          variant="text"
          onClick={onSwitchMethod}
          isDisabled={isLoading}
          dataCy={testIds.modals.purchaseError.switch}
        >
          {selectedPaymentMethod?.__typename === 'PaymentMethodPaypal'
            ? t('paymentError.tryWithCardInstead')
            : t('paymentError.tryWithPaypalInstead')}
        </Button>
      ) : null}

      <Box className={classes.filler} />

      {step === VIEWS.SUCCESS ? (
        <Box className={classes.filler} />
      ) : (
        <Button
          className={classes.cancel}
          variant="tertiary"
          onClick={onCancel}
          isDisabled={isLoading}
          dataCy={testIds.modals.purchaseError.cancel}
        >
          {t('common.cancel')}
        </Button>
      )}
      <Button
        className={classes.retry}
        variant="primary"
        onClick={onRetry}
        isLoading={isLoading}
        dataCy={testIds.modals.purchaseError.confirm}
      >
        {t('common.retry')}
      </Button>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: colors.neutral0,
  },
  switch: {
    textAlign: 'left',
  },
  cancel: {
    marginRight: spacing['16'],
    flex: 0,
  },
  retry: {
    flex: 0,
  },
  filler: {
    flex: 1,
  },
}))
