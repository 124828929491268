import { Box, Select, spacing, Text, useInput, useOnMount, useToast } from '@alohi/flow-ui'
import { handleApiErrors } from 'helpers/graphql'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from '../context/api'

interface SignSmsSectionProps {
  className?: string
}

export const SignSmsSection: React.FC<SignSmsSectionProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const toast = useToast()

  const {
    store: { selectedShopItem },
    setCart,
    updateStore,
  } = useViewApi()

  const { signSms } = useShopItems()
  const planShopItems = signSms
  const firstPlanShopItem = planShopItems?.[0]

  const smsChecker = (type: string) => {
    if (!type) {
      return 'Please select a sms package'
    }
  }
  const [, smsInputProps] = useInput<string>({
    initialValue: firstPlanShopItem?.id ?? '',
    validator: smsChecker,
    isLazyLoaded: true,
  })

  const selectItems = useMemo(
    () =>
      planShopItems.map((item) => ({
        id: item.id,
        label: item.marketingData.name,
        onClick: () => undefined,
        item: <Text>{item.marketingData.name}</Text>,
      })),
    [planShopItems]
  )

  const handleSelectItemId = useCallback(
    async (id: string) => {
      try {
        const selectedShopItem = planShopItems.find((item) => item.id === id)
        if (!selectedShopItem?.id) {
          throw {}
        }

        updateStore({ selectedShopItem })

        await setCart({
          id: selectedShopItem.id,
        })
      } catch (error) {
        handleApiErrors(error, {
          default: () => {
            toast({
              description: t('common.serverError'),
              variant: 'error',
            })
          },
        })
      }
    },
    [t, planShopItems, setCart, toast, updateStore]
  )

  useOnMount(async () => {
    try {
      if (!firstPlanShopItem) {
        return
      }

      await setCart({
        id: firstPlanShopItem.id,
      })

      updateStore({
        selectedShopItem: firstPlanShopItem,
      })
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
        },
      })
    }
  })

  return (
    <Box className={cx(classes.base, className)}>
      <Select
        className={classes.select}
        value={selectedShopItem?.id}
        {...smsInputProps}
        onChange={handleSelectItemId}
        label={t('common.quantity')}
        placeholder={t('common.quantity')}
        items={selectItems}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing['32'],
  },
  select: {
    flexGrow: 1,
  },
}))
