import {
  Box,
  CountryCode,
  getI18nCountryList,
  SelectCountry,
  SelectProps,
  useInput,
} from '@alohi/flow-ui'
import { FaxPlusNumberShopItemCountry, PlanType } from 'api/gql/generated/graphql'
import { testIds } from 'helpers/tests'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { MinimumPlanTypeRequirementChip } from './components/MinimumPlanTypeRequirementChip'
import { RegulatoryRequirementText } from './components/RegulatoryRequirementText'

export interface CountrySelectProps {
  className?: string
  country: CountryCode
  onSelect: (country: FaxPlusNumberShopItemCountry | undefined) => void
  supportedCountries: FaxPlusNumberShopItemCountry[]
  isDisabled?: boolean
  planType: PlanType
  disabledMinimumPlanTypeRequirement?: boolean
}

export const CountrySelect: React.FC<CountrySelectProps> = ({
  className,
  country,
  onSelect,
  supportedCountries,
  isDisabled,
  planType,
  disabledMinimumPlanTypeRequirement,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const countryChecker = (country: string) => {
    if (!country) {
      return `${t('common.country')} ${t('common.required')}`
    }
  }

  const [countryInputValue, countryInputProps] = useInput<CountryCode>({
    initialValue: country as CountryCode,
    validator: countryChecker,
    onChange: (country) =>
      onSelect(supportedCountries.find((supportedCountry) => supportedCountry.iso === country)),
  })

  const supportedCountriesIso = useMemo(
    () => supportedCountries.map((country) => country.iso) ?? [],
    [supportedCountries]
  )
  const items: SelectProps['items'] = useMemo(
    () =>
      getI18nCountryList()
        .filter(
          (country) =>
            supportedCountriesIso.length === 0 || supportedCountriesIso.includes(country.value)
        )
        .map((item) => {
          const supportedCountry = supportedCountries.find((country) => country.iso === item.value)
          const isItemDisabled =
            planType === PlanType.Basic &&
            disabledMinimumPlanTypeRequirement &&
            Boolean(supportedCountry?.local?.requirements?.minimumPlanTypeRequirement)
          return {
            id: item.value,
            label: item.label,
            isDisabled: isItemDisabled,
            item: (
              <SelectCountry.Item
                code={item.value}
                label={
                  supportedCountry?.local?.requirements?.regulatoryRequirement ? (
                    <RegulatoryRequirementText
                      label={item.label}
                      type={supportedCountry?.local?.requirements?.regulatoryRequirement}
                    />
                  ) : (
                    item.label
                  )
                }
                rightComponent={
                  <Box className={classes.chipsContainer}>
                    {supportedCountry?.local?.requirements?.minimumPlanTypeRequirement ? (
                      <MinimumPlanTypeRequirementChip
                        planType={planType}
                        minimumPlanTypeRequirement={
                          supportedCountry?.local?.requirements?.minimumPlanTypeRequirement
                        }
                      />
                    ) : null}
                  </Box>
                }
              />
            ),
            onClick: () => undefined,
          } satisfies SelectProps['items'][number]
        }),
    [
      classes.chipsContainer,
      disabledMinimumPlanTypeRequirement,
      planType,
      supportedCountries,
      supportedCountriesIso,
    ]
  )

  return (
    <SelectCountry
      className={cx(classes.base, className)}
      dataCys={testIds.fax.numbers.country}
      value={countryInputValue}
      {...countryInputProps}
      label={t('common.country')}
      placeholder={t('common.country')}
      items={items}
      isDisabled={isDisabled}
      classNames={{
        list: classes.list,
      }}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  list: {
    maxHeight: '200px',
  },
  chipsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))
