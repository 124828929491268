import { useFragment, useMutation } from '@apollo/client'
import {
  PaymentMethodAddDocument,
  PaymentMethodDeleteDocument,
  PaymentMethodPaypal,
  PaymentMethodSetDefaultDocument,
  PaymentMethodStripe,
  PaymentMethodsFragmentFragmentDoc,
} from 'api/gql/generated/graphql'

export type PaymentMethodData = PaymentMethodStripe | PaymentMethodPaypal

export const usePaymentMethods = () => {
  const [mutatePaymentMethodAdd] = useMutation(PaymentMethodAddDocument)
  const [mutatePaymentMethodSetDefault] = useMutation(PaymentMethodSetDefaultDocument)
  const [mutatePaymentMethodDelete] = useMutation(PaymentMethodDeleteDocument)

  const { data } = useFragment({
    from: { __ref: 'PaymentMethods:PaymentMethods' },
    fragment: PaymentMethodsFragmentFragmentDoc,
    fragmentName: 'PaymentMethodsFragment',
  })

  const paymentMethods = (data?.methods ?? []) as PaymentMethodData[]

  const defaultPaymentMethod = paymentMethods?.find((paymentMethod) =>
    Boolean(paymentMethod.default)
  )

  const getPaymentMethodTypeById = (id: string | undefined) =>
    paymentMethods?.find((paymentMethod) => paymentMethod?.id == id)?.methodType

  return {
    mutatePaymentMethodAdd,
    mutatePaymentMethodSetDefault,
    mutatePaymentMethodDelete,
    paymentMethods,
    defaultPaymentMethod,
    getPaymentMethodTypeById,
  }
}
