import { Box, Text } from '@alohi/flow-ui'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { tss } from 'tss-react'

interface ItemPriceProps {
  className?: string
  totalPrice: number
}
export const ItemPrice: React.FC<ItemPriceProps> = ({ className, totalPrice }) => {
  const { classes, cx } = useStyles()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.filler} />
      <Text isBold>{formatCurrency({ amount: totalPrice, currency })}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  filler: {
    flex: 1,
  },
}))
