import { Modal } from '@alohi/flow-ui'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { SuccessModalContent, SuccessModalContentProps } from './components/SuccessModalContent'

interface SuccessModalProps extends SuccessModalContentProps {
  onConfirm?: () => void
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  className,
  onConfirm,
  description,
  customContainer,
  customFooter,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Modal
      className={cx(classes.base, className)}
      customFooter={customFooter}
      onConfirm={customFooter ? undefined : onConfirm}
      confirmTitle={customFooter ? undefined : t('common.continue')}
    >
      <SuccessModalContent description={description} customContainer={customContainer} />
    </Modal>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
