import { Route, Routes } from 'react-router-dom'

import { SUB_DOMAIN } from 'env'
import { urls } from 'helpers/urls'
import { useMemo } from 'react'
import { Fallback } from 'views/common/Fallback'
import { AddNumber } from 'views/fax/AddNumber/AddNumber'
import { ChangeNumber } from 'views/fax/ChangeNumber/ChangeNumber'
import { FaxPlanUpgrade } from 'views/fax/FaxPlanUpgrade/FaxPlanUpgrade'
import { SignPlanUpgrade } from 'views/sign/SignPlanUpgrade/SignPlanUpgrade'
import { SignQes } from 'views/sign/SignQes/SignQes'
import { SignSeats } from 'views/sign/SignSeats/SignSeats'
import { SignSms } from 'views/sign/SignSms/SignSms'

export const PrivateRoutes: React.FC = () => {
  const isFax = SUB_DOMAIN === 'fax'
  const isSign = SUB_DOMAIN === 'sign'

  const homeComponent = useMemo(() => {
    if (isFax) {
      return <FaxPlanUpgrade />
    }
    if (isSign) {
      return <SignPlanUpgrade />
    }
    return <Fallback />
  }, [isSign, isFax])

  return (
    <Routes>
      <Route path={'/'} element={homeComponent} />
      <Route path={urls.home} element={<Fallback />} />
      <Route path={urls.fax.upgrade} element={<FaxPlanUpgrade />} />
      <Route path={urls.fax.addNumber} element={<AddNumber />} />
      <Route path={urls.fax.changeNumber} element={<ChangeNumber />} />
      <Route path={urls.sign.upgrade} element={<SignPlanUpgrade />} />
      <Route path={urls.sign.custom} element={<SignPlanUpgrade />} />
      <Route path={urls.sign.qes} element={<SignQes />} />
      <Route path={urls.sign.sms} element={<SignSms />} />
      <Route path={urls.sign.seats} element={<SignSeats />} />
      <Route path="*" element={<Fallback />} />
    </Routes>
  )
}
