import { Box, colors, radius, spacing, Switch, Text } from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { MouseEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from 'views/sign/SignPlanUpgrade/context/api'

interface TrialSwitchProps {
  className?: string
}

export const TrialSwitch: React.FC<TrialSwitchProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { setTrial } = useViewApi()

  const {
    computedItems: { signPlanCartItem },
  } = useCartApi()
  const isSignCartPlanTrial = signPlanCartItem?.isTrial ?? false
  const isSignCartPlanTrialDays = signPlanCartItem?.trialDays ?? 0

  const handleSelectPlan = useCallback(
    async (isTrial: boolean) => {
      try {
        await setTrial(isTrial)
      } catch (error) {
        // Error caught in stores
      }
    },
    [setTrial]
  )

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    handleSelectPlan(!isSignCartPlanTrial)
  }

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.content} onClick={handleClick}>
        <Switch
          className={classes.switch}
          isSwitched={isSignCartPlanTrial}
          onSwitch={() => undefined}
        />
        <Text className={classes.text}>
          {t('purchase.startFreeTrial', {
            value_1: isSignCartPlanTrialDays,
          })}
        </Text>
      </Box>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    padding: spacing['16'],
    borderRadius: radius['8'],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
  },
  text: {
    marginLeft: spacing['8'],
  },
  switch: {
    pointerEvents: 'none',
  },
}))
