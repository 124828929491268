import {
  Box,
  Message,
  Modal,
  spacing,
  Text,
  useNavigationModalApi,
  useOnMount,
} from '@alohi/flow-ui'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface SuccessMessageProps {
  className?: string
}

export const SuccessMessage: React.FC<SuccessMessageProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { updateStep } = useNavigationModalApi()

  const { navigateBack } = useRoutesApi()

  useOnMount(() => {
    updateStep({
      title: t('highVolume.customPagesTitle'),
      onConfirm: navigateBack,
      confirmTitle: t('common.done'),
    })
  })

  return (
    <Modal.Content>
      <Box className={cx(classes.base, className)}>
        <Message variant="success">
          <Text>{t('highVolume.upgradeRequestSent1')}</Text>
          <Text className={classes.desc2}>{t('highVolume.upgradeRequestSent2')}</Text>
        </Message>
      </Box>
    </Modal.Content>
  )
}
const useStyles = tss.create(() => ({
  base: {
    flex: 1,
  },
  desc2: {
    marginTop: spacing['8'],
  },
}))
