import { Text, formatNumber } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import Highligheter from './Highlighter'

interface HighlightNumbersProps {
  phoneNumber: string
  highlight: string
}

function HighlightNumbers({ highlight, phoneNumber }: HighlightNumbersProps) {
  return (
    <Text dataCy={testIds.fax.numbers.custom.availableNumber}>
      <Highligheter highlight={highlight}>
        {formatNumber(phoneNumber, 'INTERNATIONAL')}
      </Highligheter>
    </Text>
  )
}

export default HighlightNumbers
