import { Input, useInput } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID =
  'new-payment-cardholder-name'

export interface NameInputProps {
  className?: string
}

export const NameInput: React.FC<NameInputProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const nameChecker = (name: string) => {
    if (!name) {
      return `${t('payment.cardholderName')} ${t('common.required')}`
    }
  }
  const [name, nameInputProps] = useInput<string>({
    id: NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID,
    initialValue: '',
    validator: nameChecker,
  })

  return (
    <Input
      dataCy={testIds.newPaymentMethodForm.creditCard.name}
      className={cx(classes.base, className)}
      label={t('payment.cardholderName')}
      placeholder={t('payment.cardholderName')}
      value={name}
      {...nameInputProps}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
