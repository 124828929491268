import { useCallback } from 'react'

import { useAppContext } from './store/context'
import { AppDataStore } from './store/store'

export function useAppApi() {
  const { store, dispatch } = useAppContext()

  const updateStore = useCallback(
    (payload: Partial<AppDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const redirectToUnauthorized = useCallback(() => {
    updateStore({
      accessToken: undefined,
      isAppReady: true,
    })
  }, [updateStore])

  return {
    store,
    updateStore,
    redirectToUnauthorized,
  }
}
