import { Box, useBool } from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { tss } from 'tss-react'
import SelectPages from './components/SelectPages/SelectPages'
import { CustomHighVolumePlanModal } from './modals/CustomHighVolumePlanModal/CustomHighVolumePlanModal'

interface RequiredPagesSectionProps {
  className?: string
}

export const RequiredPagesSection = ({ className }: RequiredPagesSectionProps) => {
  const { classes, cx } = useStyles()

  const {
    computedItems: { faxPlanCartItem },
  } = useCartApi()
  const isInternational = !faxPlanCartItem?.dstLimits.length

  const [isCustomHighVolumePlanModalOpen, isCustomHighVolumePlanModalOpenBool] = useBool(false)

  return (
    <Box className={cx(classes.base, className)}>
      <SelectPages onClickMore={isCustomHighVolumePlanModalOpenBool.setTrue} />
      {isCustomHighVolumePlanModalOpen ? (
        <CustomHighVolumePlanModal
          onClose={isCustomHighVolumePlanModalOpenBool.setFalse}
          isInternational={isInternational}
        />
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    position: 'relative',
  },
}))
