import { Box, spacing, SvgBox, Text } from '@alohi/flow-ui'
import RandomNumberIcon from 'assets/svg/numbers/area-random.svg'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface RandomItemProps {
  className?: string
}

export const RandomItem: React.FC<RandomItemProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <SvgBox src={RandomNumberIcon} />
      <Text className={classes.mainItemText}>{t('faxNumber.randomAreaCode')}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  mainItemText: {
    marginLeft: spacing['8'],
  },
}))
