import { AppStore } from './store'

export interface AppAction {
  type: 'UPDATE'
  payload: Partial<AppStore>
}

export const AppReducer = (state: AppStore, action: AppAction): AppStore => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
