import { Box, Link, spacing, SvgBox, Text } from '@alohi/flow-ui'
import CustomNumberIcon from 'assets/svg/numbers/custom.svg'
import RandomNumberIcon from 'assets/svg/numbers/random.svg'
import { formatCurrency } from 'helpers/payment'
import { websiteUrls } from 'helpers/urls'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface TooltipTypeContentProps {
  className?: string
  isCustom: boolean
  unitPrice: number
  customFee: number
  onClick: () => void
}

export const TooltipTypeContent: React.FC<TooltipTypeContentProps> = ({
  className,
  isCustom,
  unitPrice,
  customFee,
  onClick,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const message = useMemo(() => {
    return (
      <Box>
        <Box className={classes.titleContainer}>
          <SvgBox
            className={classes.typeIcon}
            src={isCustom ? CustomNumberIcon : RandomNumberIcon}
          />
          <Text className={classes.title} isBold>
            {isCustom ? t('faxNumber.customNumber') : t('faxNumber.randomNumber')}
          </Text>
        </Box>

        <Text className={classes.description} variant="small">
          {isCustom
            ? t('faxNumber.customNumberSelectionIncursFee', {
                value_1: formatCurrency({ amount: customFee, currency }),
              })
            : t('faxNumber.eachAdditionalFaxLineCosts', {
                value_1: formatCurrency({ amount: unitPrice, currency }),
              })}
        </Text>
        <Text className={classes.description} variant="small">
          <Trans
            i18nKey={
              isCustom ? 'faxNumber.switchBackToRandomInstead' : 'faxNumber.searchForCustomInstead'
            }
            components={{
              faxLink: (
                <Link
                  key={'faxLink'}
                  variant="small"
                  color="light"
                  className={classes.link}
                  onClick={onClick}
                >
                  {''}
                </Link>
              ),
            }}
          />
        </Text>
        <Text className={classes.description} variant="small">
          {t('qes.tooltipVisitHelpCenter')}
        </Text>
        <Link
          variant="small"
          color="light"
          className={classes.helpCenterLink}
          withIcon
          hasBullet
          isExternal
          href={websiteUrls.fax.help.faxNumberHelp}
        >
          {t('faxNumber.whatTypeOfNumbersAreAvailable')}
        </Link>
      </Box>
    )
  }, [
    classes.description,
    classes.helpCenterLink,
    classes.link,
    classes.title,
    classes.titleContainer,
    classes.typeIcon,
    currency,
    customFee,
    isCustom,
    onClick,
    t,
    unitPrice,
  ])

  return <Box className={cx(classes.base, className)}>{message}</Box>
}

const useStyles = tss.create(() => ({
  base: {
    padding: spacing['8'],
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '394px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  typeIcon: {
    //
  },
  title: {
    marginLeft: spacing['8'],
  },
  description: {
    marginTop: spacing['16'],
  },
  link: {
    display: 'inline-block',
  },
  helpCenterLink: {
    marginTop: spacing['4'],
    display: 'inline-block',
  },
}))
