import {
  AnimatedBox,
  CountryCode,
  getI18nCountry,
  Link,
  Message,
  spacing,
  Text,
} from '@alohi/flow-ui'
import { Error, FaxNumberType, RegulatoryBundleStatus } from 'api/gql/generated/graphql'
import { testIds } from 'helpers/tests'
import { websiteUrls } from 'helpers/urls'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface InfosMessageProps {
  className?: string
  inputCountry: CountryCode
  notAvailable: Error | null | undefined
  number: string | undefined
  area?: string
  numberType?: FaxNumberType
}

export const InfosMessage: React.FC<InfosMessageProps> = ({
  className,
  inputCountry,
  notAvailable,
  number,
  area,
  numberType,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: { regulatoryData },
  } = useCustomer()

  const countryLabel = getI18nCountry(inputCountry)?.label

  const noNumberAvailableCountryLabel = area
    ? `${area} (${getI18nCountry(inputCountry)?.label})`
    : `${getI18nCountry(inputCountry)?.label}`

  const message = useMemo(() => {
    // France comes with a specific treatment
    if (inputCountry === 'FR') {
      const { bundles } = regulatoryData
      const hasApprovedBundle = bundles.find(
        (bundle) => bundle.iso === 'FR' && bundle.status === RegulatoryBundleStatus.TwilioApproved
      )

      // Step1: Requires a bundle
      if (!hasApprovedBundle) {
        return {
          label: t('faxNumber.missingFrenchBundleTitle'),
          component: (
            <Message variant="info">
              <Text className={classes.textTitle} isBold>
                {t('faxNumber.missingFrenchBundleTitle')}
              </Text>
              <Text className={classes.textDescription1}>
                <Trans
                  i18nKey="faxNumber.missingFrenchBundleDesc1"
                  components={{
                    faxLink: (
                      <Link
                        key={'faxLink'}
                        className={classes.link}
                        href={websiteUrls.fax.profile.bundles}
                        isExternal
                      >
                        {''}
                      </Link>
                    ),
                  }}
                />
              </Text>
              <Text className={classes.textDescription2}>
                {t('faxNumber.missingFrenchBundleDesc2')}
              </Text>
              <Link
                className={classes.contactUs}
                href={websiteUrls.fax.contactUs}
                isBold
                isExternal
              >
                {t('faxNumber.contactUsDesc')}
              </Link>
            </Message>
          ),
        }
      }

      // Step2: Contact Support
      return {
        label: t('faxNumber.missingFrenchBundleTitle'),
        component: (
          <Message variant="info">
            <Text className={classes.textTitle} isBold>
              {t('faxNumber.missingFrenchBundleTitle')}
            </Text>
            <Text className={classes.textDescription1}>
              {t('faxNumber.missingFrenchBundleContactUs')}
            </Text>
            <Link className={classes.contactUs} href={websiteUrls.fax.contactUs} isBold isExternal>
              {t('faxNumber.contactUsDesc')}
            </Link>
          </Message>
        ),
      }
    }

    switch (notAvailable) {
      case Error.NoNumbersAvailable:
        return {
          label:
            numberType === FaxNumberType.TollFree
              ? t('faxNumber.countryUnavailableTitleTollFree', {
                  value_1: noNumberAvailableCountryLabel,
                })
              : t('faxNumber.countryUnavailableTitle', {
                  value_1: noNumberAvailableCountryLabel,
                }),
          component: (
            <Message variant="info">
              <Text className={classes.textTitle} isBold>
                {numberType === FaxNumberType.TollFree
                  ? t('faxNumber.countryUnavailableTitleTollFree', {
                      value_1: noNumberAvailableCountryLabel,
                    })
                  : t('faxNumber.countryUnavailableTitle', {
                      value_1: noNumberAvailableCountryLabel,
                    })}
              </Text>
              <Text className={classes.textDescription1}>
                {t('faxNumber.useUSNumberInstead', {
                  value_1: noNumberAvailableCountryLabel,
                  value_2: number,
                })}
              </Text>
              <Text className={classes.textDescription1}>
                {t('faxNumber.partneringForInventory')}
              </Text>
            </Message>
          ),
        }

      case Error.NoValidBundleForNumber:
        return {
          label: t('faxNumber.missingBundleTitle', {
            value_1: countryLabel,
          }),
          component: (
            <Message variant="info">
              <Text className={classes.textTitle} isBold>
                {t('faxNumber.missingBundleTitle', {
                  value_1: countryLabel,
                })}
              </Text>
              <Text className={classes.textDescription1}>
                {t('faxNumber.useUSNumberInstead', {
                  value_1: countryLabel,
                  value_2: number,
                })}
              </Text>
              <Text className={classes.textDescription2}>
                <Trans
                  i18nKey={
                    numberType === FaxNumberType.TollFree
                      ? 'faxNumber.missingBundleTollFreeDesc2'
                      : 'faxNumber.missingBundleDesc2'
                  }
                  values={{
                    value_1: countryLabel,
                  }}
                  components={{
                    faxLink: (
                      <Link
                        key={'faxLink'}
                        className={classes.link}
                        href={websiteUrls.fax.profile.bundles}
                        isExternal
                      >
                        {''}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Message>
          ),
        }
      case Error.NoValidAddressForNumber:
        return {
          label: t('faxNumber.missingAddressTitle', {
            value_1: countryLabel,
          }),
          component: (
            <Message variant="info">
              <Text className={classes.textTitle} isBold>
                {t('faxNumber.missingAddressTitle', {
                  value_1: countryLabel,
                })}
              </Text>
              <Text className={classes.textDescription1}>
                {t('faxNumber.useUSNumberInstead', {
                  value_1: countryLabel,
                  value_2: number,
                })}
              </Text>
              <Text className={classes.textDescription2}>
                <Trans
                  i18nKey={
                    numberType === FaxNumberType.TollFree
                      ? 'faxNumber.missingAddressTollFreeDesc2'
                      : 'faxNumber.missingAddressDesc2'
                  }
                  values={{
                    value_1: countryLabel,
                  }}
                  components={{
                    faxLink: (
                      <Link
                        key={'faxLink'}
                        className={classes.link}
                        href={websiteUrls.fax.profile.addresses}
                        isExternal
                      >
                        {''}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Message>
          ),
        }
    }
  }, [
    classes.contactUs,
    classes.link,
    classes.textDescription1,
    classes.textDescription2,
    classes.textTitle,
    inputCountry,
    countryLabel,
    noNumberAvailableCountryLabel,
    notAvailable,
    number,
    numberType,
    regulatoryData,
    t,
  ])

  if (!message) return null
  return (
    <AnimatedBox dataCy={testIds.fax.numbers.infosMessage} className={cx(classes.base, className)}>
      {message.component}
    </AnimatedBox>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['24'],
  },
  textTitle: {
    //
  },
  textDescription1: {
    marginTop: spacing['4'],
  },
  textDescription2: {
    marginTop: spacing['16'],
  },
  contactUs: {
    marginTop: spacing['8'],
  },
  link: {
    display: 'inline-block',
  },
}))
