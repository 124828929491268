import { useBool, useOnMount, useToast } from '@alohi/flow-ui'
import { DocumentNode, useMutation, useQuery } from '@apollo/client'
import { getFragmentData } from 'api/gql/generated'
import { CartSetDocument, CustomerFragmentFragmentDoc } from 'api/gql/generated/graphql'
import { useAppApi } from 'contexts/app/api'
import { getField, handleApiErrors } from 'helpers/graphql'
import { useTranslation } from 'react-i18next'

interface InitFlowInput {
  extraIds?: string[]
}

export const useInitFlow = (queryDocument: DocumentNode, initFlowInput: InitFlowInput = {}) => {
  const toast = useToast()
  const { t } = useTranslation()

  const { refetch: queryInitFlow } = useQuery(queryDocument, {
    skip: true,
  })
  const { updateStore: updateAppStore } = useAppApi()
  const [mutateCartSet] = useMutation(CartSetDocument)
  const [isFlowReady, isFlowReadyBool] = useBool(false)

  useOnMount(async () => {
    try {
      // Fetch Customer and ShopItems
      const response = await queryInitFlow(initFlowInput)

      const { id: customerId } = getFragmentData(
        CustomerFragmentFragmentDoc,
        getField(response.data?.customer)
      )
      updateAppStore({
        customerId,
      })

      // Reset the previous Cart
      await mutateCartSet({
        variables: {
          items: [],
        },
      })
      isFlowReadyBool.setTrue()
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
        },
      })
      // Unlog user to redirect him to the Failed Authentication page
      updateAppStore({
        accessToken: undefined,
      })
    } finally {
      updateAppStore({
        isAppReady: true,
      })
    }
  })

  return { isFlowReady }
}
