import { useMutation } from '@apollo/client'
import { LoginRefreshTokenDocument } from 'api/gql/generated/graphql'
import { useAppApi } from 'contexts/app/api'
import { getField, handleApiErrors } from 'helpers/graphql'
import { useCallback } from 'react'

export const useSession = () => {
  const { updateStore: updateAppStore } = useAppApi()

  const [mutateLoginRefreshToken] = useMutation(LoginRefreshTokenDocument)

  const refreshToken = useCallback(async () => {
    try {
      const response = await mutateLoginRefreshToken()
      const loginRefreshToken = getField(response.data?.loginRefreshToken)

      if (loginRefreshToken.__typename === 'LoginTokenResponse') {
        updateAppStore({
          accessToken: loginRefreshToken.accessToken,
          tokenType: loginRefreshToken.tokenType,
        })
      }
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          updateAppStore({
            isAppReady: true,
          })
        },
      })
    }
  }, [mutateLoginRefreshToken, updateAppStore])

  return { refreshToken }
}
