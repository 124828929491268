import { useCallback } from 'react'

import {
  FaxNumberChannel,
  FaxPlusNumberOrderItem,
  SetCartItemInput,
  SetFaxNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { SuccessModalContentProps } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'
import { FaxSubstitutedNumberMessage } from 'components/fax/FaxSubstitutedNumberMessage/FaxSubstitutedNumberMessage'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { handleApiErrors } from 'helpers/graphql'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics/useGoogleAnalytics'
import { useViewContext } from './store/context'
import { ViewDataStore } from './store/store'

export interface PurchaseNumbersErrorExtensions {
  failedPurchaseNumbers: string[]
  reimbursedAmount: number
}

const MAX_NUMBERS_FOR_SYNC_PURCHASE = 1

export function useViewApi() {
  const { store, dispatch } = useViewContext()
  const { t } = useTranslation()

  const { queryCredit } = useCustomer()

  const { gtmCartPurchaseSuccess, gtmCartPurchaseFailed } = useGoogleAnalytics()

  const {
    computedItems: { faxExtraNumberCartItems },
    cart,
    processCart: processCartApi,
    setCart: setCartApi,
  } = useCartApi()

  const {
    store: { selectedPaymentMethodId },
  } = usePaymentMethodApi()

  const updateStore = useCallback(
    (payload: Partial<ViewDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const setCart = useCallback(
    async (faxNumberCartInputs: SetFaxNumberCartItemInput[]) => {
      try {
        const items: SetCartItemInput[] = faxNumberCartInputs.map((faxNumberCartInput) => ({
          faxExtraNumber: faxNumberCartInput,
        }))

        updateStore({
          selectedNumberCartInputs: faxNumberCartInputs,
        })

        await setCartApi({
          schedule: cart.isScheduled,
          useCredit: cart.creditUsed != null,
          items,
        })
      } catch {
        // Errors caught in setCartApi
      }
    },
    [cart.creditUsed, cart.isScheduled, setCartApi, updateStore]
  )

  const submit = useCallback(async (): Promise<SuccessModalContentProps | undefined> => {
    try {
      if (!faxExtraNumberCartItems) {
        throw new Error('No numbers')
      }
      const waitForCompletion = faxExtraNumberCartItems.length <= MAX_NUMBERS_FOR_SYNC_PURCHASE
      const response = await processCartApi({
        paymentMethodId: selectedPaymentMethodId,
        waitForCompletion,
      })

      gtmCartPurchaseSuccess({
        faxExtraNumbers: {
          totalCount: faxExtraNumberCartItems.length,
          customCount:
            faxExtraNumberCartItems.filter(
              (faxExtraNumberCartItem) => faxExtraNumberCartItem.channel === FaxNumberChannel.Custom
            )?.length ?? 0,
        },
        totalPrice: cart.total,
      })

      // Standard Cart
      if (response?.__typename === 'ArchivedOrder' || response?.__typename === 'ActiveOrder') {
        const newNumberOrderItems = response.orderItems.filter(
          (item): item is FaxPlusNumberOrderItem => item?.__typename === 'FaxPlusNumberOrderItem'
        )
        if (!newNumberOrderItems) {
          throw new Error('No newPlanOrderItem')
        }
        const hasSubstitutedNumbers = Boolean(
          newNumberOrderItems.filter((newNumberOrderItem) => newNumberOrderItem.requestedNumber)
            ?.length
        )

        return {
          description: t('faxNumber.numberSuccessfullyAddedSync', {
            count: faxExtraNumberCartItems.length,
          }),
          customContainer: hasSubstitutedNumbers ? (
            <FaxSubstitutedNumberMessage numbers={newNumberOrderItems} />
          ) : undefined,
        }
      }
      // Scheduled Cart
      else if (response?.__typename === 'Cart') {
        throw new Error("Extra numbers can't be scheduled")
      }
      // Async purchase
      else {
        return {
          description: t('faxNumber.numberSuccessfullyAdded', {
            count: faxExtraNumberCartItems.length,
          }),
        }
      }
    } catch (error) {
      handleApiErrors(error, {
        errors: {
          NUMBER_PURCHASE_FAILED: async ({
            NUMBERS_FAILED_TO_PURCHASE,
            AMOUNT_REIMBURSED_TO_CREDIT,
          }) => {
            updateStore({
              purchaseNumbersErrorExtensions: {
                failedPurchaseNumbers: NUMBERS_FAILED_TO_PURCHASE as string[],
                reimbursedAmount: AMOUNT_REIMBURSED_TO_CREDIT as number,
              },
            })
            if (store.selectedNumberCartInputs) {
              await queryCredit()
            }
          },
        },
        default: () => {
          // Handled in processCartApi
        },
      })

      gtmCartPurchaseFailed({
        faxExtraNumbers: {
          totalCount: faxExtraNumberCartItems?.length,
          customCount:
            faxExtraNumberCartItems?.filter(
              (faxExtraNumberCartItem) => faxExtraNumberCartItem.channel === FaxNumberChannel.Custom
            )?.length ?? 0,
        },
        totalPrice: cart.total,
      })
      throw error
    }
  }, [
    cart.total,
    faxExtraNumberCartItems,
    gtmCartPurchaseFailed,
    gtmCartPurchaseSuccess,
    processCartApi,
    queryCredit,
    selectedPaymentMethodId,
    store.selectedNumberCartInputs,
    t,
    updateStore,
  ])

  return {
    store,
    updateStore,
    setCart,
    submit,
  }
}
