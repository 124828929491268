import { Box, colors, spacing, Text, useMediaQuery } from '@alohi/flow-ui'
import { FaxPlusPlanShopItem, Period } from 'api/gql/generated/graphql'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface PlanDescriptionProps {
  className?: string
  item: FaxPlusPlanShopItem
}

export const PlanDescription: React.FC<PlanDescriptionProps> = ({ className, item }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const [isMobile] = useMediaQuery([{ key: 'sm', direction: 'down' }])

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  return (
    <Box className={cx(classes.base, className)}>
      <>
        <Text className={classes.price}>
          {item.limits?.maxAmount && item.limits.maxAmount > 1
            ? t('signPlans.planShopItemDescription', {
                value_1: formatCurrency({
                  amount: item.unitPrice,
                  currency,
                }),
              })
            : formatCurrency({
                amount: item.unitPrice,
                currency,
              })}
        </Text>
        {item.period === Period.Annual && !isMobile ? (
          <Text className={classes.descriptionAnnually}>
            {t('signPlans.planShopItemDescriptionAnnually', {
              value_1: formatCurrency({
                amount: item.marketingData.price,
                currency,
              }),
            })}
          </Text>
        ) : null}
      </>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  price: {
    //
  },
  descriptionAnnually: {
    marginLeft: spacing['4'],
    color: colors.neutral400,
  },
}))
