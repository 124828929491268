import {
  AnimatedBox,
  Box,
  breakpoints,
  Button,
  colors,
  List,
  ListItem,
  radius,
  spacing,
  Text,
} from '@alohi/flow-ui'
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons'
import { PlanType } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const PAGES_PER_FAX_BASIC = 60
export const PAGES_PER_FAX_PREMIUM = 90
export const PAGES_PER_FAX_BUSINESS = 200
export const PAGES_PER_FAX_ENTERPRISE = 250

const IncludedPerk = ({ children }: { children: string }) => {
  const { classes } = useStyles()

  return (
    <ListItem className={classes.item} classNames={{ icon: classes.successIcon }} icon={faCheck}>
      {children}
    </ListItem>
  )
}

const ExcludedPerk = ({ children }: { children: string }) => {
  const { classes } = useStyles()

  return (
    <ListItem className={classes.item} classNames={{ icon: classes.failIcon }} icon={faClose}>
      {children}
    </ListItem>
  )
}

interface PerksProps {
  className?: string
  planType: PlanType | undefined
  onComparePlans?: () => void
}

export const Perks: React.FC<PerksProps> = ({ className, planType, onComparePlans }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  if (!planType) return
  return (
    <AnimatedBox
      className={cx(classes.base, className)}
      key={planType}
      transition={{
        delay: 0.05,
        duration: 0.5,
      }}
    >
      <Box className={classes.header}>
        <Text>{t('perks.whatsIncluded')}</Text>
        {onComparePlans ? (
          <>
            <Box className={classes.filler} />
            <Button variant="text" onClick={onComparePlans}>
              <Text>{t('faxPlans.seeAllPlans')}</Text>
            </Button>
          </>
        ) : undefined}
      </Box>
      <Box className={classes.content}>
        {
          {
            BASIC: (
              <Box className={classes.perksContainer}>
                <Box className={classes.sectionLeft}>
                  <List listStyleType="none">
                    <IncludedPerk>
                      {t('perks.pagesPerFax', {
                        value_1: PAGES_PER_FAX_BASIC,
                      })}
                    </IncludedPerk>
                    <IncludedPerk>{t('perks.localFaxNumber')}</IncludedPerk>
                    <IncludedPerk>{t('perks.emailToFax')}</IncludedPerk>
                    <IncludedPerk>{t('perks.mobileApp')}</IncludedPerk>
                  </List>
                </Box>
                <Box className={classes.sectionRight}>
                  <List listStyleType="none">
                    <ExcludedPerk>{t('perks.localAndTollFreeFaxNumber')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.faxNumberPorting')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.exportFaxLogsToCsv')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.switchOffFaxReception')}</ExcludedPerk>
                  </List>
                </Box>
              </Box>
            ),
            PREMIUM: (
              <Box className={classes.perksContainer}>
                <Box className={classes.sectionLeft}>
                  <List listStyleType="none">
                    <IncludedPerk>
                      {t('perks.pagesPerFax', {
                        value_1: PAGES_PER_FAX_PREMIUM,
                      })}
                    </IncludedPerk>
                    <IncludedPerk>{t('perks.localAndTollFreeFaxNumber')}</IncludedPerk>
                    <IncludedPerk>{t('perks.faxNumberPorting')}</IncludedPerk>
                    <IncludedPerk>{t('perks.switchOffFaxReception')}</IncludedPerk>
                  </List>
                </Box>
                <Box className={classes.sectionRight}>
                  <List listStyleType="none">
                    <ExcludedPerk>{t('perks.multipleFaxNumbers')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.multipleUsersManagement')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.zapierIntegration')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.slackIntegration')}</ExcludedPerk>
                  </List>
                </Box>
              </Box>
            ),
            BUSINESS: (
              <Box className={classes.perksContainer}>
                <Box className={classes.sectionLeft}>
                  <List listStyleType="none">
                    <IncludedPerk>
                      {t('perks.pagesPerFax', {
                        value_1: PAGES_PER_FAX_BUSINESS,
                      })}
                    </IncludedPerk>
                    <IncludedPerk>{t('perks.teamSetup')}</IncludedPerk>
                    <IncludedPerk>{t('perks.multipleFaxNumbers')}</IncludedPerk>
                    <IncludedPerk>{t('perks.slackIntegration')}</IncludedPerk>
                  </List>
                </Box>
                <Box className={classes.sectionRight}>
                  <List listStyleType="none">
                    <ExcludedPerk>{t('perks.faxApiAndZapierIntegration')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.dataResidency')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.hipaaCompliance')}</ExcludedPerk>
                    <ExcludedPerk>{t('perks.analyticsDashboard')}</ExcludedPerk>
                  </List>
                </Box>
              </Box>
            ),
            ENTERPRISE: (
              <Box className={classes.perksContainer}>
                <Box className={classes.sectionLeft}>
                  <List listStyleType="none">
                    <IncludedPerk>
                      {t('perks.pagesPerFax', {
                        value_1: PAGES_PER_FAX_ENTERPRISE,
                      })}
                    </IncludedPerk>
                    <IncludedPerk>{t('perks.multipleFaxNumbers')}</IncludedPerk>
                    <IncludedPerk>{t('perks.unlimitedMembersIncluded')}</IncludedPerk>
                    <IncludedPerk>{t('perks.zapierIntegration')}</IncludedPerk>
                  </List>
                </Box>
                <Box className={classes.sectionRight}>
                  <List listStyleType="none">
                    <IncludedPerk>{t('perks.sso')}</IncludedPerk>
                    <IncludedPerk>{t('perks.dataResidency')}</IncludedPerk>
                    <IncludedPerk>{t('perks.faxApi')}</IncludedPerk>
                    <IncludedPerk>{t('perks.analyticsDashboard')}</IncludedPerk>
                  </List>
                </Box>
              </Box>
            ),
          }[planType]
        }
      </Box>
    </AnimatedBox>
  )
}

const useStyles = tss.create(() => ({
  base: {
    borderRadius: radius['8'],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
  },
  perksContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  sectionLeft: {
    //
  },
  sectionRight: {
    marginLeft: spacing['24'],
    [breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  successIcon: {
    color: colors.blue400,
    marginRight: spacing['8'],
  },
  failIcon: {
    color: colors.red700,
    marginRight: spacing['8'],
  },
  item: {
    marginTop: spacing['8'],
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '46px',
    background: colors.neutral100,
    borderRadius: radius['8'],
    padding: spacing['16'],
  },
  filler: {
    flex: 1,
  },
  content: {
    padding: spacing['16'],
    paddingTop: spacing['8'],
  },
}))
