import { ReactNode, Reducer, useReducer } from 'react'
import { AppContext } from './store/context'
import { AppAction, AppReducer } from './store/reducer'
import {
  AppStore,
  computeStore,
  initialAppComputedStore,
  initialAppStore,
} from './store/store'

interface AppContextProviderProps {
  children: ReactNode
  initialUpdate?: Partial<AppStore>
}

export function AppContextProvider({
  children,
  initialUpdate = {},
}: AppContextProviderProps) {
  const [store, dispatch] = useReducer<Reducer<AppStore, AppAction>>(
    AppReducer,
    {
      ...initialAppStore,
      ...initialAppComputedStore,
      ...initialUpdate,
    }
  )

  const computedStore = computeStore(store)

  return (
    <AppContext.Provider
      value={{
        store: {
          ...store,
          ...computedStore,
        },
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
