import { Box, Text } from '@alohi/flow-ui'
import { FaxPlusPlanShopItem, Period } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface PlanLabelProps {
  className?: string
  item: FaxPlusPlanShopItem
  period: Period
  isInInput?: boolean
}

export const PlanLabel: React.FC<PlanLabelProps> = ({ className, item, period, isInInput }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const label = isInInput
    ? `${item.marketingData.name} (${period === Period.Annual ? t('plans.annual') : t('plans.monthly')})`
    : item.marketingData.name

  return (
    <Box className={cx(classes.base, className)}>
      <Text variant="h3">{label}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
