import { CartStore } from './store'

export interface CartAction {
  type: 'UPDATE'
  payload: Partial<CartStore>
}

export const CartReducer = (
  state: CartStore,
  action: CartAction
): CartStore => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
