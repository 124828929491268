import { Dispatch, createContext, useContext } from 'react'
import { ViewAction } from './reducer'
import { ViewStore, initialViewComputedStore, initialViewStore } from './store'

export interface ViewContextProps {
  store: ViewStore
  dispatch: Dispatch<ViewAction>
}

export const ViewContext = createContext<ViewContextProps>({
  store: {
    ...initialViewStore,
    ...initialViewComputedStore,
  },
  dispatch: () => undefined,
})

export function useViewContext() {
  const context = useContext(ViewContext)

  if (!context) {
    throw new Error('useViewContext is outside of the Provider')
  }

  return context
}
