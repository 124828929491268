import { Box, Input, useInput } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const COMPANY_NAME_INPUT_ID = 'company-name'
const MAX_CHAR = 60

interface ContentProps {
  className?: string
}

export const Content: React.FC<ContentProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { customer } = useCustomer()

  const nameChecker = (name: string) => {
    if (!name) {
      return `${t('organization.companyName')} ${t('common.required')}`
    } else if (name.length > MAX_CHAR) {
      return t('organization.tooLong', { value_1: MAX_CHAR })
    }
  }

  const [companyName, companyNameInputProps] = useInput<string>({
    id: COMPANY_NAME_INPUT_ID,
    initialValue: customer.companyName ?? '',
    validator: nameChecker,
  })

  return (
    <Box className={cx(classes.base, className)}>
      <Input
        dataCy={testIds.newPaymentMethodForm.creditCard.name}
        className={cx(classes.base, className)}
        label={t('organization.companyName')}
        placeholder={t('organization.companyName')}
        value={companyName}
        {...companyNameInputProps}
        autoFocus
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    flexGrow: 1,
  },
}))
