import { Box, formatNumber, Input } from '@alohi/flow-ui'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface CurrentFaxNumberSectionProps {
  className?: string
  id: string | undefined
}

export const CurrentFaxNumberSection: React.FC<CurrentFaxNumberSectionProps> = ({
  className,
  id,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { activeFaxSubscription } = useActiveSubscriptions()
  const activeFaxSubscriptionNumber = activeFaxSubscription.computed.numbers.find(
    (number) => number.id === id
  )
  const number = activeFaxSubscriptionNumber
    ? formatNumber(activeFaxSubscriptionNumber.number, 'INTERNATIONAL')
    : undefined

  return (
    <Box className={cx(classes.base, className)}>
      <Input
        classNames={{ inputContent: classes.inputContent }}
        label={t('faxNumber.currentNumber')}
        value={number}
        isDisabled
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  inputContent: {
    opacity: 0.8,
  },
}))
