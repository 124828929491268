import { Box, spacing } from '@alohi/flow-ui'
import { tss } from 'tss-react'

import { Perks } from 'views/sign/SignPlanUpgrade/components/SignPlanSelectSection/components/Perks'
import { useViewApi } from '../../context/api'
import { TrialSwitch } from './components/TrialSwitch'

interface CustomPlanProps {
  className?: string
}

export const CustomPlan: React.FC<CustomPlanProps> = ({ className }) => {
  const { classes, cx } = useStyles()

  const {
    store: { selectedShopItem },
  } = useViewApi()

  return (
    <Box className={cx(classes.base, className)}>
      {selectedShopItem?.trialAllowed ? <TrialSwitch className={classes.trialSwitch} /> : null}
      <Perks
        className={classes.perks}
        hasApi={selectedShopItem?.hasApi}
        planType={selectedShopItem?.planType}
        requestSignature={selectedShopItem?.requestSignature}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  perks: {
    marginTop: spacing['24'],
  },
  trialSwitch: {
    marginTop: spacing['24'],
  },
}))
