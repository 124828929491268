import {
  GoogleAnalyticsCustomEvents,
  GtmDataLayerCart,
  useGoogleTagManager,
} from 'hooks/useGoogleTagManager/useGoogleTagManager'

export const useGoogleAnalytics = () => {
  const { sendCustomEvent } = useGoogleTagManager()

  const gtmCartInit = (params: GtmDataLayerCart) => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartInitalization,
      product: 'fax',
      page: 'plan_upgrade',
      extraData: {
        cart: { ...params },
      },
    })
  }

  const gtmCartPurchaseSuccess = (params: GtmDataLayerCart) => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartPurchaseSuccess,
      product: 'fax',
      page: 'plan_upgrade',
      extraData: {
        cart: { ...params },
      },
    })
  }

  const gtmCartPurchaseFailed = (params: GtmDataLayerCart) => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartPurchaseFailed,
      product: 'fax',
      page: 'plan_upgrade',
      extraData: {
        cart: { ...params },
      },
    })
  }

  return { gtmCartInit, gtmCartPurchaseSuccess, gtmCartPurchaseFailed }
}
