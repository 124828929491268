import { Box } from '@alohi/flow-ui'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { tss } from 'tss-react'
import { useViewApi } from 'views/fax/FaxPlanUpgrade/context/api'
import { FaxingDestinationRadioGroup } from './components/FaxingDestinationRadioGroup'

interface FaxingDestinationRadioSectionProps {
  className?: string
}

export const FaxingDestinationRadioSection = ({
  className,
}: FaxingDestinationRadioSectionProps) => {
  const { classes, cx } = useStyles()

  const { selectedFaxPlanShopItem, updateStore } = useViewApi()
  const isInternational = !selectedFaxPlanShopItem?.dstLimits.length

  const { faxPlans } = useShopItems()
  const faxHighVolumePlans = faxPlans.filter((faxPlan) => faxPlan.highVolume)

  const handleUscaClick = () => {
    const newPlanId = faxHighVolumePlans.find(
      (plan) =>
        plan.pages === selectedFaxPlanShopItem?.pages &&
        plan.dstLimits.length === 2 &&
        plan.dstLimits.includes('US') &&
        plan.dstLimits.includes('CA') &&
        plan.period === selectedFaxPlanShopItem.period
    )?.id

    if (!newPlanId) return

    updateStore({
      selectedFaxPlanShopItemId: newPlanId,
    })
  }

  const handleInternationalClick = () => {
    const newPlanId = faxHighVolumePlans.find(
      (plan) =>
        plan.pages === selectedFaxPlanShopItem?.pages &&
        plan.dstLimits.length === 0 &&
        plan.period === selectedFaxPlanShopItem.period
    )?.id

    if (!newPlanId) return

    updateStore({
      selectedFaxPlanShopItemId: newPlanId,
    })
  }

  return (
    <Box className={cx(classes.base, className)}>
      <FaxingDestinationRadioGroup
        isInternational={isInternational}
        onUscaClick={handleUscaClick}
        onInternationalClick={handleInternationalClick}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
