import { Dispatch, createContext, useContext } from 'react'
import { RoutesAction } from './reducer'
import {
  RoutesStore,
  initialRoutesComputedStore,
  initialRoutesStore,
} from './store'

export interface RoutesContextProps {
  store: RoutesStore
  dispatch: Dispatch<RoutesAction>
}

export const RoutesContext = createContext<RoutesContextProps>({
  store: {
    ...initialRoutesStore,
    ...initialRoutesComputedStore,
  },
  dispatch: () => undefined,
})

export function useRoutesContext() {
  const context = useContext(RoutesContext)

  if (!context) {
    throw new Error('useRoutesContext is ouside of the Provider')
  }

  return context
}
