export const testIds = {
  backButton: 'back-button',
  selectPlan: {
    select: 'select-plan-select',
    list: 'select-plan-list',
    comparePlans: 'select-plan-list-compare',
    annualTab: 'select-plan-list-annual-tab',
    monthlyTab: 'select-plan-list-monthly-tab',
    effectiveDate: {
      immediately: 'select-plan-immediately',
      nextBillingCycle: 'select-plan-next-billing-cycle',
    },
  },
  sign: {
    seats: {
      input: 'seats-input',
      increment: 'seats-increment',
      decrement: 'seats-decrement',
    },
  },
  fax: {
    plan: {
      cartItems: {
        planCartItem: 'plan-cart-item',
        firstRequiredNumberCartItem: 'first-required-number-cart-item',
        secondRequiredNumberCartItem: 'second-required-number-cart-item',
      },
    },
    numbers: {
      section: 'fax-numbers-section',
      number: 'fax-numbers-number',
      radio: {
        random: 'fax-numbers-radio-random',
        custom: 'fax-numbers-radio-custom',
      },
      country: {
        select: 'fax-numbers-country-select',
        list: 'fax-numbers-country-list',
      },
      area: {
        select: 'fax-numbers-area-select',
        list: 'fax-numbers-area-list',
      },
      bundle: {
        select: 'fax-numbers-bundle-select',
        list: 'fax-numbers-bundle-list',
      },
      address: {
        select: 'fax-numbers-address-select',
        list: 'fax-numbers-address-list',
      },
      custom: {
        searchInput: 'fax-numbers-custom-search-input',
        searchButton: 'fax-numbers-custom-search-button',
        availableNumber: 'fax-numbers-custom-search-available-number',
        availableNumberSelect: 'fax-numbers-custom-search-available-number-select',
        noNumberAvailableMessage: 'fax-numbers-custom-no-number-available-message',
      },
      infosMessage: 'fax-numbers-infos-message',
    },
  },
  orderSummary: {
    cartItemsContainer: 'order-summary-cart-item-container',
    discountCodeButton: 'order-summary-discount-code-button',
    discountCodeInput: 'order-summary-discount-code-input',
    discountCodeApply: 'order-summary-discount-code-apply',
    discountCodeClose: 'order-summary-discount-code-close',
    total: 'order-summary-total',
    submitButton: 'order-summary-submit-button',
  },
  paymentMethodsList: {
    row: 'payment-method-list-row',
    radio: 'payment-method-list-radio',
    more: {
      button: 'payment-method-list-more-button',
      menu: 'payment-method-list-more-menu',
    },
    addNew: 'payment-method-list-add-new',
  },
  newPaymentMethodForm: {
    creditCard: {
      radio: 'new-payment_method-credit-card-radio',
      name: 'new-payment_method-credit-card-name',
      infos: 'new-payment_method-credit-card-infos',
      street: 'new-payment_method-credit-card-street',
      postal: 'new-payment_method-credit-card-postal',
      country: {
        select: 'new-payment_method-credit-card-country-select',
        list: 'new-payment_method-credit-card-country-list',
      },
    },
    paypal: {
      radio: 'new-payment_method-paypal-radio',
      button: 'new-payment_method-paypal-button',
    },
  },
  modals: {
    default: {
      modal: 'modal-modal',
      header: 'modal-header',
      body: 'modal-body',
      footer: 'modal-footer',
      confirm: 'modal-confirm',
      close: 'modal-close',
      cancel: 'modal-cancel',
    },
    purchaseError: {
      cancel: 'purchase-error-modal-cancel',
      confirm: 'purchase-error-modal-confirm',
      switch: 'purchase-error-modal-switch',
    },
  },
}
