import {
  Box,
  IncrementButton,
  NumberInput,
  spacing,
  useBool,
  useDependencyChange,
  useInput,
} from '@alohi/flow-ui'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface QuantitySelectProps {
  className?: string
  min?: number
  max?: number
  onUpdate: (quantity: number) => void
  isDisabled: boolean
  cartNumbersLength: number
}

export const QuantitySelect: React.FC<QuantitySelectProps> = ({
  className,
  min = 1,
  max = 100,
  onUpdate,
  isDisabled,
  cartNumbersLength,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const [isIncrementIsLoading, isIncrementIsLoadingBool] = useBool(false)
  const [isDecrementIsLoading, isDecrementIsLoadingBool] = useBool(false)

  const quantityChecker = useCallback(
    (quantity: number | undefined) => {
      if (!quantity) {
        return `${t('common.quantity')} ${t('common.required')}`
      }
      if (quantity < min || quantity > max) {
        return t('seats.selectBetween', {
          value_1: min,
          value_2: max,
        })
      }
    },
    [max, min, t]
  )

  const updateQuantity = (quantity: number) => {
    onUpdate(quantity)
    isDecrementIsLoadingBool.setFalse()
    isIncrementIsLoadingBool.setFalse()
  }

  const handleBlur = () => {
    if (!quantity || quantity < min || quantity > max || quantity === cartNumbersLength) return

    updateQuantity(quantity)
  }

  const [quantity, quantityInputProps] = useInput<number | undefined>({
    initialValue: 1,
    validator: quantityChecker,
    onBlur: handleBlur,
  })
  const inputQuantity = quantity ?? 0

  const handleIncrement = () => {
    isIncrementIsLoadingBool.setTrue()

    const newValue = Math.min(max, Math.max(min, inputQuantity + 1))

    quantityInputProps.onChange(newValue, {
      forceCheck: true,
    })
    updateQuantity(newValue)
  }

  const handleDecrement = () => {
    isDecrementIsLoadingBool.setFalse()

    const newValue = Math.min(max, Math.max(min, inputQuantity - 1))

    quantityInputProps.onChange(newValue, {
      forceCheck: true,
    })
    updateQuantity(newValue)
  }

  useDependencyChange(() => {
    quantityInputProps.onChange(cartNumbersLength)
  }, [cartNumbersLength])

  return (
    <Box className={cx(classes.base, className)}>
      <NumberInput
        value={quantity}
        {...quantityInputProps}
        id="quantity"
        placeholder={`${min}`}
        className={classes.input}
        label={t('common.quantity')}
        isDisabled={isDisabled}
        alignedContainer={
          <Box className={classes.buttonContainer}>
            <IncrementButton
              variant="minus"
              className={classes.button}
              onClick={handleDecrement}
              isLoading={isDecrementIsLoading}
              isDisabled={isDisabled || inputQuantity <= min}
            />
            <IncrementButton
              variant="plus"
              className={classes.button}
              onClick={handleIncrement}
              isLoading={isIncrementIsLoading}
              isDisabled={isDisabled || inputQuantity >= max}
            />
          </Box>
        }
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  input: {
    '& input': {
      width: '211px',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing['8'],
  },
  button: {
    marginLeft: spacing['8'],
  },
}))
