import { Box, colors, spacing, Text } from '@alohi/flow-ui'
import { RegulatoryRequirementType } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface RegulatoryRequirementTextProps {
  className?: string
  label: string
  type: RegulatoryRequirementType
}

export const RegulatoryRequirementText: React.FC<RegulatoryRequirementTextProps> = ({
  className,
  label,
  type,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <Text>{label}</Text>
      <Text variant="medium" className={classes.description}>
        {type === RegulatoryRequirementType.Address
          ? `(${t('faxNumber.requiresAddress')})`
          : `(${t('faxNumber.requiresProofOfId')})`}
      </Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    color: colors.neutral400,
    marginLeft: spacing['8'],
    marginTop: '1px',
  },
}))
