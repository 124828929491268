import dayjs, { Dayjs } from 'dayjs'
import Calendar from 'dayjs/plugin/calendar'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import Duration from 'dayjs/plugin/duration'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import Timezone from 'dayjs/plugin/timezone'
import UpdateLocale from 'dayjs/plugin/updateLocale'
import Utc from 'dayjs/plugin/utc'

dayjs.extend(Utc)
dayjs.extend(Calendar)
dayjs.extend(Duration)
dayjs.extend(Timezone)
dayjs.extend(UpdateLocale)
dayjs.extend(LocalizedFormat)
dayjs.extend(CustomParseFormat)

export const formatDefaultDate = (unixTimestamp: number) =>
  dayjs.unix(unixTimestamp).format('ll')

export { Dayjs }

export default dayjs
