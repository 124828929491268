import {
  DOMAIN,
  FAX_APP,
  FAX_MARKETING_WEBSITE_URL,
  SIGN_APP,
  SIGN_MARKETING_WEBSITE_URL,
} from 'env'

export const sanitizeUrl = (urlString: string | undefined | null): string | undefined => {
  if (!urlString) return undefined
  try {
    const url = new URL(urlString)

    const regex = new RegExp(`^[a-zA-Z0-9.-]+\\.${DOMAIN.replace('.', '\\.')}$`)
    if (url.hostname.match(regex) || url.hostname === 'localhost') {
      return urlString
    } else {
      return undefined
    }
  } catch (e) {
    return undefined
  }
}

export const verifyQueryParam = (
  queryParamValue: string | undefined,
  expectedValue: string | undefined
): boolean => {
  return queryParamValue?.toLowerCase() === expectedValue?.toLowerCase()
}

export const websiteUrls = {
  fax: {
    help: {
      contactSales: FAX_MARKETING_WEBSITE_URL + '/contact-sales',
      faxNumberHelp: FAX_MARKETING_WEBSITE_URL + '/fax-number-help',
      numberPorting: FAX_MARKETING_WEBSITE_URL + '/number-porting-help',
    },
    numbers: FAX_APP + '/numbers',
    profile: {
      bundles: FAX_APP + '/profile/compliance?direct_open_modal=regulatory_documents',
      addresses: FAX_APP + '/profile/compliance?direct_open_modal=addresses',
    },
    home: FAX_APP + '/faxes/inbox',
    pricing: FAX_MARKETING_WEBSITE_URL + '/pricing',
    contactUs: FAX_APP + '/profile/general?direct_open_modal=contact_us_for_sales',
  },
  sign: {
    help: {
      qes: {
        credit: SIGN_MARKETING_WEBSITE_URL + '/qes-credit-help',
        eidasVerification: SIGN_MARKETING_WEBSITE_URL + '/eidas-verification-help',
        zertesVerification: SIGN_MARKETING_WEBSITE_URL + '/zertes-verification-help',
      },
      sms: {
        addSmsAuth: SIGN_MARKETING_WEBSITE_URL + '/sms-verification-help',
        recipientVerification: SIGN_MARKETING_WEBSITE_URL + '/recipient-verification-help',
      },
    },
    home: SIGN_APP + '/home',
    pricing: SIGN_MARKETING_WEBSITE_URL + '/pricing',
    api: SIGN_MARKETING_WEBSITE_URL + '/api',
  },
  alohi: {
    contactSales: 'https://www.alohi.com/contact-sales',
  },
}

export const urls = {
  home: '/',
  fax: {
    upgrade: '/fax/upgrade',
    addNumber: '/fax/add-number',
    changeNumber: '/fax/change-number',
  },
  sign: {
    upgrade: '/sign/upgrade',
    qes: '/sign/qes',
    sms: '/sign/sms',
    seats: '/sign/seats',
    custom: '/sign/custom',
  },
}
