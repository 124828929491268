import {
  Box,
  Chip,
  colors,
  SelectPlan,
  SelectPlanProps,
  spacing,
  Text,
  useBool,
} from '@alohi/flow-ui'
import { Period } from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { testIds } from 'helpers/tests'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { FaxPlusPlanComputedShopItem, useShopItems } from 'hooks/useShopItems/useShopItems'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from 'views/fax/FaxPlanUpgrade/context/api'

import {
  AlohiProducts,
  PlanSelectionModal,
} from 'modals/common/PlanSelectionModal/PlanSelectionModal'
import { HighVolumeDescription } from './components/HighVolumeDescription'
import { HighVolumeLabel } from './components/HighVolumeLabel'
import { Perks } from './components/Perks'
import { PlanDescription } from './components/PlanDescription'
import { PlanLabel } from './components/PlanLabel'

const ANNUAL_HIGH_VOLUME_ITEM_ID = 'annual_high_volume_item_id'
const MONTHLY_HIGH_VOLUME_ITEM_ID = 'monthly_high_volume_item_id'

interface FaxPlanSelectSectionProps {
  className?: string
  autoOpenPlans?: boolean
}

export const FaxPlanSelectSection: React.FC<FaxPlanSelectSectionProps> = ({
  className,
  autoOpenPlans,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { faxPlans } = useShopItems()
  const faxHighVolumePlans = useMemo(
    () => faxPlans.filter((faxPlan) => faxPlan.highVolume),
    [faxPlans]
  )
  const monthlyHighVolumeNotAvailable = useMemo(
    () =>
      faxHighVolumePlans
        .filter((faxPlan) => faxPlan.period === Period.Monthly)
        .every((faxPlan) => faxPlan.notAvailable),
    [faxHighVolumePlans]
  )

  const annualHighVolumeNotAvailable = useMemo(
    () =>
      faxHighVolumePlans
        .filter((faxPlan) => faxPlan.period === Period.Annual)
        .every((faxPlan) => faxPlan.notAvailable),
    [faxHighVolumePlans]
  )

  const { selectedFaxPlanShopItem, updateStore } = useViewApi()
  const selectedItemId = useMemo(() => {
    if (selectedFaxPlanShopItem?.highVolume) {
      if (selectedFaxPlanShopItem?.period === Period.Monthly) {
        return MONTHLY_HIGH_VOLUME_ITEM_ID
      } else {
        return ANNUAL_HIGH_VOLUME_ITEM_ID
      }
    } else {
      return selectedFaxPlanShopItem?.id
    }
  }, [
    selectedFaxPlanShopItem?.highVolume,
    selectedFaxPlanShopItem?.id,
    selectedFaxPlanShopItem?.period,
  ])

  const {
    store: { isUpdating },
  } = useCartApi()

  const { activeFaxSubscription } = useActiveSubscriptions()

  const [isPlansModalOpen, isPlansModalOpenBool] = useBool(autoOpenPlans ?? false)

  const canComparePlans = !activeFaxSubscription.computed.planItem.highVolume

  const tabHeaders: SelectPlanProps['tabHeaders'] = useMemo(
    () => ({
      annual: (
        <Box className={classes.tabContainer} dataCy={testIds.selectPlan.annualTab}>
          <Text>{t('plans.annual')}</Text>
          <Chip
            className={classes.savePercentChip}
            label={t('purchase.savePercentUpTo', { value_1: 40 })}
          />
        </Box>
      ),
      monthly: (
        <Box className={classes.tabContainer} dataCy={testIds.selectPlan.monthlyTab}>
          <Text>{t('plans.monthly')}</Text>
        </Box>
      ),
    }),
    [classes.savePercentChip, classes.tabContainer, t]
  )

  const selectItems: SelectPlanProps['items'] = useMemo(() => {
    const highVolumePlanAnnual: SelectPlanProps['items'][number] = {
      id: ANNUAL_HIGH_VOLUME_ITEM_ID,
      label: <HighVolumeLabel period={Period.Annual} />,
      inputLabel: <HighVolumeLabel period={Period.Annual} isInInput />,
      description: <HighVolumeDescription />,
      period: 'ANNUALLY',
      isDisabled: annualHighVolumeNotAvailable,
      rightContainer: null,
      hasTopDivider: true,
    }
    const highVolumePlanMonthly: SelectPlanProps['items'][number] = {
      id: MONTHLY_HIGH_VOLUME_ITEM_ID,
      label: <HighVolumeLabel period={Period.Monthly} />,
      inputLabel: <HighVolumeLabel period={Period.Monthly} isInInput />,
      description: <HighVolumeDescription />,
      period: 'MONTHLY',
      isDisabled: monthlyHighVolumeNotAvailable,
      rightContainer: null,
      hasTopDivider: true,
    }

    const items = faxPlans
      ?.filter((shopItem) => !shopItem.highVolume)
      .map((shopItem) => {
        const isActiveItem = shopItem.id === activeFaxSubscription.computed.planItem.shopItemId

        return {
          id: shopItem.id,
          period: shopItem.period === Period.Annual ? 'ANNUALLY' : 'MONTHLY',
          label: <PlanLabel item={shopItem} period={shopItem.period} />,
          inputLabel: <PlanLabel item={shopItem} period={shopItem.period} isInInput />,
          description: <PlanDescription item={shopItem} />,
          isDisabled: Boolean(shopItem.notAvailable),
          rightContainer: isActiveItem ? (
            <Chip className={classes.activeChip} label={t('purchase.currentPlan')} />
          ) : null,
        } satisfies SelectPlanProps['items'][number]
      })

    return [...items, highVolumePlanAnnual, highVolumePlanMonthly]
  }, [
    activeFaxSubscription.computed.planItem.shopItemId,
    annualHighVolumeNotAvailable,
    classes.activeChip,
    faxPlans,
    monthlyHighVolumeNotAvailable,
    t,
  ])

  const handleSelectItemId = useCallback(
    async (id: string) => {
      try {
        if (selectedFaxPlanShopItem?.id === id) return

        let newSelectedItem: FaxPlusPlanComputedShopItem | undefined = undefined

        if (id === ANNUAL_HIGH_VOLUME_ITEM_ID) {
          newSelectedItem = faxHighVolumePlans.find(
            (item) => item.period === Period.Annual && item.defaultForPeriod
          )
        } else if (id === MONTHLY_HIGH_VOLUME_ITEM_ID) {
          newSelectedItem = faxHighVolumePlans.find(
            (item) => item.period === Period.Monthly && item.defaultForPeriod
          )
        } else {
          newSelectedItem = faxPlans.find((item) => item.id === id)
        }

        if (newSelectedItem) {
          updateStore({ selectedFaxPlanShopItemId: newSelectedItem.id })
        }
      } catch {
        // Error handled in store
      }
    },
    [faxHighVolumePlans, faxPlans, selectedFaxPlanShopItem, updateStore]
  )

  return (
    <Box className={cx(classes.base, className)}>
      <SelectPlan
        selectedItemId={selectedItemId}
        onSelectItemId={handleSelectItemId}
        items={selectItems}
        tabHeaders={tabHeaders}
        onComparePlansClick={canComparePlans ? isPlansModalOpenBool.setTrue : undefined}
        dataCys={testIds.selectPlan}
        isDisabled={isUpdating}
      />
      {isPlansModalOpen ? (
        <PlanSelectionModal onClose={isPlansModalOpenBool.setFalse} product={AlohiProducts.FAX} />
      ) : null}
      <Perks
        className={classes.perks}
        planType={selectedFaxPlanShopItem?.planType}
        onComparePlans={canComparePlans ? isPlansModalOpenBool.setTrue : undefined}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  savePercentChip: {
    marginLeft: spacing['8'],
    fontWeight: 'normal',
  },
  perks: {
    marginTop: spacing['24'],
  },
  activeChip: {
    whiteSpace: 'nowrap',
    background: colors.neutral300,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral400,
  },
}))
