import { SUB_DOMAIN } from 'env'

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  }

  return navigator.language || 'en'
}

export const PRODUCTS = {
  FAX: 'Fax.Plus',
  SIGN: 'Sign.Plus',
}

export const getAppType = (): 'fax' | 'sign' | undefined => {
  const isSign = SUB_DOMAIN === 'sign'
  const isFax = SUB_DOMAIN === 'fax'

  if (isSign) return 'sign'
  else if (isFax) return 'fax'
  else return undefined
}

export const formatLocalizedNumber = ({ num }: { num: number }) => {
  return Intl.NumberFormat(getNavigatorLanguage()).format(num)
}

const letterToDigitMap: Record<string, string> = {
  A: '2',
  B: '2',
  C: '2',
  D: '3',
  E: '3',
  F: '3',
  G: '4',
  H: '4',
  I: '4',
  J: '5',
  K: '5',
  L: '5',
  M: '6',
  N: '6',
  O: '6',
  P: '7',
  R: '7',
  S: '7',
  T: '8',
  U: '8',
  V: '8',
  W: '9',
  Y: '9',
  Z: '9',
}

export const textToPhoneDigits = (text: string) => {
  const cleanText = text.toUpperCase().replace(/[^A-Z0-9]/g, '')
  return cleanText
    .split('')
    .map((char) => {
      if (/[0-9]/.test(char)) {
        return char
      }
      if (letterToDigitMap[char]) {
        return letterToDigitMap[char]
      }
      return ''
    })
    .join('')
}

export const getCustomBranchByClient = () => {
  const isSign = SUB_DOMAIN === 'sign'
  const isFax = SUB_DOMAIN === 'fax'

  if (
    'WEB_CONFIG' in window &&
    window.WEB_CONFIG &&
    typeof window.WEB_CONFIG === 'object' &&
    'admin_sign_api_target' in window.WEB_CONFIG &&
    isSign
  ) {
    return window.WEB_CONFIG?.['admin_sign_api_target']
  }

  if (
    'WEB_CONFIG' in window &&
    window.WEB_CONFIG &&
    typeof window.WEB_CONFIG === 'object' &&
    'admin_fax_api_target' in window.WEB_CONFIG &&
    isFax
  ) {
    return window.WEB_CONFIG?.['admin_fax_api_target']
  }

  return ''
}
