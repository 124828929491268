import { Box, Helper, List, Message, spacing, Text, Tooltip } from '@alohi/flow-ui'
import { FaxPlusNumberOrderItem } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { FaxSubstitutedNumber } from './components/FaxSubstitutedNumber'
import { TooltipContent } from './components/TooltipContent'

interface FaxSubstitutedNumberMessageProps {
  className?: string
  numbers: FaxPlusNumberOrderItem[]
}

export const FaxSubstitutedNumberMessage: React.FC<FaxSubstitutedNumberMessageProps> = ({
  className,
  numbers,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const hasMultipleSubsitutedNumbers =
    numbers.filter((number) => Boolean(number.requestedNumber)).length > 1

  if (numbers.length <= 0) return
  return (
    <Box className={cx(classes.base, className)}>
      <Message className={classes.message} variant="success">
        <Box className={classes.multipleNumberContent}>
          <Box className={classes.header}>
            <Text isBold>
              {t('faxNumber.yourNewFaxNumberAre', {
                count: numbers.length,
              })}
            </Text>
            <Box className={classes.filler} />
            <Tooltip
              className={classes.tooltip}
              label={<TooltipContent hasMultipleSubsitutedNumbers={hasMultipleSubsitutedNumbers} />}
            >
              <Helper className={classes.helper} variant="small" />
            </Tooltip>
          </Box>
          <Box className={classes.list}>
            <List>
              {numbers.map((number, index) => (
                <FaxSubstitutedNumber
                  key={index.toString()}
                  number={number.number}
                  requestedNumber={number.requestedNumber}
                />
              ))}
            </List>
          </Box>
        </Box>
      </Message>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['24'],
    marginBottom: spacing['8'],
    width: '100%',
  },
  message: {
    display: 'flex',
  },
  multipleNumberContent: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing['8'],
  },
  filler: {
    flexGrow: 1,
  },
  list: {
    marginLeft: spacing['24'],
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  helper: {
    //
  },
}))
