import { Box, colors, Radio, RadioGroup, radius, spacing, Text } from '@alohi/flow-ui'
import { useCartApi } from 'contexts/cart/api'
import { formatDefaultDate } from 'helpers/date'
import { testIds } from 'helpers/tests'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface EffectiveDateSectionProps {
  className?: string
  expiresAt: number
}

export const EffectiveDateSection: React.FC<EffectiveDateSectionProps> = ({
  className,
  expiresAt,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    cart: { isScheduled },
    scheduleCart,
  } = useCartApi()

  const nextBillingCycleText = `${t('purchase.nextBillingCycle')} (${formatDefaultDate(expiresAt)})`

  const ref = useRef<HTMLInputElement>(null)

  const onSelectImmediately = async () => {
    if (!isScheduled) return
    try {
      await scheduleCart(false)
    } catch (error) {
      // Error caught in stores
    }
  }

  const onSelectNextBillingCycle = async () => {
    if (isScheduled) return
    try {
      await scheduleCart(true, {
        useCredit: false,
      })
    } catch (error) {
      // Error caught in stores
    }
  }

  return (
    <Box className={cx(classes.base, className)}>
      <RadioGroup
        ref={ref}
        value={isScheduled ? 'next-billing-cycle' : 'immediately'}
        className={classes.radioGroup}
      >
        <Box
          className={cx(classes.firstRow)}
          onClick={() => {
            if (!ref.current) return
            ref.current.value = 'immediately'
          }}
          dataCy={testIds.selectPlan.effectiveDate.immediately}
        >
          <Radio value={'immediately'} onCheck={onSelectImmediately}>
            <Text>{t('common.immediately')}</Text>
          </Radio>
        </Box>
        <Box
          className={cx(classes.lastRow)}
          onClick={() => {
            if (!ref.current) return
            ref.current.value = 'next-billing-cycle'
          }}
          dataCy={testIds.selectPlan.effectiveDate.nextBillingCycle}
        >
          <Radio value={'next-billing-cycle'} onCheck={onSelectNextBillingCycle}>
            <Text>{nextBillingCycleText}</Text>
          </Radio>
        </Box>
      </RadioGroup>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing['32'],
  },
  radioGroup: {
    flexGrow: 1,
  },
  firstRow: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: spacing['16'],
    borderTopRightRadius: radius['8'],
    borderTopLeftRadius: radius['8'],
    borderBottomWidth: 0,
  },
  lastRow: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: spacing['16'],
    borderBottomRightRadius: radius['8'],
    borderBottomLeftRadius: radius['8'],
  },
}))
