import {
  Box,
  FormContextProvider,
  Helper,
  Link,
  LogoFrame,
  SectionDivider,
  StepDivider,
  Text,
  Tooltip,
  spacing,
  useOnMount,
} from '@alohi/flow-ui'
import { InitFlowQesDocument } from 'api/gql/generated/graphql'
import { FlowLayout } from 'components/common/FlowLayout/FlowLayout/FlowLayout'
import { PaymentMethodSection } from 'components/common/PaymentMethodSection/PaymentMethodSection'
import { useAppApi } from 'contexts/app/api'
import { useCartApi } from 'contexts/cart/api'
import { websiteUrls } from 'helpers/urls'
import { useInitFlow } from 'hooks/useInitFlow/useInitFlow'
import { usePermissions } from 'hooks/usePermissions/usePermissions'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { SignQesSection } from './components/SignQesSection'
import { useViewApi } from './context/api'
import { ViewContextProvider } from './context/ViewContextProvider'

export const SignQes = () => {
  const { isFlowReady } = useInitFlow(InitFlowQesDocument)
  if (!isFlowReady) return
  return (
    <FormContextProvider autoScroll>
      <ViewContextProvider>
        <SignQesContent />
      </ViewContextProvider>
    </FormContextProvider>
  )
}

const SignQesContent = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { canAccessSignQes } = usePermissions()

  const { submit } = useViewApi()

  const { redirectToUnauthorized } = useAppApi()

  const { cart } = useCartApi()

  // Display Payment Method Section
  const hasPaymentMethodSection = !(cart.total === 0 && Boolean(cart.creditUsed))

  const Label = useMemo(
    () => (
      <Box className={classes.tooltip}>
        <Text variant="body">{t('qes.qes')}</Text>
        <Text variant="small" className={classes.tooltipDesc}>
          {t('qes.tooltipDesc1')}
        </Text>
        <Text variant="small" className={classes.tooltipDesc}>
          {t('qes.tooltipDesc2')}
        </Text>
        <Text variant="small" className={classes.tooltipDesc}>
          {t('qes.tooltipDesc3')}
        </Text>
        <Text variant="small" className={classes.spacing}>
          {t('qes.tooltipVisitHelpCenter')}
        </Text>
        <Link
          variant="small"
          color="light"
          className={classes.link}
          withIcon
          hasBullet
          isExternal
          href={websiteUrls.sign.help.qes.credit}
        >
          {t('qes.tooltipLink1')}
        </Link>
        <Link
          variant="small"
          color="light"
          className={classes.link}
          withIcon
          hasBullet
          isExternal
          href={websiteUrls.sign.help.qes.eidasVerification}
        >
          {t('qes.tooltipLink2')}
        </Link>
        <Link
          variant="small"
          color="light"
          className={classes.link}
          withIcon
          hasBullet
          isExternal
          href={websiteUrls.sign.help.qes.zertesVerification}
        >
          {t('qes.tooltipLink3')}
        </Link>
      </Box>
    ),
    [classes.link, classes.spacing, classes.tooltip, classes.tooltipDesc, t]
  )

  useOnMount(() => {
    if (!canAccessSignQes) {
      redirectToUnauthorized()
    }
  })

  if (!canAccessSignQes) return
  return (
    <FlowLayout
      onProcess={submit}
      className={classes.base}
      header={
        <SectionDivider iconFrame={<LogoFrame variant="sign" />}>
          <Text className={classes.title} variant="h3">
            {t('qes.purchaseTitle')}
          </Text>
          <Tooltip label={Label}>
            <Helper variant="large" />
          </Tooltip>
        </SectionDivider>
      }
    >
      <StepDivider className={classes.firstStepDivider} index={1}>
        <Text isBold>{t('qes.yourQesCredits')}</Text>
      </StepDivider>
      <SignQesSection />

      {hasPaymentMethodSection ? (
        <>
          <StepDivider className={classes.stepDivider} index={2}>
            <Text isBold>{t('payment.paymentDetails')}</Text>
          </StepDivider>
          <PaymentMethodSection />
        </>
      ) : null}
    </FlowLayout>
  )
}
const useStyles = tss.create(() => ({
  base: {
    //
  },
  firstStepDivider: {
    marginBottom: spacing['24'],
  },
  stepDivider: {
    marginTop: spacing['48'],
    marginBottom: spacing['24'],
  },
  title: {
    flexGrow: 1,
  },
  tooltip: {
    padding: spacing['16'],
  },
  tooltipDesc: {
    marginTop: spacing['16'],
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '350px',
  },
  spacing: {
    marginTop: spacing['16'],
  },
  link: {
    marginTop: spacing['4'],
    textWrap: 'nowrap',
    marginLeft: spacing['4'],
  },
}))
