import { useCallback } from 'react'

import {
  SignPlanPurchaseFlowType,
  SignPlusPlanCartItem,
  SignPlusPlanOrderItem,
} from 'api/gql/generated/graphql'
import { SuccessModalContentProps } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useTranslation } from 'react-i18next'
import { useViewContext } from './store/context'
import { ViewDataStore } from './store/store'

export function useViewApi() {
  const { store, dispatch } = useViewContext()
  const { t } = useTranslation()

  const {
    store: { selectedPaymentMethodId },
  } = usePaymentMethodApi()
  const {
    activeSignSubscription: {
      planItem: { totalSeats },
    },
  } = useActiveSubscriptions()

  const { cart, setCart: setCartApi, processCart: processCartApi } = useCartApi()

  const updateStore = useCallback(
    (payload: Partial<ViewDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const submit = useCallback(async (): Promise<SuccessModalContentProps | undefined> => {
    const response = await processCartApi({
      paymentMethodId: selectedPaymentMethodId,
    })

    if (response?.__typename === 'ArchivedOrder' || response?.__typename === 'ActiveOrder') {
      const newPlanOrderItem = response.orderItems.find(
        (item): item is SignPlusPlanOrderItem => item?.__typename === 'SignPlusPlanOrderItem'
      )

      return {
        description: t('seats.successModalDescription', {
          value_1: totalSeats + (newPlanOrderItem?.totalSeats.value ?? 0),
        }),
      }
    } else if (response?.__typename === 'Cart') {
      const newSignPlusPlanCartItem = response.items.find(
        (item): item is SignPlusPlanCartItem => item.__typename === 'SignPlusPlanCartItem'
      )

      return {
        description: t('seats.successModalDescription', {
          value_1: totalSeats + (newSignPlusPlanCartItem?.totalSeats.value ?? 0),
        }),
      }
    }
    throw new Error('No valid response')
  }, [processCartApi, selectedPaymentMethodId, t, totalSeats])

  const setCart = useCallback(
    async (params: { id: string; seats: number }) => {
      await setCartApi({
        schedule: cart.processingInfo?.__typename === 'CartScheduled',
        useCredit: cart.creditUsed != null,
        items: [
          {
            signPlan: {
              id: params.id,
              itemAmount: params.seats,
              flowType: SignPlanPurchaseFlowType.ChangeSeats,
            },
          },
        ],
      })
    },
    [cart, setCartApi]
  )

  return {
    store,
    updateStore,
    submit,
    setCart,
  }
}
