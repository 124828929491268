import { Box, Chip, Text } from '@alohi/flow-ui'
import { MarketingData } from 'api/gql/generated/graphql'
import { formatLocalizedNumber } from 'helpers/common'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface OptionProps {
  pages: number
  marketingData: MarketingData
}

function Option({ pages, marketingData }: OptionProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const discount = marketingData?.discount?.toString() ?? ''

  return (
    <Box className={classes.wrapper}>
      <Text>{formatLocalizedNumber({ num: pages })}</Text>
      {discount ? <Chip label={t('purchase.savePercent', { value_1: discount })} /> : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export default Option
