import { InMemoryCache } from '@apollo/client'

export const cache = new InMemoryCache({
  typePolicies: {
    Cart: {
      keyFields: ['__typename'],
    },
    ShopItems: {
      fields: {
        signPlans: {
          keyArgs: false, // Ignore all arguments when caching signPlans
        },
        signApiPlans: {
          keyArgs: false, // Ignore all arguments when caching signApiPlans
        },
      },
    },
    SignPlusPlanShopItems: {
      keyFields: ['id'],
    },
    SignPlusApiPlanShopItems: {
      keyFields: ['id'],
    },
    SignPlusSmsShopItems: {
      keyFields: ['id'],
    },
    SignPlusQesShopItems: {
      keyFields: ['id'],
    },
    SignPlusQesShopItem: {
      keyFields: false,
    },
    SignPlusPlanShopItem: {
      keyFields: false,
    },
    SignPlusSmsShopItem: {
      keyFields: false,
    },
    FaxPlusPlanShopItem: {
      keyFields: false,
    },
    FaxPlusPlanCartItem: {
      keyFields: false,
    },
    FaxPlusNumberCartItem: {
      keyFields: false,
    },
    FaxPlusExtraNumberCartItem: {
      keyFields: false,
    },
    SignPlusPlanSubscriptionItem: {
      keyFields: false,
    },
    FaxPlusPlanSubscriptionItem: {
      keyFields: false,
    },
    FaxPlusNumberSubscriptionItem: {
      keyFields: false,
    },
  },
  possibleTypes: {
    SubscriptionItem: [
      'SignPlusPlanSubscriptionItem',
      'FaxPlusPlanSubscriptionItem',
      'FaxPlusNumberSubscriptionItem',
    ],
    ShopItem: [
      'SignPlusPlanShopItem',
      'SignPlusSmsShopItem',
      'SignPlusQesShopItem',
      'AlohiCreditShopItem',
      'FaxPlusPlanShopItem',
      'FaxPlusNumberShopItem',
    ],
    CartItem: [
      'AlohiCreditCartItem',
      'SignPlusPlanCartItem',
      'SignPlusQesCartItem',
      'SignPlusSmsCartItem',
      'AlohiCompensationCartItem',
      'FaxPlusPlanCartItem',
      'FaxPlusNumberCartItem',
      'FaxPlusExtraNumberCartItem',
      'FaxPlusNumberSetupCartItem',
      'FaxPlusNumberReplacementCartItem',
    ],
    PaymentMethod: ['PaymentMethodStripe', 'PaymentMethodPaypal'],
    Order: ['ActiveOrder', 'ArchivedOrder'],
    OrderItem: ['SignPlusPlanOrderItem', 'SignPlusQesOrderItem', 'SignPlusSmsOrderItem'],
    CartDiscount: ['AppliedDiscount', 'InvalidDiscount'],
  },
})
