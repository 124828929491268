import { Box, CountryCode, getI18nCountry, Link, spacing, Text } from '@alohi/flow-ui'
import { Error, FaxNumberType, RegulatoryBundleStatus } from 'api/gql/generated/graphql'
import { websiteUrls } from 'helpers/urls'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface TooltipWarningContentProps {
  className?: string
  inputCountry: CountryCode
  notAvailable: Error | null | undefined
  formattedNumber: string
  type: FaxNumberType
  area?: string
}

export const TooltipWarningContent: React.FC<TooltipWarningContentProps> = ({
  className,
  inputCountry,
  notAvailable,
  formattedNumber,
  type,
  area,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: { regulatoryData },
  } = useCustomer()

  const countryLabel = getI18nCountry(inputCountry)?.label
  const noNumberAvailableCountryLabel = area
    ? `${area} (${getI18nCountry(inputCountry)?.label})`
    : `${getI18nCountry(inputCountry)?.label}`

  const message = useMemo(() => {
    if (inputCountry === 'FR') {
      const { bundles } = regulatoryData
      const hasApprovedBundle = bundles.find(
        (bundle) => bundle.iso === 'FR' && bundle.status === RegulatoryBundleStatus.TwilioApproved
      )

      // Step1: Requires a bundle
      if (!hasApprovedBundle) {
        return (
          <Box>
            <Text className={classes.title} isBold>
              {t('faxNumber.missingFrenchBundleTitle')}
            </Text>
            <Text className={classes.description} variant="small">
              <Trans
                i18nKey="faxNumber.missingFrenchBundleDesc1"
                components={{
                  faxLink: (
                    <Link
                      key={'faxLink'}
                      className={classes.link}
                      variant="small"
                      color="light"
                      href={websiteUrls.fax.profile.bundles}
                      isExternal
                    >
                      {''}
                    </Link>
                  ),
                }}
              />
            </Text>
            <Text className={classes.description} variant="small">
              {t('faxNumber.missingFrenchBundleDesc2')}
            </Text>
            <Link
              className={classes.contactUs}
              variant="small"
              color="light"
              href={websiteUrls.fax.contactUs}
              isBold
              isExternal
            >
              {t('faxNumber.contactUsDesc')}
            </Link>
          </Box>
        )
      }

      // Step2: Contact Support
      return (
        <Box>
          <Text className={classes.title} isBold>
            {t('faxNumber.missingFrenchBundleTitle')}
          </Text>
          <Text className={classes.description}>{t('faxNumber.missingFrenchBundleContactUs')}</Text>
          <Link
            className={classes.contactUs}
            variant="small"
            color="light"
            href={websiteUrls.fax.contactUs}
            isBold
            isExternal
          >
            {t('faxNumber.contactUsDesc')}
          </Link>
        </Box>
      )
    }

    switch (notAvailable) {
      case Error.NoNumbersAvailable:
        return (
          <Box>
            <Text className={classes.title} isBold>
              {type === FaxNumberType.TollFree
                ? t('faxNumber.countryUnavailableTitleTollFree', {
                    value_1: noNumberAvailableCountryLabel,
                  })
                : t('faxNumber.countryUnavailableTitle', {
                    value_1: noNumberAvailableCountryLabel,
                  })}
            </Text>
            <Text className={classes.description} variant="small">
              {t('faxNumber.useUSNumberInstead', {
                value_1: noNumberAvailableCountryLabel,
                value_2: formattedNumber,
              })}
            </Text>
            <Text className={classes.description} variant="small">
              {t('faxNumber.partneringForInventory')}
            </Text>
          </Box>
        )

      case Error.NoValidBundleForNumber:
        return (
          <Box>
            <Text className={classes.title} isBold>
              {t('faxNumber.missingBundleTitle', {
                value_1: countryLabel,
              })}
            </Text>
            <Text className={classes.description} variant="small">
              {t('faxNumber.useUSNumberInstead', {
                value_1: countryLabel,
                value_2: formattedNumber,
              })}
            </Text>
            <Text className={classes.description} variant="small">
              <Trans
                i18nKey={
                  type === FaxNumberType.TollFree
                    ? 'faxNumber.missingBundleTollFreeDesc2'
                    : 'faxNumber.missingBundleDesc2'
                }
                values={{
                  value_1: countryLabel,
                }}
                components={{
                  faxLink: (
                    <Link
                      key={'faxLink'}
                      variant="small"
                      color="light"
                      className={classes.link}
                      href={websiteUrls.fax.profile.bundles}
                      isExternal
                    >
                      {''}
                    </Link>
                  ),
                }}
              />
            </Text>
          </Box>
        )

      case Error.NoValidAddressForNumber:
        return (
          <Box>
            <Text className={classes.title} isBold>
              {t('faxNumber.missingAddressTitle', {
                value_1: countryLabel,
              })}
            </Text>
            <Text className={classes.description} variant="small">
              {t('faxNumber.useUSNumberInstead', {
                value_1: countryLabel,
                value_2: formattedNumber,
              })}
            </Text>
            <Text className={classes.description} variant="small">
              <Trans
                i18nKey={
                  type === FaxNumberType.TollFree
                    ? 'faxNumber.missingAddressTollFreeDesc2'
                    : 'faxNumber.missingAddressDesc2'
                }
                values={{
                  value_1: countryLabel,
                }}
                components={{
                  faxLink: (
                    <Link
                      key={'faxLink'}
                      variant="small"
                      color="light"
                      className={classes.link}
                      href={websiteUrls.fax.profile.addresses}
                      isExternal
                    >
                      {''}
                    </Link>
                  ),
                }}
              />
            </Text>
          </Box>
        )

      default:
        return <></>
    }
  }, [
    classes.contactUs,
    classes.description,
    classes.link,
    classes.title,
    inputCountry,
    countryLabel,
    formattedNumber,
    noNumberAvailableCountryLabel,
    notAvailable,
    regulatoryData,
    t,
    type,
  ])

  return <Box className={cx(classes.base, className)}>{message}</Box>
}

const useStyles = tss.create(() => ({
  base: {
    padding: spacing['8'],
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '304px',
  },
  title: {
    //
  },
  description: {
    marginTop: spacing['8'],
  },
  link: {
    display: 'inline-block',
  },
  contactUs: {
    marginTop: spacing['8'],
    display: 'inline-block',
  },
}))
