import {
  Box,
  FormContextProvider,
  LogoFrame,
  SectionDivider,
  spacing,
  StepDivider,
  Text,
  useOnMount,
} from '@alohi/flow-ui'
import { InitFlowFaxChangeNumberDocument } from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { FlowLayout } from 'components/common/FlowLayout/FlowLayout/FlowLayout'
import { PaymentMethodSection } from 'components/common/PaymentMethodSection/PaymentMethodSection'
import { useCartApi } from 'contexts/cart/api'
import { urls } from 'helpers/urls'
import { useActiveFaxSubscription } from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'
import { useInitFlow } from 'hooks/useInitFlow/useInitFlow'
import { usePermissions } from 'hooks/usePermissions/usePermissions'
import { FaxNumbersFailedModal } from 'modals/fax/FaxNumbersFailedModal'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { CurrentFaxNumberSection } from '../FaxPlanUpgrade/components/CurrentFaxNumberSection/CurrentFaxNumberSection'
import { ChangeNumberSection } from './components/ChangeNumberSection/ChangeNumberSection'
import { useViewApi } from './context/api'
import { ViewContextProvider } from './context/ViewContextProvider'

export const ChangeNumber: React.FC = () => {
  const { isFlowReady } = useInitFlow(InitFlowFaxChangeNumberDocument)
  if (!isFlowReady) return
  return (
    <FormContextProvider autoScroll>
      <ViewContextProvider>
        <ChangeNumberContent />
      </ViewContextProvider>
    </FormContextProvider>
  )
}

export const ChangeNumberContent: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const {
    queryParams: { faxChangeNumber },
    navigate,
  } = useRoutesApi()
  const queryParamNumber = faxChangeNumber.number

  const { activeFaxSubscription } = useActiveFaxSubscription()

  const { canAccessChangeFaxNumber } = usePermissions()

  const {
    store: { numberReplacementCartInput, purchaseNumbersErrorExtensions },
    updateStore,
    submit,
    setCart,
  } = useViewApi()

  const { cart } = useCartApi()

  const requestedSubscriptionNumber = useMemo(() => {
    if (queryParamNumber) {
      return activeFaxSubscription.computed.numbers.find(
        (activeFaxSubscriptionNumber) => activeFaxSubscriptionNumber.number === queryParamNumber
      )
    }
  }, [activeFaxSubscription.computed.numbers, queryParamNumber])

  // Display Payment Method Section
  const hasPaymentMethodSection = !(cart.total === 0 && Boolean(cart.creditUsed))

  // Plan Selection section index
  const currentNumberSectionIndex = 1
  // Payment Details section index
  const changeNumberSectionIndex = currentNumberSectionIndex + 1
  // Payment Details section index
  const paymentDetailSectionIndex = changeNumberSectionIndex + 1

  const canAccessPage = canAccessChangeFaxNumber && requestedSubscriptionNumber

  const retry = async () => {
    updateStore({
      purchaseNumbersErrorExtensions: undefined,
    })
    try {
      if (numberReplacementCartInput) {
        await setCart({
          faxReplacementNumber: {
            ...numberReplacementCartInput,
            number: null,
          },
        })
      }
    } catch {
      // Nothing to do
    }
  }

  useOnMount(() => {
    if (!canAccessPage) {
      navigate(urls.fax.upgrade + window.location.search, {
        hardRedirection: true,
      })
    }
  })

  if (!canAccessPage) return
  return (
    <FlowLayout
      className={classes.base}
      header={
        <SectionDivider iconFrame={<LogoFrame variant="fax" />}>
          <Text className={classes.title} variant="h3">
            {t('changeNumber.changeFaxNumber')}
          </Text>
        </SectionDivider>
      }
      onProcess={submit}
    >
      <Box className={classes.container}>
        <StepDivider className={classes.firstStepDivider} index={currentNumberSectionIndex}>
          <Text isBold>{t('faxNumber.yourCurrentFaxNumber')}</Text>
        </StepDivider>
        <CurrentFaxNumberSection id={requestedSubscriptionNumber.id} />

        <StepDivider className={classes.stepDivider} index={changeNumberSectionIndex}>
          <Text isBold>{t('faxNumber.yourNewFaxNumber')}</Text>
        </StepDivider>
        <ChangeNumberSection id={requestedSubscriptionNumber.id} />

        {hasPaymentMethodSection ? (
          <>
            <StepDivider className={classes.stepDivider} index={paymentDetailSectionIndex}>
              <Text isBold>{t('payment.paymentDetails')}</Text>
            </StepDivider>
            <PaymentMethodSection />
          </>
        ) : null}
      </Box>
      {purchaseNumbersErrorExtensions ? (
        <FaxNumbersFailedModal
          purchaseNumbersErrorExtensions={purchaseNumbersErrorExtensions}
          onConfirm={retry}
        />
      ) : null}
    </FlowLayout>
  )
}
const useStyles = tss.create(() => ({
  base: {
    //
  },
  firstStepDivider: {
    marginBottom: spacing['24'],
  },
  stepDivider: {
    marginTop: spacing['48'],
    marginBottom: spacing['24'],
  },
  container: {
    position: 'relative',
    zIndex: 0,
  },
  title: {
    flexGrow: 1,
  },
}))
