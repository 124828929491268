import { Box, Button, colors, spacing, Text } from '@alohi/flow-ui'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface MobileHeaderProps {
  className?: string
  onBack: () => void
}
export const MobileHeader: React.FC<MobileHeaderProps> = ({ className, onBack }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <Button
        className={classes.button}
        classNames={{
          icon: classes.backIcon,
        }}
        variant="text"
        iconPlacement="left"
        icon={faArrowLeft}
        onClick={onBack}
      >
        <Text variant="h2">{t('common.back')}</Text>
      </Button>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    height: '64px',
    width: '100%',
    borderBottomWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    display: 'flex',
    alignItems: 'center',
    padding: spacing['16'],
  },
  button: {
    width: 'fit-content',
    '*': {
      color: colors.neutral1000,
    },
  },
  backIcon: {
    width: '20px',
    height: '17px',
  },
}))
