import { Box, colors, List, ListItem, spacing, Text } from '@alohi/flow-ui'
import { faClose } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { PlanType } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface SignFeaturesProps {
  className?: string
  planType: PlanType
  hasApi: boolean
}

export const SignFeatures: React.FC<SignFeaturesProps> = ({ className, planType, hasApi }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const title = (() => {
    if (hasApi) {
      return `${t('features.keyFeatures')}`
    } else {
      switch (planType) {
        case PlanType.Basic:
          return `${t('features.keyFeatures')}`
        case PlanType.Premium:
          return t('features.everythingPlus', {
            value_1: 'Personal',
          })
        case PlanType.Business:
          return t('features.everythingPlus', {
            value_1: 'Professional',
          })
        case PlanType.Enterprise:
          return t('features.everythingPlus', {
            value_1: 'Business',
          })
      }
    }
  })()

  const features = (() => {
    if (hasApi) {
      switch (planType) {
        case PlanType.Basic:
          return [
            t('signFeatures.notificationAndReminders'),
            t('signFeatures.temperProofAuditTrail'),
            t('signFeatures.cloudStorageIntegration'),
            'eIDAS/ZertES',
          ]
        case PlanType.Premium:
          return [
            t('signFeatures.notificationAndReminders'),
            t('signFeatures.temperProofAuditTrail'),
            t('signFeatures.cloudStorageIntegration'),
            'eIDAS/ZertES',
            t('signFeatures.customBranding'),
            t('signFeatures.smsAuthentication'),
          ]
        case PlanType.Business:
          return [
            t('signFeatures.notificationAndReminders'),
            t('signFeatures.temperProofAuditTrail'),
            t('signFeatures.cloudStorageIntegration'),
            'eIDAS/ZertES',
            t('signFeatures.customBranding'),
            t('signFeatures.smsAuthentication'),
          ]
        case PlanType.Enterprise:
          return [
            t('signFeatures.notificationAndReminders'),
            t('signFeatures.temperProofAuditTrail'),
            t('signFeatures.cloudStorageIntegration'),
            'eIDAS/ZertES',
            t('signFeatures.customBranding'),
            t('signFeatures.smsAuthentication'),
            'HIPAA',
          ]
      }
    } else {
      switch (planType) {
        case PlanType.Basic:
          return [
            t('signFeatures.signMyself'),
            t('signFeatures.setReminders'),
            t('signFeatures.setExpirationDate'),
            t('signFeatures.realTimeAuditTrail'),
            t('signFeatures.mobileApp'),
            t('signFeatures.cloudStorageIntegration'),
            t('signFeatures.gmailExtension'),
          ]
        case PlanType.Premium:
          return [
            t('signFeatures.folders'),
            t('signFeatures.smsAuthentication'),
            t('signFeatures.inPersonSigning'),
            t('signFeatures.publicForms'),
          ]
        case PlanType.Business:
          return [
            t('signFeatures.documentSharing'),
            t('signFeatures.zapierIntegration'),
            t('signFeatures.slackIntegration'),
            t('signFeatures.customBranding'),
          ]
        case PlanType.Enterprise:
          return [
            t('signFeatures.salesforceIntegration'),
            t('signFeatures.singleSignOn'),
            t('signFeatures.advancedSecurityControls'),
            t('signFeatures.dataResidency'),
            t('signFeatures.prioritySupport'),
          ]
      }
    }
  })()

  const unsupportedFeatures = (() => {
    if (hasApi) {
      switch (planType) {
        case PlanType.Basic:
          return [t('signFeatures.customBranding'), t('signFeatures.smsAuthentication'), 'HIPAA']
        case PlanType.Premium:
          return ['HIPAA']
        case PlanType.Business:
          return ['HIPAA']
        case PlanType.Enterprise:
          return []
      }
    } else {
      switch (planType) {
        case PlanType.Basic:
          return []
        case PlanType.Premium:
          return []
        case PlanType.Business:
          return []
        case PlanType.Enterprise:
          return []
      }
    }
  })()

  return (
    <Box className={cx(classes.base, className)}>
      <Text className={classes.title}>{title}</Text>

      <List listStyleType="none" className={classes.list}>
        {features.map((feature) => (
          <ListItem
            key={feature}
            icon={faCheck}
            className={classes.item}
            classNames={{
              icon: classes.icon,
            }}
            variant="medium"
          >
            {feature}
          </ListItem>
        ))}
        {unsupportedFeatures.map((feature) => (
          <ListItem
            key={feature}
            icon={faClose}
            className={classes.item}
            classNames={{
              icon: classes.closeIcon,
            }}
            variant="medium"
          >
            {feature}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['24'],
    width: '100%',
  },
  title: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: spacing['16'],
  },
  list: {
    //
  },
  item: {
    marginTop: spacing['8'],
    display: 'flex',
    alignItems: 'center',
    color: colors.neutral700,
  },
  icon: {
    color: colors.blue400,
    width: '15px',
    height: '15px',
  },
  closeIcon: {
    color: colors.red500,
    width: '15px',
    height: '15px',
  },
}))
