import { UserStatus, UserVerificationStatus } from 'api/gql/generated/graphql'
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser'

export const useCanAccessApp = (): boolean => {
  const {
    user: {
      status,
      permissions: { managePlan },
      verification: { email, skipEmailVerification },
    },
  } = useCurrentUser()

  const hasEmailVerified = email === UserVerificationStatus.Verified || skipEmailVerification

  const canAccessApp = status === UserStatus.Active && managePlan && hasEmailVerified

  return canAccessApp
}
