import { Box, CountryCode, spacing, useDependencyChange } from '@alohi/flow-ui'
import {
  FaxPlusExtraNumberCartItem,
  FaxPlusNumberArea,
  FaxPlusNumberCartItem,
  SetFaxNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { useRef } from 'react'
import { tss } from 'tss-react'
import { NumbersList } from './components/NumbersList/NumbersList'
import { PriceType, UniqueNumber } from './components/UniqueNumber/UniqueNumber'

interface NumbersProps {
  className?: string
  country: CountryCode
  numberCartInputs: SetFaxNumberCartItemInput[]
  numberCartItems: (FaxPlusExtraNumberCartItem | FaxPlusNumberCartItem)[]
  supportedAreas: FaxPlusNumberArea[]
  isLoading: boolean
  onCustomNumberSelect: (params: { index: number; number: string | null }) => Promise<void>
  priceType: PriceType
}

export const Numbers: React.FC<NumbersProps> = ({
  className,
  country,
  numberCartInputs,
  numberCartItems,
  supportedAreas,
  isLoading,
  onCustomNumberSelect,
  priceType,
}) => {
  const { classes, cx } = useStyles()

  const lastRef = useRef<HTMLDivElement>(null)

  const hasOneNumber = numberCartInputs.length === 1
  const hasMultipleNumber = numberCartInputs.length > 1
  const hasNotAvailableItem = Boolean(
    numberCartItems.find((numberCartItem) => Boolean(numberCartItem.notAvailable))
  )
  const resolvedCountry = hasNotAvailableItem ? 'US' : country

  useDependencyChange(() => {
    lastRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }, [numberCartItems.length])

  return (
    <Box className={cx(classes.base, className)}>
      {hasOneNumber ? (
        <UniqueNumber
          country={resolvedCountry}
          numberCartInput={numberCartInputs[0]}
          numberCartItem={numberCartItems[0]}
          supportedAreas={supportedAreas}
          isLoading={isLoading}
          onCustomNumberSelect={(number) =>
            onCustomNumberSelect({
              index: 0,
              number,
            })
          }
          priceType={priceType}
        />
      ) : null}
      {hasMultipleNumber ? (
        <NumbersList
          country={country}
          isLoading={isLoading}
          numberCartInputs={numberCartInputs}
          numberCartItems={numberCartItems}
          onCustomNumberSelect={onCustomNumberSelect}
          supportedAreas={supportedAreas}
        />
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['24'],
  },
}))
