import { Box, colors, Text } from '@alohi/flow-ui'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface AvailableAreaCodesHeaderProps {
  className?: string
}

export const AvailableAreaCodesHeader: React.FC<
  AvailableAreaCodesHeaderProps
> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <Text>{t('faxNumber.availableCodeAreas')}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    height: '30px',
    background: colors.neutral500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.neutral0,
  },
}))
