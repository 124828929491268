import { FormContextProvider, Modal, NavigationModalSteps } from '@alohi/flow-ui'
import { useMemo } from 'react'
import { tss } from 'tss-react'
import { CustomHighVolumeOptions } from './components/CustomHighVolumeOptions'
import { SuccessMessage } from './components/SuccessMessage'

export const VIEWS = {
  STEP1: 'step1',
  STEP2: 'step2',
}

interface CustomHighVolumePlanModalProps {
  className?: string
  isInternational: boolean
  onClose?: () => void
}

export const CustomHighVolumePlanModal: React.FC<CustomHighVolumePlanModalProps> = (props) => {
  return (
    <FormContextProvider>
      <CustomHighVolumePlanModalContent {...props} />
    </FormContextProvider>
  )
}

export const CustomHighVolumePlanModalContent: React.FC<CustomHighVolumePlanModalProps> = ({
  className,
  isInternational,
  onClose,
}) => {
  const { classes, cx } = useStyles()

  const steps: NavigationModalSteps = useMemo(
    () => ({
      [VIEWS.STEP1]: {
        id: VIEWS.STEP1,
        children: <CustomHighVolumeOptions isInternational={isInternational} />,
      },
      [VIEWS.STEP2]: {
        id: VIEWS.STEP2,
        children: <SuccessMessage />,
      },
    }),
    [isInternational]
  )

  return (
    <Modal
      className={cx(classes.base, className)}
      onClose={onClose}
      steps={steps}
      stepId={VIEWS.STEP1}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
