import { Box, Button, colors, radius, shadows, spacing, Text } from '@alohi/flow-ui'
import { faMessages } from '@fortawesome/pro-solid-svg-icons'
import {
  Error,
  Period,
  PlanType,
  SignPlanPurchaseFlowType,
  SignPlusPlanShopItem,
} from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { useCartApi } from 'contexts/cart/api'
import { formatCurrency } from 'helpers/payment'
import { urls, websiteUrls } from 'helpers/urls'
import { useActiveSignSubscription } from 'hooks/useActiveSubscription/hooks/useActiveSignSubscription'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from 'views/sign/SignPlanUpgrade/context/api'
import { SignFeatures } from './components/SignFeatures'
import { SignQuantities } from './components/SignQuantities'

interface PlanProps {
  className?: string
  item: SignPlusPlanShopItem
  onSelect: () => void
  isMonthlyPeriod: boolean
}

export const Plan: React.FC<PlanProps> = ({ className, item, onSelect, isMonthlyPeriod }) => {
  const { t } = useTranslation()
  const { navigate } = useRoutesApi()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const { updateStore, setCart } = useViewApi()
  const { activeSignSubscription } = useActiveSignSubscription()

  const {
    computedItems: { signPlanCartItem },
  } = useCartApi()
  const cartItemSeats = signPlanCartItem?.totalSeats.value ?? 1
  const cartItemHasApi = signPlanCartItem?.hasApi

  const isCurrentPlan = item.id === activeSignSubscription.planItem.shopItemId
  const isApiEnterprise = item.hasApi && item.planType === PlanType.Enterprise
  const isCartPlan = item.id === signPlanCartItem?.shopItemId

  const min = item.limits?.minAmount ?? 1
  const max = item.limits?.maxAmount ?? 1

  const { classes, cx } = useStyles({ isCurrentPlan, isCartPlan })

  const priceDescription = (() => {
    if (item.planType === PlanType.Basic) {
      return t('purchase.perMonth')
    } else {
      return `${t('purchase.perMonth')}, ${t('purchase.perUser')}`
    }
  })()

  const annualPriceDescription = (() => {
    if (!isMonthlyPeriod && isApiEnterprise) {
      return ' ' // Space needed to keep the select buttons aligned
    }
    if (item.period === Period.Monthly) {
      return ''
    } else {
      return t('purchase.billedAnnually', {
        value_1: formatCurrency({ amount: item.unitPrice, currency }),
      })
    }
  })()

  const buttonText = (() => {
    if (item.notAvailable === Error.CantPurchaseSameItem) {
      return t('purchase.currentPlan')
    }
    if (isApiEnterprise) {
      return t('purchase.requestQuote')
    }
    if (isCurrentPlan) {
      return `${t('seats.purchaseTitle')} (${t('common.current')} ${activeSignSubscription.planItem.totalSeats})`
    }
    if (isCartPlan) {
      return t('common.continue')
    }
    return t('plans.selectPlan')
  })()

  const handleSelectPlan = useCallback(async () => {
    if (isCurrentPlan) {
      navigate(urls.sign.seats + window.location.search)
      return
    }

    if (isApiEnterprise) {
      window.open(websiteUrls.alohi.contactSales, '_blank')
      return
    }

    try {
      updateStore({
        selectedShopItem: item,
      })
      onSelect()

      let newSeats = 1
      if (cartItemHasApi) {
        newSeats = Math.min(Math.max(activeSignSubscription.planItem.itemAmount, min), max)
      } else {
        newSeats = Math.max(Math.min(max, cartItemSeats), min)
      }

      await setCart({
        id: item.id,
        seats: newSeats,
        hasApi: item.hasApi,
        flow:
          signPlanCartItem?.isTrial ?? false
            ? SignPlanPurchaseFlowType.Trial
            : SignPlanPurchaseFlowType.ChangePlan,
      })
    } catch (error) {
      // Error caught in stores
    }
  }, [
    isCurrentPlan,
    isApiEnterprise,
    navigate,
    updateStore,
    item,
    onSelect,
    cartItemHasApi,
    setCart,
    signPlanCartItem?.isTrial,
    activeSignSubscription.planItem.itemAmount,
    min,
    max,
    cartItemSeats,
  ])

  return (
    <Box className={cx(classes.base, className)}>
      <Text className={classes.title}>{item.marketingData.name}</Text>
      <Text className={classes.price}>
        {isApiEnterprise
          ? t('plans.letsTalk')
          : formatCurrency({
              amount: item.marketingData.price,
              currency,
            })}
      </Text>
      {item.hasApi ? null : <Text className={classes.priceDescription}>{priceDescription}</Text>}
      <Text className={classes.priceDescription}>{annualPriceDescription}</Text>
      <Button
        size="small"
        className={classes.button}
        onClick={handleSelectPlan}
        isDisabled={Boolean(item.notAvailable)}
        variant={isCurrentPlan ? 'tertiary' : 'primary'}
        icon={isApiEnterprise ? faMessages : undefined}
        classNames={{
          icon: classes.selectPlanButtonIcon,
        }}
      >
        {buttonText}
      </Button>
      <SignQuantities
        hasApi={item.hasApi}
        maxAmountSeats={max}
        templates={item.templates}
        requestSignature={item.requestSignature}
      />
      <SignFeatures hasApi={item.hasApi} planType={item.planType} />
      {isCurrentPlan || isCartPlan ? (
        <Box className={classes.topLabelContainer}>
          <Text className={classes.topLabelText} isBold>
            {isCartPlan ? t('plans.selectedPlan') : t('purchase.currentPlan')}
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}

const useStyles = tss
  .withParams<{
    isCurrentPlan: boolean
    isCartPlan: boolean
  }>()
  .create(({ isCurrentPlan, isCartPlan }) => ({
    base: {
      paddingTop: spacing['32'],
      paddingBottom: spacing['32'],
      paddingLeft: spacing['32'],
      paddingRight: spacing['32'],
      margin: spacing['16'],
      marginTop: spacing['16'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      borderRadius: spacing['16'],
      width: '270px',
      boxShadow: shadows.surface,
      '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: spacing['16'],
        borderStyle: 'solid',
        borderWidth: isCurrentPlan || isCartPlan ? 2 : 1,
        borderColor: isCartPlan
          ? colors.blue400
          : isCurrentPlan
            ? colors.neutral1000
            : colors.neutral200,
        transition: 'border 0.3s ease',
        pointerEvents: 'none',
      },
    },
    title: {
      fontSize: '23px',
      lineHeight: '30px',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    price: {
      marginTop: spacing['32'],
      fontSize: '23px',
      lineHeight: '30px',
    },
    priceDescription: {
      marginTop: spacing['4'],
      whiteSpace: 'pre',
      color: colors.neutral700,
    },
    button: {
      marginTop: spacing['24'],
      width: '193px',
    },
    topLabelContainer: {
      background: isCartPlan ? colors.blue400 : colors.neutral1000,
      transition: 'background 0.3s ease',
      position: 'absolute',
      top: '-11.5px',
      height: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: spacing['24'],
      paddingRight: spacing['24'],
      paddingTop: spacing['4'],
      paddingBottom: spacing['4'],
      borderRadius: radius['max'],
    },
    topLabelText: {
      color: colors.neutral0,
    },
    selectPlanButtonIcon: {
      marginRight: spacing['4'],
    },
  }))
