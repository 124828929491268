import { Box, colors, spacing, Text } from '@alohi/flow-ui'
import { Period, SignPlusPlanCartItem } from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { formatDefaultDate } from 'helpers/date'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { ItemPrice } from './ItemPrice'

interface SignPlusPlanItemProps {
  className?: string
  item: SignPlusPlanCartItem
}
export const SignPlusPlanItem: React.FC<SignPlusPlanItemProps> = ({ className, item }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    cart: { purchaseDate },
    computedItems: { signPlanCartItem },
  } = useCartApi()

  const formattedStart = formatDefaultDate(purchaseDate.unix())
  const formattedEnd = formatDefaultDate(
    purchaseDate.add(1, item.period === Period.Monthly ? 'month' : 'year').unix()
  )
  const name = `${item.marketingData.name} (${item.period === Period.Annual ? t('plans.annual') : t('plans.monthly')})`
  const subscriptionPeriod = item.isTrial
    ? t('purchase.freeTrial', {
        value_1: item.trialDays ?? 0,
      })
    : `${formattedStart} - ${formattedEnd}`

  const isChangingSeats = !signPlanCartItem?.hasApi && item.totalSeats.value != item.itemAmount

  if (isChangingSeats) {
    const text =
      item.itemAmount < 0
        ? t('signPlans.removedSeats', {
            value_1: Math.abs(item.itemAmount),
            count: Math.abs(item.itemAmount),
          })
        : t('signPlans.addedSeats', {
            value_1: item.itemAmount,
            count: item.itemAmount,
          })

    return (
      <Box className={cx(classes.base, className)}>
        <Text isBold>{text}</Text>
      </Box>
    )
  }

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.container}>
        <Text isBold>{name}</Text>
        <Text className={classes.description}>{subscriptionPeriod}</Text>
        {item.hasApi ? null : (
          <Text className={classes.description}>
            {t('signPlans.totalSeats', {
              count: item.itemAmount,
              value_1: item.itemAmount,
            })}
          </Text>
        )}
      </Box>

      <ItemPrice totalPrice={item.totalPrice} />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  description: {
    color: colors.neutral400,
  },
}))
