import { Box, LottieBox, spacing, Text } from '@alohi/flow-ui'
import { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface SuccessModalContentProps {
  className?: string
  description?: string
  customContainer?: ReactNode
  customFooter?: ReactElement
}

export const SuccessModalContent: React.FC<SuccessModalContentProps> = ({
  className,
  description,
  customContainer,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <LottieBox className={classes.animation} variant="success" />
      <Text variant="h1">{t('common.congratulations')}</Text>
      {description ? <Text className={classes.description}>{description}</Text> : null}
      {customContainer}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  animation: {
    paddingTop: spacing['16'],
    paddingBottom: spacing['16'],
    width: '141px',
  },
  description: {
    paddingTop: spacing['16'],
    textAlign: 'center',
  },
}))
