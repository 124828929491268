import {
  Box,
  Button,
  CountryCode,
  PhoneInput,
  spacing,
  useInput,
  useOnPressKey,
} from '@alohi/flow-ui'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface CustomNumberInputProps {
  className?: string
  country: CountryCode
  areaCode?: number
  isLoading: boolean
  handleSearch: () => void
  setCustomNumber: (customNumber: string) => void
}

export const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  className,
  country,
  areaCode,
  isLoading,
  handleSearch,
  setCustomNumber,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const [customNumber, customNumberProps] = useInput<string>({
    initialValue: '',
    onChange: setCustomNumber,
  })

  useOnPressKey('Enter', handleSearch)

  return (
    <Box className={cx(classes.base, className)}>
      <PhoneInput
        className={classes.input}
        classNames={{
          country: areaCode ? undefined : classes.countryOnly,
        }}
        label={t('faxPlans.findYouCustomNumber')}
        placeholder={t('faxPlans.searchByDigitsOrText')}
        phoneValue={customNumber}
        country={country}
        areaCode={areaCode}
        dataCy={testIds.fax.numbers.custom.searchInput}
        {...customNumberProps}
      />
      <Button
        isLoading={isLoading}
        dataCy={testIds.fax.numbers.custom.searchButton}
        onClick={handleSearch}
        icon={faMagnifyingGlass}
        iconPlacement="left"
        className={classes.button}
        classNames={{
          icon: classes.icon,
        }}
      >
        {t('common.search')}
      </Button>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  input: {
    flexGrow: 1,
  },
  icon: {
    marginRight: spacing['8'],
  },
  button: {
    marginTop: 10,
    marginLeft: spacing['16'],
    alignSelf: 'center',
  },
  countryOnly: {
    marginRight: '2px',
  },
}))
