import { useCallback, useMemo } from 'react'

import { capitalizeString, CountryCode, getI18nCountryList } from '@alohi/flow-ui'
import {
  FaxNumberChannel,
  FaxNumberType,
  FaxPlusPlanCartItem,
  FaxPlusPlanOrderItem,
  FaxPlusPlanShopItem,
  Period,
  RegulatoryBundleStatus,
  SetCartItemInput,
  SetFaxNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { SuccessRedirectToApp, useRoutesApi } from 'app/components/Routes/context/api'
import { SuccessModalContentProps } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'
import { SuccessModalFooter } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalFooter'
import { FaxSubstitutedNumberMessage } from 'components/fax/FaxSubstitutedNumberMessage/FaxSubstitutedNumberMessage'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { Maybe } from 'graphql/jsutils/Maybe'
import { handleApiErrors } from 'helpers/graphql'
import { websiteUrls } from 'helpers/urls'
import { ActiveFaxSubscriptionType } from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useBundles } from 'hooks/useBundles/useBundles'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { useGeolocation } from 'hooks/useGeolocation/useGeolocation'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useTranslation } from 'react-i18next'
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics/useGoogleAnalytics'
import { useViewContext } from './store/context'
import { ViewDataStore } from './store/store'

export function useViewApi() {
  const { store, dispatch } = useViewContext()
  const { t } = useTranslation()

  const { queryCredit } = useCustomer()

  const { iso } = useGeolocation()

  const { gtmCartPurchaseSuccess, gtmCartPurchaseFailed } = useGoogleAnalytics()

  const { getFaxSupportedIso } = useFaxSupportedCountries()

  const { navigateBack, queryParams } = useRoutesApi()
  const querySuccessRedirectToApp = queryParams.faxPlan.successRedirectToApp
  const queryParamsIso = useMemo(
    () =>
      getI18nCountryList().find(
        (country) => country.value === queryParams.faxPlan.iso?.toUpperCase()
      ),
    [queryParams.faxPlan.iso]
  )?.value
  const faxSupportedIso = getFaxSupportedIso(queryParamsIso ?? iso)

  const { extractBundles } = useBundles()

  const { activeFaxSubscription } = useActiveSubscriptions()
  const firstActiveSubscriptionNumber = activeFaxSubscription.computed.numbers[0]
  const secondActiveSubscriptionNumber = activeFaxSubscription.computed.numbers[1]

  const {
    computedItems: { faxPlanCartItem, faxNumberCartItems },
    cart,
    setCart: setCartApi,
    processCart: processCartApi,
  } = useCartApi()

  const { faxPlans } = useShopItems()
  const selectedFaxPlanShopItem = faxPlans.find(
    (plan) => plan.id === store.selectedFaxPlanShopItemId
  )

  const {
    store: { selectedPaymentMethodId },
  } = usePaymentMethodApi()

  const updateStore = useCallback(
    (payload: Partial<ViewDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const setCart = useCallback(
    async (params: {
      selectedFaxPlanShopItemId: string
      firstFaxNumberCartInput: SetFaxNumberCartItemInput | undefined
      secondFaxNumberCartInput: SetFaxNumberCartItemInput | undefined
      discountCode?: string
    }) => {
      try {
        const selectedFaxPlanShopItemId = params.selectedFaxPlanShopItemId
        const firstFaxNumberCartInput = params.firstFaxNumberCartInput
        const secondFaxNumberCartInput = params.secondFaxNumberCartInput

        const items: SetCartItemInput[] = [
          {
            faxPlan: {
              id: selectedFaxPlanShopItemId,
              itemAmount: 1,
            },
          },
        ]
        if (firstFaxNumberCartInput) {
          items.push({
            faxNumber: firstFaxNumberCartInput,
          })
        }
        if (secondFaxNumberCartInput) {
          items.push({
            faxNumber: secondFaxNumberCartInput,
          })
        }
        await setCartApi({
          schedule: cart.isScheduled,
          useCredit: cart.creditUsed != null,
          discountCode: params.discountCode,
          items,
        })
      } catch {
        // Errors caught in setCartApi
      }
    },
    [cart.creditUsed, cart.isScheduled, setCartApi]
  )

  const setPlanCart = useCallback(
    async (
      planShopItem: FaxPlusPlanShopItem,
      patch?: {
        firstNumber?: Maybe<Partial<SetFaxNumberCartItemInput>>
        secondNumber?: Maybe<Partial<SetFaxNumberCartItemInput>>
      },
      options?: {
        skipCartUpdate?: boolean
        discountCode?: string
      }
    ) => {
      try {
        const planId = planShopItem.id

        const initialCartInput = {
          iso: faxSupportedIso,
          numberType: FaxNumberType.Local,
          areaCode: undefined,
          bundleSid: extractBundles({
            country: iso as CountryCode,
            statuses: [RegulatoryBundleStatus.TwilioApproved, RegulatoryBundleStatus.PendingReview],
            numberType: FaxNumberType.Local,
          })?.[0]?.sid,
          number: undefined,
        }

        const firstFaxNumberCartInput = firstActiveSubscriptionNumber
          ? undefined
          : {
              ...initialCartInput,
              ...store.selectedFirstFaxNumberCartInput,
              ...patch?.firstNumber,
              period: planShopItem.period,
            }
        const secondFaxNumberCartInput =
          secondActiveSubscriptionNumber || planShopItem.numbersIncluded < 2
            ? undefined
            : {
                ...initialCartInput,
                ...store.selectedSecondFaxNumberCartInput,
                ...patch?.secondNumber,
                period: planShopItem.period,
              }

        updateStore({
          selectedFaxPlanShopItemId: planShopItem.id,
          selectedFirstFaxNumberCartInput: firstFaxNumberCartInput,
          selectedSecondFaxNumberCartInput: secondFaxNumberCartInput,
        })
        if (!options?.skipCartUpdate) {
          setCart({
            selectedFaxPlanShopItemId: planId,
            firstFaxNumberCartInput,
            secondFaxNumberCartInput,
            discountCode: options?.discountCode,
          })
        }
      } catch (error) {
        // Error caught in setCart
      }
    },
    [
      extractBundles,
      firstActiveSubscriptionNumber,
      iso,
      faxSupportedIso,
      secondActiveSubscriptionNumber,
      setCart,
      store.selectedFirstFaxNumberCartInput,
      store.selectedSecondFaxNumberCartInput,
      updateStore,
    ]
  )

  const submit = useCallback(async (): Promise<SuccessModalContentProps | undefined> => {
    let customFooter = undefined
    if (querySuccessRedirectToApp === SuccessRedirectToApp.Fax) {
      customFooter = (
        <SuccessModalFooter
          primaryButtonTitle={t('common.continueTo', { value_1: 'Fax.Plus' })}
          onPrimaryButtonClick={() => {
            window.location.href = websiteUrls.fax.home
          }}
          secondaryButtonTitle={t('common.returnTo', { value_1: 'Sign.Plus' })}
          onSecondaryButtonClick={navigateBack}
        />
      )
    }

    try {
      const response = await processCartApi({
        paymentMethodId: selectedPaymentMethodId,
      })

      const planPeriod =
        activeFaxSubscription.period === Period.Annual ? t('plans.annual') : t('plans.monthly')

      // Standard Cart
      if (response?.__typename === 'ArchivedOrder' || response?.__typename === 'ActiveOrder') {
        const newPlanOrderItem = response.orderItems.find(
          (item): item is FaxPlusPlanOrderItem => item?.__typename === 'FaxPlusPlanOrderItem'
        )

        if (!newPlanOrderItem) {
          throw new Error('No newPlanOrderItem')
        }

        const isHighVolume = faxPlans.find(
          (shopItem) => shopItem.id === newPlanOrderItem.shopItemId
        )?.highVolume
        const newPlanPeriod =
          newPlanOrderItem?.period === Period.Annual ? t('plans.annual') : t('plans.monthly')
        const newPlan = isHighVolume
          ? `${capitalizeString(newPlanOrderItem.planType.toLocaleLowerCase())} - ${Intl.NumberFormat().format(newPlanOrderItem?.pages ?? 0)}`
          : `${newPlanOrderItem?.marketingData.name ?? ''}`
        const hasSubstitutedNumbers = Boolean(
          newPlanOrderItem.numbers.filter(
            (newNumberOrderItem) => newNumberOrderItem.requestedNumber
          )?.length
        )

        gtmCartPurchaseSuccess({
          faxPlan: {
            planType: newPlanOrderItem.planType,
            period: newPlanOrderItem.period,
            highVolumePages: newPlanOrderItem.pages,
          },
          faxNumbers: {
            totalCount: faxNumberCartItems?.length ?? 0,
            customCount:
              faxNumberCartItems?.filter(
                (faxNumberCartItem) => faxNumberCartItem.channel === FaxNumberChannel.Custom
              )?.length ?? 0,
          },
          totalPrice: cart.total,
        })

        if (activeFaxSubscription.computed.planItem.highVolume) {
          return {
            description: t('faxPlans.yourEnterprisePlanNowIncludes', {
              value_1: Intl.NumberFormat().format(newPlanOrderItem?.pages ?? 0),
            }),
            customContainer: hasSubstitutedNumbers ? (
              <FaxSubstitutedNumberMessage numbers={newPlanOrderItem.numbers} />
            ) : undefined,
            customFooter: customFooter,
          }
        }

        if (activeFaxSubscription.computed.type === ActiveFaxSubscriptionType.FREE) {
          return {
            description: t('signPlans.freeSuccessModalDescription', {
              value_1: `${newPlan} (${newPlanPeriod})`,
            }),
            customContainer: hasSubstitutedNumbers ? (
              <FaxSubstitutedNumberMessage numbers={newPlanOrderItem.numbers} />
            ) : undefined,
            customFooter: customFooter,
          }
        }

        return {
          description: t('signPlans.successModalDescription', {
            value_1: `${activeFaxSubscription.computed.planItem.marketingName} (${planPeriod})`,
            value_2: `${newPlan} (${newPlanPeriod})`,
          }),
          customContainer: hasSubstitutedNumbers ? (
            <FaxSubstitutedNumberMessage numbers={newPlanOrderItem.numbers} />
          ) : undefined,
          customFooter: customFooter,
        }
      }
      // Scheduled Cart
      else if (response?.__typename === 'Cart') {
        const newFaxPlusPlanCartItem = response.items.find(
          (item): item is FaxPlusPlanCartItem => item.__typename === 'FaxPlusPlanCartItem'
        )

        if (!newFaxPlusPlanCartItem) {
          throw new Error('No newFaxPlusPlanCartItem')
        }

        const newPlan = newFaxPlusPlanCartItem?.marketingData.name ?? ''
        const newPlanPeriod =
          newFaxPlusPlanCartItem?.period === Period.Annual ? t('plans.annual') : t('plans.monthly')

        gtmCartPurchaseSuccess({
          faxPlan: {
            planType: newFaxPlusPlanCartItem.planType,
            period: newFaxPlusPlanCartItem.period,
            highVolumePages: newFaxPlusPlanCartItem.pages,
          },
          faxNumbers: {
            totalCount: faxNumberCartItems?.length ?? 0,
            customCount:
              faxNumberCartItems?.filter(
                (faxNumberCartItem) => faxNumberCartItem.channel === FaxNumberChannel.Custom
              )?.length ?? 0,
          },
          totalPrice: cart.total,
        })

        if (activeFaxSubscription.computed.planItem.highVolume) {
          return {
            description: t('faxPlans.yourEnterprisePlanWillIncludes', {
              value_1: Intl.NumberFormat().format(newFaxPlusPlanCartItem?.pages ?? 0),
            }),
            customFooter: customFooter,
          }
        }

        return {
          description: t('signPlans.scheduledSuccessModalDescription', {
            value_1: `${activeFaxSubscription.computed.planItem.marketingName} (${planPeriod})`,
            value_2: `${newPlan} (${newPlanPeriod})`,
          }),
          customFooter: customFooter,
        }
      }

      throw new Error('No valid response')
    } catch (error) {
      handleApiErrors(error, {
        errors: {
          NUMBER_PURCHASE_FAILED: async ({
            NUMBERS_FAILED_TO_PURCHASE,
            AMOUNT_REIMBURSED_TO_CREDIT,
          }) => {
            updateStore({
              purchaseNumbersErrorExtensions: {
                failedPurchaseNumbers: NUMBERS_FAILED_TO_PURCHASE as string[],
                reimbursedAmount: AMOUNT_REIMBURSED_TO_CREDIT as number,
              },
            })
            await queryCredit()
          },
        },
        default: () => {
          // Handled in processCartApi
        },
      })

      gtmCartPurchaseFailed({
        faxPlan: {
          planType: faxPlanCartItem?.planType,
          period: faxPlanCartItem?.period,
          highVolumePages: faxPlanCartItem?.pages,
        },
        faxNumbers: {
          totalCount: faxNumberCartItems?.length ?? 0,
          customCount:
            faxNumberCartItems?.filter(
              (faxNumberCartItem) => faxNumberCartItem.channel === FaxNumberChannel.Custom
            )?.length ?? 0,
        },
        totalPrice: cart.total,
      })
      throw error
    }
  }, [
    activeFaxSubscription.computed.planItem.highVolume,
    activeFaxSubscription.computed.planItem.marketingName,
    activeFaxSubscription.computed.type,
    activeFaxSubscription.period,
    cart.total,
    faxNumberCartItems,
    faxPlanCartItem?.pages,
    faxPlanCartItem?.period,
    faxPlanCartItem?.planType,
    faxPlans,
    gtmCartPurchaseFailed,
    gtmCartPurchaseSuccess,
    navigateBack,
    processCartApi,
    queryCredit,
    querySuccessRedirectToApp,
    selectedPaymentMethodId,
    t,
    updateStore,
  ])

  return {
    store,
    updateStore,
    submit,
    setCart,
    selectedFaxPlanShopItem,
    setPlanCart,
  }
}
