import {
  AnimatedBox,
  Box,
  Button,
  colors,
  CountryCode,
  formatNumber,
  Icon,
  spacing,
  SvgBox,
  Text,
  Tooltip,
  useBool,
} from '@alohi/flow-ui'
import { faRepeat } from '@fortawesome/pro-regular-svg-icons'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import {
  FaxPlusExtraNumberCartItem,
  FaxPlusNumberArea,
  FaxPlusNumberCartItem,
  FaxPlusNumberShopItemCountryInfo,
  SetFaxNumberCartItemInput,
} from 'api/gql/generated/graphql'
import CustomNumberIcon from 'assets/svg/numbers/custom.svg'
import RandomNumberIcon from 'assets/svg/numbers/random.svg'
import { useFaxNumberRequirementsCompliance } from 'hooks/useFaxNumberRequirementsCompliance/useFaxNumberRequirementsCompliance'
import { RefObject, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { CustomNumberModal } from '../../modals/CustomNumberModal/CustomNumberModal'
import { DotAnimation } from './components/DotAnimation'
import { TooltipTypeContent } from './components/TooltipTypeContent'
import { TooltipWarningContent } from './components/TooltipWarningContent'

interface NumberItemProps {
  className?: string
  ref?: RefObject<HTMLDivElement>
  index: number
  isFirstIndex: boolean
  isLastIndex: boolean
  country: CountryCode
  numberCartInput: SetFaxNumberCartItemInput
  numberCartItem?: FaxPlusExtraNumberCartItem | FaxPlusNumberCartItem
  supportedAreas: FaxPlusNumberArea[]
  isLoading: boolean
  onCustomNumberSelect: (number: string | null) => void
}

export const NumberItem: React.FC<NumberItemProps> = ({
  className,
  ref,
  index,
  isFirstIndex,
  isLastIndex,
  country,
  numberCartInput,
  numberCartItem,
  supportedAreas,
  isLoading,
  onCustomNumberSelect,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { getCountrySettings } = useFaxNumberRequirementsCompliance()

  const countrySettings: FaxPlusNumberShopItemCountryInfo | null | undefined = useMemo(
    () =>
      getCountrySettings({
        country,
        numberType: numberCartInput.numberType,
      }),
    [country, getCountrySettings, numberCartInput]
  )

  const [isCustomNumberModalOpen, isCustomNumberModalOpenBool] = useBool(false)

  const areaCode = numberCartInput.areaCode
  const formattedNumber = numberCartItem?.number
    ? formatNumber(numberCartItem.number, 'INTERNATIONAL')
    : undefined

  const isCustom = Boolean(numberCartInput?.number)
  const notAvailable = numberCartItem?.notAvailable
  const area = supportedAreas.find((supportedArea) => supportedArea.prefix === areaCode)?.name

  const inputCountry = (numberCartInput.iso ?? 'US') as CountryCode

  const handleCustomClick = () => {
    isCustomNumberModalOpenBool.setTrue()
  }

  const handleTooltipClick = () => {
    if (isCustom) {
      onCustomNumberSelect(null)
    } else {
      isCustomNumberModalOpenBool.setTrue()
    }
  }

  return (
    <Box
      ref={ref}
      className={cx(classes.base, className, isLoading ? classes.disabled : undefined)}
    >
      <Box
        className={cx(
          classes.row,
          isFirstIndex ? classes.firstRow : undefined,
          isLastIndex ? classes.lastRow : undefined
        )}
      >
        <Text className={classes.index}>{index + 1}</Text>
        <Box className={classes.numberContainer}>
          <Tooltip
            label={
              <TooltipTypeContent
                isCustom={isCustom}
                unitPrice={numberCartItem?.unitPrice ?? 0}
                customFee={countrySettings?.setupPrice?.custom ?? 0}
                onClick={handleTooltipClick}
              />
            }
          >
            <SvgBox
              className={classes.typeIcon}
              src={isCustom ? CustomNumberIcon : RandomNumberIcon}
            />
          </Tooltip>
          {isLoading ? (
            <DotAnimation color={colors.neutral500} />
          ) : (
            <AnimatedBox>
              <Text className={classes.number}>{formattedNumber ?? ''}</Text>
            </AnimatedBox>
          )}
          {!isLoading && notAvailable ? (
            <Tooltip
              className={classes.tooltip}
              label={
                <TooltipWarningContent
                  inputCountry={inputCountry}
                  formattedNumber={formattedNumber ?? ''}
                  notAvailable={numberCartItem.notAvailable}
                  type={numberCartItem.type}
                  area={area}
                />
              }
            >
              <Icon icon={faCircleExclamation} />
            </Tooltip>
          ) : null}
        </Box>

        {isLoading ? null : (
          <Button
            variant="text"
            icon={faRepeat}
            classNames={{
              icon: classes.buttonIcon,
            }}
            onClick={handleCustomClick}
          >
            <Text isBold>{isCustom ? t('common.change') : t('faxNumber.pickACustomNumber')}</Text>
          </Button>
        )}
      </Box>
      {isCustomNumberModalOpen ? (
        <CustomNumberModal
          country={country}
          areaCode={areaCode}
          onCancel={isCustomNumberModalOpenBool.setFalse}
          numberType={numberCartInput.numberType}
          onCustomNumberSelect={onCustomNumberSelect}
        />
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  firstRow: {
    borderTopWidth: 0,
    overflow: 'hidden',
  },
  row: {
    height: 40,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    borderTopWidth: 1,
    display: 'flex',
    alignItems: 'center',
    paddingRight: spacing['8'],
  },
  lastRow: {
    overflow: 'hidden',
  },
  index: {
    width: '40px',
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.neutral100,
  },
  numberContainer: {
    width: '216px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing['16'],
    paddingRight: spacing['16'],
  },
  number: {
    marginLeft: spacing['4'],
  },
  tooltip: {
    paddingLeft: spacing['8'],
    display: 'flex',
    alignItems: 'center',
    color: colors.orange500,
    paddingTop: '0px',
    cursor: 'pointer',
  },
  buttonIcon: {
    width: '15px',
    height: '15px',
    marginRight: spacing['4'],
  },
  typeIcon: {
    cursor: 'pointer',
  },
}))
