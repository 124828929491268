import { Box, Select, SelectProps, useInput } from '@alohi/flow-ui'
import { formatLocalizedNumber } from 'helpers/common'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from 'views/fax/FaxPlanUpgrade/context/api'
import MoreItem from './components/MoreItem'
import Option from './components/Option'

interface SelectPagesProps {
  className?: string
  onClickMore: () => void
}

function SelectPages({ className, onClickMore }: SelectPagesProps) {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { selectedFaxPlanShopItem, updateStore } = useViewApi()
  const isInternational = !selectedFaxPlanShopItem?.dstLimits.length

  const { faxPlans } = useShopItems()
  const faxHighVolumePlans = useMemo(
    () =>
      faxPlans.filter((faxPlan) =>
        faxPlan.highVolume && isInternational ? !faxPlan.dstLimits.length : faxPlan.dstLimits.length
      ),
    [faxPlans, isInternational]
  )

  const pagesChecker = (type: string) => {
    if (!type) {
      return 'Please select some pages amount'
    }
  }

  const [, pagesInputProps] = useInput<string>({
    initialValue: selectedFaxPlanShopItem?.id ?? '',
    validator: pagesChecker,
    isLazyLoaded: true,
  })

  const handleSelectItemId = useCallback(
    (id: string) => {
      updateStore({
        selectedFaxPlanShopItemId: id,
      })
    },
    [updateStore]
  )

  const handleClickMore = useCallback(() => {
    pagesInputProps.ref.current?.blur()
    onClickMore()
  }, [onClickMore, pagesInputProps.ref])

  const selectItems = useMemo(
    () => [
      ...faxHighVolumePlans
        .slice()
        .sort(({ pages: a }, { pages: b }) => a - b)
        .filter((item) => item.period === selectedFaxPlanShopItem?.period)
        .filter((item) => !item.notAvailable)
        .map((item) => ({
          id: item.id,
          label: formatLocalizedNumber({ num: item.pages }),
          item: <Option pages={item.pages} marketingData={item.marketingData} />,
        })),
      ...[
        {
          id: 'more',
          label: t('common.more'),
          item: <MoreItem onClick={handleClickMore} />,
          renderRawItem: true,
          onClick: () => undefined,
        } satisfies SelectProps['items'][number],
      ],
    ],
    [faxHighVolumePlans, handleClickMore, selectedFaxPlanShopItem?.period, t]
  )

  return (
    <Box className={cx(classes.base, className)}>
      <Select
        items={selectItems}
        className={classes.select}
        value={selectedFaxPlanShopItem?.id}
        {...pagesInputProps}
        onChange={handleSelectItemId}
        placeholder={t('common.pages')}
        label={t('purchase.pagesPerMonth')}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    flexGrow: 1,
  },
}))

export default SelectPages
