import { Box, Text } from '@alohi/flow-ui'
import { tss } from 'tss-react'

export interface AreaCodeItemProps {
  className?: string
  name: string
  countryPrefix: string
  areaPrefix: string
}

export const AreaCodeItem: React.FC<AreaCodeItemProps> = ({
  className,
  name,
  countryPrefix,
  areaPrefix,
}) => {
  const { classes, cx } = useStyles()

  const prefix = `${countryPrefix} ${areaPrefix}`

  return (
    <Box className={cx(classes.base, className)}>
      <Text>{name}</Text>
      <Box className={classes.filler} />
      <Text>{prefix}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  filler: {
    flex: 1,
  },
}))
