import { Box, CountryCode, useOnMount, useToast } from '@alohi/flow-ui'
import { useQuery } from '@apollo/client'
import {
  FaxNumberSupportedAreasDocument,
  FaxNumberType,
  FaxPlusExtraNumberCartItem,
  FaxPlusNumberArea,
  FaxPlusNumberCartItem,
  FaxPlusNumberShopItemCountry,
  PlanType,
  RegulatoryBundleStatus,
  RegulatoryRequirementType,
  SetFaxNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { Maybe } from 'graphql/jsutils/Maybe'
import { getField, handleApiErrors } from 'helpers/graphql'
import { testIds } from 'helpers/tests'
import { useAddresses } from 'hooks/useAddresses/useAddresses'
import { useBundles } from 'hooks/useBundles/useBundles'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { AddressSelect } from './components/AddressSelect/AddressSelect'
import { AreaCodeSelect } from './components/AreaCodeSelect/AreaCodeSelect'
import { BundleSelect } from './components/BundleSelect/BundleSelect'
import { CountrySelect } from './components/CountrySelect/CountrySelect'
import { PriceType } from './components/NumbersList/components/UniqueNumber/UniqueNumber'
import { Numbers } from './components/NumbersList/Numbers'
import { QuantitySelect } from './components/QuantitySelect/QuantitySelect'

interface FaxNumberSectionProps {
  className?: string
  numberCartInputs: SetFaxNumberCartItemInput[]
  numberCartItems: (FaxPlusNumberCartItem | FaxPlusExtraNumberCartItem)[]
  onUpdateCart: (params: {
    index?: number
    cartInput?: Maybe<Partial<SetFaxNumberCartItemInput>>
  }) => Promise<void>
  onUpdateQuantity?: (quantity: number) => void
  supportedCountries: FaxPlusNumberShopItemCountry[]
  planType: PlanType
  disabledMinimumPlanTypeRequirement?: boolean
  priceType: PriceType
}

export const FaxNumberSection: React.FC<FaxNumberSectionProps> = ({
  className,
  numberCartInputs,
  numberCartItems,
  supportedCountries,
  onUpdateCart,
  onUpdateQuantity,
  planType,
  disabledMinimumPlanTypeRequirement,
  priceType,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const toast = useToast()

  const { refetch: queryFaxNumberSupportedAreas } = useQuery(FaxNumberSupportedAreasDocument, {
    skip: true,
  })

  const {
    store: { isUpdating },
  } = useCartApi()
  const isLoading = isUpdating

  const faxNumbersFirstCartInput = numberCartInputs[0]
  const country = (faxNumbersFirstCartInput?.iso ?? 'US') as CountryCode

  const requireBundle = useMemo(
    () =>
      Boolean(
        numberCartItems.find(
          (numberCartItem) =>
            numberCartItem.regulatoryRequirement === RegulatoryRequirementType.Bundle
        )
      ),
    [numberCartItems]
  )
  const requireAddress = useMemo(
    () =>
      Boolean(
        numberCartItems.find(
          (numberCartItem) =>
            numberCartItem.regulatoryRequirement === RegulatoryRequirementType.Address
        )
      ),
    [numberCartItems]
  )

  const { extractBundles, checkIfBundlesAvailable } = useBundles()
  const haveAvailableBundles = checkIfBundlesAvailable({
    country,
    numberType: faxNumbersFirstCartInput?.numberType,
  })

  const { extractAddresses, checkIfAddressesAvailable } = useAddresses()
  const haveAvailableAddresses = checkIfAddressesAvailable({
    country,
  })

  const [supportedAreas, setSupportedAreas] = useState<FaxPlusNumberArea[]>([])

  const areaName = supportedAreas.find(
    (supportedArea) => supportedArea.prefix === faxNumbersFirstCartInput?.areaCode
  )?.name

  const getSupportedAreas = async (country: CountryCode) => {
    try {
      const response = await queryFaxNumberSupportedAreas({
        iso: country,
      })
      const faxNumbers = getField(response.data.shopItems?.faxNumbers)
      setSupportedAreas(faxNumbers?.areas ?? [])
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
        },
      })
    }
  }

  const handleCountrySelect = async (country: FaxPlusNumberShopItemCountry | undefined) => {
    try {
      if (!country) return

      const selectedCountry = country.iso as CountryCode
      const numberType = country.tollFree
        ? faxNumbersFirstCartInput?.numberType
        : FaxNumberType.Local
      const bundleSid = extractBundles({
        country: selectedCountry,
        statuses: [RegulatoryBundleStatus.TwilioApproved, RegulatoryBundleStatus.PendingReview],
        numberType,
      })?.[0]?.sid
      const addressSid = extractAddresses({
        country: selectedCountry,
      })?.[0]?.sid

      await Promise.all([
        getSupportedAreas(selectedCountry),
        onUpdateCart({
          cartInput: {
            iso: country.iso,
            areaCode: null,
            // If the selected country does not support Toll-Free we change the type to Local
            numberType,
            bundleSid,
            addressSid,
            number: null,
          },
        }),
      ])
    } catch {
      // Error caught in onUpdateCart
    }
  }

  const handleAreaSelect = async (params: {
    areaName: string | undefined
    areaPrefix: string | undefined
    numberType: FaxNumberType
  }) => {
    try {
      const prefix = supportedAreas.find(
        (supportedArea) =>
          supportedArea.name === params.areaName && supportedArea.prefix === params.areaPrefix
      )?.prefix

      const areaCode = prefix ?? null

      const bundleSid = extractBundles({
        country,
        statuses: [RegulatoryBundleStatus.TwilioApproved, RegulatoryBundleStatus.PendingReview],
        numberType: params.numberType,
      })?.[0]?.sid
      const addressSid = extractAddresses({
        country,
      })?.[0]?.sid

      if (
        faxNumbersFirstCartInput.numberType === params.numberType &&
        faxNumbersFirstCartInput.areaCode === areaCode &&
        faxNumbersFirstCartInput.bundleSid === bundleSid &&
        faxNumbersFirstCartInput.addressSid === addressSid
      ) {
        return
      }

      await onUpdateCart({
        cartInput: {
          numberType: params.numberType,
          areaCode: areaCode,
          number: null,
          bundleSid,
          addressSid,
        },
      })
    } catch {
      // Error caught in onUpdateCart
    }
  }

  const handleBundleSelect = async (bundleSid: string | null) => {
    try {
      await onUpdateCart({
        cartInput: {
          bundleSid,
        },
      })
    } catch {
      // Error caught in onUpdateCart
    }
  }

  const handleAddressSelect = async (addressSid: string | null) => {
    try {
      await onUpdateCart({
        cartInput: {
          addressSid,
        },
      })
    } catch {
      // Error caught in onUpdateCart
    }
  }

  const handleCustomNumberSelect = async (params: { index: number; number: string | null }) => {
    try {
      await onUpdateCart({
        index: params.index,
        cartInput: {
          number: params.number,
        },
      })
    } catch {
      // Error caught in onUpdateCart
    }
  }

  useOnMount(() => {
    if (!faxNumbersFirstCartInput?.iso) return

    getSupportedAreas(faxNumbersFirstCartInput.iso as CountryCode)
  }, Boolean(faxNumbersFirstCartInput?.iso))

  if (!faxNumbersFirstCartInput) return
  return (
    <Box className={cx(classes.base, className)} dataCy={testIds.fax.numbers.section}>
      <CountrySelect
        country={country}
        onSelect={handleCountrySelect}
        supportedCountries={supportedCountries}
        isDisabled={isLoading}
        planType={planType}
        disabledMinimumPlanTypeRequirement={disabledMinimumPlanTypeRequirement}
      />
      <AreaCodeSelect
        area={areaName}
        numberType={faxNumbersFirstCartInput.numberType}
        country={country}
        onSelect={handleAreaSelect}
        supportedAreas={supportedAreas}
        isDisabled={isLoading}
        planType={planType}
        disabledMinimumPlanTypeRequirement={disabledMinimumPlanTypeRequirement}
      />
      {haveAvailableBundles && requireBundle ? (
        <BundleSelect
          bundleSid={faxNumbersFirstCartInput.bundleSid}
          country={country}
          numberType={faxNumbersFirstCartInput.numberType}
          isDisabled={isLoading}
          onSelect={handleBundleSelect}
        />
      ) : null}
      {haveAvailableAddresses && requireAddress ? (
        <AddressSelect
          addressSid={faxNumbersFirstCartInput.addressSid}
          country={country}
          isDisabled={isLoading}
          onSelect={handleAddressSelect}
        />
      ) : null}
      {onUpdateQuantity ? (
        <QuantitySelect
          cartNumbersLength={numberCartItems.length}
          onUpdate={onUpdateQuantity}
          isDisabled={isLoading}
        />
      ) : null}
      <Numbers
        numberCartInputs={numberCartInputs}
        numberCartItems={numberCartItems}
        supportedAreas={supportedAreas}
        country={country}
        isLoading={isLoading}
        onCustomNumberSelect={handleCustomNumberSelect}
        priceType={priceType}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
