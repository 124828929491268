import { ReactNode, Reducer, useReducer } from 'react'
import { PaymentMethodContext } from './store/context'
import { PaymentMethodAction, PaymentMethodReducer } from './store/reducer'
import {
  PaymentMethodStore,
  computeStore,
  initialPaymentMethodComputedStore,
  initialPaymentMethodStore,
} from './store/store'

interface PaymentMethodContextProviderProps {
  children: ReactNode
  initialUpdate?: Partial<PaymentMethodStore>
}

export function PaymentMethodContextProvider({
  children,
  initialUpdate = {},
}: PaymentMethodContextProviderProps) {
  const [store, dispatch] = useReducer<
    Reducer<PaymentMethodStore, PaymentMethodAction>
  >(PaymentMethodReducer, {
    ...initialPaymentMethodStore,
    ...initialPaymentMethodComputedStore,
    ...initialUpdate,
  })

  const computedStore = computeStore(store)

  return (
    <PaymentMethodContext.Provider
      value={{
        store: {
          ...store,
          ...computedStore,
        },
        dispatch,
      }}
    >
      {children}
    </PaymentMethodContext.Provider>
  )
}
