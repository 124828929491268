import { Box, Switcher, Text } from '@alohi/flow-ui'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface PeriodSwitchProps {
  className?: string
  isMonthlyPeriod: boolean
  onIsMonthlyPeriodChange: (isMonthlyPeriod: boolean) => void
}

export const PeriodSwitch: React.FC<PeriodSwitchProps> = ({
  className,
  isMonthlyPeriod,
  onIsMonthlyPeriodChange,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <Switcher
        isSwitched={isMonthlyPeriod}
        onSwitch={onIsMonthlyPeriodChange}
        className={classes.base}
        leftComponent={
          <Text className={classes.text} variant="small" isBold>
            {t('plans.annual').toUpperCase()}
          </Text>
        }
        rightComponent={
          <Text className={classes.text} variant="small" isBold>
            {t('plans.monthly').toUpperCase()}
          </Text>
        }
      ></Switcher>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    width: '100px',
    display: 'block',
  },
}))
