import { AnimatedBox, Grid, GridItem, GridProps, PaypalButton, spacing, Text } from '@alohi/flow-ui'
import { PaymentMethodType } from 'api/gql/generated/graphql'
import { BRAINTREE_CLIENT_ID } from 'env'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { CountrySelect } from './components/CountrySelect'
import { MethodRadioGroup } from './components/MethodRadioGroup'
import { NameInput } from './components/NameInput'
import { PaymentInput } from './components/PaymentInput'
import { PostalInput } from './components/PostalInput'
import { StreetInput } from './components/StreetInput'

interface NewPaymentMethodFormProps {
  className?: string
  onPaypalApprove?: (token: string) => Promise<void>
  type: PaymentMethodType
  onTypeSelect: (type: PaymentMethodType) => void
  isPaypalLoading?: boolean
}

export const NewPaymentMethodForm: React.FC<NewPaymentMethodFormProps> = ({
  className,
  onPaypalApprove,
  type,
  onTypeSelect,
  isPaypalLoading,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const templateAreas: GridProps['templateAreas'] = {
    base: '"street" "postal"', // One column
    lg: '"street postal"', // One row
  }

  return (
    <>
      <AnimatedBox className={cx(classes.base, className)}>
        <MethodRadioGroup
          className={classes.methodRadioGroup}
          type={type}
          onTypeSelect={onTypeSelect}
        />
        {type === PaymentMethodType.Card ? (
          <AnimatedBox className={classes.cardContent}>
            <NameInput />
            <PaymentInput />
            <Grid templateAreas={templateAreas} columnGap={spacing['16']}>
              <GridItem area={'street'}>
                <StreetInput />
              </GridItem>
              <GridItem area={'postal'}>
                <PostalInput />
              </GridItem>
            </Grid>
            <CountrySelect />
          </AnimatedBox>
        ) : null}
        {type === PaymentMethodType.Paypal && onPaypalApprove ? (
          <AnimatedBox className={classes.paypalContent}>
            <Text isBold>{t('purchase.toPayWithPaypal')}</Text>
            <PaypalButton
              clientId={BRAINTREE_CLIENT_ID}
              className={classes.paypalButton}
              onApprove={onPaypalApprove}
              isLoading={isPaypalLoading}
              dataCy={testIds.newPaymentMethodForm.paypal.button}
            />
          </AnimatedBox>
        ) : null}
      </AnimatedBox>
    </>
  )
}

const useStyles = tss.create(() => ({
  base: {
    width: '100%',
  },
  cardContent: {
    //
  },
  paypalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paypalButton: {
    marginTop: spacing['24'],
    width: '100%',
  },
  methodRadioGroup: {
    paddingBottom: spacing['24'],
  },
}))
