import { Box, spacing } from '@alohi/flow-ui'
import { Trans } from 'react-i18next'
import { getNavigatorLanguage } from './common'

export interface PaymentErrorExtensions {
  BRAINTREE_ERROR_CODE: PaypalErrorCode
  STRIPE_ERROR_CODE: StripeErrorCode
  STRIPE_DECLINE_CODE: StripeDeclineCode
}

export type StripeErrorCode =
  | 'account_country_invalid_address'
  | 'account_error_country_change_requires_additional_steps'
  | 'account_invalid'
  | 'account_number_invalid'
  | 'acss_debit_session_incomplete'
  | 'alipay_upgrade_required'
  | 'amount_too_large'
  | 'amount_too_small'
  | 'api_key_expired'
  | 'authentication_required'
  | 'balance_insufficient'
  | 'bank_account_declined'
  | 'bank_account_exists'
  | 'bank_account_unusable'
  | 'bank_account_unverified'
  | 'bank_account_verification_failed'
  | 'billing_invalid_mandate'
  | 'bitcoin_upgrade_required'
  | 'card_decline_rate_limit_exceeded'
  | 'card_declined'
  | 'expired_card'
  | 'fraudulent'
  | 'incorrect_number'
  | 'incorrect_cvc'
  | 'incorrect_zip'
  | 'insufficient_funds'
  | 'invalid_cvc'
  | 'invalid_expiry_month'
  | 'invalid_expiry_year'
  | 'processing_error'
  | 'cardholder_phone_number_required'
  | 'charge_already_captured'
  | 'charge_already_refunded'
  | 'charge_disputed'
  | 'charge_exceeds_source_limit'
  | 'charge_expired_for_capture'
  | 'charge_invalid_parameter'
  | 'clearing_code_unsupported'
  | 'country_code_invalid'
  | 'country_unsupported'
  | 'coupon_expired'
  | 'customer_max_payment_methods'
  | 'customer_max_subscriptions'
  | 'debit_not_authorized'
  | 'email_invalid'
  | 'expired_card'
  | 'idempotency_key_in_use'
  | 'incorrect_address'
  | 'incorrect_cvc'
  | 'incorrect_number'
  | 'incorrect_zip'
  | 'instant_payouts_limit_exceeded'
  | 'instant_payouts_unsupported'
  | 'insufficient_funds'
  | 'intent_invalid_state'
  | 'intent_verification_method_missing'
  | 'invalid_card_type'
  | 'invalid_characters'
  | 'invalid_charge_amount'
  | 'invalid_cvc'
  | 'invalid_expiry_month'
  | 'invalid_expiry_year'
  | 'invalid_number'
  | 'invalid_source_usage'
  | 'invoice_no_customer_line_items'
  | 'invoice_no_payment_method_types'
  | 'invoice_no_subscription_line_items'
  | 'invoice_not_editable'
  | 'invoice_on_behalf_of_not_editable'
  | 'invoice_payment_intent_requires_action'
  | 'invoice_upcoming_none'
  | 'livemode_mismatch'
  | 'lock_timeout'
  | 'missing'
  | 'no_account'
  | 'not_allowed_on_standard_account'
  | 'out_of_inventory'
  | 'parameter_invalid_empty'
  | 'parameter_invalid_integer'
  | 'parameter_invalid_string_blank'
  | 'parameter_invalid_string_empty'
  | 'parameter_missing'
  | 'parameter_unknown'
  | 'parameters_exclusive'
  | 'payment_intent_action_required'
  | 'payment_intent_authentication_failure'
  | 'payment_intent_incompatible_payment_method'
  | 'payment_intent_invalid_parameter'
  | 'payment_intent_konbini_rejected_confirmation_number'
  | 'payment_intent_mandate_invalid'
  | 'payment_intent_payment_attempt_expired'
  | 'payment_intent_payment_attempt_failed'
  | 'payment_intent_unexpected_state'
  | 'payment_method_bank_account_already_verified'
  | 'payment_method_bank_account_blocked'
  | 'payment_method_billing_details_address_missing'
  | 'payment_method_currency_mismatch'
  | 'payment_method_invalid_parameter'
  | 'payment_method_invalid_parameter_testmode'
  | 'payment_method_microdeposit_failed'
  | 'payment_method_microdeposit_verification_amounts_invalid'
  | 'payment_method_microdeposit_verification_amounts_mismatch'
  | 'payment_method_microdeposit_verification_attempts_exceeded'
  | 'payment_method_microdeposit_verification_descriptor_code_mismatch'
  | 'payment_method_microdeposit_verification_timeout'
  | 'payment_method_provider_decline'
  | 'payment_method_provider_timeout'
  | 'payment_method_unactivated'
  | 'payment_method_unexpected_state'
  | 'payment_method_unsupported_type'
  | 'payouts_not_allowed'
  | 'platform_account_required'
  | 'platform_api_key_expired'
  | 'postal_code_invalid'
  | 'processing_error'
  | 'product_inactive'
  | 'rate_limit'
  | 'refer_to_customer'
  | 'refund_disputed_payment'
  | 'resource_already_exists'
  | 'resource_missing'
  | 'return_intent_already_processed'
  | 'routing_number_invalid'
  | 'secret_key_required'
  | 'sepa_unsupported_account'
  | 'setup_attempt_failed'
  | 'setup_intent_authentication_failure'
  | 'setup_intent_invalid_parameter'
  | 'setup_intent_setup_attempt_expired'
  | 'setup_intent_unexpected_state'
  | 'shipping_calculation_failed'
  | 'sku_inactive'
  | 'state_unsupported'
  | 'tax_id_invalid'
  | 'taxes_calculation_failed'
  | 'terminal_location_country_unsupported'
  | 'testmode_charges_only'
  | 'tls_version_unsupported'
  | 'token_already_used'
  | 'token_in_use'
  | 'transfers_not_allowed'
  | 'url_invalid'
  | 'account_closed'
  | 'account_information_mismatch'
  | 'application_fees_not_allowed'
  | 'balance_invalid_parameter'
  | 'billing_policy_remote_function_response_invalid'
  | 'billing_policy_remote_function_timeout'
  | 'billing_policy_remote_function_unexpected_status_code'
  | 'billing_policy_remote_function_unreachable'
  | 'capture_charge_authorization_expired'
  | 'capture_unauthorized_payment'
  | 'charge_not_refundable'
  | 'customer_tax_location_invalid'
  | 'financial_connections_account_inactive'
  | 'financial_connections_no_successful_transaction_refresh'
  | 'forwarding_api_inactive'
  | 'forwarding_api_invalid_parameter'
  | 'forwarding_api_upstream_connection_error'
  | 'forwarding_api_upstream_connection_timeout'
  | 'invalid_tax_location'
  | 'payment_method_configuration_failures'
  | 'payment_method_customer_decline'
  | 'payment_method_not_available'
  | 'shipping_address_invalid'
  | 'status_transition_invalid'
  | 'stripe_tax_inactive'
  | 'token_card_network_invalid'
  | undefined

export type StripeDeclineCode =
  | 'authentication_required'
  | 'approve_with_id'
  | 'call_issuer'
  | 'card_not_supported'
  | 'card_velocity_exceeded'
  | 'currency_not_supported'
  | 'do_not_honor'
  | 'do_not_try_again'
  | 'duplicate_transaction'
  | 'expired_card'
  | 'fraudulent'
  | 'generic_decline'
  | 'incorrect_number'
  | 'incorrect_cvc'
  | 'incorrect_pin'
  | 'incorrect_zip'
  | 'insufficient_funds'
  | 'invalid_account'
  | 'invalid_amount'
  | 'invalid_cvc'
  | 'invalid_expiry_month'
  | 'invalid_expiry_year'
  | 'invalid_number'
  | 'invalid_pin'
  | 'issuer_not_available'
  | 'lost_card'
  | 'merchant_blacklist'
  | 'new_account_information_available'
  | 'no_action_taken'
  | 'not_permitted'
  | 'offline_pin_required'
  | 'online_or_offline_pin_required'
  | 'pickup_card'
  | 'pin_try_exceeded'
  | 'processing_error'
  | 'reenter_transaction'
  | 'restricted_card'
  | 'revocation_of_all_authorizations'
  | 'revocation_of_authorization'
  | 'security_violation'
  | 'service_not_allowed'
  | 'stolen_card'
  | 'stop_payment_order'
  | 'testmode_decline'
  | 'transaction_not_allowed'
  | 'try_again_later'
  | 'withdrawal_count_limit_exceeded'
  | 'live_mode_test_card'
  | undefined

export type PaypalErrorCode =
  | '2000'
  | '2001'
  | '2002'
  | '2003'
  | '2004'
  | '2005'
  | '2006'
  | '2007'
  | '2008'
  | '2009'
  | '2010'
  | '2011'
  | '2012'
  | '2013'
  | '2014'
  | '2015'
  | '2016'
  | '2017'
  | '2018'
  | '2019'
  | '2020'
  | '2021'
  | '2022'
  | '2023'
  | '2024'
  | '2025'
  | '2026'
  | '2027'
  | '2028'
  | '2029'
  | '2030'
  | '2031'
  | '2032'
  | '2033'
  | '2034'
  | '2035'
  | '2036'
  | '2037'
  | '2038'
  | '2039'
  | '2040'
  | '2041'
  | '2042'
  | '2043'
  | '2044'
  | '2045'
  | '2046'
  | '2047'
  | '2048'
  | '2049'
  | '2050'
  | '2051'
  | '2052'
  | '2053'
  | '2054'
  | '2055'
  | '2056'
  | '2057'
  | '2058'
  | '2059'
  | '2060'
  | '2061'
  | '2062'
  | '2063'
  | '2064'
  | '2065'
  | '2066'
  | '2067'
  | '2068'
  | '2069'
  | '2070'
  | '2071'
  | '2072'
  | '2073'
  | '2074'
  | '2075'
  | '2076'
  | '2077'
  | '2078'
  | '2079'
  | '2080'
  | '2081'
  | '2082'
  | '2083'
  | '2084'
  | '2085'
  | '2086'
  | '2087'
  | '2088'
  | '2089'
  | '2090'
  | '2091'
  | '2092'
  | '2093'
  | '2094'
  | '2095'
  | '2096'
  | '2097'
  | '2098'
  | '2099'
  | '2100'
  | '2101'
  | '2102'
  | '2103'
  | '2104'
  | '2105'
  | '2999'
  | '3000'
  | '4001'
  | '4003'
  | '4004'
  | '4005'
  | '4006'
  | '4018'
  | '4019'
  | '4020'
  | '4021'
  | '4022'
  | '4023'
  | '4024'
  | '4025'
  | '4026'
  | undefined

export const formatCurrency = ({ amount, currency }: { amount: number; currency: string }) =>
  Intl.NumberFormat(getNavigatorLanguage(), {
    style: 'currency',
    currency: currency,
  }).format(amount)

const stripeDeclinedCodeError = (params: { declineCode?: StripeDeclineCode }) => {
  switch (params.declineCode) {
    case 'approve_with_id':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentAuthorizationFailed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'call_issuer':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'card_not_supported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDoesNotSupportPurchaseType'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'card_velocity_exceeded':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardTransactionLimitExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'currency_not_supported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardCurrencyNotSupported'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'do_not_honor':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'do_not_try_again':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'duplicate_transaction':
      return (
        <Trans
          i18nKey={'stripeErrorV2.duplicateTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'expired_card':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardExpired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'fraudulent':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclined'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'generic_decline':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_number':
      return (
        <Trans
          i18nKey={'stripeErrorV2.incorrectCardNumber'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_cvc':
      return (
        <Trans
          i18nKey={'stripeErrorV2.incorrectCVC'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_pin':
      return (
        <Trans
          i18nKey={'stripeErrorV2.incorrectPIN'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_zip':
      return (
        <Trans
          i18nKey={'stripeErrorV2.incorrectPostalCode'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'insufficient_funds':
      return (
        <Trans
          i18nKey={'stripeErrorV2.insufficientFunds'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_account':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCardOrAccount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_amount':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidPaymentAmount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_cvc':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCVC'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_expiry_month':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidExpirationMonth'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_expiry_year':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidExpirationYear'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_number':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCardNumber'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_pin':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidPIN'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'issuer_not_available':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardIssuerNotReachable'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'lost_card':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardReportedLost'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'merchant_blacklist':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclined'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'new_account_information_available':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCardOrAccount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'no_action_taken':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'not_permitted':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'offline_pin_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardRequiresPIN'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'online_or_offline_pin_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardRequiresPIN'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'pickup_card':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardNotSupportedForTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'pin_try_exceeded':
      return (
        <Trans
          i18nKey={'stripeErrorV2.exceededPINAttempts'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'processing_error':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingGenericError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'reenter_transaction':
      return (
        <Trans
          i18nKey={'stripeErrorV2.transactionFailedGenericError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'restricted_card':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardNotSupportedForTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'revocation_of_all_authorizations':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'revocation_of_authorization':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'security_violation':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'service_not_allowed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'stolen_card':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardReportedStolen'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'stop_payment_order':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'testmode_decline':
      return (
        <Trans
          i18nKey={'stripeErrorV2.validCardRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'transaction_not_allowed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'try_again_later':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'withdrawal_count_limit_exceeded':
      return (
        <Trans
          i18nKey={'stripeErrorV2.withdrawalLimitExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'authentication_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardAuthenticationRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'live_mode_test_card':
      return (
        <Trans
          i18nKey={'stripeErrorV2.liveModeTestCard'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    default:
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
  }
}

export const stripePaymentError = (params: {
  errorCode?: StripeErrorCode
  declineCode?: StripeDeclineCode
}) => {
  switch (params.errorCode) {
    case 'account_country_invalid_address':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'account_error_country_change_requires_additional_steps':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'account_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'account_number_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidBankAccountNumber'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'acss_debit_session_incomplete':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentNotComplete'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'alipay_upgrade_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingTryAnotherMethod'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'amount_too_large':
      return (
        <Trans
          i18nKey={'stripeErrorV2.amountTooLarge'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'amount_too_small':
      return (
        <Trans
          i18nKey={'stripeErrorV2.amountTooSmall'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'api_key_expired':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'authentication_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.additionalAuthenticationRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'balance_insufficient':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'bank_account_declined':
      return (
        <Trans
          i18nKey={'stripeErrorV2.bankAccountCannotBeUsedForTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'bank_account_exists':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'bank_account_unusable':
      return (
        <Trans
          i18nKey={'stripeErrorV2.bankAccountCannotBeUsed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'bank_account_unverified':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unverifiedBankAccount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'bank_account_verification_failed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.bankAccountVerificationFailed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'billing_invalid_mandate':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'bitcoin_upgrade_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingTryAnotherMethod'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'card_decline_rate_limit_exceeded':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedMultipleTimes'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'cardholder_phone_number_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.phoneNumberRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'charge_already_captured':
      return (
        <Trans
          i18nKey={'stripeErrorV2.chargeAlreadyProcessed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'charge_already_refunded':
      return (
        <Trans
          i18nKey={'stripeErrorV2.chargeAlreadyRefunded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'charge_disputed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingContactSupport'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'charge_exceeds_source_limit':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'charge_expired_for_capture':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'charge_invalid_parameter':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'clearing_code_unsupported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'country_code_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCountryCode'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'country_unsupported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'coupon_expired':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'customer_max_payment_methods':
      return (
        <Trans
          i18nKey={'stripeErrorV2.maximumPaymentMethodsReached'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'customer_max_subscriptions':
      return (
        <Trans
          i18nKey={'stripeErrorV2.maximumSubscriptionsReached'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'debit_not_authorized':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentReportedUnauthorized'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'email_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidEmailAddress'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'expired_card':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardExpired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'idempotency_key_in_use':
      return (
        <Trans
          i18nKey={'stripeErrorV2.temporaryPaymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_address':
      return (
        <Trans
          i18nKey={'stripeErrorV2.billingAddressIncorrect'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_cvc':
      return (
        <Trans
          i18nKey={'stripeErrorV2.incorrectCVC'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_number':
      return (
        <Trans
          i18nKey={'stripeErrorV2.incorrectCardNumber'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'incorrect_zip':
      return (
        <Trans
          i18nKey={'stripeErrorV2.incorrectPostalCode'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'instant_payouts_limit_exceeded':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentProcessingTryAnotherMethod'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'instant_payouts_unsupported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardIneligibleForTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'insufficient_funds':
      return (
        <Trans
          i18nKey={'stripeErrorV2.insufficientFunds'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'intent_invalid_state':
      return (
        <Trans
          i18nKey={'stripeErrorV2.temporaryPaymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'intent_verification_method_missing':
      return (
        <Trans
          i18nKey={'stripeErrorV2.temporaryPaymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_card_type':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardNotSupported'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_characters':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCharactersInFields'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_charge_amount':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidOrExceededAmount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_cvc':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCVC'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_expiry_month':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidExpirationMonth'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_expiry_year':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidExpirationYear'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_number':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidCardNumber'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_source_usage':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invoice_no_customer_line_items':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invoice_no_payment_method_types':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invoice_no_subscription_line_items':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invoice_not_editable':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invoice_on_behalf_of_not_editable':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invoice_payment_intent_requires_action':
      return (
        <Trans
          i18nKey={'stripeErrorV2.paymentRequiresAdditionalAction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invoice_upcoming_none':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'livemode_mismatch':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'lock_timeout':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'missing':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'no_account':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'not_allowed_on_standard_account':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'out_of_inventory':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'parameter_invalid_empty':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'parameter_invalid_integer':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'parameter_invalid_string_blank':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'parameter_invalid_string_empty':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'parameter_missing':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'parameter_unknown':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'parameters_exclusive':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_action_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_authentication_failure':
      return (
        <Trans
          i18nKey={'stripeErrorV2.authenticationFailed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_incompatible_payment_method':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_invalid_parameter':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_konbini_rejected_confirmation_number':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_mandate_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_payment_attempt_expired':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_payment_attempt_failed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_intent_unexpected_state':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_bank_account_already_verified':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_bank_account_blocked':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_billing_details_address_missing':
      return (
        <Trans
          i18nKey={'stripeErrorV2.billingAddressRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_currency_mismatch':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardNotSupportedCurrency'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_invalid_parameter':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_invalid_parameter_testmode':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_microdeposit_failed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.accountVerificationUnsuccessful'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_microdeposit_verification_amounts_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_microdeposit_verification_amounts_mismatch':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_microdeposit_verification_attempts_exceeded':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_microdeposit_verification_descriptor_code_mismatch':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_microdeposit_verification_timeout':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_provider_decline':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_provider_timeout':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_unactivated':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_unexpected_state':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_unsupported_type':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payouts_not_allowed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'platform_account_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'platform_api_key_expired':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'postal_code_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidPostalCode'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'processing_error':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'product_inactive':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'rate_limit':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'refer_to_customer':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'refund_disputed_payment':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'resource_already_exists':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'resource_missing':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'return_intent_already_processed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'routing_number_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'secret_key_required':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'sepa_unsupported_account':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'setup_attempt_failed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'setup_intent_authentication_failure':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'setup_intent_invalid_parameter':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'setup_intent_setup_attempt_expired':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'setup_intent_unexpected_state':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'shipping_calculation_failed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'sku_inactive':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'state_unsupported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'tax_id_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidTaxID'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'taxes_calculation_failed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'terminal_location_country_unsupported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'testmode_charges_only':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'tls_version_unsupported':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'token_already_used':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'token_in_use':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'transfers_not_allowed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'url_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'account_closed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.bankAccountClosed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'account_information_mismatch':
      return (
        <Trans
          i18nKey={'stripeErrorV2.accountInfoMismatch'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'application_fees_not_allowed':
      return (
        <Trans
          i18nKey={'stripeErrorV2.regulatoryRestrictions'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'balance_invalid_parameter':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'billing_policy_remote_function_response_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'billing_policy_remote_function_timeout':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'billing_policy_remote_function_unexpected_status_code':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'billing_policy_remote_function_unreachable':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'capture_charge_authorization_expired':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'capture_unauthorized_payment':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'charge_not_refundable':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'customer_tax_location_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.taxCalculationError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'financial_connections_account_inactive':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'financial_connections_no_successful_transaction_refresh':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'forwarding_api_inactive':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'forwarding_api_invalid_parameter':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'forwarding_api_upstream_connection_error':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'forwarding_api_upstream_connection_timeout':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'invalid_tax_location':
      return (
        <Trans
          i18nKey={'stripeErrorV2.taxCalculationError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_configuration_failures':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_customer_decline':
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedDueToSecurity'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'payment_method_not_available':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'shipping_address_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.invalidShippingAddress'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'status_transition_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'stripe_tax_inactive':
      return (
        <Trans
          i18nKey={'stripeErrorV2.taxCalculationError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'token_card_network_invalid':
      return (
        <Trans
          i18nKey={'stripeErrorV2.unexpectedError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case 'card_declined': {
      return stripeDeclinedCodeError(params)
    }
    default:
      return (
        <Trans
          i18nKey={'stripeErrorV2.cardDeclinedInternational'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
  }
}

export const paypalPaymentError = (params: { errorCode?: PaypalErrorCode }) => {
  switch (params.errorCode) {
    case '2000':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2001':
      return (
        <Trans
          i18nKey={'paypalErrorV2.insufficientFunds'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2002':
      return (
        <Trans
          i18nKey={'paypalErrorV2.withdrawalLimitExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2003':
      return (
        <Trans
          i18nKey={'paypalErrorV2.activityLimitExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2004':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardExpired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2005':
      return (
        <Trans
          i18nKey={'paypalErrorV2.invalidCardNumber'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2006':
      return (
        <Trans
          i18nKey={'paypalErrorV2.invalidExpirationDate'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2007':
      return (
        <Trans
          i18nKey={'paypalErrorV2.invalidCardAccount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2008':
      return (
        <Trans
          i18nKey={'paypalErrorV2.incompleteCardNumber'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2009':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardIssuerNotIdentified'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2010':
      return (
        <Trans
          i18nKey={'paypalErrorV2.invalidCVV'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2011':
      return (
        <Trans
          i18nKey={'paypalErrorV2.additionalAuthorizationRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2012':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardReportedLost'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2013':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardDeclinedSecurity'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2014':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionDeclinedSecurity'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2015':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2016':
      return (
        <Trans
          i18nKey={'paypalErrorV2.duplicateTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2017':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionCancelled'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2018':
      return (
        <Trans
          i18nKey={'paypalErrorV2.recurringTransactionCancelled'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2019':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2020':
      return (
        <Trans
          i18nKey={'paypalErrorV2.bankDeclinedTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2021':
      return (
        <Trans
          i18nKey={'paypalErrorV2.bankDeclinedTransactionSecurity'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2022':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardExpiredOrReplaced'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2023':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2024':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardTypeNotAccepted'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2025':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2026':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2027':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionAmountProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2028':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2029':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardIssue'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2030':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2031':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardVerificationError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2032':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardDoesNotPermitSurcharge'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2033':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2034':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionNotCompleted'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2035':
      return (
        <Trans
          i18nKey={'paypalErrorV2.partialTransactionApproval'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2036':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionErrorTryAgain'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2037':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionErrorTryAgain'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2038':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2039':
      return (
        <Trans
          i18nKey={'paypalErrorV2.authorizationError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2040':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentProcessingErrorContactUs'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2041':
      return (
        <Trans
          i18nKey={'paypalErrorV2.additionalApprovalRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2042':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentIssueTryAgain'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2043':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2044':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2045':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2046':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2047':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardReportedLostOrStolen'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2048':
      return (
        <Trans
          i18nKey={'paypalErrorV2.invalidTransactionAmount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2049':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingContactUs'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2050':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardNotUsableForTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2051':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardInfoError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2052':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingContactUs'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2053':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardReportedLostOrStolen'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2054':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2055':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingContactUs'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2056':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionAmountIssue'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2057':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardRestrictions'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2058':
      return (
        <Trans
          i18nKey={'paypalErrorV2.securityFeaturesRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2059':
      return (
        <Trans
          i18nKey={'paypalErrorV2.addressVerificationError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2060':
      return (
        <Trans
          i18nKey={'paypalErrorV2.addressAndSecurityCodeError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2061':
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2062':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionProcessingContactUs'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2063':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentProcessingTryAnotherMethod'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2064':
      return (
        <Trans
          i18nKey={'paypalErrorV2.unsupportedCurrencyError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2065':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundNotAllowedAfterTime'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2066':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentProcessingTryAgainLater'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2067':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentAuthorizationExpired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2068':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalPaymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2069':
      return (
        <Trans
          i18nKey={'paypalErrorV2.duplicateTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2070':
      return (
        <Trans
          i18nKey={'paypalErrorV2.authorizationRevoked'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2071':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalAccountNotSetUp'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2072':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalEmailError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2073':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalPaymentError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2074':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalFundingSourceDeclined'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2075':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalAccountLockedOrClosed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2076':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalTransactionNotAllowed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2077':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalRiskAssessmentDeclined'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2078':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentProcessingError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2079':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentProcessingTryAgainLater'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2080':
      return (
        <Trans
          i18nKey={'paypalErrorV2.invalidUserCredentials'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2081':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalPaymentUnavailable'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2082':
      return (
        <Trans
          i18nKey={'paypalErrorV2.countryRestriction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2083':
      return (
        <Trans
          i18nKey={'paypalErrorV2.phoneNumberRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2084':
      return (
        <Trans
          i18nKey={'paypalErrorV2.taxDetailsRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2085':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalFraudSettingsBlocked'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2086':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionAmountLimit'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2087':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentProcessingTryAgainLater'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2088':
      return (
        <Trans
          i18nKey={'paypalErrorV2.unsupportedCurrency'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2089':
      return (
        <Trans
          i18nKey={'paypalErrorV2.emailPermissionsIssue'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2090':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundGreaterThanOriginal'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2091':
      return (
        <Trans
          i18nKey={'paypalErrorV2.homeCurrencyRestriction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2092':
      return (
        <Trans
          i18nKey={'paypalErrorV2.bankAccountVerificationFailed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2093':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalPaymentMethodExpiredOrCanceled'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2094':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentAlreadyProcessed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2095':
      return (
        <Trans
          i18nKey={'paypalErrorV2.fullRefundNotAllowedAfterPartial'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2096':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalBuyerSellerConflict'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2097':
      return (
        <Trans
          i18nKey={'paypalErrorV2.authorizationAmountExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2098':
      return (
        <Trans
          i18nKey={'paypalErrorV2.authorizationLimitExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2099':
      return (
        <Trans
          i18nKey={'paypalErrorV2.additionalAuthenticationRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2100':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentProcessingTryAgainLater'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2101':
      return (
        <Trans
          i18nKey={'paypalErrorV2.additionalCredentialsRequired'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2102':
      return (
        <Trans
          i18nKey={'paypalErrorV2.incorrectPIN'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2103':
      return (
        <Trans
          i18nKey={'paypalErrorV2.exceededPINAttempts'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2104':
      return (
        <Trans
          i18nKey={'paypalErrorV2.bankDeclinedTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '2105':
    case '2999':
      return (
        <Trans
          i18nKey={'paypalErrorV2.bankDeclinedTransactionTryAnotherMethod'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '3000':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paymentNetworkError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4001':
      return (
        <Trans
          i18nKey={'paypalErrorV2.settlementError'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4003':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionAlreadyProcessed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4004':
      return (
        <Trans
          i18nKey={'paypalErrorV2.transactionAlreadyRefunded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4005':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalRiskAssessmentRejection'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4006':
      return (
        <Trans
          i18nKey={'paypalErrorV2.captureAmountExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4018':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalPaymentUnavailable'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4019':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundOpenDispute'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4020':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundAttemptsExceeded'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4021':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundNotAllowedForTransaction'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4022':
      return (
        <Trans
          i18nKey={'paypalErrorV2.invalidRefundAmount'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4023':
      return (
        <Trans
          i18nKey={'paypalErrorV2.unverifiedBankAccountForRefunds'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4024':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundNotAllowedAfterTime'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4025':
      return (
        <Trans
          i18nKey={'paypalErrorV2.paypalAccountLockedOrClosed'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    case '4026':
      return (
        <Trans
          i18nKey={'paypalErrorV2.refundGreaterThanOriginalPaypal'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
    default:
      return (
        <Trans
          i18nKey={'paypalErrorV2.cardDeclinedUnknownReason'}
          components={{ br: <Box style={{ marginTop: spacing['8'] }} /> }}
        />
      )
  }
}
