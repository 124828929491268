import {
  Box,
  Button,
  colors,
  CountryCode,
  Message,
  radius,
  spacing,
  SvgBox,
  Text,
} from '@alohi/flow-ui'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FaxNumberType, FaxPlusNumberShopItems } from 'api/gql/generated/graphql'
import CustomNumberIcon from 'assets/svg/numbers/custom.svg'
import { formatCurrency } from 'helpers/payment'
import { testIds } from 'helpers/tests'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import HighlightNumbers from './components/HighlightNumbers'

export const NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID = 'new-payment-cardholder-name'

export interface AvailableCustomNumbersListProps {
  className?: string
  faxNumberShopItems: FaxPlusNumberShopItems | undefined
  country: CountryCode
  numberType: FaxNumberType
  onSelect: (number: string) => void
  highlight: string
}

export const AvailableCustomNumbersList: React.FC<AvailableCustomNumbersListProps> = ({
  className,
  faxNumberShopItems,
  country,
  numberType,
  onSelect,
  highlight,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const { supportedCountries } = useFaxSupportedCountries()

  const isReady = faxNumberShopItems?.customNumbers !== undefined
  const availableCustomNumbers = faxNumberShopItems?.customNumbers ?? []
  const list = availableCustomNumbers.map((number) => ({
    number,
  }))

  const setupPrice: number = useMemo(() => {
    const supportedCountry = supportedCountries.find(
      (supportedCountry) => supportedCountry.iso === country
    )

    if (numberType === FaxNumberType.TollFree) {
      return supportedCountry?.tollFree?.setupPrice?.custom ?? 0
    } else {
      return supportedCountry?.local?.setupPrice?.custom ?? 0
    }
  }, [country, numberType, supportedCountries])

  if (!isReady) return
  if (!list.length)
    return (
      <Box
        dataCy={testIds.fax.numbers.custom.noNumberAvailableMessage}
        className={cx(classes.base, className)}
      >
        <Message className={classes.noNumbersAvailable} variant="help">
          <Text isBold>{t('faxNumber.noCustomNumberFoundTitle')}</Text>
          <Text className={classes.noNumbersAvailableDescription}>
            {t('faxNumber.noCustomNumberFoundDescription')}
          </Text>
        </Message>
      </Box>
    )
  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.header}>
        <Box className={classes.col1}>
          <Text isBold>{t('common.number')}</Text>
        </Box>
        <Box className={classes.col2}>
          <Text isBold>{t('faxNumber.oneTimeFee')}</Text>
        </Box>
        <Box className={classes.col3} />
      </Box>
      {list.map((customNumber, index) => (
        <Box
          key={index.toString()}
          className={index === list.length - 1 ? classes.lastRow : classes.row}
        >
          <Box className={classes.col1}>
            <SvgBox className={classes.customNumberIcon} src={CustomNumberIcon} />
            <HighlightNumbers highlight={highlight} phoneNumber={customNumber.number} />
          </Box>
          <Box className={classes.col2}>
            <Text>
              {formatCurrency({
                amount: setupPrice,
                currency,
              })}
            </Text>
          </Box>
          <Box className={classes.col3}>
            <Button
              dataCy={testIds.fax.numbers.custom.availableNumberSelect}
              variant="tertiary"
              icon={faChevronRight}
              iconPlacement="right"
              classNames={{ icon: classes.selectIcon }}
              onClick={() => onSelect(customNumber.number)}
            >
              {t('common.select')}
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    flexGrow: 1,
    marginTop: spacing['8'],
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '44px',
    background: colors.neutral150,
    borderTopLeftRadius: radius['8'],
    borderTopRightRadius: radius['8'],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
  },
  row: {
    display: 'flex',
    height: '72px',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    borderTopWidth: 0,
  },
  lastRow: {
    display: 'flex',
    height: '72px',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    borderTopWidth: 0,
    borderBottomLeftRadius: radius['8'],
    borderBottomRightRadius: radius['8'],
  },
  col1: {
    flex: 1,
    padding: spacing['8'],
    paddingLeft: spacing['24'],
    display: 'flex',
    alignItems: 'center',
  },
  col2: {
    flex: 1,
    padding: spacing['8'],
  },
  col3: {
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    padding: spacing['8'],
    paddingRight: spacing['24'],
  },
  selectIcon: {
    paddingLeft: spacing['8'],
  },
  customNumberIcon: {
    marginRight: spacing['8'],
  },
  noNumbersAvailable: {
    alignItems: 'flex-start',
  },
  noNumbersAvailableDescription: {
    marginTop: spacing['4'],
  },
}))
