import {
  ActiveSubscription,
  FaxPlusNumberSubscriptionItem,
  FaxPlusPlanSubscriptionItem,
  Period,
  PlanType,
  SubscriptionItemFragmentFragment,
} from 'api/gql/generated/graphql'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useMemo } from 'react'

export enum ActiveFaxSubscriptionType {
  FREE = 'free',
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate',
}

export const useActiveFaxSubscription = (): {
  activeFaxSubscription: Omit<ActiveSubscription, 'items'> & {
    items: SubscriptionItemFragmentFragment[]
    computed: {
      numbers: FaxPlusNumberSubscriptionItem[]
      type: ActiveFaxSubscriptionType
      planItem: {
        name: string
        period: Period
        shopItemId: string
        itemAmount: number
        type: PlanType | undefined
        marketingName: string
        pages: number
        highVolume: boolean
        isInternational: boolean
      }
    }
  }
} => {
  const {
    customer: { subscriptions },
  } = useCustomer()
  const activeSubscriptions = subscriptions?.filter(
    (subscription) => subscription.__typename === 'ActiveSubscription'
  )
  const activeFaxSubscription = activeSubscriptions?.find((subscription) =>
    (subscription.items as SubscriptionItemFragmentFragment[]).find(
      (item): item is FaxPlusPlanSubscriptionItem =>
        item.__typename === 'FaxPlusPlanSubscriptionItem'
    )
  )
  const activeFaxSubscriptionItems = (activeFaxSubscription?.items ??
    []) as unknown as SubscriptionItemFragmentFragment[]
  const activeFaxSubscriptionItem = activeFaxSubscriptionItems?.find(
    (item): item is FaxPlusPlanSubscriptionItem => item.__typename === 'FaxPlusPlanSubscriptionItem'
  )
  const activeFaxSubscriptionNumbers = (activeFaxSubscriptionItem?.numbers ?? []).concat(
    activeFaxSubscriptionItems.filter(
      (item): item is FaxPlusNumberSubscriptionItem =>
        item.__typename === 'FaxPlusNumberSubscriptionItem'
    )
  )

  const activeFaxSubscriptionType: ActiveFaxSubscriptionType = useMemo(() => {
    if (activeFaxSubscriptionNumbers.length === 1) {
      return ActiveFaxSubscriptionType.INDIVIDUAL
    }
    if (activeFaxSubscriptionNumbers.length > 1) {
      return ActiveFaxSubscriptionType.CORPORATE
    }
    return ActiveFaxSubscriptionType.FREE
  }, [activeFaxSubscriptionNumbers.length])

  return {
    activeFaxSubscription: {
      id: activeFaxSubscription?.id ?? '',
      items: activeFaxSubscription?.items ?? [],
      expiresAt: activeFaxSubscription?.expiresAt ?? 0,
      period: activeFaxSubscription?.period ?? Period.Annual,
      nextCart: activeFaxSubscription?.nextCart,
      autoRenew: activeFaxSubscription?.autoRenew ?? false,
      lastRechargeDate: activeFaxSubscription?.lastRechargeDate ?? 0,
      computed: {
        numbers: activeFaxSubscriptionNumbers,
        type: activeFaxSubscriptionType,
        planItem: {
          name: activeFaxSubscriptionItem?.name ?? '',
          type: activeFaxSubscriptionItem?.planType,
          period: activeFaxSubscriptionItem?.period ?? Period.Annual,
          shopItemId: activeFaxSubscriptionItem?.shopItemId ?? '',
          itemAmount: activeFaxSubscriptionItem?.itemAmount ?? 0,
          marketingName: activeFaxSubscriptionItem?.marketingData.name ?? '',
          pages: activeFaxSubscriptionItem?.pages ?? 0,
          highVolume: activeFaxSubscriptionItem?.highVolume ?? false,
          isInternational: !activeFaxSubscriptionItem?.dstLimits.length,
        },
      },
    },
  }
}
