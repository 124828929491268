import { CountryCode, Select, SelectProps, useDependencyChange, useInput } from '@alohi/flow-ui'
import { FaxNumberType, RegulatoryBundleStatus } from 'api/gql/generated/graphql'
import { testIds } from 'helpers/tests'
import { useBundles } from 'hooks/useBundles/useBundles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { Label } from './components/Label'
import { SelectedLabel } from './components/SelectedLabel'

export interface BundleSelectProps {
  className?: string
  bundleSid: string | undefined | null
  country: CountryCode
  numberType: FaxNumberType
  isDisabled?: boolean
  onSelect: (bundleSid: string | null) => void
}

export const BundleSelect: React.FC<BundleSelectProps> = ({
  className,
  bundleSid,
  country,
  numberType,
  isDisabled,
  onSelect,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { extractBundles } = useBundles()
  const approvedOrPendingBundles = extractBundles({
    country,
    numberType,
    statuses: [RegulatoryBundleStatus.TwilioApproved, RegulatoryBundleStatus.PendingReview],
  })

  const bundleChecker = (bundle: string) => {
    if (!bundle) {
      return `${t('faxNumber.regulatorDocuments')} ${t('common.required')}`
    }
  }

  const [bundleInputValue, bundleInputProps] = useInput<string>({
    initialValue: bundleSid ?? '',
    validator: bundleChecker,
    onChange: (value) => {
      if (!value) {
        onSelect(null)
      } else if (value !== bundleSid) {
        onSelect(value)
      }
    },
    useForm: false,
  })

  const items: SelectProps['items'] = useMemo(
    () =>
      approvedOrPendingBundles.map(
        (approvedOrPendingBundle) =>
          ({
            id: approvedOrPendingBundle.sid,
            label: `${approvedOrPendingBundle.address?.city} (${approvedOrPendingBundle.endUserType})`,
            item: (
              <Label
                city={approvedOrPendingBundle.address?.city}
                endUserType={approvedOrPendingBundle.endUserType}
                submittedOn={approvedOrPendingBundle.updatedAt}
                isPending={approvedOrPendingBundle.status === RegulatoryBundleStatus.PendingReview}
              />
            ),
            itemSelected: (
              <SelectedLabel
                city={approvedOrPendingBundle.address?.city}
                endUserType={approvedOrPendingBundle.endUserType}
                isPending={approvedOrPendingBundle.status === RegulatoryBundleStatus.PendingReview}
              />
            ),
          }) satisfies SelectProps['items'][number]
      ),
    [approvedOrPendingBundles]
  )

  useDependencyChange(() => {
    if (bundleSid && bundleSid !== bundleInputValue) {
      bundleInputProps.onChange(bundleSid ?? '')
    }
  }, [bundleSid])

  return (
    <Select
      className={cx(classes.base, className)}
      dataCys={testIds.fax.numbers.bundle}
      value={bundleInputValue}
      {...bundleInputProps}
      label={t('faxNumber.regulatorDocuments')}
      placeholder={t('faxNumber.regulatorDocuments')}
      items={items}
      isDisabled={isDisabled}
      classNames={{
        list: classes.list,
      }}
      isSortingAlphabetically
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  list: {
    maxHeight: '200px',
  },
}))
