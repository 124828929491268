import { Box, colors, Text } from '@alohi/flow-ui'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface MoreItemProps {
  className?: string
  onClick: () => void
}

function MoreItem({ className, onClick }: MoreItemProps) {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)} onClick={onClick}>
      <Text isBold>{t('common.more')}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    paddingLeft: '12px',
    cursor: 'pointer',
    ':hover': {
      background: colors.neutral150,
    },
    ':active': {
      background: colors.neutral200,
    },
  },
}))

export default MoreItem
