import {
  AnimatedBox,
  Box,
  Button,
  colors,
  Icon,
  PaypalButton,
  spacing,
  SvgBox,
  Text,
  useBool,
  useFormApi,
} from '@alohi/flow-ui'
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons'
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons'
import { PaymentMethodType, Period } from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import PayPalSvg from 'assets/svg/payment/paypal.svg'
import StripeSvg from 'assets/svg/payment/stripe.svg'
import { NEW_PAYMENT_COUNTRY_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/CountrySelect'
import { NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/NameInput'
import { NEW_PAYMENT_POSTAL_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/PostalInput'
import { NEW_PAYMENT_STREET_INPUT_ID } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/components/StreetInput'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { BRAINTREE_CLIENT_ID } from 'env'
import dayjs, { formatDefaultDate } from 'helpers/date'
import { localStorageKeys } from 'helpers/localStorage'
import {
  formatCurrency,
  PaypalErrorCode,
  StripeDeclineCode,
  StripeErrorCode,
} from 'helpers/payment'
import { testIds } from 'helpers/tests'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { PaymentErrorModal } from './modals/PaymentErrorModal/PaymentErrorModal'
import { SuccessModal } from './modals/SuccessModal/SuccessModal'
import { SuccessModalContentProps } from './modals/SuccessModal/components/SuccessModalContent'

interface TotalContainerProps {
  className?: string
  onProcess: () => Promise<SuccessModalContentProps | undefined>
  successRedirectionUrl?: string
}
export const TotalContainer: React.FC<TotalContainerProps> = ({
  className,
  onProcess,
  successRedirectionUrl,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { navigateBack } = useRoutesApi()

  const {
    store: { hasPaymentFailed, paymentErrorExtensions, isUpdating, isProcessing },
    computedItems: { signPlanCartItem },
    cart,
    updateStore: updateCartStore,
  } = useCartApi()

  const {
    activeSignSubscription: {
      planItem: { isTrial: isCurrentSubsciptionTrial },
    },
  } = useActiveSubscriptions()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const { signPlans } = useShopItems()

  const hasCart = Boolean(cart.items?.length)
  const unitPrice = signPlanCartItem?.unitPrice ?? 0
  const trialDays = signPlanCartItem?.trialDays ?? 0
  const period = signPlanCartItem?.period
  const maxAmount = signPlans.find((signPlan) => signPlan.id === signPlanCartItem?.shopItemId)
    ?.limits?.maxAmount
  const formattedCurrency = formatCurrency({
    currency,
    amount: unitPrice,
  })

  const scheduledTotal =
    cart.processingInfo?.__typename === 'CartScheduled'
      ? cart.processingInfo.scheduledTotal
      : cart.processingInfo?.__typename === 'CartTrial'
        ? cart.processingInfo.priceAfterTrial
        : 0

  const scheduledDate =
    cart.processingInfo?.__typename === 'CartScheduled'
      ? cart.processingInfo.processAt
      : cart.processingInfo?.__typename === 'CartTrial'
        ? cart.processingInfo.trialEndsAt
        : 0

  const scheduledDateLabel = useMemo(() => {
    if (cart.processingInfo?.__typename === 'CartTrial') {
      return t('purchase.dueAtTheEndOfTrial', {
        value_1: formatDefaultDate(scheduledDate),
      })
    } else if (cart.processingInfo?.__typename === 'CartScheduled') {
      if (isCurrentSubsciptionTrial) {
        return t('purchase.dueAtTheEndOfTrial', {
          value_1: formatDefaultDate(scheduledDate),
        })
      } else {
        return t('purchase.dueOnNextBillingCycle', {
          value_1: formatDefaultDate(scheduledDate),
        })
      }
    }
    return ''
  }, [isCurrentSubsciptionTrial, cart.processingInfo?.__typename, scheduledDate, t])

  const {
    store: { isPaymentDefaultLoading, isNewPaymentMethodLoading, newPaymentMethodSelectedType },
    paymentMethods,
    selectNewPaymentMethodType,
    addStripePaymentMethod,
    addPaypalPaymentMethod,
    selectedPaymentMethodType,
    updateStore: updatePaymentMethodStore,
  } = usePaymentMethodApi()
  const hasNoPaymentMethod = !paymentMethods.length
  const isLoading =
    isNewPaymentMethodLoading || isUpdating || isProcessing || isPaymentDefaultLoading

  const paymentMethodRequired =
    paymentMethods?.length === 0 &&
    (signPlanCartItem?.isTrial || !(cart.total === 0 && Boolean(cart.creditUsed)))

  const {
    store: { canSubmit, tooltip },
    checkFormValidity,
    readInput,
  } = useFormApi()

  const [isSuccessModalOpen, isSuccessModalOpenBool] = useBool(false)
  const [successModalProps, setSuccessModalProps] = useState<SuccessModalContentProps | undefined>()

  const handlePaypalApprove = useCallback(
    async (token: string) => {
      try {
        if (!(await checkFormValidity())) return

        if (paymentMethodRequired) {
          await addPaypalPaymentMethod({
            token,
          })
        }

        setSuccessModalProps(await onProcess())
        selectNewPaymentMethodType(PaymentMethodType.Card)
        isSuccessModalOpenBool.setTrue()
      } catch {
        // Nothing to do
      }
    },
    [
      addPaypalPaymentMethod,
      checkFormValidity,
      isSuccessModalOpenBool,
      onProcess,
      paymentMethodRequired,
      selectNewPaymentMethodType,
    ]
  )

  const handleStripePayment = useCallback(async () => {
    try {
      if (!(await checkFormValidity())) return

      if (paymentMethodRequired) {
        const name = readInput<string>(NEW_PAYMENT_CARDHOLDER_NAME_INPUT_ID)
        const country = readInput<string>(NEW_PAYMENT_COUNTRY_INPUT_ID)
        const street = readInput<string>(NEW_PAYMENT_STREET_INPUT_ID)
        const postalCode = readInput<string>(NEW_PAYMENT_POSTAL_INPUT_ID)

        if (!name || !country || !street || !postalCode) {
          throw new Error('Missing parameters')
        }

        await addStripePaymentMethod({
          country,
          name,
          postalCode,
          street,
        })
      }

      const simulatedPaypalErrorCode = localStorage.getItem(
        localStorageKeys.simulatePaypalErrorCode
      )
      const simulatedStripeDeclineCode = localStorage.getItem(
        localStorageKeys.simulateStripeDeclineCode
      )
      const simulatedStripeErrorCode = localStorage.getItem(
        localStorageKeys.simulateStripeErrorCode
      )

      if (simulatedPaypalErrorCode || simulatedStripeDeclineCode || simulatedStripeErrorCode) {
        updateCartStore({
          paymentErrorExtensions: {
            BRAINTREE_ERROR_CODE: simulatedPaypalErrorCode
              ? (parseInt(simulatedPaypalErrorCode) as unknown as PaypalErrorCode)
              : undefined,
            STRIPE_DECLINE_CODE: simulatedStripeDeclineCode as unknown as StripeDeclineCode,
            STRIPE_ERROR_CODE: simulatedStripeErrorCode as unknown as StripeErrorCode,
          },
        })
        return
      }

      setSuccessModalProps(await onProcess())
      isSuccessModalOpenBool.setTrue()
    } catch {
      // Nothing to do
    }
  }, [
    addStripePaymentMethod,
    checkFormValidity,
    isSuccessModalOpenBool,
    onProcess,
    paymentMethodRequired,
    readInput,
    updateCartStore,
  ])

  const handleSuccessConfirm = () => {
    isSuccessModalOpenBool.setFalse()
    if (successRedirectionUrl) {
      window.location.href = successRedirectionUrl
    } else {
      navigateBack({
        skipLocalRoutes: true, // Redirect the user to the initial App
      })
    }
  }

  const handleCancelErrorModal = () => {
    isSuccessModalOpenBool.setFalse()
    updateCartStore({
      paymentErrorExtensions: undefined,
    })
    updatePaymentMethodStore({
      newPaymentMethodErrorExtensions: undefined,
    })
  }

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.total}>
        <Text variant="h1">{t('common.total')}</Text>
        <Text variant="h2">{formatCurrency({ amount: cart.total, currency })}</Text>
      </Box>

      {cart.processingInfo?.__typename === 'CartScheduled' ||
      cart.processingInfo?.__typename === 'CartTrial' ? (
        <Box className={classes.scheduledTotal}>
          <Text isBold>{scheduledDateLabel}</Text>
          <Text isBold>{formatCurrency({ amount: scheduledTotal, currency })}</Text>
        </Box>
      ) : null}

      {hasNoPaymentMethod && newPaymentMethodSelectedType === PaymentMethodType.Paypal ? (
        <AnimatedBox key="paypal">
          <PaypalButton
            clientId={BRAINTREE_CLIENT_ID}
            className={classes.paypalButton}
            onApprove={handlePaypalApprove}
            dataCy={testIds.newPaymentMethodForm.paypal.button}
          />
        </AnimatedBox>
      ) : (
        <AnimatedBox key="card">
          <Button
            size="large"
            icon={cart.processingInfo?.__typename ? undefined : faLockKeyhole}
            className={classes.button}
            isDisabled={!hasCart || !canSubmit}
            isLoading={isLoading}
            tooltip={tooltip}
            tooltipPlacement="right"
            onClick={handleStripePayment}
            dataCy={testIds.orderSummary.submitButton}
          >
            {cart.processingInfo?.__typename === 'CartScheduled'
              ? t('common.confirm')
              : cart.processingInfo?.__typename === 'CartTrial'
                ? t('purchase.startTrial')
                : t('purchase.confirmAndPay')}
          </Button>
          {cart.isTrial ? (
            <Text className={classes.trialInfos} variant="body">
              {t('purchase.trialInfo', {
                value_1: formatDefaultDate(dayjs().add(trialDays, 'day').unix()),
                value_2: formattedCurrency,
                value_3: maxAmount && maxAmount > 1 ? `${t('purchase.perSeat')}, ` : '',
                value_4: period === Period.Annual ? t('purchase.perYear') : t('purchase.perMonth'),
              })}
            </Text>
          ) : isCurrentSubsciptionTrial ? (
            <Text className={classes.trialInfos} variant="body">
              {period === Period.Annual
                ? t('purchase.endOfTrialAnnual')
                : t('purchase.endOfTrialMonthly')}
            </Text>
          ) : null}

          <Box className={classes.stripe}>
            <Icon className={classes.shieldIcon} icon={faShieldCheck} />

            <Text className={classes.text} variant="body">
              {t('purchase.poweredBy')}
            </Text>
            {selectedPaymentMethodType === PaymentMethodType.Paypal ? (
              <SvgBox className={classes.paypalSvg} src={PayPalSvg} />
            ) : (
              <SvgBox className={classes.stripeSvg} src={StripeSvg} />
            )}
          </Box>
        </AnimatedBox>
      )}
      {isSuccessModalOpen ? (
        <SuccessModal {...successModalProps} onConfirm={handleSuccessConfirm} />
      ) : null}
      {hasPaymentFailed ? (
        <PaymentErrorModal
          paymentErrorExtensions={paymentErrorExtensions}
          onCancel={handleCancelErrorModal}
          onProcess={onProcess}
        />
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['8'],
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  scheduledTotal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: spacing['16'],
    color: colors.neutral400,
  },
  paypalButton: {
    marginTop: spacing['24'],
    width: '100% !important',
    height: '44px',
  },
  button: {
    marginTop: spacing['24'],
    marginBottom: spacing['16'],
    width: '100% !important',
    height: '44px',
  },
  stripe: {
    paddingTop: spacing['4'],
    display: 'flex',
    alignItems: 'center',
  },
  shieldIcon: {
    marginBottom: '1px',
    marginTop: '1px',
    marginRight: spacing['4'],
  },
  text: {
    //
  },
  paypalSvg: {
    marginLeft: 3.5,
    height: '14px',
  },
  stripeSvg: {
    marginLeft: 3.5,
    marginTop: 2,
    height: '14px',
  },
  trialInfos: {
    marginTop: spacing['16'],
    marginBottom: spacing['16'],
  },
}))
