import { StripeElementLocale } from '@stripe/stripe-js'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/pt'
import i18n, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import dayjs, { Dayjs } from 'helpers/date'

import en from './en.json'
import fr from './fr.json'
import pt from './pt.json'

import { NODE_ENV } from 'env'
import kitEn from './kit/en.json'
import kitFr from './kit/fr.json'
import kitPt from './kit/pt.json'

export const allowedStripeLocales: StripeElementLocale[] = [
  'auto',
  'en',
  'fr',
  'pt',
]

export const resources = {
  en: { ...en, ...kitEn },
  fr: { ...fr, ...kitFr },
  pt: { ...pt, ...kitPt },
}

const options: InitOptions = {
  resources,
  fallbackLng: 'en',
  nsSeparator: '.',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      brand: 'Alohi.Admin',
    },
  },
  detection: {
    order: ['querystring', 'cookie', 'localStorage'],
  },
  debug: NODE_ENV !== 'production',
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options)
  .then(() => {
    if (!Object.keys(resources).includes(i18n.language)) {
      i18n.changeLanguage('en').catch(() => {
        // Nothing to do
      })
    }
    dayjs.locale(i18n.language)
  })
  .catch(() => undefined)

i18n.on('languageChanged', function (lng: string) {
  dayjs.locale(lng)
})

dayjs.updateLocale('en', {
  calendar: {
    sameDay: '[Today -] H:mm', // Today - 06:23
    lastDay: '[Yesterday -] H:mm', // Yesterday - 06:32
    lastWeek: 'dddd [-] H:mm', // Last Tuesday - 06:21
    sameElse: function (now: Dayjs) {
      const date = this as unknown as Dayjs
      if (date.isSame(now, 'year')) {
        return date.format('ddd[,] MMM DD [-] H:mm') // Tue, Jan 11 - 8:57
      } else {
        return date.format('MMM DD[,] YYYY [-] H:mm') // Dec 26, 2021 - 16:17
      }
    },
  },
})

dayjs.updateLocale('fr', {
  calendar: {
    sameDay: "[Aujourd'hui -] H:mm",
    lastDay: '[Hier -] H:mm',
    lastWeek: 'dddd [-] H:mm',
    sameElse: function (now: Dayjs) {
      const date = this as unknown as Dayjs
      if (date.isSame(now, 'year')) {
        return date.format('ddd DD MMM [-] H:mm')
      } else {
        return date.format('DD MMM YYYY [-] H:mm')
      }
    },
  },
})

dayjs.updateLocale('pt', {
  calendar: {
    sameDay: '[Hoje -] H:mm',
    lastDay: '[Ontem -] H:mm',
    lastWeek: 'dddd [-] H:mm',
    sameElse: function (now: Dayjs) {
      const date = this as unknown as Dayjs
      if (date.isSame(now, 'year')) {
        return date.format('ddd DD MMM [-] H:mm')
      } else {
        return date.format('DD MMM YYYY [-] H:mm')
      }
    },
  },
})

export default i18n
