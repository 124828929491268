import { SetFaxNumberCartItemInput } from 'api/gql/generated/graphql'

export interface PurchaseNumbersErrorExtensions {
  failedPurchaseNumbers: string[]
  reimbursedAmount: number
}

export interface ViewDataStore {
  selectedFaxPlanShopItemId: string | null
  selectedFirstFaxNumberCartInput: SetFaxNumberCartItemInput | undefined
  selectedSecondFaxNumberCartInput: SetFaxNumberCartItemInput | undefined
  purchaseNumbersErrorExtensions: PurchaseNumbersErrorExtensions | undefined
}

export const initialViewStore: ViewDataStore = {
  selectedFaxPlanShopItemId: null,
  selectedFirstFaxNumberCartInput: undefined,
  selectedSecondFaxNumberCartInput: undefined,
  purchaseNumbersErrorExtensions: undefined,
}

export interface ViewComputedStore {
  computed: boolean
}

export const initialViewComputedStore: ViewComputedStore = {
  computed: false,
}

export type ViewStore = ViewDataStore & ViewComputedStore

export const computeStore = (store: ViewStore): ViewComputedStore => ({
  computed: Boolean(store),
})
