import {
  Box,
  Input,
  Modal,
  spacing,
  Text,
  useFormApi,
  useInput,
  useNavigationModalApi,
  useToast,
} from '@alohi/flow-ui'
import { contactSupport } from 'api/rest/zendesk/api/contactSupport'
import { formatLocalizedNumber } from 'helpers/common'
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { VIEWS } from '../CustomHighVolumePlanModal'

const MIN = 500000
const INITIAL_VALUE = 1000000

interface CustomHighVolumeOptionsProps {
  className?: string
  isInternational: boolean
}

export const CustomHighVolumeOptions: React.FC<CustomHighVolumeOptionsProps> = ({
  className,
  isInternational,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const toast = useToast()

  const {
    user: { email, name, lastName },
  } = useCurrentUser()

  const { store, updateStep, goToStep } = useNavigationModalApi()

  const {
    store: { canSubmit, tooltip },
    checkFormValidity,
  } = useFormApi()

  const stripNumber = useCallback((formattedNumber: string | undefined) => {
    return parseInt((formattedNumber ?? '').replace(/,/g, '')) || 0
  }, [])

  const pagesChecker = (pages: string | undefined) => {
    if (!pages) {
      return `${t('common.pages')} ${t('common.required')}`
    }
    if (stripNumber(pages) < MIN) {
      return t('purchase.selectAtLeast', {
        value_1: formatLocalizedNumber({ num: MIN }),
      })
    }
  }
  const [pages, pagesInputProps] = useInput<string | undefined>({
    initialValue: formatLocalizedNumber({ num: INITIAL_VALUE }),
    validator: pagesChecker,
  })

  const handleChange = (value: string) => {
    pagesInputProps.onChange(formatLocalizedNumber({ num: stripNumber(value) }))
  }

  const handleConfirm = useCallback(async () => {
    const destinations = isInternational ? t('support.all_countries') : t('support.us_ca')

    try {
      if (!(await checkFormValidity())) return

      await contactSupport({
        body: {
          email,
          name: `${name} ${lastName}`,
          request: {
            category: 'HIGH_VOLUME',
            destinations,
            requested_pages: stripNumber(pages),
          },
        },
      })

      goToStep(VIEWS.STEP2)
    } catch {
      toast({
        description: t('common.serverError'),
        variant: 'error',
      })
    }
  }, [
    checkFormValidity,
    email,
    goToStep,
    isInternational,
    lastName,
    name,
    pages,
    stripNumber,
    t,
    toast,
  ])

  useEffect(() => {
    updateStep({
      title: t('highVolume.customPagesTitle'),
      onCancel: store.onClose,
      onConfirm: handleConfirm,
      confirmTitle: t('faxNumber.contactUsDesc'),
      isConfirmDisabled: !canSubmit,
      confirmTooltip: tooltip,
      tooltipPlacement: 'bottom',
    })
  }, [canSubmit, goToStep, handleConfirm, store.onClose, t, tooltip, updateStep])

  return (
    <Modal.Content>
      <Box className={cx(classes.base, className)}>
        <Text>{t('highVolume.customPagesDescription')}</Text>
        <Input
          className={classes.input}
          label={t('common.pages')}
          placeholder={t('common.pages')}
          value={pages}
          {...pagesInputProps}
          onChange={handleChange}
        />
      </Box>
    </Modal.Content>
  )
}
const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  input: {
    paddingTop: spacing['16'],
  },
}))
