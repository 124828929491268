import { Box, colors, spacing, Text } from '@alohi/flow-ui'
import { FaxNumberChannel, FaxPlusNumberReplacementCartItem } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { ItemPrice } from './ItemPrice'

interface FaxPlusNumberReplacementItemProps {
  className?: string
  item: FaxPlusNumberReplacementCartItem
}
export const FaxPlusNumberReplacementItem: React.FC<FaxPlusNumberReplacementItemProps> = ({
  className,
  item,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.container}>
        <Text isBold>{t('faxNumber.faxNumberChange')}</Text>
        {item.channel === FaxNumberChannel.Custom ? (
          <Text className={classes.description}>{t('faxNumber.customNumberSelection')}</Text>
        ) : null}
      </Box>
      <ItemPrice totalPrice={item.totalPrice} />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  description: {
    color: colors.neutral400,
  },
}))
