import { textToPhoneDigits } from 'helpers/common'
import { ReactNode, useCallback } from 'react'

interface HighligheterProps {
  children: ReactNode
  highlight: string
}

function Highligheter({ children, highlight }: HighligheterProps) {
  const toHighlight = useCallback((text: string, textToHighlight: string) => {
    const textWithoutSpaces = text.split('').filter((char) => char !== ' ')
    const highlightWithoutSpaces = textToHighlight.split('').filter((char) => char !== ' ')
    const arrLength = textWithoutSpaces.length

    for (let i = 0; i < arrLength; i++) {
      const char = textWithoutSpaces[i]
      if (char === highlightWithoutSpaces[0]) {
        // Found possible, slide window
        const indexes = []
        for (let ii = i, ih = 0; ii < arrLength; ii++) {
          const current = textWithoutSpaces[ii]
          if (ih === highlightWithoutSpaces.length) {
            // Complete match
            break
          }
          if (current === highlightWithoutSpaces[ih]) {
            indexes.push({
              index: ii,
              char: current,
            })
            ih++
            continue
          }
          break
        }
        if (indexes.length === highlightWithoutSpaces.length) {
          return indexes
        }
      }
    }
    return []
  }, [])

  const text = typeof children === 'string' ? children : ''
  let textToHighlight = toHighlight(text, highlight)

  if (textToHighlight.length === 0) {
    const textToDigits = textToPhoneDigits(highlight)
    textToHighlight = toHighlight(text, textToDigits)
  }

  if (textToHighlight.length) {
    const allChars = text.split('')
    const allCharsWithoutSpaces = text.split('').filter((char) => char !== ' ')
    const toRender: (ReactNode | string)[] = []
    const highlightCopy = textToHighlight.slice()
    let isHighlighting = false

    for (let i = 0, j = 0; i < allChars.length; i++, j++) {
      if (allChars[i] === ' ') {
        if (isHighlighting) {
          toRender.push(
            <span key={j + allChars[i]} style={{ background: '#E8EFFB', fontWeight: '600' }}>
              {allChars[i]}
            </span>
          )
        } else {
          toRender.push(allChars[i])
        }
        j--
        continue
      }
      if (highlightCopy.length && j === highlightCopy[0].index) {
        toRender.push(
          <span key={j} style={{ background: '#E8EFFB', fontWeight: '600' }}>
            {highlightCopy[0].char}
          </span>
        )
        highlightCopy.shift()
        isHighlighting = highlightCopy.length > 0
        continue
      }

      if (allChars[i] === allCharsWithoutSpaces[j]) {
        toRender.push(allChars[i])
        continue
      }
    }

    return toRender
  }

  return children
}

export default Highligheter
