import { ReactNode, Reducer, useReducer } from 'react'
import { RoutesContext } from './store/context'
import { RoutesAction, RoutesReducer } from './store/reducer'
import {
  RoutesStore,
  computeStore,
  initialRoutesComputedStore,
  initialRoutesStore,
} from './store/store'

interface RoutesContextProviderProps {
  children: ReactNode
  initialUpdate?: Partial<RoutesStore>
}

export function RoutesContextProvider({
  children,
  initialUpdate = {},
}: RoutesContextProviderProps) {
  const [store, dispatch] = useReducer<Reducer<RoutesStore, RoutesAction>>(
    RoutesReducer,
    {
      ...initialRoutesStore,
      ...initialRoutesComputedStore,
      ...initialUpdate,
    }
  )

  const computedStore = computeStore(store)

  return (
    <RoutesContext.Provider
      value={{
        store: {
          ...store,
          ...computedStore,
        },
        dispatch,
      }}
    >
      {children}
    </RoutesContext.Provider>
  )
}
