import { Box, capitalizeString, Chip, spacing } from '@alohi/flow-ui'
import { PlanType } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface MinimumPlanTypeRequirementChipProps {
  className?: string
  planType: PlanType
  minimumPlanTypeRequirement: PlanType
}

export const MinimumPlanTypeRequirementChip: React.FC<MinimumPlanTypeRequirementChipProps> = ({
  className,
  planType,
  minimumPlanTypeRequirement,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  if (planType !== PlanType.Basic) return
  return (
    <Box className={cx(classes.base, className)}>
      <Chip
        variant="regular"
        color={'blue'}
        label={t('purchase.productPlan', {
          value_1: capitalizeString(minimumPlanTypeRequirement.toLowerCase()),
        })}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginLeft: spacing['8'],
  },
}))
