import { Box } from '@alohi/flow-ui'
import {
  AlohiCompensationCartItem,
  AlohiCreditCartItem,
  FaxPlusExtraNumberCartItem,
  FaxPlusNumberCartItem,
  FaxPlusNumberReplacementCartItem,
  FaxPlusNumberSetupCartItem,
  FaxPlusPlanCartItem,
  Period,
  SignPlusPlanCartItem,
  SignPlusQesCartItem,
  SignPlusSmsCartItem,
} from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { testIds } from 'helpers/tests'
import { useMemo } from 'react'
import { tss } from 'tss-react'
import { AlohiCompensationItem } from './components/AlohiCompensationItem'
import { AlohiCreditItem } from './components/AlohiCreditItem'
import { FaxPlusExtraNumberItemGroup } from './components/FaxPlusExtraNumberItemGroup/FaxPlusExtraNumberItemGroup'
import { FaxPlusNumberItemGroup } from './components/FaxPlusNumberItemGroup/FaxPlusNumberItemGroup'
import { FaxPlusNumberReplacementItem } from './components/FaxPlusNumberReplacementItem'
import { FaxPlusNumberSetupItem } from './components/FaxPlusNumberSetupItem'
import { FaxPlusPlanItem } from './components/FaxPlusPlanItem'
import { SignPlusPlanItem } from './components/SignPlusPlanItem'
import { SignPlusQesItem } from './components/SignPlusQesItem'
import { SignPlusSmsItem } from './components/SignPlusSmsItem'

interface CartItemsProps {
  className?: string
}
export const CartItems: React.FC<CartItemsProps> = ({ className }) => {
  const { classes, cx } = useStyles()

  const { cart } = useCartApi()

  const faxNumberItems = useMemo(
    () =>
      cart.items
        ?.filter(
          (item): item is FaxPlusNumberCartItem => item.__typename === 'FaxPlusNumberCartItem'
        )
        .filter((item) => item.idx > 1) ?? [],
    [cart.items]
  )

  const faxExtraNumberItem = useMemo(
    () =>
      cart.items?.filter(
        (item): item is FaxPlusExtraNumberCartItem =>
          item.__typename === 'FaxPlusExtraNumberCartItem'
      ) ?? [],
    [cart.items]
  )

  const hasCompensation = useMemo(
    () => Boolean(cart.items?.find((item) => item.__typename === 'AlohiCompensationCartItem')),
    [cart.items]
  )

  return (
    <Box className={cx(classes.base, className)} dataCy={testIds.orderSummary.cartItemsContainer}>
      <FaxPlusExtraNumberItemGroup
        items={faxExtraNumberItem}
        isAnnual={faxExtraNumberItem[0]?.period === Period.Annual}
      />
      {/* Product items */}
      {cart.items?.map((item, index) => (
        <Box key={index.toString()}>
          {item.__typename === 'SignPlusPlanCartItem' ? (
            <SignPlusPlanItem item={item as SignPlusPlanCartItem} />
          ) : undefined}
          {item.__typename === 'SignPlusQesCartItem' ? (
            <SignPlusQesItem item={item as SignPlusQesCartItem} />
          ) : undefined}
          {item.__typename === 'SignPlusSmsCartItem' ? (
            <SignPlusSmsItem item={item as SignPlusSmsCartItem} />
          ) : undefined}
          {item.__typename === 'FaxPlusPlanCartItem' ? (
            <FaxPlusPlanItem item={item as FaxPlusPlanCartItem} />
          ) : undefined}
          {item.__typename === 'FaxPlusNumberReplacementCartItem' ? (
            <FaxPlusNumberReplacementItem item={item as FaxPlusNumberReplacementCartItem} />
          ) : undefined}
          {item.__typename === 'FaxPlusNumberSetupCartItem' ? (
            <FaxPlusNumberSetupItem item={item as FaxPlusNumberSetupCartItem} />
          ) : undefined}
        </Box>
      ))}
      <FaxPlusNumberItemGroup
        items={faxNumberItems}
        hasCompensation={hasCompensation}
        isAnnual={faxNumberItems[0]?.period === Period.Annual}
      />
      {/* Alohi items */}
      {cart.items?.map((item, index) => (
        <Box key={index.toString()}>
          {item.__typename === 'AlohiCompensationCartItem' ? (
            <AlohiCompensationItem item={item as AlohiCompensationCartItem} />
          ) : undefined}
          {item.__typename === 'AlohiCreditCartItem' ? (
            <AlohiCreditItem item={item as AlohiCreditCartItem} />
          ) : undefined}
        </Box>
      ))}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
