import { Dispatch, createContext, useContext } from 'react'
import { PaymentMethodAction } from './reducer'
import {
  PaymentMethodStore,
  initialPaymentMethodComputedStore,
  initialPaymentMethodStore,
} from './store'

export interface PaymentMethodContextProps {
  store: PaymentMethodStore
  dispatch: Dispatch<PaymentMethodAction>
}

export const PaymentMethodContext = createContext<PaymentMethodContextProps>({
  store: {
    ...initialPaymentMethodStore,
    ...initialPaymentMethodComputedStore,
  },
  dispatch: () => undefined,
})

export function usePaymentMethodContext() {
  const context = useContext(PaymentMethodContext)

  if (!context) {
    throw new Error('usePaymentMethodContext is ouside of the Provider')
  }

  return context
}
