import {
  AnimatedBox,
  Box,
  breakpoints,
  Button,
  colors,
  List,
  ListItem,
  radius,
  spacing,
  Text,
} from '@alohi/flow-ui'
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons'
import { IntOrInfinity, PlanType } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

const IncludedPerk = ({ children }: { children: string }) => {
  const { classes } = useStyles()

  return (
    <ListItem className={classes.item} classNames={{ icon: classes.successIcon }} icon={faCheck}>
      {children}
    </ListItem>
  )
}

const ExcludedPerk = ({ children }: { children: string }) => {
  const { classes } = useStyles()

  return (
    <ListItem className={classes.item} classNames={{ icon: classes.failIcon }} icon={faClose}>
      {children}
    </ListItem>
  )
}

interface PerksProps {
  className?: string
  requestSignature: IntOrInfinity | undefined
  planType: PlanType | undefined
  hasApi: boolean | undefined
  onComparePlans?: () => void
}

export const Perks: React.FC<PerksProps> = ({
  className,
  requestSignature,
  planType,
  hasApi,
  onComparePlans,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const EIDAS = 'eIDAS/ZertES'
  const HIPAA = 'HIPAA'

  if (!planType) return
  return (
    <AnimatedBox
      className={cx(classes.base, className)}
      key={planType}
      transition={{
        delay: 0.05,
        duration: 0.5,
      }}
    >
      <Box className={classes.header}>
        <Text>{t('perks.whatsIncluded')}</Text>
        {onComparePlans ? (
          <>
            <Box className={classes.filler} />
            <Button variant="text" onClick={onComparePlans}>
              <Text>{t('faxPlans.seeAllPlans')}</Text>
            </Button>
          </>
        ) : undefined}
      </Box>
      <Box className={classes.content}>
        {hasApi
          ? {
              BASIC: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>
                        {t('perks.signaturesPerMonth', {
                          value_1: requestSignature?.value ?? 1,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t('perks.templates', { value_1: 5 })}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.notificationAndReminders')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.temperProofAuditTrail')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('signFeatures.cloudStorageIntegration')}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <ExcludedPerk>{t('signFeatures.customBranding')}</ExcludedPerk>
                      <ExcludedPerk>{t('signFeatures.smsAuthentication')}</ExcludedPerk>
                      <ExcludedPerk>{HIPAA}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              PREMIUM: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>
                        {t('perks.signaturesPerMonth', {
                          value_1: requestSignature?.value ?? 1,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t('perks.templates', { value_1: 10 })}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.notificationAndReminders')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.temperProofAuditTrail')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('signFeatures.cloudStorageIntegration')}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.customBranding')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.smsAuthentication')}</IncludedPerk>
                      <ExcludedPerk>{HIPAA}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              BUSINESS: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>
                        {t('perks.signaturesPerMonth', {
                          value_1: requestSignature?.value ?? 1,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t('perks.unlimitedTemplates')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.notificationAndReminders')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.temperProofAuditTrail')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('signFeatures.cloudStorageIntegration')}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.customBranding')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.smsAuthentication')}</IncludedPerk>
                      <ExcludedPerk>{HIPAA}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              ENTERPRISE: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>
                        {t('perks.signaturesPerMonth', {
                          value_1: requestSignature?.value ?? 1,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t('perks.unlimitedTemplates')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.notificationAndReminders')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.temperProofAuditTrail')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('signFeatures.cloudStorageIntegration')}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.customBranding')}</IncludedPerk>
                      <IncludedPerk>{t('signFeatures.smsAuthentication')}</IncludedPerk>
                      <IncludedPerk>{HIPAA}</IncludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
            }[planType]
          : {
              BASIC: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.oneUser')}</IncludedPerk>
                      <IncludedPerk>
                        {t('perks.signaturesPerMonth', {
                          value_1: requestSignature?.value ?? 1,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t('perks.templates', { value_1: 5 })}</IncludedPerk>
                      <IncludedPerk>{t('perks.realTimeAuditTrail')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.mobileApp')}</IncludedPerk>
                      <ExcludedPerk>{t('perks.folders')}</ExcludedPerk>
                      <ExcludedPerk>{t('perks.smsAuthentication', { value_1: 5 })}</ExcludedPerk>
                      <ExcludedPerk>{t('perks.ips')}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              PREMIUM: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.upToFiveUsers')}</IncludedPerk>
                      <IncludedPerk>{t('perks.unlimitedSignatures')}</IncludedPerk>
                      <IncludedPerk>{t('perks.templates', { value_1: 10 })}</IncludedPerk>
                      <IncludedPerk>{t('perks.smsAuthentication')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.ips')}</IncludedPerk>
                      <ExcludedPerk>{t('perks.zapierIntegration')}</ExcludedPerk>
                      <ExcludedPerk>{t('perks.documentSharing')}</ExcludedPerk>
                      <ExcludedPerk>{t('perks.sso')}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              BUSINESS: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.multipleUsers')}</IncludedPerk>
                      <IncludedPerk>{t('perks.unlimitedSignatures')}</IncludedPerk>
                      <IncludedPerk>{t('perks.unlimitedTemplates')}</IncludedPerk>
                      <IncludedPerk>{t('perks.zapierIntegration')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.documentSharing')}</IncludedPerk>
                      <ExcludedPerk>{t('perks.sso')}</ExcludedPerk>
                      <ExcludedPerk>{t('perks.prioritySupport')}</ExcludedPerk>
                      <ExcludedPerk>{t('perks.dataResidency')}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              ENTERPRISE: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.multipleUsers')}</IncludedPerk>
                      <IncludedPerk>{t('perks.unlimitedSignatures')}</IncludedPerk>
                      <IncludedPerk>{t('perks.unlimitedTemplates')}</IncludedPerk>
                      <IncludedPerk>{t('perks.sso')}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List listStyleType="none">
                      <IncludedPerk>{t('perks.dataResidency')}</IncludedPerk>
                      <IncludedPerk>{t('perks.bulkSend')}</IncludedPerk>
                      <IncludedPerk>{t('perks.prioritySupport')}</IncludedPerk>
                      <IncludedPerk>{t('perks.asc')}</IncludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
            }[planType]}
      </Box>
    </AnimatedBox>
  )
}

const useStyles = tss.create(() => ({
  base: {
    borderRadius: radius['8'],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
  },
  perksContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  sectionLeft: {
    //
  },
  sectionRight: {
    marginLeft: spacing['24'],
    [breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  successIcon: {
    color: colors.blue400,
    marginRight: spacing['8'],
  },
  failIcon: {
    color: colors.red700,
    marginRight: spacing['8'],
  },
  item: {
    marginTop: spacing['8'],
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '46px',
    background: colors.neutral100,
    borderRadius: radius['8'],
    padding: spacing['16'],
  },
  filler: {
    flex: 1,
  },
  content: {
    padding: spacing['16'],
    paddingTop: spacing['8'],
  },
}))
