import {
  Box,
  Message,
  Modal,
  spacing,
  Text,
  useNavigationModalApi,
  useOnMount,
} from '@alohi/flow-ui'
import {
  PaymentErrorExtensions,
  paypalPaymentError,
  stripePaymentError,
} from 'helpers/payment'
import { tss } from 'tss-react'

interface ErrorContentProps {
  className?: string
  paymentErrorExtensions?: PaymentErrorExtensions
  onCancel?: () => void
  onConfirm?: () => void
  confirmTitle?: string
  title: string
  description: string
}

export const ErrorContent: React.FC<ErrorContentProps> = ({
  className,
  paymentErrorExtensions,
  onCancel,
  onConfirm,
  confirmTitle,
  title,
  description,
}) => {
  const { classes, cx } = useStyles()

  const { updateStep, closeModal } = useNavigationModalApi()

  useOnMount(() => {
    updateStep({
      title,
      confirmTitle,
      onCancel,
      onClose: closeModal,
      onConfirm,
    })
  })

  return (
    <Modal.Content>
      <Box className={cx(classes.base, className)}>
        {
          <>
            <Text className={classes.description} variant="body">
              {description}
            </Text>
            <Message className={classes.messageContainer} variant="error">
              <Text className={classes.messageText1} variant="body">
                {paymentErrorExtensions?.BRAINTREE_ERROR_CODE
                  ? paypalPaymentError({
                      errorCode: paymentErrorExtensions.BRAINTREE_ERROR_CODE,
                    })
                  : stripePaymentError({
                      errorCode: paymentErrorExtensions?.STRIPE_ERROR_CODE,
                      declineCode: paymentErrorExtensions?.STRIPE_DECLINE_CODE,
                    })}
              </Text>
            </Message>
          </>
        }
      </Box>
    </Modal.Content>
  )
}
const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  description: {
    width: '100%',
  },
  messageContainer: {
    marginTop: spacing['16'],
    width: '100%',
  },
  messageText1: {
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
}))

export default ErrorContent
