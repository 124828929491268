import { useOnMount } from '@alohi/flow-ui'
import { useSession } from '../useSession/useSession'

export const useInitApp = () => {
  const { refreshToken } = useSession()

  useOnMount(() => {
    refreshToken()
  })
}
