import {
  Box,
  formatNumber,
  parseNumber,
  radius,
  spacing,
  SvgCountryFlag,
  Text,
} from '@alohi/flow-ui'
import { useMemo } from 'react'
import { tss } from 'tss-react'

export interface FaxNumberProps {
  className?: string
  formattedNumber: string
}

export const FaxNumber: React.FC<FaxNumberProps> = ({ className, formattedNumber }) => {
  const { classes, cx } = useStyles()

  const iso: string | undefined = useMemo(
    () => parseNumber(formattedNumber),
    [formattedNumber]
  )?.country

  return (
    <Box className={cx(classes.base, className)}>
      {iso ? (
        <SvgCountryFlag className={classes.flag} classNames={{ svg: classes.svg }} code={iso} />
      ) : null}
      <Text>{formatNumber(formattedNumber, 'INTERNATIONAL')}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  flag: {
    marginRight: spacing['8'],
    width: '16px',
    height: '16px',
  },
  svg: {
    borderRadius: radius['max'],
  },
}))
