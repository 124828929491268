import {
  Box,
  colors,
  colorWithAlpha,
  CountryCode,
  Message,
  radius,
  spacing,
  Text,
  useDependencyChange,
} from '@alohi/flow-ui'
import {
  FaxPlusExtraNumberCartItem,
  FaxPlusNumberArea,
  FaxPlusNumberCartItem,
  SetFaxNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { NumberItem } from '../NumberItem/NumberItem'

interface NumbersListProps {
  className?: string
  country: CountryCode
  numberCartInputs: SetFaxNumberCartItemInput[]
  numberCartItems: (FaxPlusExtraNumberCartItem | FaxPlusNumberCartItem)[]
  supportedAreas: FaxPlusNumberArea[]
  isLoading: boolean
  onCustomNumberSelect: (params: { index: number; number: string | null }) => Promise<void>
}

export const NumbersList: React.FC<NumbersListProps> = ({
  className,
  country,
  numberCartInputs,
  numberCartItems,
  supportedAreas,
  isLoading,
  onCustomNumberSelect,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const lastRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const topShadowRef = useRef<HTMLDivElement>(null)
  const bottomShadowRef = useRef<HTMLDivElement>(null)

  const hasNotAvailableItem = Boolean(
    numberCartItems.find((numberCartItem) => Boolean(numberCartItem.notAvailable))
  )
  const resolvedCountry = hasNotAvailableItem ? 'US' : country

  const handleScroll = () => {
    if (listRef.current && topShadowRef.current && bottomShadowRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current

      // Show top shadow if the list is scrolled down
      topShadowRef.current.style.opacity = scrollTop > 0 ? '1' : '0'

      // Show bottom shadow if the list is not scrolled to the bottom
      const isAtBottom = scrollTop + clientHeight >= scrollHeight
      bottomShadowRef.current.style.opacity = isAtBottom ? '0' : '1'
    }
  }

  useEffect(() => {
    const listElement = listRef.current
    listElement?.addEventListener('scroll', handleScroll)
    return () => {
      listElement?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useDependencyChange(() => {
    lastRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }, [numberCartItems.length])

  return (
    <Box className={cx(classes.base, className)}>
      <Box className={classes.listContainer}>
        <Box className={classes.topShadow} ref={topShadowRef} />
        <Box className={classes.list} ref={listRef}>
          {numberCartInputs.map((_, index) => (
            <NumberItem
              key={index.toString()}
              ref={index === numberCartInputs.length - 1 ? lastRef : undefined}
              index={index}
              isFirstIndex={index === 0}
              isLastIndex={index === numberCartInputs.length - 1}
              country={resolvedCountry}
              numberCartInput={numberCartInputs[index]}
              numberCartItem={numberCartItems[index]}
              supportedAreas={supportedAreas}
              isLoading={isLoading}
              onCustomNumberSelect={(number) =>
                onCustomNumberSelect({
                  index,
                  number,
                })
              }
            />
          ))}
        </Box>
        <Box className={classes.bottomShadow} ref={bottomShadowRef} />
      </Box>
      {!isLoading && hasNotAvailableItem ? (
        <Message className={classes.message} variant="info" noIcon>
          <Text>{t('faxNumber.someNumberHaveBeenAdjusted')}</Text>
        </Message>
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    marginTop: spacing['24'],
  },
  listContainer: {
    position: 'relative',
    borderRadius: radius['8'],
  },
  list: {
    overflow: 'scroll',
    maxHeight: 5 * 40,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
    borderRadius: radius['8'],
  },
  topShadow: {
    borderRadius: `calc(${radius['8']} - 1px)`,
    top: 1,
    left: 1,
    right: 1,
    position: 'absolute',
    height: '20px',
    background: `linear-gradient(to bottom, ${colorWithAlpha(colors.neutral300, 20)}, ${colorWithAlpha(colors.neutral0, 0)})`,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },
  bottomShadow: {
    borderRadius: `calc(${radius['8']} - 1px)`,
    bottom: 1,
    left: 1,
    right: 1,
    position: 'absolute',
    height: '20px',
    background: `linear-gradient(to bottom, ${colorWithAlpha(colors.neutral0, 0)}, ${colorWithAlpha(colors.neutral300, 20)})`,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },
  message: {
    marginTop: spacing['24'],
  },
}))
