import { Box, Checkbox, colors, Divider, spacing, Text } from '@alohi/flow-ui'

import { useCartApi } from 'contexts/cart/api'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface AvailableCreditProps {
  className?: string
}
export const AvailableCredit: React.FC<AvailableCreditProps> = ({ className }) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const { cart, setUseAvailableCredit } = useCartApi()

  const {
    customer: {
      credit: { currency, amount: currentCredit },
    },
  } = useCustomer()

  const creditUsed = cart.creditUsed ?? 0
  const isChecked = cart.creditUsed != null
  const creditUsedLabel = `${creditUsed > 0 ? '-' : ''}${formatCurrency({ amount: creditUsed, currency })}`

  if (
    !currentCredit ||
    currentCredit <= 0 ||
    cart.processingInfo?.__typename === 'CartScheduled' ||
    cart.processingInfo?.__typename === 'CartTrial' ||
    (cart.total <= 0 && !isChecked)
  ) {
    return null
  }

  return (
    <>
      <Box className={cx(classes.base, className)}>
        <Checkbox
          isChecked={isChecked}
          className={classes.checkbox}
          onCheck={setUseAvailableCredit}
        >
          <Box className={classes.container}>
            <Text>{t('purchase.useAvailableCredit')}</Text>
            {isChecked ? (
              <Box className={classes.creditContainerChecked}>
                <Text className={classes.usedCredits} isBold>
                  {creditUsedLabel}
                </Text>
              </Box>
            ) : (
              <Text className={classes.credits}>
                {formatCurrency({ amount: currentCredit, currency })}
              </Text>
            )}
          </Box>
        </Checkbox>
      </Box>
      <Divider className={classes.divider} />
    </>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 0,
    padding: 0,
    paddingTop: spacing['4'],
    paddingBottom: spacing['4'],
  },
  checkbox: {
    display: 'flex',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  credits: {
    color: colors.neutral400,
    marginLeft: spacing['8'],
  },
  divider: {
    marginTop: spacing['16'],
    marginBottom: spacing['16'],
  },
  creditContainerChecked: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  usedCredits: {
    color: colors.green500,
  },
}))
