import {
  AnimatedBox,
  FormContextProvider,
  LogoFrame,
  SectionDivider,
  spacing,
  StepDivider,
  Text,
  useOnMount,
} from '@alohi/flow-ui'
import {
  InitFlowSignUpgradeDocument,
  PlanType,
  SignPlanPurchaseFlowType,
} from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { EffectiveDateSection } from 'components/common/EffectiveDateSection'
import { FlowLayout } from 'components/common/FlowLayout/FlowLayout/FlowLayout'
import { PaymentMethodSection } from 'components/common/PaymentMethodSection/PaymentMethodSection'
import { SignSeatsSection } from 'components/sign/SignSeatsSection'
import { useCartApi } from 'contexts/cart/api'
import { PRODUCTS } from 'helpers/common'
import { verifyQueryParam } from 'helpers/urls'
import { useActiveSignSubscription } from 'hooks/useActiveSubscription/hooks/useActiveSignSubscription'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useInitFlow } from 'hooks/useInitFlow/useInitFlow'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { CustomPlan } from './components/CustomPlan/CustomPlan'
import { SignPlanSelectSection } from './components/SignPlanSelectSection/SignPlanSelectSection'
import { ViewContextProvider } from './context/ViewContextProvider'
import { useViewApi } from './context/api'
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics/useGoogleAnalytics'

export const SignPlanUpgrade: React.FC = () => {
  const {
    queryParams: {
      signPlan: { planId },
    },
  } = useRoutesApi()

  const { isFlowReady } = useInitFlow(InitFlowSignUpgradeDocument, {
    extraIds: planId ? [planId] : [],
  })

  if (!isFlowReady) return

  return (
    <FormContextProvider autoScroll>
      <ViewContextProvider>
        <SignPlanUpgradeContent />
      </ViewContextProvider>
    </FormContextProvider>
  )
}

const SignPlanUpgradeContent: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { gtmCartInit } = useGoogleAnalytics()

  const {
    queryParams: { signPlan },
  } = useRoutesApi()
  const queryParamPlanId = signPlan.planId
  const queryParamSeats = signPlan.seats
  const queryParamTrial = signPlan.trial
  const queryParamPlanType = signPlan.planType
  const queryParamPeriod = signPlan.period
  const queryParamApi = signPlan.api
  const queryParamShowAllPlans = signPlan.showAllPlans
  const queryParamDiscountCode = signPlan.discountCode

  const {
    store: { selectedShopItem },
    setCart,
    submit,
    updateStore,
  } = useViewApi()

  const {
    customer: {
      licenseUse: { sign: signUsedLicences },
    },
  } = useCustomer()

  const {
    cart,
    computedItems: { signPlanCartItem },
  } = useCartApi()

  const { activeSignSubscription } = useActiveSignSubscription()

  const { signPlans } = useShopItems()
  const queryParamsFaxPlanPerId = signPlans.find((signPlan) => signPlan.id === queryParamPlanId)
  const queryParamsFaxApiPlan = signPlans.find((signPlan) => {
    if (signPlan.hiddenByDefault) return
    if (queryParamApi) {
      if (activeSignSubscription.planItem.hasApi) {
        return signPlan.hasApi && signPlan.default
      } else {
        return signPlan.hasApi && signPlan.period === activeSignSubscription.planItem.period
      }
    }
  })
  const queryParamsFaxPlanPerTypes = signPlans.find((signPlan) => {
    if (signPlan.hiddenByDefault) return
    return (
      verifyQueryParam(queryParamPlanType, signPlan.planType) &&
      verifyQueryParam(
        queryParamPeriod ?? activeSignSubscription.planItem.period,
        signPlan.period
      ) &&
      (!queryParamApi ||
        (queryParamApi && signPlan.hasApi && signPlan.planType !== PlanType.Enterprise))
    )
  })

  const queryParamsSignPlan =
    queryParamsFaxPlanPerId ?? queryParamsFaxPlanPerTypes ?? queryParamsFaxApiPlan

  const defaultSignPlusPlan = signPlans.find(
    (signPlan) => signPlan.__typename === 'SignPlusPlanShopItem' && signPlan.default
  )
  const requestedShopItem = useMemo(() => {
    if (queryParamsSignPlan) {
      // API subscription can't access standard plans
      const isQueryParamValid =
        !queryParamsSignPlan.notAvailable &&
        (!activeSignSubscription.planItem.hasApi ||
          (activeSignSubscription.planItem.hasApi && queryParamsSignPlan.hasApi))

      // If the query param plan is the same as the active subscription one,
      // we skip to the default plan sent by the BE (next suggested cart)
      if (isQueryParamValid) {
        return queryParamsSignPlan
      }
    }

    return defaultSignPlusPlan
  }, [defaultSignPlusPlan, activeSignSubscription.planItem.hasApi, queryParamsSignPlan])

  const requestedSeats = useMemo(() => {
    let seats = 1
    if (queryParamSeats) {
      seats = queryParamSeats
    } else {
      seats = activeSignSubscription.planItem.itemAmount
    }

    if (requestedShopItem?.hasApi) {
      // No number of seats for API plans.
      return 1
    }

    // Make sure the requested seats are within the limits of the requested plan
    if (requestedShopItem?.limits) {
      const { minAmount, maxAmount } = requestedShopItem.limits
      const min = Math.max(signUsedLicences, minAmount ?? 1)
      const max = maxAmount ?? 1

      return Math.max(min, Math.min(max, seats))
    }

    return seats
  }, [
    requestedShopItem,
    activeSignSubscription.planItem.itemAmount,
    queryParamSeats,
    signUsedLicences,
  ])

  const requestedFlow = useMemo(() => {
    let flow = SignPlanPurchaseFlowType.ChangePlan

    if (queryParamTrial && requestedShopItem?.trialAllowed) {
      flow = SignPlanPurchaseFlowType.Trial
    }

    return flow
  }, [requestedShopItem?.trialAllowed, queryParamTrial])

  const hasEffectiveDateSection = Boolean(activeSignSubscription.expiresAt)
  const hasSignSeatsSection =
    selectedShopItem?.planType !== PlanType.Basic && !selectedShopItem?.hasApi
  // Display Payment Method Section
  const hasPaymentMethodSection = !(
    !signPlanCartItem?.isTrial &&
    cart.total === 0 &&
    Boolean(cart.creditUsed)
  )

  useOnMount(async () => {
    try {
      // Create a cart with the appropriate initial plan
      if (!requestedShopItem) return

      await setCart({
        id: requestedShopItem.id,
        seats: requestedSeats,
        flow: requestedFlow,
        discountCode: queryParamDiscountCode,
      })
      updateStore({
        selectedShopItem: requestedShopItem,
      })
    } catch (error) {
      // Error caught in stores
    }
  })

  // Send event to GA
  useOnMount(() => {
    if (signPlanCartItem) {
      gtmCartInit({
        signPlan: {
          planType: signPlanCartItem.planType,
          period: signPlanCartItem.period,
          api: signPlanCartItem.hasApi,
        },
        totalPrice: cart.total,
      })
    }
  }, Boolean(signPlanCartItem?.id))

  return (
    <FlowLayout
      className={classes.base}
      header={
        <SectionDivider iconFrame={<LogoFrame variant="sign" />}>
          <Text variant="h3">
            {t('purchase.productPlan', {
              value_1: PRODUCTS.SIGN,
            })}
          </Text>
        </SectionDivider>
      }
      onProcess={submit}
    >
      <StepDivider className={classes.firstStepDivider} index={1}>
        <Text isBold>{t('purchase.yourPlan')}</Text>
      </StepDivider>
      {queryParamsSignPlan?.hiddenByDefault ? (
        <CustomPlan />
      ) : (
        <SignPlanSelectSection autoOpenPlans={queryParamShowAllPlans} />
      )}

      {hasEffectiveDateSection ? (
        <AnimatedBox>
          <StepDivider className={classes.stepDivider} index={2}>
            <Text isBold>{t('purchase.effectiveDate')}</Text>
          </StepDivider>
          <EffectiveDateSection expiresAt={activeSignSubscription.expiresAt} />
        </AnimatedBox>
      ) : null}

      {hasSignSeatsSection ? (
        <AnimatedBox>
          <StepDivider className={classes.stepDivider} index={hasEffectiveDateSection ? 3 : 2}>
            <Text isBold>{t('seats.numberOfSeats')}</Text>
          </StepDivider>
          <SignSeatsSection
            flow={SignPlanPurchaseFlowType.ChangePlan}
            minSeats={selectedShopItem?.limits?.minAmount}
            maxSeats={selectedShopItem?.limits?.maxAmount}
            onUpdate={setCart}
          />
        </AnimatedBox>
      ) : null}

      {hasPaymentMethodSection ? (
        <>
          <StepDivider
            className={classes.stepDivider}
            index={
              hasEffectiveDateSection && hasSignSeatsSection
                ? 4
                : hasEffectiveDateSection || hasSignSeatsSection
                  ? 3
                  : 2
            }
          >
            <Text isBold>{t('payment.paymentDetails')}</Text>
          </StepDivider>
          <PaymentMethodSection />
        </>
      ) : null}
    </FlowLayout>
  )
}
const useStyles = tss.create(() => ({
  base: {
    //
  },
  firstStepDivider: {
    marginBottom: spacing['24'],
  },
  stepDivider: {
    marginTop: spacing['48'],
    marginBottom: spacing['24'],
  },
}))
