import { Box, spacing, Text } from '@alohi/flow-ui'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface TooltipContentProps {
  className?: string
  hasMultipleSubsitutedNumbers: boolean
}

export const TooltipContent: React.FC<TooltipContentProps> = ({
  className,
  hasMultipleSubsitutedNumbers,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      {hasMultipleSubsitutedNumbers ? (
        <>
          <Text variant="small">{t('faxNumber.faxNumbersWereUnavailable')}</Text>
          <Text variant="small">{t('faxNumber.weProvidedDifferentNumbers')}</Text>
        </>
      ) : (
        <>
          <Text variant="small">{t('faxNumber.faxNumberWasUnavailable')}</Text>
          <Text variant="small">{t('faxNumber.weProvidedADifferentNumber')}</Text>
        </>
      )}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    padding: spacing['8'],
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '300px',
  },
}))
