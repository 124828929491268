import { CountryCode } from '@alohi/flow-ui'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useCallback } from 'react'

export const useAddresses = () => {
  const {
    customer: {
      regulatoryData: { addresses },
    },
  } = useCustomer()

  const checkIfAddressesAvailable = useCallback(
    (params: { country: CountryCode }) => {
      const countryAddresses = addresses.filter((bundle) => bundle.iso === params.country)
      return countryAddresses.length > 0
    },
    [addresses]
  )

  const extractAddresses = useCallback(
    (params: { country: CountryCode }) => {
      return addresses.filter((address) => {
        return address.iso === params.country
      })
    },
    [addresses]
  )

  return {
    addresses,
    extractAddresses,
    checkIfAddressesAvailable,
  }
}
