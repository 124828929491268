import { useBool, useToast } from '@alohi/flow-ui'
import { getFragmentData } from 'api/gql/generated'
import { CurrentUserFragmentFragmentDoc } from 'api/gql/generated/graphql'
import { useAppApi } from 'contexts/app/api'
import { getField, handleApiErrors } from 'helpers/graphql'
import { useCurrentUser } from 'hooks/useCurrentUser/useCurrentUser'
import { useGoogleTagManager } from 'hooks/useGoogleTagManager/useGoogleTagManager'
import { usePermissions } from 'hooks/usePermissions/usePermissions'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FailedAuth } from 'views/common/FailedAuth'
import { PrivateRoutes } from './components/PrivateRoutes'
import { RoutesContextProvider } from './context/RoutesContextProvider'

export const Routes: React.FC = () => (
  <RoutesContextProvider>
    <RoutesContent />
  </RoutesContextProvider>
)

export const RoutesContent: React.FC = () => {
  const { canAccessApp } = usePermissions()
  const { t } = useTranslation()
  const toast = useToast()

  const { initialize: initializeGoogleAnalytics } = useGoogleTagManager()

  const { queryCurrentUser, isReady: isCurrentUserReady } = useCurrentUser()

  const {
    store: { isUserLogged },
    updateStore: updateAppStore,
  } = useAppApi()

  const [wasUserLogged, wasUserLoggedBool] = useBool(false)

  const fetchCurrentUser = useCallback(async () => {
    try {
      const response = await queryCurrentUser()
      const currentUser = getField(response.data.currentUser)
      const { id: currentUserId } = getFragmentData(CurrentUserFragmentFragmentDoc, currentUser)

      updateAppStore({
        currentUserId,
      })

      // Start GA with the User ID
      initializeGoogleAnalytics(currentUserId)
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
          updateAppStore({
            accessToken: undefined,
            isAppReady: true,
          })
        },
      })

      // Start GA without User ID
      initializeGoogleAnalytics()
    }
  }, [initializeGoogleAnalytics, queryCurrentUser, t, toast, updateAppStore])

  useEffect(() => {
    if (isCurrentUserReady && !canAccessApp) {
      updateAppStore({
        isAppReady: true,
      })
    }
  }, [canAccessApp, isCurrentUserReady, updateAppStore])

  useEffect(() => {
    if (isUserLogged && !wasUserLogged) {
      fetchCurrentUser()
    }
    wasUserLoggedBool.set(isUserLogged)
  }, [fetchCurrentUser, isUserLogged, wasUserLogged, wasUserLoggedBool])

  return canAccessApp && isUserLogged ? <PrivateRoutes /> : <FailedAuth />
}
