import { Box, capitalizeString, colors, formatNumber, spacing, Text } from '@alohi/flow-ui'
import { FaxPlusNumberCartItem, FaxPlusPlanCartItem, Period } from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { formatDefaultDate } from 'helpers/date'
import { formatCurrency } from 'helpers/payment'
import { testIds } from 'helpers/tests'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { ItemPrice } from './ItemPrice'

interface FaxPlusPlanItemProps {
  className?: string
  item: FaxPlusPlanCartItem
}
export const FaxPlusPlanItem: React.FC<FaxPlusPlanItemProps> = ({ className, item }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const {
    cart: { items, purchaseDate },
  } = useCartApi()

  const { faxPlans } = useShopItems()

  const formattedStart = formatDefaultDate(purchaseDate.unix())
  const formattedEnd = formatDefaultDate(
    purchaseDate.add(1, item.period === Period.Monthly ? 'month' : 'year').unix()
  )
  const period = item.period === Period.Annual ? t('plans.annual') : t('plans.monthly')
  const isHighVolume = faxPlans.find((shopItem) => shopItem.id === item.shopItemId)?.highVolume
  const name = isHighVolume
    ? `Fax.Plus - ${capitalizeString(item.planType.toLocaleLowerCase())} (${period})`
    : `Fax.Plus - ${item.marketingData.name} (${period})`
  const subscriptionPeriod = `${formattedStart} - ${formattedEnd}`

  const firstRequiredNumberCartItem = items?.find(
    (item): item is FaxPlusNumberCartItem =>
      item.__typename === 'FaxPlusNumberCartItem' && item.idx === 0
  )
  const secondRequiredNumberCartItem = items?.find(
    (item): item is FaxPlusNumberCartItem =>
      item.__typename === 'FaxPlusNumberCartItem' && item.idx === 1
  )

  const formattedDestination = item.dstLimits.includes('CA')
    ? `${t('purchase.faxingDestination')}${t('common.comma')} ${t('purchase.usAndCanada')}`
    : `${t('purchase.faxingDestination')}${t('common.comma')} ${t('purchase.international')}`

  return (
    <Box className={cx(classes.base, className)} dataCy={testIds.fax.plan.cartItems.planCartItem}>
      <Box className={classes.container}>
        <Text isBold>{name}</Text>
        <ItemPrice totalPrice={item.totalPrice} />
      </Box>
      <Text className={classes.description}>{subscriptionPeriod}</Text>
      {firstRequiredNumberCartItem ? (
        <Box className={classes.subItemsContainer}>
          {isHighVolume ? (
            <Text className={classes.subItemText} isBold>
              {formattedDestination}
            </Text>
          ) : null}

          <Text isBold>
            {t('faxPlans.monthlyPages', {
              value_1: Intl.NumberFormat().format(item?.pages ?? 0),
            })}
          </Text>
          <Text className={cx(classes.description, classes.subItemText)}>
            {t('faxPlans.monthlyPagesExtraPageRate', {
              value_1: formatCurrency({ amount: item.extraPageRate, currency }),
            })}
          </Text>

          <Text
            className={secondRequiredNumberCartItem ? classes.subItemText : undefined}
            isBold
            dataCy={testIds.fax.plan.cartItems.firstRequiredNumberCartItem}
          >
            {t('faxNumber.faxNumberX', {
              value_1: formatNumber(firstRequiredNumberCartItem.number, 'INTERNATIONAL'),
            })}
          </Text>
          {secondRequiredNumberCartItem ? (
            <Text isBold dataCy={testIds.fax.plan.cartItems.secondRequiredNumberCartItem}>
              {t('faxNumber.faxNumberX', {
                value_1: formatNumber(secondRequiredNumberCartItem.number, 'INTERNATIONAL'),
              })}
            </Text>
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  description: {
    color: colors.neutral400,
  },
  subItemsContainer: {
    marginTop: spacing['16'],
    paddingLeft: spacing['16'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral200,
  },
  subItemText: {
    marginBottom: spacing['16'],
  },
}))
