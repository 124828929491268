import {
  Grid,
  GridItem,
  GridProps,
  RadioCard,
  RadioGroup,
  SvgBox,
  Text,
  spacing,
} from '@alohi/flow-ui'
import InternationalSvg from 'assets/svg/purchase/international.svg'
import UsAndCaSvg from 'assets/svg/purchase/us-and-canada.svg'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface FaxingDestinationRadioGroupProps {
  className?: string
  isInternational: boolean
  onUscaClick: () => void
  onInternationalClick: () => void
}

export const FaxingDestinationRadioGroup = ({
  className,
  isInternational,
  onUscaClick,
  onInternationalClick,
}: FaxingDestinationRadioGroupProps) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const destination = isInternational ? 'international' : 'usca'

  const templateAreas: GridProps['templateAreas'] = {
    base: '"usca" "international"', // One column
    lg: '"usca international"', // One row
  }

  const gridTemplateColumns: GridProps['gridTemplateColumns'] = {
    base: '100%', // Single column layout for small screens
    lg: '1fr 1fr', // Two equal columns for large screens
  }

  return (
    <RadioGroup className={cx(classes.group, className)} value={destination}>
      <Grid
        templateAreas={templateAreas}
        gridTemplateColumns={gridTemplateColumns}
        columnGap={spacing['16']}
        rowGap={spacing['16']}
      >
        <GridItem area={'usca'}>
          <RadioCard
            value={'usca'}
            label1={
              <>
                <SvgBox src={UsAndCaSvg} />
                <Text className={classes.text}>{t('purchase.usAndCanada')}</Text>
              </>
            }
            onClick={onUscaClick}
          />
        </GridItem>
        <GridItem area={'international'}>
          <RadioCard
            value={'international'}
            label1={
              <>
                <SvgBox src={InternationalSvg} />
                <Text className={classes.text}>{t('purchase.international')}</Text>
              </>
            }
            onClick={onInternationalClick}
          />
        </GridItem>
      </Grid>
    </RadioGroup>
  )
}

const useStyles = tss.create(() => ({
  group: {
    paddingTop: spacing[4],
    marginRight: '1px',
  },
  text: {
    marginLeft: spacing['8'],
  },
}))
