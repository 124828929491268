import { CountryCode, Select, SelectProps, useDependencyChange, useInput } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import { useAddresses } from 'hooks/useAddresses/useAddresses'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export interface AddressSelectProps {
  className?: string
  addressSid: string | undefined | null
  country: CountryCode
  isDisabled?: boolean
  onSelect: (addressSid: string | null) => void
}

export const AddressSelect: React.FC<AddressSelectProps> = ({
  className,
  addressSid,
  country,
  isDisabled,
  onSelect,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { extractAddresses } = useAddresses()
  const countryAddresses = extractAddresses({
    country,
  })

  const addressChecker = (address: string) => {
    if (!address) {
      return `${t('faxNumber.regulatorDocuments')} ${t('common.required')}`
    }
  }

  const [addressInputValue, addressInputProps] = useInput<string>({
    initialValue: addressSid ?? '',
    validator: addressChecker,
    onChange: (value) => {
      if (!value) {
        onSelect(null)
      } else if (value !== addressSid) {
        onSelect(value)
      }
    },
    useForm: false,
  })

  const items: SelectProps['items'] = useMemo(
    () =>
      countryAddresses.map(
        (countryAddress) =>
          ({
            id: countryAddress.sid,
            label: `${countryAddress.city}`,
          }) satisfies SelectProps['items'][number]
      ),
    [countryAddresses]
  )

  useDependencyChange(() => {
    if (addressSid && addressSid !== addressInputValue) {
      addressInputProps.onChange(addressSid ?? '')
    }
  }, [addressSid])

  return (
    <Select
      className={cx(classes.base, className)}
      dataCys={testIds.fax.numbers.address}
      value={addressInputValue}
      {...addressInputProps}
      label={t('faxNumber.addresses')}
      placeholder={t('faxNumber.addresses')}
      items={items}
      isDisabled={isDisabled}
      classNames={{
        list: classes.list,
      }}
      isSortingAlphabetically
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  list: {
    maxHeight: '200px',
  },
}))
