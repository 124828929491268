import { useBool, useToast } from '@alohi/flow-ui'
import { useQuery } from '@apollo/client'
import {
  FaxNumberShopItemsDocument,
  FaxNumberType,
  FaxPlusNumberShopItems,
} from 'api/gql/generated/graphql'
import { getField, handleApiErrors } from 'helpers/graphql'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface UseSearchCustomNumberProps {
  country: string
  customNumber: string
  areaCode: number | undefined
  numberType: FaxNumberType | undefined
  setHighlightNumber: (highlight: string) => void
  onShopItemChange: (shopItems: FaxPlusNumberShopItems) => void
}

function useSearchCustomNumber({
  country,
  areaCode,
  numberType,
  customNumber,
  onShopItemChange,
  setHighlightNumber,
}: UseSearchCustomNumberProps) {
  const toast = useToast()
  const { t } = useTranslation()

  const { refetch: queryFaxNumberShopItems } = useQuery(FaxNumberShopItemsDocument, {
    skip: true,
  })

  const [isLoading, isLoadingBool] = useBool(false)

  const handleSearch = useCallback(async () => {
    try {
      isLoadingBool.setTrue()

      if (country && numberType) {
        const response = await queryFaxNumberShopItems({
          iso: country,
          numberType,
          areaCode: areaCode ? `${areaCode}` : undefined,
          contains: customNumber.replace(/ /g, ''),
        })
        const faxNumberShopItems = getField(response.data.shopItems?.faxNumbers)

        setHighlightNumber(customNumber)
        onShopItemChange(faxNumberShopItems as FaxPlusNumberShopItems)
      }
    } catch (error) {
      handleApiErrors(error, {
        errors: {
          NO_NUMBERS_AVAILABLE: () => {
            toast({
              description: t('common.serverError'),
              variant: 'error',
            })
          },
          ITEM_DEPENDENCY_NOT_SATISFIED: () => {
            toast({
              description: t('common.serverError'),
              variant: 'error',
            })
          },
        },
        default: () => {
          toast({
            description: t('common.serverError'),
            variant: 'error',
          })
        },
      })
    } finally {
      isLoadingBool.setFalse()
    }
  }, [
    areaCode,
    country,
    isLoadingBool,
    numberType,
    onShopItemChange,
    setHighlightNumber,
    queryFaxNumberShopItems,
    t,
    toast,
    customNumber,
  ])

  return { isLoading, handleSearch }
}

export default useSearchCustomNumber
