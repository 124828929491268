import {
  Box,
  IncrementButton,
  NumberInput,
  spacing,
  useBool,
  useDependencyChange,
  useInput,
} from '@alohi/flow-ui'
import { SignPlanPurchaseFlowType } from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { testIds } from 'helpers/tests'
import { useActiveSignSubscription } from 'hooks/useActiveSubscription/hooks/useActiveSignSubscription'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const SIGN_SEATS_INPUT_ID = 'sign-seats'

interface SignSeatsSectionProps {
  className?: string
  flow: SignPlanPurchaseFlowType
  minSeats?: number | null
  maxSeats?: number | null
  onUpdate: (params: { id: string; seats: number; flow: SignPlanPurchaseFlowType }) => Promise<void>
}

export const SignSeatsSection: React.FC<SignSeatsSectionProps> = ({
  className,
  flow,
  minSeats,
  maxSeats,
  onUpdate,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    computedItems: { signPlanCartItem },
  } = useCartApi()

  const { activeSignSubscription } = useActiveSignSubscription()

  const {
    customer: {
      licenseUse: { sign: signUsedLicences },
    },
  } = useCustomer()

  const min = Math.max(signUsedLicences, minSeats ?? 1)
  const max = maxSeats ?? 1
  const currentCartTotalSeats = signPlanCartItem?.totalSeats.value ?? min

  const [isIncrementIsLoading, isIncrementIsLoadingBool] = useBool(false)
  const [isDecrementIsLoading, isDecrementIsLoadingBool] = useBool(false)

  const seatsChecker = useCallback(
    (seats: number | undefined) => {
      if (!seats) {
        return t('seats.atLeastOneSeat')
      }
      if (seats < min || seats > max) {
        return t('seats.selectBetween', {
          value_1: min,
          value_2: activeSignSubscription.planItem.totalSeats === max ? max - 1 : max,
        })
      }
      if (
        signPlanCartItem?.shopItemId === activeSignSubscription.planItem.shopItemId &&
        activeSignSubscription.planItem.totalSeats === seats &&
        !activeSignSubscription.planItem.isTrial
      ) {
        return t('seats.alreadyHave', {
          value_1: seats,
          count: seats,
        })
      }
    },
    [
      min,
      max,
      signPlanCartItem?.shopItemId,
      activeSignSubscription.planItem.shopItemId,
      activeSignSubscription.planItem.totalSeats,
      activeSignSubscription.planItem.isTrial,
      t,
    ]
  )

  const handleBlur = () => {
    if (!seats) return
    updatePlan(seats)
  }

  const [seats, seatsInputProps] = useInput<number | undefined>({
    id: SIGN_SEATS_INPUT_ID,
    initialValue: signPlanCartItem?.totalSeats.value ?? 0,
    validator: seatsChecker,
    onBlur: handleBlur,
    isLazyLoaded: true,
  })
  const inputSeats = seats ?? 0

  const updatePlan = async (seats: number) => {
    try {
      if (
        !signPlanCartItem ||
        !seats ||
        seats < min ||
        seats > max ||
        currentCartTotalSeats === seats
      ) {
        throw new Error('Bad input')
      }

      await onUpdate({
        id: signPlanCartItem.shopItemId,
        seats,
        flow,
      })
    } catch (error) {
      // Error caught in stores
    } finally {
      isDecrementIsLoadingBool.setFalse()
      isIncrementIsLoadingBool.setFalse()
    }
  }

  const handleIncrement = () => {
    isIncrementIsLoadingBool.setTrue()

    let newValue = Math.min(max, Math.max(min, inputSeats + 1))
    if (
      activeSignSubscription.planItem.totalSeats === newValue &&
      flow === SignPlanPurchaseFlowType.ChangeSeats
    ) {
      newValue += 1
    }

    seatsInputProps.onChange(newValue, {
      forceCheck: true,
    })
    updatePlan(newValue)
  }

  const handleDecrement = () => {
    isDecrementIsLoadingBool.setTrue()

    let newValue = Math.min(max, Math.max(min, inputSeats - 1))
    if (
      activeSignSubscription.planItem.totalSeats === newValue &&
      flow === SignPlanPurchaseFlowType.ChangeSeats
    ) {
      newValue -= 1
    }

    seatsInputProps.onChange(newValue, {
      forceCheck: true,
    })
    updatePlan(newValue)
  }

  useDependencyChange(() => {
    seatsInputProps.onChange(currentCartTotalSeats)
  }, [currentCartTotalSeats])

  return (
    <Box className={cx(classes.base, className)}>
      <NumberInput
        value={seats}
        {...seatsInputProps}
        id="seats"
        placeholder={`${min}`}
        className={classes.input}
        label={t('seats.totalSeats')}
        dataCy={testIds.sign.seats.input}
        alignedContainer={
          <Box className={classes.buttonContainer}>
            <IncrementButton
              variant="minus"
              className={classes.button}
              onClick={handleDecrement}
              isLoading={isDecrementIsLoading}
              isDisabled={inputSeats <= min}
              dataCy={testIds.sign.seats.increment}
            />
            <IncrementButton
              variant="plus"
              className={classes.button}
              onClick={handleIncrement}
              isLoading={isIncrementIsLoading}
              isDisabled={
                inputSeats >= max ||
                (activeSignSubscription.planItem.totalSeats === max && inputSeats === max - 1)
              }
              dataCy={testIds.sign.seats.decrement}
            />
          </Box>
        }
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    '& input': {
      width: '211px',
    },
    marginBottom: 0,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing['8'],
  },
  button: {
    marginLeft: spacing['8'],
  },
}))
