import { Box } from '@alohi/flow-ui'
import { PlanType, SetFaxNumberCartItemInput } from 'api/gql/generated/graphql'
import { PriceType } from 'components/fax/FaxNumberSection/components/NumbersList/components/UniqueNumber/UniqueNumber'
import { FaxNumberSection } from 'components/fax/FaxNumberSection/FaxNumberSection'
import { useCartApi } from 'contexts/cart/api'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useActiveFaxSubscription } from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { tss } from 'tss-react'
import { useViewApi } from 'views/fax/AddNumber/context/api'

interface AddExtraNumberSectionProps {
  className?: string
}

export const AddExtraNumberSection: React.FC<AddExtraNumberSectionProps> = ({ className }) => {
  const { classes, cx } = useStyles()

  const { supportedCountries } = useFaxSupportedCountries()

  const { activeFaxSubscription } = useActiveFaxSubscription()

  const {
    computedItems: { faxExtraNumberCartItems },
  } = useCartApi()

  const {
    store: { selectedNumberCartInputs },
    setCart,
  } = useViewApi()

  const handleUpdateQuantity = async (quantity: number) => {
    try {
      await setCart(
        Array.from({ length: quantity }).map((_, index) => ({
          ...{
            areaCode: selectedNumberCartInputs[0].areaCode,
            bundleSid: selectedNumberCartInputs[0].bundleSid,
            addressSid: selectedNumberCartInputs[0].addressSid,
            iso: selectedNumberCartInputs[0].iso,
            numberType: selectedNumberCartInputs[0].numberType,
            period: selectedNumberCartInputs[0].period,
          },
          ...selectedNumberCartInputs[index],
        }))
      )
    } catch {
      // Error caught in setCart
    }
  }

  const handleUpdateCart = async (params: {
    index?: number
    cartInput?: Maybe<Partial<SetFaxNumberCartItemInput>>
  }) => {
    try {
      await setCart(
        Array.from({ length: selectedNumberCartInputs.length }).map((_, index) => ({
          ...selectedNumberCartInputs[index],
          ...(params.index === undefined || index === params.index ? params.cartInput : undefined),
        }))
      )
    } catch {
      // Error caught in setCart
    }
  }

  return (
    <Box className={cx(classes.base, className)}>
      <FaxNumberSection
        supportedCountries={supportedCountries}
        numberCartInputs={selectedNumberCartInputs}
        numberCartItems={faxExtraNumberCartItems ?? []}
        onUpdateCart={handleUpdateCart}
        onUpdateQuantity={handleUpdateQuantity}
        planType={activeFaxSubscription.computed.planItem.type ?? PlanType.Basic}
        priceType={PriceType.setup}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
