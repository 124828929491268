import { useCallback } from 'react'

import {
  FaxPlusNumberOrderItem,
  SetFaxReplacementNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { SuccessModalContentProps } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'
import { FaxSubstitutedNumberMessage } from 'components/fax/FaxSubstitutedNumberMessage/FaxSubstitutedNumberMessage'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { handleApiErrors } from 'helpers/graphql'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useTranslation } from 'react-i18next'
import { useViewContext } from './store/context'
import { ViewDataStore } from './store/store'

export function useViewApi() {
  const { store, dispatch } = useViewContext()
  const { t } = useTranslation()

  const { queryCredit } = useCustomer()

  const { cart, processCart: processCartApi, setCart: setCartApi } = useCartApi()

  const {
    store: { selectedPaymentMethodId },
  } = usePaymentMethodApi()

  const updateStore = useCallback(
    (payload: Partial<ViewDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const setCart = useCallback(
    async (params: {
      faxReplacementNumber: SetFaxReplacementNumberCartItemInput
      useCredit?: boolean
    }) => {
      try {
        updateStore({
          numberReplacementCartInput: params.faxReplacementNumber,
        })

        await setCartApi({
          schedule: cart.isScheduled,
          useCredit: params.useCredit ?? cart.creditUsed != null,
          items: [
            {
              faxReplacementNumber: params.faxReplacementNumber,
            },
          ],
        })
      } catch {
        // Errors caught in setCartApi
      }
    },
    [cart.creditUsed, cart.isScheduled, setCartApi, updateStore]
  )

  const submit = useCallback(async (): Promise<SuccessModalContentProps | undefined> => {
    try {
      const response = await processCartApi({
        paymentMethodId: selectedPaymentMethodId,
      })

      // Standard Cart
      if (response?.__typename === 'ArchivedOrder' || response?.__typename === 'ActiveOrder') {
        const newNumberOrderItem = response.orderItems.find(
          (item): item is FaxPlusNumberOrderItem => item?.__typename === 'FaxPlusNumberOrderItem'
        )

        if (!newNumberOrderItem) {
          throw new Error('No newNumberOrderItem')
        }

        return {
          description: t('faxNumber.numberSuccessfullyReplaced'),
          customContainer: newNumberOrderItem.requestedNumber ? (
            <FaxSubstitutedNumberMessage numbers={[newNumberOrderItem]} />
          ) : undefined,
        }
      }

      throw new Error('No valid response')
    } catch (error) {
      handleApiErrors(error, {
        errors: {
          NUMBER_PURCHASE_FAILED: async ({
            NUMBERS_FAILED_TO_PURCHASE,
            AMOUNT_REIMBURSED_TO_CREDIT,
          }) => {
            updateStore({
              purchaseNumbersErrorExtensions: {
                failedPurchaseNumbers: NUMBERS_FAILED_TO_PURCHASE as string[],
                reimbursedAmount: AMOUNT_REIMBURSED_TO_CREDIT as number,
              },
            })
            await queryCredit()
          },
        },
        default: () => {
          // Handled in processCartApi
        },
      })
      throw error
    }
  }, [processCartApi, queryCredit, selectedPaymentMethodId, t, updateStore])

  return {
    store,
    updateStore,
    setCart,
    submit,
  }
}
