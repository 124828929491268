import { Box, Button, colors, radius, shadows, spacing, Text } from '@alohi/flow-ui'
import { Error, FaxPlusPlanShopItem, Period } from 'api/gql/generated/graphql'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { useCartApi } from 'contexts/cart/api'
import { formatCurrency } from 'helpers/payment'
import { urls } from 'helpers/urls'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from 'views/fax/FaxPlanUpgrade/context/api'
import { FaxFeatures } from './components/FaxFeatures'
import { FaxQuantities } from './components/FaxQuantities'

interface PlanProps {
  className?: string
  item: FaxPlusPlanShopItem
  onSelect: () => void
}

export const Plan: React.FC<PlanProps> = ({ className, item, onSelect }) => {
  const { t } = useTranslation()
  const { navigate } = useRoutesApi()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const { updateStore } = useViewApi()
  const {
    activeSignSubscription: {
      planItem: { shopItemId, totalSeats },
    },
  } = useActiveSubscriptions()

  const {
    computedItems: { faxPlanCartItem },
  } = useCartApi()

  const isCurrentPlan = item.id === shopItemId
  const isCartPlan = item.id === faxPlanCartItem?.shopItemId

  const { classes, cx } = useStyles({ isCurrentPlan, isCartPlan })

  const annualPriceDescription = (() => {
    if (item.period === Period.Monthly) {
      return ''
    } else {
      return t('purchase.billedAnnually', {
        value_1: formatCurrency({ amount: item.unitPrice, currency }),
      })
    }
  })()

  const buttonText = (() => {
    if (item.notAvailable === Error.CantPurchaseSameItem) {
      return t('purchase.currentPlan')
    }
    if (isCurrentPlan) {
      return `${t('seats.purchaseTitle')} (${t('common.current')} ${totalSeats})`
    }
    if (isCartPlan) {
      return t('common.continue')
    }
    return t('plans.selectPlan')
  })()

  const handleSelectPlan = useCallback(async () => {
    if (isCurrentPlan) {
      navigate(urls.sign.seats + window.location.search)
      return
    }

    try {
      updateStore({
        selectedFaxPlanShopItemId: item.id,
      })
      onSelect()
    } catch (error) {
      // Error caught in stores
    }
  }, [isCurrentPlan, navigate, updateStore, item, onSelect])

  return (
    <Box className={cx(classes.base, className)}>
      <Text className={classes.title}>{item.marketingData.name}</Text>
      <Text className={classes.price}>
        {formatCurrency({
          amount: item.marketingData.price,
          currency,
        })}
      </Text>
      <Text className={classes.priceDescription}>{t('purchase.perMonth')}</Text>
      <Text className={classes.priceDescription}>{annualPriceDescription}</Text>
      <Button
        size="small"
        className={classes.button}
        onClick={handleSelectPlan}
        isDisabled={Boolean(item.notAvailable)}
        variant={isCurrentPlan ? 'tertiary' : 'primary'}
        classNames={{
          icon: classes.selectPlanButtonIcon,
        }}
      >
        {buttonText}
      </Button>
      <FaxQuantities
        planType={item.planType}
        extraPageRate={item.extraPageRate}
        pages={item.pages}
      />
      <FaxFeatures planType={item.planType} />
      {isCurrentPlan || isCartPlan ? (
        <Box className={classes.topLabelContainer}>
          <Text className={classes.topLabelText} isBold>
            {isCartPlan ? t('plans.selectedPlan') : t('purchase.currentPlan')}
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}

const useStyles = tss
  .withParams<{
    isCurrentPlan: boolean
    isCartPlan: boolean
  }>()
  .create(({ isCurrentPlan, isCartPlan }) => ({
    base: {
      paddingTop: spacing['32'],
      paddingBottom: spacing['32'],
      paddingLeft: spacing['32'],
      paddingRight: spacing['32'],
      margin: spacing['16'],
      marginTop: spacing['16'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      borderRadius: spacing['16'],
      width: '270px',
      boxShadow: shadows.surface,
      '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: spacing['16'],
        borderStyle: 'solid',
        borderWidth: isCurrentPlan || isCartPlan ? 2 : 1,
        borderColor: isCartPlan
          ? colors.blue400
          : isCurrentPlan
            ? colors.neutral1000
            : colors.neutral200,
        transition: 'border 0.3s ease',
        pointerEvents: 'none',
      },
    },
    title: {
      fontSize: '23px',
      lineHeight: '30px',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    price: {
      marginTop: spacing['32'],
      fontSize: '23px',
      lineHeight: '30px',
    },
    priceDescription: {
      marginTop: spacing['4'],
      whiteSpace: 'pre',
      color: colors.neutral700,
    },
    button: {
      marginTop: spacing['24'],
      width: '193px',
    },
    topLabelContainer: {
      background: isCartPlan ? colors.blue400 : colors.neutral1000,
      transition: 'background 0.3s ease',
      position: 'absolute',
      top: '-11.5px',
      height: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: spacing['24'],
      paddingRight: spacing['24'],
      paddingTop: spacing['4'],
      paddingBottom: spacing['4'],
      borderRadius: radius['max'],
    },
    topLabelText: {
      color: colors.neutral0,
    },
    selectPlanButtonIcon: {
      marginRight: spacing['4'],
    },
  }))
