import { useCallback, useMemo } from 'react'

import { Period, PlanType } from 'api/gql/generated/graphql'
import { getAppType } from 'helpers/common'
import { sanitizeUrl, websiteUrls } from 'helpers/urls'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRoutesContext } from './store/context'
import { RoutesDataStore } from './store/store'

export enum SuccessRedirectToApp {
  Fax = 'fax',
  Sign = 'sign',
}

interface BaseQueryParams {
  navigateBackUrl: string | undefined
  lng: string | undefined
}

interface SignPlanQueryParams {
  planId: string | undefined
  seats: number | undefined
  trial: boolean | undefined
  planType: PlanType | undefined
  period: Period | undefined
  api: boolean | undefined
  showAllPlans: boolean | undefined
  discountCode: string | undefined
  successRedirectToApp: SuccessRedirectToApp | undefined
}

interface FaxPlanQueryParams {
  planId: string | undefined
  isHighVolume: boolean | undefined
  planType: PlanType | undefined
  period: Period | undefined
  iso: string | undefined
  showAllPlans: boolean | undefined
  discountCode: string | undefined
  successRedirectToApp: SuccessRedirectToApp | undefined
}

interface FaxChangeNumberQueryParams {
  number: string | undefined
}

interface QueryParams extends BaseQueryParams {
  signPlan: SignPlanQueryParams
  faxPlan: FaxPlanQueryParams
  faxChangeNumber: FaxChangeNumberQueryParams
}

export function useRoutesApi() {
  const [searchParams] = useSearchParams()
  const { store, dispatch } = useRoutesContext()
  const routerNavigate = useNavigate()

  const updateStore = useCallback(
    (payload: Partial<RoutesDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  function sanitizeQueryParam<T extends Record<string, string>>(
    value: string | undefined | null,
    enumType: T
  ): T[keyof T] | undefined {
    if (
      value &&
      Object.values(enumType)
        .map((value) => value.toLowerCase())
        .includes(value.toLowerCase() as T[keyof T])
    ) {
      return value.toLowerCase() as T[keyof T]
    }
    return undefined
  }

  const getQueryParam = useCallback(
    (name: string) => {
      return searchParams.get(name)?.toLowerCase()
    },
    [searchParams]
  )

  const queryParams = useMemo(() => {
    const navigateBackParam = sanitizeUrl(getQueryParam('back'))
    const lngParam = getQueryParam('lng')
    const planIdParam = getQueryParam('id')
    const seatsParam = getQueryParam('seats')
    const trialParam = getQueryParam('trial')
    const showAllPlansParam = getQueryParam('show-all-plans')
    const discoundCodeParam = getQueryParam('discount-code')
    const apiParam = getQueryParam('api')
    const highVolumeParam = getQueryParam('high-volume')
    const planTypeParam = sanitizeQueryParam(getQueryParam('plan-type'), PlanType)
    const periodParam = sanitizeQueryParam(getQueryParam('period'), Period)
    const numberParam = getQueryParam('number')
    const isoParam = getQueryParam('iso')
    const successRedirectToAppParam = sanitizeQueryParam(
      getQueryParam('success-redirect-to-app'),
      SuccessRedirectToApp
    )

    return {
      navigateBackUrl: navigateBackParam,
      lng: lngParam,
      signPlan: {
        planId: planIdParam,
        seats: seatsParam ? parseInt(seatsParam) : undefined,
        trial: trialParam ? trialParam === 'true' : undefined,
        planType: planTypeParam,
        period: periodParam,
        api: apiParam ? apiParam === 'true' : undefined,
        showAllPlans: showAllPlansParam ? showAllPlansParam === 'true' : undefined,
        discountCode: discoundCodeParam,
        successRedirectToApp: successRedirectToAppParam,
      },
      faxPlan: {
        planId: planIdParam,
        isHighVolume: highVolumeParam === 'true',
        planType: planTypeParam,
        period: periodParam,
        iso: isoParam,
        showAllPlans: showAllPlansParam ? showAllPlansParam === 'true' : undefined,
        discountCode: discoundCodeParam,
        successRedirectToApp: successRedirectToAppParam,
      },
      faxChangeNumber: {
        number: numberParam ?? undefined,
      },
    } satisfies QueryParams
  }, [getQueryParam])

  const navigate = useCallback(
    (pathname: string, options?: { hardRedirection?: boolean }) => {
      if (!options?.hardRedirection) {
        updateStore({
          history: [...store.history, window.location.pathname + window.location.search],
        })
      }
      routerNavigate(pathname)
    },
    [routerNavigate, store.history, updateStore]
  )

  const navigateBack = useCallback(
    (options?: { skipLocalRoutes: boolean }) => {
      const { navigateBackUrl } = queryParams
      if (store.previousRoute && !options?.skipLocalRoutes) {
        routerNavigate(-1)
        updateStore({
          history: store.history.slice(0, -1),
        })
      } else if (navigateBackUrl) {
        window.location.href = navigateBackUrl
      } else {
        if (getAppType() === 'sign') {
          window.location.href = websiteUrls.sign.home
        } else if (getAppType() === 'fax') {
          window.location.href = websiteUrls.fax.home
        }
      }
    },
    [queryParams, routerNavigate, store.history, store.previousRoute, updateStore]
  )

  return {
    store,
    updateStore,
    navigate,
    navigateBack,
    queryParams,
  }
}
