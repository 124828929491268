import { Box, Button, IconButton, spacing, Text } from '@alohi/flow-ui'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface RightContainerProps {
  className?: string
  onApply: () => void
  onClose: () => void
  isDisabled: boolean
}
export const RightContainer: React.FC<RightContainerProps> = ({
  className,
  onApply,
  onClose,
  isDisabled,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={cx(classes.base, className)}>
      <Button variant="text" onClick={onApply} isDisabled={isDisabled}>
        <Text className={classes.text} isBold>
          {t('common.apply')}
        </Text>
      </Button>
      <IconButton
        onClick={onClose}
        variant="small"
        classNames={{
          icon: classes.icon,
        }}
        icon={faCircleXmark}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginRight: spacing['16'],
    marginBottom: '1px',
  },
  icon: {
    width: 15,
    height: 15,
  },
}))
