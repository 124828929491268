import { useOnMount } from '@alohi/flow-ui'
import { ApmBase, init as initApm } from '@elastic/apm-rum'
import * as Sentry from '@sentry/react'
import { useAppApi } from 'contexts/app/api'

import {
  ENVIRONMENT_NAME,
  GRAPHQL_ENDPOINT,
  KIBANA_RUM_NAME,
  KIBANA_RUM_URL,
  NODE_ENV,
  SENTRY_URL,
  VERSION,
} from 'env'
import { useEffect } from 'react'

export const IS_MONITORING_ENABLED = NODE_ENV === 'production'

let apm: ApmBase | undefined = undefined

export const useMonitoring = (): {
  apm: ApmBase | undefined
} => {
  const {
    store: { currentUserId },
  } = useAppApi()

  const isProduction = ENVIRONMENT_NAME === 'production'

  useOnMount(() => {
    if (IS_MONITORING_ENABLED) {
      if (SENTRY_URL) {
        Sentry.init({
          dsn: SENTRY_URL,
          release: VERSION,
          ...(isProduction
            ? {
                integrations: [
                  Sentry.replayIntegration({
                    networkDetailAllowUrls: [GRAPHQL_ENDPOINT],
                    networkResponseHeaders: ['X-Request-Id'],
                  }),
                ],
                // Session Replay
                replaysSessionSampleRate: 0, // No need for sampling for normal sessions
                replaysOnErrorSampleRate: 1, // 100% of error sessions recorded
              }
            : undefined),
        })
      }
      if (KIBANA_RUM_URL) {
        apm = initApm({
          serviceName: KIBANA_RUM_NAME,
          serverUrl: KIBANA_RUM_URL,
          serviceVersion: VERSION,
          distributedTracingOrigins: [(GRAPHQL_ENDPOINT || '').replace('/v2/graphql', '')],
        })
      }
    }
  })

  useEffect(() => {
    if (IS_MONITORING_ENABLED && apm && currentUserId) {
      apm.setUserContext({
        id: currentUserId,
      })
    }
  }, [currentUserId])

  useEffect(() => {
    if (IS_MONITORING_ENABLED && SENTRY_URL && currentUserId) {
      Sentry.setUser({ id: currentUserId })
    }
  }, [currentUserId])

  return { apm }
}
