import { Box, Button, colors, spacing, Text, useOnMount } from '@alohi/flow-ui'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { useAppApi } from 'contexts/app/api'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const Fallback: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { navigateBack } = useRoutesApi()

  const { updateStore: updateAppStore } = useAppApi()

  useOnMount(() => {
    updateAppStore({
      isAppReady: true,
    })
  })

  return (
    <Box className={classes.base}>
      <Text className={classes.error404} variant="h2">
        {t('fallback.error404')}
      </Text>
      <Text className={classes.oops} isBold>
        {t('fallback.pageNotFound')}
      </Text>
      <Text className={classes.description}>{t('fallback.description')}</Text>
      <Button
        className={classes.backButton}
        variant="tertiary"
        size="large"
        onClick={() => navigateBack()}
      >
        {t('common.back')}
      </Button>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    background: colors.neutral0,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  error404: {
    color: colors.blue500,
  },
  oops: {
    marginTop: spacing['8'],
    fontSize: '35px',
    lineHeight: '42px',
    textAlign: 'center',
  },
  description: {
    marginTop: spacing['16'],
  },
  backButton: {
    marginTop: spacing['24'],
  },
}))
