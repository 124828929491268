import { useCallback } from 'react'

import {
  Period,
  SignPlanPurchaseFlowType,
  SignPlusPlanCartItem,
  SignPlusPlanOrderItem,
} from 'api/gql/generated/graphql'
import { SuccessRedirectToApp, useRoutesApi } from 'app/components/Routes/context/api'
import { SuccessModalContentProps } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'
import { SuccessModalFooter } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalFooter'
import { useCartApi } from 'contexts/cart/api'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { websiteUrls } from 'helpers/urls'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useTranslation } from 'react-i18next'
import { useGoogleAnalytics } from '../hooks/useGoogleAnalytics/useGoogleAnalytics'
import { useViewContext } from './store/context'
import { ViewDataStore } from './store/store'

export function useViewApi() {
  const { store, dispatch } = useViewContext()
  const { t } = useTranslation()

  const { gtmCartPurchaseSuccess, gtmCartPurchaseFailed } = useGoogleAnalytics()
  const { navigateBack, queryParams } = useRoutesApi()
  const querySuccessRedirectToApp = queryParams.signPlan.successRedirectToApp

  const {
    store: { selectedPaymentMethodId },
  } = usePaymentMethodApi()

  const {
    activeSignSubscription: {
      isFree,
      planItem: { marketingName, period },
    },
  } = useActiveSubscriptions()

  const {
    cart,
    computedItems: { signPlanCartItem },
    setCart: setCartApi,
    processCart: processCartApi,
  } = useCartApi()

  const updateStore = useCallback(
    (payload: Partial<ViewDataStore>) => {
      dispatch({
        type: 'UPDATE',
        payload: payload,
      })
    },
    [dispatch]
  )

  const submit = useCallback(async (): Promise<SuccessModalContentProps | undefined> => {
    let customFooter = undefined
    if (querySuccessRedirectToApp === SuccessRedirectToApp.Sign) {
      customFooter = (
        <SuccessModalFooter
          primaryButtonTitle={t('common.continueTo', { value_1: 'Sign.Plus' })}
          onPrimaryButtonClick={() => {
            window.location.href = websiteUrls.sign.home
          }}
          secondaryButtonTitle={t('common.returnTo', { value_1: 'Fax.Plus' })}
          onSecondaryButtonClick={navigateBack}
        />
      )
    }
    try {
      const response = await processCartApi({
        paymentMethodId: selectedPaymentMethodId,
      })

      const planPeriod = period === Period.Annual ? t('plans.annual') : t('plans.monthly')

      // Standard Cart
      if (response?.__typename === 'ArchivedOrder' || response?.__typename === 'ActiveOrder') {
        const newPlanOrderItem = response.orderItems.find(
          (item): item is SignPlusPlanOrderItem => item?.__typename === 'SignPlusPlanOrderItem'
        )
        if (!newPlanOrderItem) {
          throw new Error('No newPlanOrderItem')
        }

        const newPlan = newPlanOrderItem.marketingData.name ?? ''
        const newPlanPeriod =
          newPlanOrderItem.period === Period.Annual ? t('plans.annual') : t('plans.monthly')

        gtmCartPurchaseSuccess({
          signPlan: {
            planType: newPlanOrderItem.planType,
            period: newPlanOrderItem.period,
            api: newPlanOrderItem.hasApi,
          },
          totalPrice: cart.total,
        })

        if (isFree) {
          return {
            description: t('signPlans.freeSuccessModalDescription', {
              value_1: `${newPlan} (${newPlanPeriod})`,
            }),
            customFooter: customFooter,
          }
        }

        return {
          description: t('signPlans.successModalDescription', {
            value_1: `${marketingName} (${planPeriod})`,
            value_2: `${newPlan} (${newPlanPeriod})`,
          }),
          customFooter: customFooter,
        }
      }
      // Scheduled Cart
      else if (response?.__typename === 'Cart') {
        const newSignPlusPlanCartItem = response.items.find(
          (item): item is SignPlusPlanCartItem => item.__typename === 'SignPlusPlanCartItem'
        )
        if (!newSignPlusPlanCartItem) {
          throw new Error('No newSignPlusPlanCartItem')
        }

        const newPlan = newSignPlusPlanCartItem.marketingData.name ?? ''
        const newPlanPeriod =
          newSignPlusPlanCartItem.period === Period.Annual ? t('plans.annual') : t('plans.monthly')

        gtmCartPurchaseSuccess({
          signPlan: {
            planType: newSignPlusPlanCartItem.planType,
            period: newSignPlusPlanCartItem.period,
            api: newSignPlusPlanCartItem.hasApi,
          },
          totalPrice: cart.total,
        })

        return {
          description: t('signPlans.scheduledSuccessModalDescription', {
            value_1: `${marketingName} (${planPeriod})`,
            value_2: `${newPlan} (${newPlanPeriod})`,
          }),
          customFooter: customFooter,
        }
      }
      throw new Error('No valid response')
    } catch (error) {
      gtmCartPurchaseFailed({
        signPlan: {
          planType: signPlanCartItem?.planType,
          period: signPlanCartItem?.period,
          api: signPlanCartItem?.hasApi,
        },
        totalPrice: cart.total,
      })
      throw error
    }
  }, [
    querySuccessRedirectToApp,
    t,
    navigateBack,
    processCartApi,
    selectedPaymentMethodId,
    period,
    gtmCartPurchaseSuccess,
    cart.total,
    isFree,
    marketingName,
    gtmCartPurchaseFailed,
    signPlanCartItem?.planType,
    signPlanCartItem?.period,
    signPlanCartItem?.hasApi,
  ])

  const setCart = useCallback(
    async (params: {
      id: string
      seats: number
      flow: SignPlanPurchaseFlowType
      discountCode?: string
      hasApi?: boolean
    }) => {
      let flowType = signPlanCartItem?.isTrial ? SignPlanPurchaseFlowType.Trial : params.flow

      // API plans have no trial
      if (params.hasApi) {
        flowType = SignPlanPurchaseFlowType.ChangePlan
      }

      await setCartApi({
        schedule: cart.isScheduled,
        useCredit: cart.creditUsed != null,
        discountCode: params.discountCode,
        items: [
          {
            signPlan: {
              id: params.id,
              flowType,
              itemAmount: params.seats,
            },
          },
        ],
      })
    },
    [setCartApi, cart.isScheduled, cart.creditUsed, signPlanCartItem?.isTrial]
  )

  const setTrial = useCallback(
    async (isTrial: boolean) => {
      if (!signPlanCartItem) return

      await setCartApi({
        schedule: cart.isScheduled,
        useCredit: cart.creditUsed != null,
        // In case of setTrial, setCartApi discount code reinjection is no enough
        // as it only reinject the code for valid carts. (Codes cannot be applied for Trials)
        // Therefore, we reinject it manually.
        discountCode: cart.discount?.code,
        items: [
          {
            signPlan: {
              id: signPlanCartItem.shopItemId,
              flowType: isTrial
                ? SignPlanPurchaseFlowType.Trial
                : SignPlanPurchaseFlowType.ChangePlan,
              itemAmount: signPlanCartItem.totalSeats.value,
            },
          },
        ],
      })
    },
    [cart.creditUsed, cart.discount?.code, cart.isScheduled, setCartApi, signPlanCartItem]
  )

  return {
    store,
    updateStore,
    submit,
    setCart,
    setTrial,
  }
}
