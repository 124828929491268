import { Box, CountryCode, useOnMount } from '@alohi/flow-ui'
import {
  FaxNumberType,
  Period,
  PlanType,
  SetFaxNumberCartItemInput,
  SetFaxReplacementNumberCartItemInput,
} from 'api/gql/generated/graphql'
import { PriceType } from 'components/fax/FaxNumberSection/components/NumbersList/components/UniqueNumber/UniqueNumber'
import { FaxNumberSection } from 'components/fax/FaxNumberSection/FaxNumberSection'
import { useCartApi } from 'contexts/cart/api'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useActiveFaxSubscription } from 'hooks/useActiveSubscription/hooks/useActiveFaxSubscription'
import { useFaxNumberRequirementsCompliance } from 'hooks/useFaxNumberRequirementsCompliance/useFaxNumberRequirementsCompliance'
import { useFaxSupportedCountries } from 'hooks/useFaxSupportedCountries/useFaxSupportedCountries'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { tss } from 'tss-react'
import { useViewApi } from '../../context/api'

interface ChangeNumberSectionProps {
  className?: string
  id: string
}

export const ChangeNumberSection: React.FC<ChangeNumberSectionProps> = ({ className, id }) => {
  const { classes, cx } = useStyles()

  const { checkRequirementsCompliance } = useFaxNumberRequirementsCompliance()

  const { supportedCountries } = useFaxSupportedCountries()

  const { activeFaxSubscription } = useActiveFaxSubscription()
  const activeFaxSubscriptionPlan = activeFaxSubscription.computed.planItem.type
  const activeFaxSubscriptionNumber = activeFaxSubscription.computed.numbers.find(
    (number) => number.id === id
  )

  const { faxPlans } = useShopItems()
  const premiumShopItem = faxPlans.find(
    (faxPlan) => faxPlan.planType === PlanType.Premium && faxPlan.period === Period.Annual
  )

  const {
    computedItems: { faxExtraNumberCartItems },
  } = useCartApi()

  const {
    store: { numberReplacementCartInput },
    setCart,
  } = useViewApi()

  const initialCartInput: SetFaxReplacementNumberCartItemInput = {
    iso: activeFaxSubscriptionNumber?.iso ?? 'US',
    numberType: activeFaxSubscriptionNumber?.type ?? FaxNumberType.Local,
    period: activeFaxSubscriptionNumber?.period ?? Period.Annual,
    replaces: activeFaxSubscriptionNumber?.number ?? '',
  }

  useOnMount(() => {
    setCart({ faxReplacementNumber: initialCartInput })
  })

  const handleUpdateCart = async (params: {
    index?: number
    cartInput?: Maybe<Partial<SetFaxNumberCartItemInput>>
  }) => {
    try {
      const requiresPremium =
        activeFaxSubscriptionPlan === PlanType.Basic &&
        !checkRequirementsCompliance({
          country:
            (params.cartInput?.iso as CountryCode) ??
            (numberReplacementCartInput?.iso as CountryCode),
          numberType: params.cartInput?.numberType ?? numberReplacementCartInput?.numberType,
          planType: activeFaxSubscriptionPlan,
        })

      await setCart({
        faxReplacementNumber: {
          ...initialCartInput,
          ...numberReplacementCartInput,
          ...params.cartInput,
        },
        ...(requiresPremium && premiumShopItem
          ? {
              faxPlan: {
                id: premiumShopItem.id,
              },
            }
          : undefined),
      })
    } catch {
      // Error caught in setCart
    }
  }

  if (!numberReplacementCartInput) return
  return (
    <Box className={cx(classes.base, className)}>
      <FaxNumberSection
        supportedCountries={supportedCountries}
        numberCartInputs={[numberReplacementCartInput]}
        numberCartItems={faxExtraNumberCartItems ?? []}
        onUpdateCart={handleUpdateCart}
        planType={activeFaxSubscription.computed.planItem.type ?? PlanType.Basic}
        disabledMinimumPlanTypeRequirement
        priceType={PriceType.replacement}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
