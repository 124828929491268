import {
  AnimatePresenceBox,
  Box,
  colors,
  Spinner,
  useBool,
  useDependencyChange,
} from '@alohi/flow-ui'
import { useAppApi } from 'contexts/app/api'
import { tss } from 'tss-react'

export const InitOverlay: React.FC = () => {
  const { classes } = useStyles()

  const {
    store: { isAppReady },
  } = useAppApi()

  const [isInitOverlayVisible, isInitOverlayVisibleBool] = useBool(true)

  // Make sure InitOverlay detect isAppReady change before disappearing
  useDependencyChange(() => {
    if (isAppReady) {
      isInitOverlayVisibleBool.setFalse()
    } else {
      isInitOverlayVisibleBool.setTrue()
    }
  }, [isAppReady])

  return (
    <AnimatePresenceBox
      transition={{ duration: 0.5 }}
      isVisible={isInitOverlayVisible}
      initial={{
        zIndex: 99999,
      }}
    >
      <Box className={classes.overlay}>
        {isAppReady ? null : <Spinner size="large" color={colors.blue500} />}
      </Box>
    </AnimatePresenceBox>
  )
}

const useStyles = tss.create(() => ({
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: colors.neutral0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
