import { ReactNode } from 'react'
import { AppContextProvider } from './app/AppContextProvider'
import { CartContextProvider } from './cart/CartContextProvider'
import { PaymentMethodContextProvider } from './paymentMethod/PaymentMethodContextProvider'

interface GlobalContextsProviderProps {
  children: ReactNode
}

export function GlobalContextsProvider({ children }: GlobalContextsProviderProps) {
  return (
    <AppContextProvider>
      <PaymentMethodContextProvider>
        <CartContextProvider>
          <>{children}</>
        </CartContextProvider>
      </PaymentMethodContextProvider>
    </AppContextProvider>
  )
}
