import { Box, colors, formatNumber, Icon, ListItem, spacing, Text } from '@alohi/flow-ui'
import { faArrowUpLong } from '@fortawesome/pro-light-svg-icons'
import { tss } from 'tss-react'

interface FaxSubstitutedNumberProps {
  className?: string
  number: string
  requestedNumber: string | undefined | null
}

export const FaxSubstitutedNumber: React.FC<FaxSubstitutedNumberProps> = ({
  className,
  number,
  requestedNumber,
}) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.base, className)}>
      <ListItem>
        {requestedNumber ? (
          <Box className={classes.itemContent}>
            <Text className={classes.requestedNumber}>
              {formatNumber(requestedNumber, 'INTERNATIONAL')}
            </Text>
            <Icon className={classes.arrow} icon={faArrowUpLong} />
            <Text>{formatNumber(number, 'INTERNATIONAL')}</Text>
          </Box>
        ) : (
          <Text>{formatNumber(number, 'INTERNATIONAL')}</Text>
        )}
      </ListItem>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  itemContent: {
    display: 'flex',
    alignItems: 'center',
  },
  requestedNumber: {
    color: colors.neutral400,
    textDecoration: 'line-through',
  },
  arrow: {
    transform: 'rotate(90deg)',
    marginLeft: spacing['4'],
    marginRight: spacing['4'],
  },
}))
