import { useToast } from '@alohi/flow-ui'
import { Error } from 'api/gql/generated/graphql'
import { ReactNode, Reducer, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { CartContext } from './store/context'
import { CartAction, CartReducer } from './store/reducer'
import { CartStore, computeStore, initialCartComputedStore, initialCartStore } from './store/store'

interface CartContextProviderProps {
  children: ReactNode
  initialUpdate?: Partial<CartStore>
}

export function CartContextProvider({ children, initialUpdate = {} }: CartContextProviderProps) {
  const toast = useToast()
  const { t } = useTranslation()

  const [store, dispatch] = useReducer<Reducer<CartStore, CartAction>>(CartReducer, {
    ...initialCartStore,
    ...initialCartComputedStore,
    ...initialUpdate,
  })

  const computedStore = computeStore(store)

  useEffect(() => {
    switch (store.discountCodeInvalid) {
      case Error.DiscountCodeNotFound:
        {
          toast({
            description: t('error.discountCodeNotFound'),
            variant: 'error',
          })
        }
        break
      case Error.DiscountCodeNotPossibleForAmount:
        {
          toast({
            description: t('error.discountCodeNotPossibleForAmount'),
            variant: 'error',
          })
        }
        break
      case Error.DiscountCodeAlreadyUsedForUser:
        {
          toast({
            description: t('error.discountCodeAlreadyUsedForUser'),
            variant: 'error',
          })
        }
        break
      case Error.DiscountCodeIsFirstPurchaseOnly:
        {
          toast({
            description: t('error.discountCodeIsFirstPurchaseOnly'),
            variant: 'error',
          })
        }
        break
      case Error.DiscountCodeIsNonFirstPurchaseOnly:
        {
          toast({
            description: t('error.discountCodeIsNotFirstPurchaseOnly'),
            variant: 'error',
          })
        }
        break
      case Error.DiscountCodeCurrencyMismatch:
        {
          toast({
            description: t('error.discountCodeCurrencyMismatch'),
            variant: 'error',
          })
        }
        break
      case Error.DiscountCodeTotalAmountExceeded:
        {
          toast({
            description: t('error.discountCodeTotalAmountExceeded'),
            variant: 'error',
          })
        }
        break
      case Error.DiscountCodeTotalAmountNotReached:
        {
          toast({
            description: t('error.discountCodeTotalAmountNotReached'),
            variant: 'error',
          })
        }
        break
    }
    if (store.discountCodeInvalid) {
      dispatch({
        type: 'UPDATE',
        payload: {
          discountCodeInvalid: undefined,
        },
      })
    }
  }, [store.discountCodeInvalid, t, toast])

  return (
    <CartContext.Provider
      value={{
        store: {
          ...store,
          ...computedStore,
        },
        dispatch,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
