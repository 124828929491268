import { ApolloError } from '@apollo/client'
import { Error as ApiError, ErrorExtension as ApiErrorExtensions } from 'api/gql/generated/graphql'

export type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T
}
export type ApiErrorHandler = PartialRecord<
  ApiError,
  (extensions: Record<ApiErrorExtensions, unknown>) => void
>

export function handleApiErrors(
  error: unknown,
  handlers: {
    default: () => void
    errors?: ApiErrorHandler
  }
) {
  let hasUncaughtError = false

  if (error instanceof ApolloError) {
    if (!error.graphQLErrors.length) {
      handlers.default()
    }

    error.graphQLErrors?.forEach((graphQLError) => {
      const errorHandler = handlers.errors?.[graphQLError.message as ApiError]

      if (errorHandler) {
        errorHandler(graphQLError.extensions as Record<ApiErrorExtensions, unknown>)
      } else {
        hasUncaughtError = true
      }
    })

    if (hasUncaughtError) {
      handlers.default()
    }
  } else {
    handlers.default()
  }
}

export const getField = <T>(field: T | null | undefined): T => {
  if (!field) {
    throw new Error(`Failed to get the requested field`)
  }
  return field
}
