import { SignPlusQesShopItem } from 'api/gql/generated/graphql'

export interface ViewDataStore {
  selectedShopItem: SignPlusQesShopItem | null
}

export const initialViewStore: ViewDataStore = {
  selectedShopItem: null,
}

export interface ViewComputedStore {
  computed: boolean
}

export const initialViewComputedStore: ViewComputedStore = {
  computed: false,
}

export type ViewStore = ViewDataStore & ViewComputedStore

export const computeStore = (store: ViewStore): ViewComputedStore => ({
  computed: Boolean(store),
})
