import { Input, useInput } from '@alohi/flow-ui'
import { testIds } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const NEW_PAYMENT_STREET_INPUT_ID = 'new-payment-street'

export interface StreetInputProps {
  className?: string
}

export const StreetInput: React.FC<StreetInputProps> = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const streetChecker = (street: string) => {
    if (!street) {
      return `${t('payment.streetAddress')} ${t('common.required')}`
    }
  }
  const [street, streetInputProps] = useInput<string>({
    id: NEW_PAYMENT_STREET_INPUT_ID,
    initialValue: '',
    validator: streetChecker,
  })

  return (
    <Input
      dataCy={testIds.newPaymentMethodForm.creditCard.street}
      className={cx(classes.base, className)}
      label={t('payment.streetAddress')}
      placeholder={t('payment.streetAddress')}
      value={street}
      {...streetInputProps}
    />
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
