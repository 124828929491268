import { Box, Text } from '@alohi/flow-ui'
import { formatDefaultDate } from 'helpers/date'
import { tss } from 'tss-react'

export interface AcquiredOnProps {
  className?: string
  acquiredOn: number | undefined | null
}

export const AcquiredOn: React.FC<AcquiredOnProps> = ({ className, acquiredOn }) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.base, className)}>
      {acquiredOn ? <Text>{formatDefaultDate(acquiredOn)}</Text> : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    display: 'flex',
    alignItems: 'center',
  },
}))
