export interface RoutesDataStore {
  history: string[]
}

export const initialRoutesStore: RoutesDataStore = {
  history: [],
}

export interface RoutesComputedStore {
  previousRoute: string | undefined
}

export const initialRoutesComputedStore: RoutesComputedStore = {
  previousRoute: undefined,
}

export type RoutesStore = RoutesDataStore & RoutesComputedStore

export const computeStore = (store: RoutesStore): RoutesComputedStore => ({
  previousRoute: store.history.length
    ? store.history[store.history.length - 1]
    : undefined,
})
