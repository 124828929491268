import { Box, Helper, Link, spacing, Text, Tooltip, useBool } from '@alohi/flow-ui'
import { FaxPlusNumberCartItem } from 'api/gql/generated/graphql'
import { formatCurrency } from 'helpers/payment'
import { useCustomer } from 'hooks/useCustomer/useCustomer'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { ItemPrice } from '../ItemPrice'
import { NumbersDetailModal } from './modals/NumbersDetailModal/NumbersDetailModal'

interface FaxPlusNumberItemGroupProps {
  className?: string
  items: FaxPlusNumberCartItem[]
  hasCompensation?: boolean
  isAnnual?: boolean
}
export const FaxPlusNumberItemGroup: React.FC<FaxPlusNumberItemGroupProps> = ({
  className,
  items,
  hasCompensation,
  isAnnual,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    customer: {
      credit: { currency },
    },
  } = useCustomer()

  const [isNumbersDetailModalOpen, isNumbersDetailModalOpenBool] = useBool(false)

  const totalPrice = items.reduce((acc, current) => {
    return acc + current.totalPrice
  }, 0)

  const Label = useMemo(
    () => (
      <Box className={classes.tooltipContent}>
        <Text variant="small">
          {isAnnual
            ? t('faxNumber.eachAdditionalFaxLineCostsAnnual', {
                value_1: formatCurrency({ amount: items?.[0]?.totalPrice ?? 0, currency }),
              })
            : t('faxNumber.eachAdditionalFaxLineCosts', {
                value_1: formatCurrency({ amount: items?.[0]?.totalPrice ?? 0, currency }),
              })}
        </Text>
        {hasCompensation ? (
          <Text className={classes.tooltipExtraInfo} variant="small">
            {t('faxNumber.youWillBeCompensatedForRemainingMonths')}
          </Text>
        ) : null}
        <Link
          variant="small"
          color="light"
          className={classes.link}
          onClick={isNumbersDetailModalOpenBool.setTrue}
        >
          {t('faxNumber.viewAllMyNumbers')}
        </Link>
      </Box>
    ),
    [
      classes.link,
      classes.tooltipContent,
      classes.tooltipExtraInfo,
      currency,
      hasCompensation,
      isAnnual,
      isNumbersDetailModalOpenBool.setTrue,
      items,
      t,
    ]
  )

  if (items.length === 0) return
  return (
    <Box className={cx(classes.base, className)}>
      <Text isBold>
        {t('faxNumber.additionnalFaxNumbers', {
          value_1: items.length,
        })}
      </Text>
      <Tooltip className={classes.tooltip} label={Label}>
        <Helper className={classes.helper} variant="small" />
      </Tooltip>
      <ItemPrice totalPrice={totalPrice} />
      {isNumbersDetailModalOpen ? (
        <NumbersDetailModal onClose={isNumbersDetailModalOpenBool.setFalse} items={items} />
      ) : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    paddingTop: spacing['16'],
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipContent: {
    padding: spacing['8'],
    // This make sure that the width will stick to the browser width for tooltip responsiveness
    width: '50vw',
    maxWidth: '290px',
  },
  helper: {
    marginLeft: spacing['8'],
  },
  link: {
    marginTop: spacing['8'],
    textWrap: 'nowrap',
    marginLeft: spacing['4'],
  },
  tooltipExtraInfo: {
    marginTop: spacing['8'],
  },
}))
