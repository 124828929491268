import { Modal, useNavigationModalApi, useOnMount } from '@alohi/flow-ui'
import { PaymentMethodType } from 'api/gql/generated/graphql'
import { NewPaymentMethodForm } from 'components/common/PaymentMethodSection/components/NewPaymentMethodForm/NewPaymentMethodForm'
import { usePaymentMethodApi } from 'contexts/paymentMethod/api'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface AddPaymentMethodContentProps {
  className?: string
  type: PaymentMethodType
  isLoading: boolean
  confirmTooltip: string | undefined
  isConfirmDisabled: boolean
  onCancel: () => void
  onConfirm: () => void
  onPaypalApprove: (token: string) => Promise<void>
}
export const AddPaymentMethodContent: React.FC<AddPaymentMethodContentProps> = ({
  className,
  type,
  isLoading,
  confirmTooltip,
  isConfirmDisabled,
  onCancel,
  onConfirm,
  onPaypalApprove,
}) => {
  const { t } = useTranslation()

  const { updateStep, closeModal } = useNavigationModalApi()

  const {
    store: { newPaymentMethodSelectedType },
    selectNewPaymentMethodType,
  } = usePaymentMethodApi()

  useOnMount(() => {
    selectNewPaymentMethodType(type)
  })

  useEffect(() => {
    updateStep({
      title: t('payment.addMethod'),
      onCancel,
      onClose: closeModal,
      isConfirmLoading: isLoading,
      confirmTooltip: confirmTooltip,
      isConfirmDisabled: isConfirmDisabled,
      onConfirm: newPaymentMethodSelectedType === PaymentMethodType.Card ? onConfirm : undefined,
    })
  }, [
    closeModal,
    confirmTooltip,
    isConfirmDisabled,
    isLoading,
    newPaymentMethodSelectedType,
    onCancel,
    onConfirm,
    t,
    updateStep,
  ])

  return (
    <Modal.Content>
      <NewPaymentMethodForm
        className={className}
        type={newPaymentMethodSelectedType}
        onTypeSelect={selectNewPaymentMethodType}
        onPaypalApprove={onPaypalApprove}
        isPaypalLoading={isLoading}
      />
    </Modal.Content>
  )
}

export default AddPaymentMethodContent
