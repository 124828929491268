import {
  Box,
  breakpoints,
  Card,
  colors,
  Grid,
  GridItem,
  GridProps,
  IconButton,
  spacing,
  useMediaQuery,
} from '@alohi/flow-ui'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { SuccessModalContentProps } from 'components/common/OrderSummarySection/components/TotalContainer/modals/SuccessModal/components/SuccessModalContent'
import { OrderSummarySection } from 'components/common/OrderSummarySection/OrderSummarySection'
import { testIds } from 'helpers/tests'
import { ReactNode, useState } from 'react'
import { tss } from 'tss-react'
import { FlowContent } from '../FlowContent'
import { MobileFooterPurchase } from './components/MobileFooterPurchase'
import { MobileHeader } from './components/MobileHeader'

interface FlowLayoutProps {
  children: ReactNode
  className?: string
  header: ReactNode
  onProcess: () => Promise<SuccessModalContentProps | undefined>
  successRedirectionUrl?: string
}
export const FlowLayout: React.FC<FlowLayoutProps> = ({
  children,
  className,
  header,
  onProcess,
  successRedirectionUrl,
}) => {
  const { classes, cx } = useStyles()

  const { navigateBack } = useRoutesApi()

  const [isMobile] = useMediaQuery([{ key: 'sm', direction: 'down' }])
  const [step, setStep] = useState<'purchase' | 'confirm'>('purchase')

  const templateAreas: GridProps['templateAreas'] = {
    base: `"back-col content filler-col"
           "back-col summary filler-col"`,
    lg: '"back-col content summary filler-col"',
  }

  const gridTemplateColumns: GridProps['gridTemplateColumns'] = {
    base: `30px auto 30px`,
    lg: '30px auto auto 30px',
  }

  const handleMobileBack = () => {
    if (step === 'confirm') {
      setStep('purchase')
    } else {
      navigateBack()
    }
  }

  const handleMobileReview = () => {
    setStep('confirm')
  }

  return (
    <Box className={cx(classes.base, className)}>
      {isMobile ? <MobileHeader onBack={handleMobileBack} /> : null}
      {isMobile ? (
        {
          purchase: (
            <Box className={classes.mobileContainer}>
              <FlowContent header={header}>
                <Card className={isMobile ? classes.mobileContentCard : classes.contentCard}>
                  {children}
                </Card>
              </FlowContent>
            </Box>
          ),
          confirm: (
            <Box className={classes.mobileContainer}>
              <OrderSummarySection
                onProcess={onProcess}
                successRedirectionUrl={successRedirectionUrl}
              />
            </Box>
          ),
        }[step]
      ) : (
        <Grid
          className={classes.grid}
          templateAreas={templateAreas}
          gridTemplateColumns={gridTemplateColumns}
          columnGap={'32px'}
          rowGap={'32px'}
        >
          <GridItem area={'back-col'} className={classes.backCol}>
            <IconButton
              dataCy={testIds.backButton}
              className={classes.backButton}
              icon={faArrowLeft}
              onClick={navigateBack}
            />
          </GridItem>
          <GridItem area={'content'} className={classes.content}>
            <FlowContent header={header}>
              <Card className={isMobile ? classes.mobileContentCard : classes.contentCard}>
                {children}
              </Card>
            </FlowContent>
          </GridItem>
          <GridItem className={classes.summary} area={'summary'}>
            <OrderSummarySection
              onProcess={onProcess}
              successRedirectionUrl={successRedirectionUrl}
            />
          </GridItem>
          <GridItem area={'filler-col'} className={classes.fillerCol}>
            <></>
          </GridItem>
        </Grid>
      )}

      {isMobile
        ? {
            purchase: <MobileFooterPurchase onReview={handleMobileReview} />,
            confirm: <></>,
          }[step]
        : null}
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [breakpoints.down('sm')]: {
      // small viewport size to accomodate mobile views with notched areas
      height: '100svh',
      background: colors.neutral0,
    },
  },
  grid: {
    paddingTop: spacing['32'],
    paddingBottom: spacing['32'],
    paddingLeft: spacing['24'],
    paddingRight: spacing['24'],
    [breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  mobileContainer: {
    paddingTop: spacing['32'],
    paddingLeft: spacing['24'],
    paddingRight: spacing['24'],
    flex: 1,
    overflow: 'auto',
  },
  backCol: {
    paddingTop: 12,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  summary: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  fillerCol: {
    //
  },
  mobileContentCard: {
    border: 'none',
    padding: 0,
    paddingTop: spacing['24'],
  },
  contentCard: {
    //
  },
  backButton: {
    position: 'absolute',
    width: '34px',
    height: '34px',
    transition: 'background-color 0.2s ease-in-out',
    ':not(:hover):not(:active)': {
      backgroundColor: colors.neutral150,
      transition: 'background-color 0.2s ease-in-out',
    },
    ':hover': {
      backgroundColor: colors.neutral200,
    },
    ':active': {
      backgroundColor: colors.neutral300,
      transition: 'none',
    },
  },
}))
