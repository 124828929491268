import { Box, Button, colors, spacing, Text } from '@alohi/flow-ui'
import { useRoutesApi } from 'app/components/Routes/context/api'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

export const FailedAuth: React.FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { navigateBack } = useRoutesApi()

  return (
    <Box className={classes.base}>
      <Text className={classes.title} variant="h2">
        {t('failedAuth.title')}
      </Text>
      <Text className={classes.header} isBold>
        {t('failedAuth.header')}
      </Text>
      <Text className={classes.description}>{t('failedAuth.description')}</Text>
      <Button
        className={classes.backButton}
        variant="tertiary"
        size="large"
        onClick={() => navigateBack()}
      >
        {t('common.back')}
      </Button>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    background: colors.neutral0,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    color: colors.blue500,
  },
  header: {
    marginTop: spacing['8'],
    fontSize: '35px',
    lineHeight: '42px',
    textAlign: 'center',
  },
  description: {
    marginTop: spacing['16'],
  },
  backButton: {
    marginTop: spacing['24'],
  },
}))
