import { Box, Text } from '@alohi/flow-ui'
import { Period } from 'api/gql/generated/graphql'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'

interface HighVolumeLabelProps {
  className?: string
  isInInput?: boolean
  period: Period
}

export const HighVolumeLabel: React.FC<HighVolumeLabelProps> = ({
  className,
  isInInput,
  period,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const label = isInInput
    ? `Enterprise High Volume (${period === Period.Annual ? t('plans.annual') : t('plans.monthly')})`
    : 'Enterprise High Volume'

  return (
    <Box className={cx(classes.base, className)}>
      <Text variant="h3">{label}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
}))
