import {
  Box,
  Chip,
  colors,
  SelectPlan,
  SelectPlanProps,
  spacing,
  Text,
  useBool,
} from '@alohi/flow-ui'
import { faMessages } from '@fortawesome/pro-solid-svg-icons'
import { Period, PlanType, SignPlanPurchaseFlowType } from 'api/gql/generated/graphql'
import { useCartApi } from 'contexts/cart/api'
import { testIds } from 'helpers/tests'
import { websiteUrls } from 'helpers/urls'
import { useActiveSubscriptions } from 'hooks/useActiveSubscription/useActiveSubscriptions'
import { useShopItems } from 'hooks/useShopItems/useShopItems'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react'
import { useViewApi } from 'views/sign/SignPlanUpgrade/context/api'

import {
  AlohiProducts,
  PlanSelectionModal,
} from 'modals/common/PlanSelectionModal/PlanSelectionModal'
import { Perks } from './components/Perks'
import { PlanDescription } from './components/PlanDescription'
import { PlanLabel } from './components/PlanLabel'
import { TrialSwitch } from './components/TrialSwitch'

interface SignPlanSelectSectionProps {
  className?: string
  autoOpenPlans?: boolean
}

export const SignPlanSelectSection: React.FC<SignPlanSelectSectionProps> = ({
  className,
  autoOpenPlans,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const { signPlans } = useShopItems()

  const {
    computedItems: { signPlanCartItem },
  } = useCartApi()
  const cartItemSeats = signPlanCartItem?.totalSeats.value ?? 1
  const cartItemHasApi = signPlanCartItem?.hasApi

  const {
    store: { selectedShopItem },
    updateStore,
    setCart,
  } = useViewApi()
  const {
    activeSignSubscription: {
      planItem: { shopItemId },
    },
  } = useActiveSubscriptions()

  const [isPlansModalOpen, isPlansModalOpenBool] = useBool(autoOpenPlans ?? false)

  const tabHeaders: SelectPlanProps['tabHeaders'] = useMemo(
    () => ({
      annual: (
        <Box className={classes.tabContainer} dataCy={testIds.selectPlan.annualTab}>
          <Text>{t('plans.annual')}</Text>
          <Chip
            className={classes.savePercentChip}
            label={t('purchase.savePercentUpTo', { value_1: 40 })}
          />
        </Box>
      ),
      monthly: (
        <Box className={classes.tabContainer} dataCy={testIds.selectPlan.monthlyTab}>
          <Text>{t('plans.monthly')}</Text>
        </Box>
      ),
    }),
    [classes.savePercentChip, classes.tabContainer, t]
  )

  const selectItems: SelectPlanProps['items'] = useMemo(
    () =>
      signPlans
        ?.filter((shopItem) => Boolean(cartItemHasApi) === shopItem.hasApi)
        .map((shopItem) => {
          const isActiveItem = shopItem.id === shopItemId
          const isApiEnterprise = shopItem.hasApi && shopItem.planType === PlanType.Enterprise

          return {
            id: shopItem.id,
            period: shopItem.period === Period.Annual ? 'ANNUALLY' : 'MONTHLY',
            label: <PlanLabel period={shopItem.period} item={shopItem} />,
            inputLabel: <PlanLabel period={shopItem.period} item={shopItem} isInInput />,
            description: <PlanDescription item={shopItem} />,
            isDisabled: Boolean(shopItem.notAvailable),
            rightContainer: isActiveItem ? (
              <Chip className={classes.activeChip} label={t('purchase.currentPlan')} />
            ) : isApiEnterprise ? (
              <Chip
                color="gray"
                icon={faMessages}
                className={classes.apiEnterpriseChip}
                label={t('purchase.requestQuote')}
              />
            ) : null,
          } satisfies SelectPlanProps['items'][0]
        }) ?? [],
    [cartItemHasApi, classes.activeChip, classes.apiEnterpriseChip, signPlans, shopItemId, t]
  )

  const handleSelectItemId = useCallback(
    async (id: string) => {
      try {
        if (selectedShopItem && id === selectedShopItem.id) return

        const newShopItem = signPlans.find((item) => item.id === id)
        if (!newShopItem) return

        if (newShopItem.hasApi && newShopItem.planType === PlanType.Enterprise) {
          window.open(websiteUrls.alohi.contactSales, '_blank')
          return
        }

        const newSeats = Math.max(
          Math.min(newShopItem.limits?.maxAmount ?? 1, cartItemSeats),
          newShopItem.limits?.minAmount ?? 1
        )

        updateStore({
          selectedShopItem: newShopItem,
        })
        await setCart({
          id,
          flow: signPlanCartItem?.isTrial
            ? SignPlanPurchaseFlowType.Trial
            : SignPlanPurchaseFlowType.ChangePlan,
          seats: newSeats,
        })
      } catch (error) {
        // Error caught in stores
      }
    },
    [cartItemSeats, signPlans, selectedShopItem, setCart, signPlanCartItem?.isTrial, updateStore]
  )

  return (
    <Box className={cx(classes.base, className)}>
      <SelectPlan
        selectedItemId={selectedShopItem?.id}
        onSelectItemId={handleSelectItemId}
        items={selectItems}
        tabHeaders={tabHeaders}
        onComparePlansClick={isPlansModalOpenBool.setTrue}
        dataCys={testIds.selectPlan}
      />
      {isPlansModalOpen ? (
        <PlanSelectionModal onClose={isPlansModalOpenBool.setFalse} product={AlohiProducts.SIGN} />
      ) : null}
      {selectedShopItem?.trialAllowed ? <TrialSwitch className={classes.trialSwitch} /> : null}
      <Perks
        className={classes.perks}
        requestSignature={selectedShopItem?.requestSignature}
        planType={selectedShopItem?.planType}
        hasApi={selectedShopItem?.hasApi}
        onComparePlans={isPlansModalOpenBool.setTrue}
      />
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  savePercentChip: {
    marginLeft: spacing['8'],
    fontWeight: 'normal',
  },
  perks: {
    marginTop: spacing['24'],
  },
  trialSwitch: {
    marginTop: spacing['24'],
  },
  activeChip: {
    whiteSpace: 'nowrap',
    background: colors.neutral300,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.neutral400,
  },
  apiEnterpriseChip: {
    whiteSpace: 'nowrap',
    padding: spacing['4'],
    paddingLeft: spacing['8'],
    paddingRight: spacing['8'],
  },
}))
