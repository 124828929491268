import { Error, MutationCartSetArgs } from 'api/gql/generated/graphql'
import { PaymentErrorExtensions } from 'helpers/payment'

export interface CartDataStore {
  isUpdating: boolean
  isProcessing: boolean
  paymentErrorExtensions?: PaymentErrorExtensions
  cartInput: MutationCartSetArgs
  discountCodeInvalid: Error | undefined | null
}

export const initialCartStore: CartDataStore = {
  isUpdating: false,
  isProcessing: false,
  paymentErrorExtensions: undefined,
  cartInput: {
    discountCode: '',
    schedule: false,
    useCredit: false,
    items: [],
  },
  discountCodeInvalid: undefined,
}

export interface CartComputedStore {
  hasPaymentFailed: boolean
}

export const initialCartComputedStore: CartComputedStore = {
  hasPaymentFailed: false,
}

export type CartStore = CartDataStore & CartComputedStore

export const computeStore = (store: CartStore): CartComputedStore => ({
  hasPaymentFailed: Boolean(store.paymentErrorExtensions),
})
