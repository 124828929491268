import { Box, colors, Text } from '@alohi/flow-ui'
import { tss } from 'tss-react'

interface HighVolumeDescriptionProps {
  className?: string
}

export const HighVolumeDescription: React.FC<HighVolumeDescriptionProps> = ({
  className,
}) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.base, className)}>
      {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
      <Text className={classes.text}>{'Customize your Pricing'}</Text>
    </Box>
  )
}

const useStyles = tss.create(() => ({
  base: {
    //
  },
  text: {
    color: colors.neutral400,
  },
}))
